import React, { useContext, useState } from 'react'
import { LookupTableContext } from './LookupTable';
import { Draggable } from 'react-beautiful-dnd';
// import CIcon from '@coreui/icons-react';
import SelectSearchField from '../../../../../general/custom-search-select/SelectSearchField';
import { useSelector } from 'react-redux';
import { CInput, CSelect } from '../../../../../migration/CInput';
import CTooltip from '../../../../../migration/CTooltip';

const TableBodyRows = () => {
    const {
        name,
        tableRow,
        setTableRow,
        setRemoveRowModal,
        resetBtnSaveStatus,
        handleChangeRowData,
        hasError,
        // setCursorPosition,
        // toggleVariableModal,
        // setVariablePositionToInsert,
    } = useContext(LookupTableContext);

    const [hiddenClose, setHiddenClose] = useState(false); // eslint-disable-line
    const listUserSource = useSelector((state) => state.subscriber.listUserSources);

    const handleRemoveRow = (index) => {
        let data = [...tableRow];
        let flag = false;
        flag = data[index].conditions.urlParameter !== "";
        if (flag === false) {
            flag = data[index].customVariables.userSource !== "";
        }
        if (flag === true) {
            setRemoveRowModal({ show: true, index });
        } else {
            if (data.length > 1) {
                data.splice(index, 1);
                setTableRow(data);
            }
        }
        resetBtnSaveStatus();
    }

    // const onClickInsertVariable = useCallback((rowIndex, colIndex) => {
    //     setVariablePositionToInsert({ rowIndex, colIndex });
    //     toggleVariableModal();
    // }, [toggleVariableModal, setVariablePositionToInsert])

    // const handleChangeCursorPosition = (position) => {
    //     setCursorPosition(position);
    // }

    // const onHoverInsertVariable = () => {
    //     setHiddenClose(true);
    // };

    // const onLeaveInsertVariable = () => {
    //     setHiddenClose(false);
    // }

    const filterOptions = listUserSource.filter(item => !item.isHidden).map((item) => {
        return { label: item.name, value: item.name }
    })

    return (
        <>
            {
                tableRow && tableRow.map((row, rowIndex) => {
                    let conditionRow = row.conditions;
                    let cusVariableRow = row.customVariables;

                    return (
                        <Draggable key={rowIndex} draggableId={rowIndex.toString()} index={rowIndex}>
                            {
                                (provided, snapshot) => (
                                    <tr className={`table-body${snapshot.isDragging ? ' isDragging' : ''}${hasError.includes(rowIndex) ? ' hasError' : ''}${conditionRow.block ? ' block' : ''}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {
                                            name === 'Referrer Rules' ? (
                                                <td className="event-variable-value event-variable-value-ellipsis">
                                                    <span>
                                                        <i className="fas fa-ellipsis-v"></i>
                                                    </span>
                                                    <CSelect
                                                        name="variableUrlParameter"
                                                        value={conditionRow.urlParameter || 'hostname'}
                                                        className="rounded-0"
                                                        onChange={(e) => { handleChangeRowData(e.target.value, 'urlParameter', rowIndex) }}
                                                        disabled={conditionRow.block || false}
                                                    >
                                                        <option value="hostname">referring hostname</option>
                                                        <option value="url">entire referring url</option>
                                                    </CSelect>
                                                </td>
                                            ) : (
                                                <td className="event-variable-value url-parameter event-variable-value-ellipsis">
                                                    <span>
                                                        <i className="fas fa-ellipsis-v"></i>
                                                    </span>
                                                    <CInput
                                                        name="urlParameter"
                                                        value={conditionRow.urlParameter}
                                                        className="rounded-0"
                                                        onChange={(e) => { handleChangeRowData(e.target.value, 'urlParameter', rowIndex) }}
                                                        autoComplete="off"
                                                        data-lpignore="true"
                                                        disabled={conditionRow.block || false}
                                                    />
                                                </td>
                                            )
                                        }
                                        <td className="event-variable-value">
                                            <CSelect
                                                name="matchType"
                                                value={conditionRow.matchType}
                                                className="rounded-0"
                                                onChange={(e) => { handleChangeRowData(e.target.value, 'matchType', rowIndex) }}
                                                disabled={conditionRow.block || false}
                                            >
                                                <option value="ct">Contains</option>
                                                <option value="nct">Not Contain</option>
                                                <option value="eq">Equals To</option>
                                                <option value="neq">Not Equals To</option>
                                                <option value="exav">Exists with any value</option>
                                                <option value="nex">Does not exist</option>
                                            </CSelect>
                                        </td>
                                        <td className="event-variable-value value">
                                            <CInput
                                                name="value"
                                                value={conditionRow.value}
                                                className="rounded-0"
                                                onChange={(e) => { handleChangeRowData(e.target.value, 'value', rowIndex) }}
                                                autoComplete="off"
                                                data-lpignore="true"
                                                disabled={conditionRow.block || false}
                                            />
                                        </td>
                                        <td className="custom-variable-value user-source">
                                            <SelectSearchField
                                                id="userSource"
                                                value={filterOptions.find((option) => option.value === cusVariableRow.userSource) || { value: '', label: '' }}
                                                className="rounded-0"
                                                data-lpignore="true"
                                                isDisabled={conditionRow.block || false}
                                                onChange={(e) => { handleChangeRowData(e.value, 'userSource', rowIndex) }}
                                                options={filterOptions}
                                            />
                                            {/* {!conditionRow.block && (
                                                <div className="insert-variable">
                                                    <CIcon icon={hiddenClose ? 'iconInsertSortCodeActive' : 'iconInsertSortCode'} height={14} onClick={() => onClickInsertVariable(rowIndex, 0)}
                                                        onMouseEnter={() => onHoverInsertVariable()}
                                                        onMouseLeave={() => onLeaveInsertVariable()}
                                                    />
                                                </div>
                                            )} */}
                                        </td>
                                        {
                                            !conditionRow.block && (
                                                <td key={rowIndex} className={`btn-remove-row ${hiddenClose ? 'icon-close-hidden' : ''}`}>
                                                    <CTooltip
                                                        content="Remove row"
                                                        placement="bottom-start"
                                                    >
                                                        <i
                                                            className="fal fa-times text-danger"
                                                            onClick={() => handleRemoveRow(rowIndex)}
                                                        >
                                                        </i>
                                                    </CTooltip>
                                                </td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                        </Draggable>)
                })
            }
        </>
    )
}

export default TableBodyRows
