import React from 'react';

import { ADMIN_PATH } from '../constants';

const Dashboard = React.lazy(() => import('../components/cms/admin/dashboard/Dashboard'));
const Profile = React.lazy(() => import('../components/cms/admin/profile/Profile'));
const Accounts = React.lazy(() => import('../components/cms/admin/accounts/Accounts'));
const Account = React.lazy(() => import('../components/cms/admin/accounts/Account'));
const AccountDetail = React.lazy(() => import('../components/cms/admin/accounts/AccountDetail'));
const Activities = React.lazy(() => import('../components/cms/admin/activities/Activities'));
// const Events = React.lazy(() => import('../components/cms/admin/events/Events'));
// const Event = React.lazy(() => import('../components/cms/admin/events/Event'));
const Features = React.lazy(() => import('../components/cms/admin/features/Features'));
const Feature = React.lazy(() => import('../components/cms/admin/features/Feature'));
const Listeners = React.lazy(() => import('../components/cms/admin/listeners/Listeners'));
const Listener = React.lazy(() => import('../components/cms/admin/listeners/Listener'));
const ListenerAccounts = React.lazy(() => import('../components/cms/admin/listeners/ListenerAccounts'));
// const Orders = React.lazy(() => import('../components/cms/admin/orders/Orders'));
const Packages = React.lazy(() => import('../components/cms/admin/packages/Packages'));
const Package = React.lazy(() => import('../components/cms/admin/packages/Package'));
const Users = React.lazy(() => import('../components/cms/admin/users/Users'));
const User = React.lazy(() => import('../components/cms/admin/users/User'));
const ControlAdmin = React.lazy(() => import('../components/cms/admin/control/ControlAdmin'));
const Media = React.lazy(() => import('../components/cms/admin/upload/UploadMedia'));
const Redis = React.lazy(() => import('../components/cms/admin/redis/Redis'));
const ViewRedis = React.lazy(() => import('../components/cms/admin/redis/ViewRedis'));
const Term = React.lazy(() => import('../components/cms/admin/terms/Term'));
const Terms = React.lazy(() => import('../components/cms/admin/terms/Terms'));

// ===== MASTER CLASS =======
const Courses = React.lazy(() => import('../components/cms/admin/masterClass/courses/Courses'));
const AddCourse = React.lazy(() => import('../components/cms/admin/masterClass/courses/AddCourse'));
const EditCourse = React.lazy(() => import('../components/cms/admin/masterClass/courses/EditCourse'));
const AddSection = React.lazy(() => import('../components/cms/admin/masterClass/sections/AddSection'));
const EditSection = React.lazy(() => import('../components/cms/admin/masterClass/sections/EditSection'));
const Lessons = React.lazy(() => import('../components/cms/admin/masterClass/lesson/Lessons'));
const AddLesson = React.lazy(() => import('../components/cms/admin/masterClass/lesson/AddLesson'));
const EditLesson = React.lazy(() => import('../components/cms/admin/masterClass/lesson/EditLesson'));
const Certificates = React.lazy(() => import('../components/cms/admin/masterClass/certificates/Certificates'));
const AddCertificate = React.lazy(() => import('../components/cms/admin/masterClass/certificates/AddCertificate'));
const EditCertificate = React.lazy(() => import('../components/cms/admin/masterClass/certificates/EditCertificate'));
const Tests = React.lazy(() => import('../components/cms/admin/masterClass/tests/Tests'));
const EditTest = React.lazy(() => import('../components/cms/admin/masterClass/tests/EditTest'));
const AddTest = React.lazy(() => import('../components/cms/admin/masterClass/tests/AddTest'));
const AddQuestion = React.lazy(() => import('../components/cms/admin/masterClass/questions/AddQuestion'));
const EditQuestion = React.lazy(() => import('../components/cms/admin/masterClass/questions/EditQuestion'));
// const UserCoupon = React.lazy(() => import('../components/cms/admin/masterClass/coupons/UserCoupon'));
const Coupons = React.lazy(() => import('../components/cms/admin/masterClass/coupons/Coupons'));
const CouponDetail = React.lazy(() => import('../components/cms/admin/masterClass/coupons/CouponDetail'));
const Partners = React.lazy(() => import('../components/cms/admin/partners/Partners'));
const PartnerDetail = React.lazy(() => import('../components/cms/admin/partners/Partner'));
const AddOfficeHour = React.lazy(() => import('../components/cms/admin/officeHour/AddOfficeHour'));
const OfficeHours = React.lazy(() => import('../components/cms/admin/officeHour/OfficeHours'));
const EditOfficeHour = React.lazy(() => import('../components/cms/admin/officeHour/OfficeHour'));
const UsersCourse = React.lazy(() => import('../components/cms/admin/user-course/UsersCourse'));
const UsersCourseDetail = React.lazy(() => import('../components/cms/admin/user-course/UsersCourseDetail'));
// const UserPartner = React.lazy(() => import('../components/cms/admin/partners/user-partners/UserPartner'));
const GoogleCalendar = React.lazy(() => import('../components/cms/admin/google-calendar/GoogleCalendar'));
const VisitorsComponent = React.lazy(() => import('../components/cms/admin/visitors-sessions/Visitors'));
const SessionsComponent = React.lazy(() => import('../components/cms/admin/visitors-sessions/Sessions'));
const Destinations = React.lazy(() => import('../components/cms/admin/destinations'));
const Destination = React.lazy(() => import('../components/cms/admin/destinations/Destination'));
const DestinationAccounts = React.lazy(() => import('../components/cms/admin/destinations/DestinationAccounts'));
const CustomReport = React.lazy(() => import('../components/cms/admin/custom-report/CustomReport.js'));
const Dimensions = React.lazy(() => import('../components/cms/admin/dimensions/Dimensions.js'));
const Dimension = React.lazy(() => import('../components/cms/admin/dimensions/Dimension.js'));
const Metrics = React.lazy(() => import('../components/cms/admin/metrics/Metrics.js'));
const Metric = React.lazy(() => import('../components/cms/admin/metrics/Metric.js'));
const ContractPlans = React.lazy(() => import('../components/cms/admin/contract-plans'));
const ContractPlan = React.lazy(() => import('../components/cms/admin/contract-plans/ContractPlan'));
const ContractPlanAccounts = React.lazy(() => import('../components/cms/admin/contract-plans/ContractPlanAccounts'));
const AccountCompanyMatch = React.lazy(() => import('../components/cms/admin/accounts/AccountCompanyMatch'));
const ContractPlanCompanyMatch = React.lazy(() => import('../components/cms/admin/contract-plans/ContractPlaneCompanyMatch'));
const CompanyMatches = React.lazy(() => import('../components/cms/admin/company-matches'));
const Externals = React.lazy(() => import('../components/cms/admin/externals'));
const External = React.lazy(() => import('../components/cms/admin/externals/External.js'));
const Notifications = React.lazy(() => import('../components/cms/admin/notifications/notifications.js'));

const adminRoutes = [
     { path: '/', exact: true, name: 'Dashboard', component: Dashboard },
     // { path: ADMIN_PATH.DASHBOARD, name: 'Dashboard', component: Dashboard },
     { path: ADMIN_PATH.PROFILE, name: 'Profile', component: Profile },
     { path: ADMIN_PATH.ACCOUNTS, name: 'Accounts', component: Accounts, exact: true },
     { path: ADMIN_PATH.ACCOUNTS, name: 'Accounts', component: Accounts, exact: true },

     { path: ADMIN_PATH.ACCOUNT_WITH_COMPANY_MATCH, name: 'Account Company Matches', component: AccountCompanyMatch },
     { path: ADMIN_PATH.ACCOUNTS_ADD_USER, name: 'Add User', component: Account },
     { path: ADMIN_PATH.PACKAGES, name: 'Packages', component: Packages, exact: true },
     { path: ADMIN_PATH.PACKAGES_CREATE, name: 'Create Package', component: Package }, // Must put /packages/create before /packages/:id
     { path: ADMIN_PATH.PACKAGES_WITH_PARAMS_ID, name: 'Edit Package', component: Package },
     { path: ADMIN_PATH.ACTIVITIES, name: 'Activities', component: Activities },
     { path: ADMIN_PATH.USERS, name: 'Users', component: Users, exact: true },
     { path: ADMIN_PATH.USERS_CREATE, name: 'Create User', component: User },
     { path: ADMIN_PATH.USERS_WITH_PARAMS_ID, name: 'Edit User', component: User },
     { path: ADMIN_PATH.LISTENERS, name: 'Listeners', component: Listeners, exact: true },
     { path: ADMIN_PATH.LISTENERS_CREATE, name: 'Create Listener', component: Listener },
     { path: ADMIN_PATH.LISTENERS_ACCOUNTS, name: 'View Listener Accounts', component: ListenerAccounts },
     { path: ADMIN_PATH.LISTENERS_WITH_PARAMS_ID, name: 'Edit Listener', component: Listener },
     { path: ADMIN_PATH.FEATURES, name: 'Features', component: Features, exact: true },
     { path: ADMIN_PATH.FEATURES_CREATE, name: 'Create Feature', component: Feature },
     { path: ADMIN_PATH.FEATURES_WITH_PARAMS_ID, name: 'Edit Feature', component: Feature },
     { path: ADMIN_PATH.CONTROL_ADMIN, name: 'Control Admin', component: ControlAdmin },
     { path: ADMIN_PATH.MEDIA, name: 'Media', component: Media },
     { path: ADMIN_PATH.REDIS, name: 'Redis', component: Redis, exact: true },
     { path: ADMIN_PATH.VIEW_REDIS, name: 'View Redis', component: ViewRedis },
     { path: ADMIN_PATH.TERMS, name: 'Terms', component: Terms, exact: true },
     { path: ADMIN_PATH.TERMS_CREATE, name: 'Create Terms', component: Term },
     { path: ADMIN_PATH.TERMS_WITH_PARAMS_ID, name: 'Edit Terms', component: Term },
     { path: ADMIN_PATH.ACCOUNTS_WITH_PARAMS_ID, name: 'Account Detail', component: AccountDetail },
     // { path: ADMIN_PATH.EVENTS, name: 'Events', component: Events, exact: true },
     // { path: ADMIN_PATH.EVENTS_CREATE, name: 'Create Event', component: Event },
     // { path: ADMIN_PATH.EVENTS_WITH_PARAMS_ID, name: 'Update Event', component: Event },
     // { path: ADMIN_PATH.ORDERS, name: 'Orders', component: Orders, exact: true },

     { path: ADMIN_PATH.COURSES, name: 'Courses', component: Courses, exact: true },
     { path: ADMIN_PATH.COURSES_CREATE, name: 'Create', component: AddCourse },
     { path: ADMIN_PATH.COURSES_WITH_PARAMS_ID, name: 'Edit', component: EditCourse },
     { path: ADMIN_PATH.SECTIONS, name: 'Sections', component: AddSection, exact: true },
     { path: ADMIN_PATH.SECTIONS_CREATE, name: 'Create', component: AddSection },
     { path: ADMIN_PATH.SECTIONS_WITH_PARAMS_ID, name: 'Edit', component: EditSection },
     { path: ADMIN_PATH.LESSONS, name: 'Lessons', component: Lessons, exact: true },
     { path: ADMIN_PATH.LESSONS_CREATE, name: 'Create', component: AddLesson },
     { path: ADMIN_PATH.LESSONS_WITH_PARAMS_ID, name: 'Edit', component: EditLesson },
     { path: ADMIN_PATH.CERTIFICATES, name: 'Certificates', component: Certificates, exact: true },
     { path: ADMIN_PATH.CERTIFICATES_CREATE, name: 'Create', component: AddCertificate },
     { path: ADMIN_PATH.CERTIFICATES_WITH_PARAMS_ID, name: 'Edit', component: EditCertificate },
     { path: ADMIN_PATH.TESTS, name: 'Tests', component: Tests, exact: true },
     { path: ADMIN_PATH.EDIT_QUESTION_WITH_PARAMS_ID, name: 'Edit Question', component: EditQuestion, exact: true },
     { path: ADMIN_PATH.EDIT_QUESTION_WITH_2_PARAMS_ID, name: 'Edit Question', component: EditQuestion, exact: true },
     { path: ADMIN_PATH.TESTS_CREATE_QUESTION, name: 'Question', component: AddQuestion },
     { path: ADMIN_PATH.TESTS_CREATE, name: 'Create', component: AddTest },
     { path: ADMIN_PATH.TESTS_WITH_PARAMS_ID, name: 'Edit Test', component: EditTest },
     { path: ADMIN_PATH.COUPONS, name: 'Coupons', component: Coupons, exact: true },
     { path: ADMIN_PATH.COUPON_PARTNERS_WITH_PARAMS_ID, name: 'Coupon Detail', component: CouponDetail },
     { path: ADMIN_PATH.PARTNERS, name: 'Partners', component: Partners, exact: true },
     { path: ADMIN_PATH.PARTNER_WITH_PARAMS_ID, name: 'Partner Detail', component: PartnerDetail },
     { path: ADMIN_PATH.OFFICE_HOUR, name: 'Office Hour', component: OfficeHours, exact: true },
     { path: ADMIN_PATH.OFFICE_HOUR_CREATE, name: 'Create Office Hour', component: AddOfficeHour },
     { path: ADMIN_PATH.OFFICE_HOUR_WITH_PARAMS_ID, name: 'Edit Office Hour', component: EditOfficeHour },
     { path: ADMIN_PATH.USER_COURSE, name: 'Users Course', component: UsersCourse, exact: true },
     { path: ADMIN_PATH.USER_COURSE_WITH_PARAMS_ID, name: 'User Course Detail', component: UsersCourseDetail },
     // { path: ADMIN_PATH.USER_PARTNERS_WITH_PARAMS_ID, name: 'User Partner Details', component: UserPartner },
     { path: ADMIN_PATH.GOOGLE_CALENDAR, name: 'Google Calendar', component: GoogleCalendar },
     { path: ADMIN_PATH.VISITORS, name: 'Users', component: VisitorsComponent },
     { path: ADMIN_PATH.SESSIONS, name: 'Sessions', component: SessionsComponent },
     { path: ADMIN_PATH.DESTINATIONS, name: 'Destinations', component: Destinations, exact: true },
     { path: ADMIN_PATH.DESTINATIONS_CREATE, name: 'Destination', component: Destination },
     { path: ADMIN_PATH.DESTINATIONS_EDIT, name: 'Destination', component: Destination, exact: true },
     { path: ADMIN_PATH.DESTINATIONS_ACCOUNTS, name: 'Destination Accounts', component: DestinationAccounts, exact: true },
     { path: ADMIN_PATH.CUSTOM_REPORT, name: 'Custom-report', component: CustomReport },
     { path: ADMIN_PATH.DIMENSIONS, name: 'Dimensions', component: Dimensions, exact: true },
     { path: ADMIN_PATH.DIMENSION_CREATE, name: 'Create Dimensions', component: Dimension },
     { path: ADMIN_PATH.DIMENSION_WITH_PARAMS_ID, name: 'Edit Dimension', component: Dimension },
     { path: ADMIN_PATH.METRICS, name: 'Metrics', component: Metrics, exact: true },
     { path: ADMIN_PATH.METRIC_CREATE, name: 'Create Metrics', component: Metric },
     { path: ADMIN_PATH.METRIC_WITH_PARAMS_ID, name: 'Edit Metric', component: Metric },
     { path: ADMIN_PATH.CONTRACT_PLANS, name: 'Contract Plans', component: ContractPlans, exact: true },
     { path: ADMIN_PATH.CONTRACT_PLAN_COMPANY_MATCH, name: 'Contract Plan Company Matches', component: ContractPlanCompanyMatch },
     { path: ADMIN_PATH.ACCOUNT_COMPANY_MATCH, name: 'Account Company Matches', component: AccountCompanyMatch },
     { path: ADMIN_PATH.CONTRACT_PLAN_ACCOUNTS, name: 'View Contract Plan Accounts', component: ContractPlanAccounts },
     { path: ADMIN_PATH.CONTRACT_PLANS_CREATE, name: 'Create Contract Plan', component: ContractPlan },
     { path: ADMIN_PATH.CONTRACT_PLANS_EDIT, name: 'Contract Plan Detail', component: ContractPlan },
     { path: ADMIN_PATH.COMPANY_MATCHES, name: 'Company Matches', component: CompanyMatches, exact: true },
     { path: ADMIN_PATH.EXTERNALS, name: 'Externals', component: Externals, exact: true },
     { path: ADMIN_PATH.EXTERNALS_CREATE, name: 'External', component: External },
     { path: ADMIN_PATH.EXTERNALS_EDIT, name: 'External', component: External },
     { path: ADMIN_PATH.NOTIFICATIONS, name: 'Notifications', component: Notifications },
];

export default adminRoutes;
