import { SF_FETCH_JOBS, PUSHER_UPDATE_JOBS, SF_GET_LIST_JOBS } from "./types"

export const SFGetListJobs = (data) => {
     return {
          type: SF_GET_LIST_JOBS,
          payload: data
     }
}

export const SFUpdateJobs = (data) => {
     return {
          type: PUSHER_UPDATE_JOBS,
          payload: data
     }
}
export const SFFetchJobs = (data) => {
     return {
          type: SF_FETCH_JOBS,
          payload: data
     }
}

