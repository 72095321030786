import { CCard, CCardBody, CSpinner } from '@coreui/react';
import React, { useContext, useEffect } from 'react';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_DESTINATIONS_GADS_SEND_CONVERSION_SERVER_SIDE, SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { useSelector } from 'react-redux';

const getInitialDestinationConversions = ({ conversionSendTo, accountDestination }) => {
     if (accountDestination && accountDestination.data) {
          const newConversions = [];
          accountDestination.data.conversions.forEach((conversion) => {
               if(conversionSendTo && conversionSendTo.includes(conversion.id)) {
                    conversion.sendTo = true
               }
               if (conversion.isCustom) {
                    conversion.customScript ? newConversions.push({ ...conversion }) : newConversions.push({ ...conversion, customScript: '' });
               } else {
                    newConversions.push(conversion);
               }
          });

          const newData = {
               ...accountDestination.data,
               conversions: newConversions,
          };

          return newData;
     }

     return { conversions: [] };
};

const GAdsMakeChanges = () => {
     const { stepsData, setCurrentStep, handleReloadData, handleUpdateSwitchType } = useContext(SwitchTypeWizardContext);
     const { conversionSendTo } = stepsData;
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const triggerEventEcommerce = useSelector((state) => state.subscriber.triggerEventEcommerce);
     const accountDestination = useSelector((state) => state.subscriber.accountDestination);

     const newConversion = getInitialDestinationConversions({conversionSendTo, accountDestination})
     const handleSuccess = async () => {
          // Refresh data
          handleReloadData();

          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSuccess);
          handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.getStarted }); // Reset step
     };

     const handleServerSideGadsConversions = () => {
          const postData = {
               accountId: activeAccount.id,
               conversions: newConversion.conversions,
               ecommerceEvent: triggerEventEcommerce,
               accountDestinationId: accountDestination.id
          };

          callTokenApi(API_CLIENT_DESTINATIONS_GADS_SEND_CONVERSION_SERVER_SIDE, 'POST', postData).then((response) => {
               if (response && response.status === 200) {
                    handleSuccess();
               }
          });
     };

     const handleFirstLoad = () => {
          handleServerSideGadsConversions();
     };
     useEffect(handleFirstLoad, []); //eslint-disable-line

     return (
          <SwitchTypeWizardBody>
               <CCard className='account-gtm making-changes'>
                    <CCardBody>
                         <h3>Making your changes</h3>
                         <p className='des'>We're making changes to your Conversions so they'll run Server Side. </p>
                         <div className='center-spinner text-center m-3'>
                              <CSpinner color='primary' />
                         </div>
                         <span className='foot-note'>Please be patient; this can take a couple of minutes. This screen will change once the process is complete.</span>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default GAdsMakeChanges;
