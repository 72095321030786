import React, { useContext, useState, useMemo } from 'react'
import { LookupTableContext } from './LookupTable';
import { Draggable } from 'react-beautiful-dnd';
import { deepCopyArray, jsonAndCompare } from '../../../../../../utils';
import debounce from 'lodash/debounce';
import { CInput, CInputCheckbox, CSelect } from '../../../../../migration/CInput';
import CTooltip from '../../../../../migration/CTooltip';

const CustomInput = ({ value, onChangeInput, ...rest }) => {
    const [inputValue, setInputValue] = useState(value);

    const onChange = (e) => {
        const { value } = e.target;
        setInputValue(value);
        onChangeInput(value);
    }

    useMemo(() => setInputValue(value), [value]);

    return (
        <CInput
            value={inputValue}
            onChange={onChange}
            autoComplete="off"
            data-lpignore="true"
            {...rest}
        />
    )
}

const areEqual = (prevProps, nextProps) => {
    const compare = (key) => {
        return prevProps[key].length === nextProps[key].length;
    }

    let shouldNotRender = jsonAndCompare(prevProps.row, nextProps.row) &&
        compare('eventOptions') &&
        compare('nativeBrowserStorageOptions') &&
        compare('variableOptions');

    return shouldNotRender;
}

const Row = React.memo((props) => {
    const {
        row,
        rowIndex,
        setTableRow,
        setRemoveRowModal,
        IsExisted,
        resetBtnSaveStatus,
        handleChangeRowData,
        listTriggersName
    } = props;

    const eventTypes = ["Event", "MyEventNative"];
    const variableTypes = ["Variable", "Selector"];

    const handleCheckRegexBox = (rowIndex, colIndex) => {
        setTableRow(prevTableRow => {
            let data = deepCopyArray(prevTableRow);
            let rowConditions = data[rowIndex].conditions;
            let condition = rowConditions[colIndex];
            condition.g0.isRegex = !condition.g0.isRegex;

            return data;
        });
        resetBtnSaveStatus();
    }

    const handleRemoveRow = (index) => {
        setTableRow(prevTableRow => {
            let data = [...prevTableRow];
            let flag = false;
            flag = data[index].conditions.some(condition => {
                if (condition.g0.value !== "") {
                    return true;
                }
                return false;
            });
            if (flag === false) {
                flag = data[index].customVariables.some(customVariable => {
                    if (customVariable.id !== "") {
                        return true;
                    }
                    return false;
                });
            }
            if (flag === true) {
                setRemoveRowModal({ show: true, index });
            } else {
                if (data.length > 1) {
                    data.splice(index, 1);
                    return data;
                }
            }

            return prevTableRow;
        });

        resetBtnSaveStatus();
    }

    let conditionRow = row.conditions;
    let cusVariableRow = row.customVariables;

    const onChangeInput = debounce((value, type, rowIndex, colIndex) => {
        handleChangeRowData(value, type, rowIndex, colIndex)
    }, 200);

    return (
        <Draggable key={rowIndex} draggableId={rowIndex.toString()} index={rowIndex}>
            {
                (provided, snapshot) => (
                    <tr className={`table-body ${snapshot.isDragging ? 'isDragging' : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {
                            conditionRow && conditionRow.map((col, colIndex) => {
                                let conditionValues = col.g0;
                                let find = IsExisted(conditionValues.key, conditionValues.type);
                                return (
                                    <React.Fragment key={colIndex}>
                                        {
                                            find && (
                                                <>
                                                    {
                                                        eventTypes.indexOf(conditionValues.type) > -1 && (
                                                            <td className="event-variable-value event-variable-value-ellipsis">
                                                                {
                                                                    colIndex === 0 && <span>
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </span>
                                                                }
                                                                <CSelect
                                                                    name="event"
                                                                    value={conditionValues.value}
                                                                    className="rounded-0"
                                                                    onChange={(e) => { handleChangeRowData(e.target.value, 'conditions', rowIndex, colIndex) }}
                                                                >
                                                                    <option value="" disabled hidden>Select</option>
                                                                    <option value="false">false</option>
                                                                    <option value="true">true</option>
                                                                </CSelect>
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        (variableTypes.indexOf(conditionValues.type) > -1 || conditionValues.type === "NativeBrowserStorage") && (
                                                            <td className="event-variable-value variable-column event-variable-value-ellipsis">
                                                                {
                                                                    colIndex === 0 && <span>
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </span>
                                                                }
                                                                <CustomInput
                                                                    name="variable"
                                                                    className="rounded-0"
                                                                    value={conditionValues.value}
                                                                    onChangeInput={(value) => onChangeInput(value, 'conditions', rowIndex, colIndex)}
                                                                />
                                                                <div className={`regex-group-wrapper ${conditionValues.isRegex ? 'checked' : ''}`}>
                                                                    <CInputCheckbox
                                                                        style={{appearance: 'auto'}}
                                                                        name="regexCheckbox"
                                                                        id={`regexCheckbox-${rowIndex}-${colIndex}`}
                                                                        checked={conditionValues.isRegex}
                                                                        onChange={() => handleCheckRegexBox(rowIndex, colIndex)}
                                                                    />
                                                                    <label htmlFor={`regexCheckbox-${rowIndex}-${colIndex}`}>Use RegEx</label>
                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        {
                            cusVariableRow && cusVariableRow.map((col, colIndex) => {
                                let find = true;

                                return (
                                    <React.Fragment key={colIndex}>
                                        {
                                            find && (
                                                <td className="custom-variable-value">
                                                    <CSelect
                                                        name="event"
                                                        value={col.id}
                                                        className="rounded-0"
                                                        onChange={(e) => { handleChangeRowData(e.target.value, 'customVariables', rowIndex, colIndex) }}
                                                    >
                                                        <option value="">Select</option>
                                                        {
                                                            listTriggersName.map((trigger, i) => {
                                                                return (<option key={`${trigger.id}${i}`} value={trigger.id}>{trigger.name}</option>)
                                                            })
                                                        }
                                                    </CSelect>
                                                </td>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        <td key={rowIndex} className={`btn-remove-row `}>
                            <CTooltip
                                content="Remove row"
                                placement="bottom-start"
                            >
                                <i
                                    className="fal fa-times text-danger"
                                    onClick={() => handleRemoveRow(rowIndex)}
                                >
                                </i>
                            </CTooltip>
                        </td>
                    </tr>
                )
            }
        </Draggable>
    )
}, areEqual);

const TableBodyRows = () => {
    const theContext = useContext(LookupTableContext);
    const { tableRow } = theContext;

    return (
        <>
            {
                tableRow && tableRow.map((row, rowIndex) => (
                    <Row
                        key={rowIndex}
                        row={row}
                        rowIndex={rowIndex}
                        {...theContext}
                    />
                ))
            }
        </>
    )
}

export default TableBodyRows
