import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toastError } from '../../../../utils';
import { API_CLIENT_REPORTING, CHART_COLORS, CHART_VISITOR_SESSION_COLORS } from '../../../../constants';
import { callTokenApi } from '../../../../apiCaller';
import Chart from 'react-apexcharts';
import { setEventChart, setShowBlockAccountPopup } from '../../../../actions/subscriber';
import { CSpinner } from '@coreui/react';
// import { usePrevious } from '../../../../helpers/customHooks';

const EventChart = ({ filter, fromDay, endDay, selected, keysConcept, chartLoading, setChartLoading, keysFilterMetrics, chartStateReload }) => {
    const dispatch = useDispatch();
    const eventChart = useSelector(state => state.subscriber.eventChart);
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    // const { showNewLayout, minimizeNav, showNavChild } = useSelector(state => state.subscriber.newLayout);
    // let prevState = usePrevious({ keysConcept });

    const getChartSeries = () => {
        let series = [];

        keysFilterMetrics.forEach((vs, matchedKeyConceptIndex) => {
            let chart = {
                name: vs.label,
                type: 'line',
                color: CHART_VISITOR_SESSION_COLORS[matchedKeyConceptIndex],
                data: eventChart.chartData[vs.value].map((val, index) => { return { x: eventChart.chartData.labels[index], y: val.number } })
            };
            series.push(chart);
        });

        if (eventChart.chartData.charts) {
            // Show options user selected on chart
            keysConcept.forEach(kc => {
                let matchedKeyConceptIndex = eventChart.keysConcept.findIndex(el => el.value === kc.value);

                if (matchedKeyConceptIndex > -1) {
                    let chartEvent = {
                        name: kc.label,
                        type: 'line',
                        color: CHART_COLORS[matchedKeyConceptIndex],
                        data: eventChart.chartData.charts[matchedKeyConceptIndex].map((val, index) => { return { x: eventChart.chartData.labels[index], y: val.number } })
                    };

                    series.push(chartEvent);
                }
            })
        }

        return series;
    }

    const getMax = () => {
        const charts = eventChart.chartData.charts;

        let max = 1;
        charts.forEach(chart => {
            let maxOfChart = Math.max(...chart.map(value => { return parseInt(value.number) }));

            if (maxOfChart > max) {
                max = maxOfChart;
            }
        });

        keysFilterMetrics.forEach(vs => {
            let matchedKeyConceptIndex = eventChart.keysFilterMetrics.findIndex(el => el.value === vs.value);

            if (matchedKeyConceptIndex > -1) {
                let _maxOfChart = Math.max(...eventChart.chartData[vs.value].map(value => { return parseInt(value.number) }));

                if (_maxOfChart > max) {
                    max = _maxOfChart;
                }
            }
        });

        return Math.ceil(max / 10) * 10;
    }

    const chartOptions = {
        series: getChartSeries(),
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
                stacked: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            markers: {
                size: [5],
                strokeColors: '#f3f3f3',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                showNullDataPoints: true,
                hover: {
                    size: [6],
                    sizeOffset: 3
                }
            },
            xaxis: {
                categories: eventChart.chartData.labels,
                tickAmount: 15,
                tickPlacement: 'between',
                labels: {
                    trim: true,
                    rotate: -80,
                    maxHeight: 180
                }
            },
            yaxis: {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB',
                    offsetX: -2,
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    },
                    formatter: (value) => value ? Number(value.toFixed()).toLocaleString() : value
                },
                min: 0,
                max: getMax(),
                tickAmount: 10,
            },
            colors: [...CHART_COLORS] // Need to define like this [...CHART_COLORS], or else it will always repeat the first color when all colors of the array are used
        },
    }

    const fetchData = () => {
        let apiURL = `${API_CLIENT_REPORTING}`;
        let selectedParam = `selected=${selected}`;
        let keysConceptParam = `keysConcept=${keysConcept.map(key => key.value)}`;
        let date00 = `date00=${fromDay}`;
        let date01 = `date01=${endDay}`;
        let accountIdParam = `accountId=${activeAccount.id}`;
        apiURL += `?${accountIdParam}&${date00}&${date01}&${selectedParam}&${keysConceptParam}`;
        setChartLoading(true);
        callTokenApi(`${apiURL}`, 'GET', null)
            .then(response => {
                if (response.status === 200) {
                    let data = response.data.data;

                    //  When choosing hourly and not select date, server returned 25 hours so need to display 24 hours
                    const chartData = {
                        charts: data.charts,
                        labels: data.labels,
                        visitors: data.visitors,
                        sessions: data.sessions,
                        visitorsStart: data.visitorsStart,
                        sessionsStart: data.sessionsStart
                    }

                    dispatch(setEventChart({
                        accountId: activeAccount.id,
                        selected,
                        filter: '',
                        listenerId: '',
                        keysConcept,
                        keysFilterMetrics,
                        chartData
                    }));
                } else {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true))
                    } else {
                        toastError(response)
                    }
                }
            })
            .finally(() => {
                setChartLoading(false);
            })
    }

    const fetchDataFilter = () => {
        let apiURL = `${API_CLIENT_REPORTING}`;
        let filterParam = `filter=custom`;
        let selectedParam = `selected=${selected}`;
        let keysConceptParam = `keysConcept=${keysConcept.map(key => key.value)}`;
        let date00 = `date00=${fromDay}`;
        let date01 = `date01=${endDay}`;
        let accountIdParam = `accountId=${activeAccount.id}`;
        apiURL += `?${accountIdParam}&${filterParam}&${date00}&${date01}&${selectedParam}&${keysConceptParam}`;
        setChartLoading(true);
        callTokenApi(`${apiURL}`, 'GET', null)
            .then(response => {
                if (response.status === 200) {
                    let data = response.data.data;

                    const chartData = {
                        charts: data.charts,
                        labels: data.labels,
                        visitors: data.visitors,
                        sessions: data.sessions,
                        visitorsStart: data.visitorsStart,
                        sessionsStart: data.sessionsStart
                    }
                    dispatch(setEventChart({
                        accountId: activeAccount.id,
                        selected,
                        filter: 'custom',
                        listenerId: '',
                        keysConcept,
                        keysFilterMetrics,
                        chartData
                    }));
                } else {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true))
                    } else {
                        toastError(response)
                    }
                }
            })
            .finally(() => {
                setChartLoading(false);
            })
    }

    const fetchEventChart = () => {
        // Get data when first fetching or a user selects an event option
        if (filter === 'custom') {
            fetchDataFilter();
        } else {
            fetchData();
        }
    }
    useEffect(fetchEventChart, [filter, fromDay, endDay, selected, keysConcept, chartStateReload]); // eslint-disable-line react-hooks/exhaustive-deps

    // const refreshChart = () => {
    //     setChartLoading(true);
    //     setTimeout(() => {
    //         setChartLoading(false);
    //     }, 700);
    // }
    // useEffect(refreshChart, [showNewLayout, minimizeNav, showNavChild]);

    return (
        <div className="apex-chart">
            {chartLoading === true && (
                <div className="loading-overlay text-center">
                    <CSpinner color="primary" className="loading-spinner" />
                </div>
            )}
            <Chart options={chartOptions.options} series={chartOptions.series} type="line" height={350} />
        </div>
    )
}

export default EventChart