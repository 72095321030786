import React, { useContext, useState } from 'react'
import { LookupTableContext } from './LookupTable';
import { Draggable } from 'react-beautiful-dnd';
// import CIcon from '@coreui/icons-react';
import { useSelector } from 'react-redux';
import SelectSearchField from '../../../../../general/custom-search-select/SelectSearchField';
import { CInput, CInputCheckbox } from '../../../../../migration/CInput';
import CTooltip from '../../../../../migration/CTooltip';

const TableBodyRows = () => {
    const {
        tableRow,
        setTableRow,
        setRemoveRowModal,
        IsExisted,
        resetBtnSaveStatus,
        handleChangeRowData,
        // setCursorPosition,
        // toggleVariableModal,
        // setVariablePositionToInsert,
    } = useContext(LookupTableContext);

    const variableTypes = ["Variable"];
    const [hiddenClose, setHiddenClose] = useState(false); //eslint-disable-line
    const handleCheckRegexBox = (rowIndex, colIndex) => {
        let data = [...tableRow];
        let rowConditions = data[rowIndex].conditions;
        let condition = rowConditions[colIndex];
        condition.g0.isRegex = !condition.g0.isRegex;
        setTableRow(data);
        resetBtnSaveStatus();
    }

    const handleRemoveRow = (index) => {
        let data = [...tableRow];
        let flag = false;
        flag = data[index].conditions.some(condition => {
            if (condition.g0.value !== "") {
                return true;
            }
            return false;
        });
        if (flag === false) {
            flag = data[index].customVariables.some(customVariable => {
                if (customVariable.value !== "") {
                    return true;
                }
                return false;
            });
        }
        if (flag === true) {
            setRemoveRowModal({ show: true, index });
        }
        else {
            if (data.length > 1) {
                data.splice(index, 1);
                setTableRow(data);
            }
        }
        resetBtnSaveStatus();
    }

    const listUserSource = useSelector((state) => state.subscriber.listUserSources);
    const filterOptions = listUserSource.filter(item => !item.isHidden).map((item) => {
        return { label: item.name, value: item.name }
    })

    // const onClickInsertVariable = useCallback((rowIndex, colIndex) => {
    //     setVariablePositionToInsert({ rowIndex, colIndex });
    //     toggleVariableModal();
    // }, [toggleVariableModal, setVariablePositionToInsert])

    // const handleChangeCursorPosition = (position) => {
    //     setCursorPosition(position);
    // }

    // const onHoverInsertVariable = () => {
    //     setHiddenClose(true);
    // };

    // const onLeaveInsertVariable = () => {
    //     setHiddenClose(false);
    // }

    return (
        <>
            {
                tableRow && tableRow.map((row, rowIndex) => {
                    let conditionRow = row.conditions;
                    let cusVariableRow = row.customVariables;
                    return (
                        <Draggable key={rowIndex} draggableId={rowIndex.toString()} index={rowIndex}>
                            {
                                (provided, snapshot) => (
                                    <tr className={`table-body ${snapshot.isDragging ? 'isDragging' : ''}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        {
                                            conditionRow && conditionRow.map((col, colIndex) => {
                                                let conditionValues = col.g0;
                                                let find = IsExisted(conditionValues.id, conditionValues.type);
                                                return (
                                                    <React.Fragment key={colIndex}>
                                                        {
                                                            find && (
                                                                <>
                                                                    {
                                                                        variableTypes.indexOf(conditionValues.type) > -1 && (
                                                                            <td className="event-variable-value variable-column event-variable-value-ellipsis">
                                                                                {
                                                                                    colIndex === 0 && <span>
                                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                                    </span>
                                                                                }
                                                                                <CInput
                                                                                    name="variable"
                                                                                    value={conditionValues.value}
                                                                                    className="rounded-0"
                                                                                    onChange={(e) => { handleChangeRowData(e.target.value, 'conditions', rowIndex, colIndex) }}
                                                                                    autoComplete="off"
                                                                                    data-lpignore="true"
                                                                                />
                                                                                <div className={`regex-group-wrapper ${conditionValues.isRegex ? 'checked' : ''}`}>
                                                                                    <CInputCheckbox
                                                                                        style={{ appearance: 'auto' }}
                                                                                        name="regexCheckbox"
                                                                                        id={`regexCheckbox-${rowIndex}-${colIndex}`}
                                                                                        checked={conditionValues.isRegex}
                                                                                        onChange={() => handleCheckRegexBox(rowIndex, colIndex)}
                                                                                    />
                                                                                    <label htmlFor={`regexCheckbox-${rowIndex}-${colIndex}`}>Use RegEx</label>
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        {
                                            cusVariableRow && cusVariableRow.map((col, colIndex) => {
                                                return (
                                                    <React.Fragment key={colIndex}>
                                                        {
                                                            <td className="custom-variable-value">
                                                                <SelectSearchField
                                                                    id="userSource"
                                                                    value={filterOptions.find((option) => option.value === col.value) || { value: '', label: 'Select...' }}
                                                                    className="rounded-0"
                                                                    data-lpignore="true"
                                                                    isDisabled={conditionRow.block || false}
                                                                    onChange={(e) => { handleChangeRowData(e.value, 'customVariables', rowIndex, colIndex) }}
                                                                    options={filterOptions}
                                                                />
                                                                {/* <div className="insert-variable">
                                                                    <CIcon icon={hiddenClose ? 'iconInsertSortCodeActive' : 'iconInsertSortCode'} height={14} onClick={() => onClickInsertVariable(rowIndex, colIndex)}
                                                                        onMouseEnter={() => onHoverInsertVariable()}
                                                                        onMouseLeave={() => onLeaveInsertVariable()}
                                                                    /> */}
                                                                {/* <span
                                                                        onClick={() => onClickInsertVariable(rowIndex, colIndex)}
                                                                    >
                                                                        Insert Data Layer Variable
                                                                    </span> */}
                                                                {/* </div> */}
                                                            </td>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        <td key={rowIndex} className={`btn-remove-row ${hiddenClose ? 'icon-close-hidden' : ''}`}>
                                            <CTooltip
                                                content="Remove row"
                                                placement="bottom-start"
                                            >
                                                <i
                                                    className="fal fa-times text-danger"
                                                    onClick={() => handleRemoveRow(rowIndex)}
                                                >
                                                </i>
                                            </CTooltip>
                                        </td>
                                    </tr>
                                )
                            }
                        </Draggable>
                    )
                })
            }
        </>
    )
}

export default TableBodyRows
