import { CCard } from '@coreui/react';
import debounce from 'lodash/debounce';
import React, { useContext, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// import { deepCopyArray } from '../../../../../../../utils';
import { LookupTableContext } from './FormRuleTable';
import { CInput, CSelect } from '../../../../../../migration/CInput';
import CTooltip from '../../../../../../migration/CTooltip';

const CustomInput = ({ value, onChangeInput, ...rest }) => {
     const [inputValue, setInputValue] = useState(value);

     const onChange = (e) => {
          const { value } = e.target;
          setInputValue(value);
          onChangeInput(value);
     };

     useMemo(() => setInputValue(value), [value]);

     return <CInput value={inputValue} onChange={onChange} autoComplete='off' data-lpignore='true' {...rest} />;
};

// const areEqual = (prevProps, nextProps) => {
//      let shouldNotRender = jsonAndCompare(prevProps.row, nextProps.row);

//      return shouldNotRender;
// };

const Row =(props) => {
     const { row, rowIndex, setTableRow, setRemoveRowModal, resetBtnSaveStatus, handleChangeRowData, listCustomCategories, onlyView } = props;
     const variableTypes = ['Variable', 'Selector'];

     // const handleCheckRegexBox = (rowIndex, colIndex) => {
     //      setTableRow((prevTableRow) => {
     //           let data = deepCopyArray(prevTableRow);
     //           let rowConditions = data[rowIndex].conditions;
     //           let condition = rowConditions[colIndex];
     //           condition.g0.isRegex = !condition.g0.isRegex;

     //           return data;
     //      });
     //      resetBtnSaveStatus();
     // };

     const handleRemoveRow = (index) => {
          setTableRow((prevTableRow) => {
               let data = [...prevTableRow];
               let flag = false;
               flag = data[index].conditions.some((condition) => {
                    if (condition.g0.value !== '') {
                         return true;
                    }
                    return false;
               });
               if (flag === true) {
                    setRemoveRowModal({ show: true, index });
               } else {
                    if (data.length > 1) {
                         data.splice(index, 1);
                         return data;
                    }
               }

               return prevTableRow;
          });

          resetBtnSaveStatus();
     };

     let conditionRow = row.conditions;
     let cusVariableRow = row.customVariables;

     const onChangeInput = debounce((value, type, rowIndex, colIndex) => {
          handleChangeRowData(value, type, rowIndex, colIndex);
     }, 200);

     return (
          <Draggable key={rowIndex} draggableId={rowIndex.toString()} index={rowIndex} isDragDisabled={onlyView}>
               {(provided, snapshot) => (
                    <tr
                         className={`table-body ${snapshot.isDragging ? 'isDragging' : ''}`}
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                    >
                         {conditionRow &&
                              conditionRow.map((col, colIndex) => {
                                   let conditionValues = col.g0;

                                   return (
                                        <React.Fragment key={colIndex}>
                                             <>
                                                  {(variableTypes.indexOf(conditionValues.type) > -1 ||
                                                       conditionValues.type === 'NativeBrowserStorage') && (
                                                       <td className='event-variable-value variable-column event-variable-value-ellipsis'>
                                                            {colIndex === 0 && (
                                                                 <span>
                                                                      <i className='fas fa-ellipsis-v'></i>
                                                                 </span>
                                                            )}
                                                            <CustomInput
                                                                 name='variable'
                                                                 className='rounded-0'
                                                                 value={conditionValues.value}
                                                                 onChangeInput={(value) => onChangeInput(value, 'conditions', rowIndex, colIndex)}
                                                                 disabled={onlyView}
                                                            />
                                                            {/* <div className={`regex-group-wrapper ${conditionValues.isRegex ? 'checked' : ''}`}>
                                                                 <CInputCheckbox
                                                                      style={{ appearance: 'auto'}}
                                                                      name='regexCheckbox'
                                                                      id={`regexCheckbox-${rowIndex}-${colIndex}`}
                                                                      checked={conditionValues.isRegex}
                                                                      onChange={() => handleCheckRegexBox(rowIndex, colIndex)}
                                                                      disabled={onlyView}
                                                                 />
                                                                 <label htmlFor={`regexCheckbox-${rowIndex}-${colIndex}`}>Use RegEx</label>
                                                            </div> */}

                                                            {onlyView &&
                                                                 ((colIndex === 0 && conditionValues.value.trim().length >= 30) ||
                                                                      (colIndex === 1 && conditionValues.value.trim().length) >= 60) && (
                                                                      <CCard className='block-tooltip reporting-values-tooltip condition-tooltip'>
                                                                           <div className='block-tooltip-text'>{conditionValues.value}</div>
                                                                      </CCard>
                                                                 )}
                                                       </td>
                                                  )}
                                             </>
                                        </React.Fragment>
                                   );
                              })}

                         {cusVariableRow &&
                              cusVariableRow.map((col, colIndex) => {
                                   return (
                                        <React.Fragment key={colIndex}>
                                             <td className='custom-variable-value'>
                                                  <CSelect
                                                       name='event'
                                                       value={col.id}
                                                       className='rounded-0'
                                                       onChange={(e) => {
                                                            handleChangeRowData(e.target.value, 'formCategory', rowIndex, colIndex);
                                                       }}
                                                       disabled={onlyView}
                                                  >
                                                       <option value=''>Select</option>
                                                       {listCustomCategories && 
                                                            listCustomCategories.map((category, i) => {
                                                            return (
                                                                 <option key={`${category.id}${i}`} value={category.id}>
                                                                      {category.name}
                                                                 </option>
                                                            );
                                                       })}
                                                  </CSelect>
                                             </td>
                                             <td className='custom-variable-value'>
                                                  <CInput
                                                       name='formName'
                                                       type='text'
                                                       value={col.formName}
                                                       autoComplete='off'
                                                       data-lpignore='true'
                                                       onChange={(e) => {
                                                            handleChangeRowData(e.target.value, 'formName', rowIndex, colIndex);
                                                       }}
                                                       disabled={onlyView}
                                                  />
                                             </td>
                                        </React.Fragment>
                                   );
                              })}
                         {!onlyView && (
                              <td key={rowIndex} className={`btn-remove-row `}>
                                   <CTooltip content='Remove row' placement='bottom-start'>
                                        <i className='fal fa-times text-danger' onClick={() => handleRemoveRow(rowIndex)}></i>
                                   </CTooltip>
                              </td>
                         )}
                    </tr>
               )}
          </Draggable>
     );
};

const TableBodyRows = () => {
     const theContext = useContext(LookupTableContext);
     const { tableRow, onlyView } = theContext;

     return (
          <>{tableRow && tableRow.map((row, rowIndex) => <Row onlyView={onlyView} key={rowIndex} row={row} rowIndex={rowIndex} {...theContext} />)}</>
     );
};

export default TableBodyRows;
