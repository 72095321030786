import { CButton, CForm } from '@coreui/react';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useActiveDestination } from '../../../../../../../helpers/customHooks';
import Waiting from '../../../../../../general/Loadings/Waiting';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import CFormGroup from '../../../../../../migration/CFormGroup';
import CLabel from '../../../../../../migration/CLabel';
import { CInput } from '../../../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../../../migration/CInvalidFeedback';

const validationSchema = () =>
     Yup.object().shape({
          conversionId: Yup.string().required('Required!'),
          customer_id: Yup.string().required('Required!'),
     });

const GoogleAds = ({ onSubmit, initialData }) => {
     const activeDestination = useActiveDestination();
     const initialValues = { conversionId: initialData.conversionId || '', customer_id: initialData.customer_id || '' };
     const googleAdsConnection = useSelector((state) => state.subscriber.accountExternalData.googleAdsAccounts.googleAdsConnection);

     const customerIdsOption = (googleAdsConnection && googleAdsConnection.map((item) => {
          return { label: item.name, customer_id: item.customer_id, value: item.id, parent_id: item.parent_id };
     })) || [];

     const formatOptionLabel = ({ label, customer_id, parent_id }) => (
          <div className="option-row">
               <div>
                    {label} | CID: {customer_id} {parent_id ? ` | ParentId: ${parent_id}`: ''}
               </div>
          </div>
     );

     return (
          <Formik
               initialValues={initialValues}
               onSubmit={onSubmit}
               validationSchema={validationSchema}
               validateOnChange={false}
               validateOnBlur={false}
          >
               {({ values, setValues, errors, handleSubmit, isSubmitting, handleChange }) => {
                    const handleChangeCustomer = (connection) => {
                         setValues({
                              ...values,
                              customer_id: connection.value
                         });
                    };
                    return (
                         <CForm noValidate onSubmit={handleSubmit} className={`general-setting-form ${activeDestination.alias}`}>
                              <CFormGroup>
                                   {!initialData.conversionId && (
                                        <div className="form-group-general">
                                             <div className="label-wrapper">
                                                  <CLabel>
                                                       Conversion ID <span className="text-danger">*</span>
                                                  </CLabel>
                                                  {/* <div className='tooltip-wrapper'>
                                                       <ClickInfoIconTooltip>
                                                            <p>
                                                                 Enter the measurement ID from your Google Analytics 4 stream. You can obtain this inside GA4 &gt; Admin
                                                                 &gt; Data Streams &gt; and then select your Data Stream. The measurement ID matches this pattern:
                                                                 G-ABC1234567
                                                            </p>
                                                       </ClickInfoIconTooltip>
                                                  </div> */}
                                             </div>
                                             <div className="input-field">
                                                  <CInput
                                                       name="conversionId"
                                                       value={values.conversionId}
                                                       onChange={handleChange}
                                                       invalid={!!errors.conversionId}
                                                       disabled={isSubmitting}
                                                  />
                                                  <CInvalidFeedback>{errors.conversionId}</CInvalidFeedback>
                                             </div>
                                        </div>
                                   )}
                                   {!initialData.customer_id && (
                                        <div className="form-group-general">
                                             <div className="label-wrapper">
                                                  <CLabel>
                                                       Customer ID <span className="text-danger">*</span>
                                                  </CLabel>
                                                  {/* <div className='tooltip-wrapper'>
                                                       <ClickInfoIconTooltip>
                                                            <p>
                                                                 Enter the API secret from your Google Analytics 4 stream. You can obtain this inside GA4 &gt; Admin &gt;
                                                                 Data Streams &gt; and then select your Measurement Protocol API secrets.
                                                            </p>
                                                       </ClickInfoIconTooltip>
                                                  </div> */}
                                             </div>

                                             <div className="input-field">
                                                  <Select
                                                       className={`customerId ${errors.customerId ? 'is-invalid' : ''}`}
                                                       options={customerIdsOption}
                                                       value={customerIdsOption.filter((item) => item.value === values.customer_id)}
                                                       formatOptionLabel={formatOptionLabel}
                                                       invalid={!!errors.customerId}
                                                       disabled={isSubmitting}
                                                       onChange={(connection) => handleChangeCustomer(connection)}
                                                  />
                                                  <CInvalidFeedback>{errors.customerId}</CInvalidFeedback>
                                             </div>
                                        </div>
                                   )}
                              </CFormGroup>
                              <div className="save-changes-button-wrapper">
                                   <CButton type="submit" color="primary" disabled={isSubmitting}>
                                        <Waiting isLoading={isSubmitting}>Continue</Waiting>
                                   </CButton>
                              </div>
                         </CForm>
                    );
               }}
          </Formik>
     );
};

export default GoogleAds;
