import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CSpinner } from '@coreui/react'; //eslint-disable-line
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setFlexibleModal } from '../../../../../actions/common';
import { SUBSCRIBER_PATH } from '../../../../../constants';
import { ClickInfoIconTooltip } from '../../../../general/custom-tooltips';
import CSwitch from '../../../../migration/CSwitch';


function ManageGoalItems({ goalTypes }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const activeAccount = useSelector((state) => state.subscriber.activeAccount);

    const handleLinkToPageDetail = (alias) => {
        history.push(`${SUBSCRIBER_PATH.GOALS.replace(':secondId', activeAccount.secondId)}/${alias}`);
        dispatch(setFlexibleModal({ show: false, component: '' }));
    };

    return (
        <div className='goals-wrapper'>
            <div className='goals-items row-items'>
                {goalTypes.map((goalType) => {
                    const { name, description, configStatus, status } = goalType;
                    // let iconSrc = getListenerColorIcon(listener);

                    return (
                        <div className='goals-item' key={name}>
                            <CCard>
                                <CCardBody>
                                    <div className='card-title d-flex justify-content-between w-100'>
                                        <div className='d-flex align-items-center' style={{ gap: '10px' }}>
                                            <CIcon icon={goalType.icon} height={20} />
                                            <h4 className='m-0'>{name}</h4>
                                        </div>
                                    </div>

                                    <div className='card-text h-100'>{description && <p className='f-14'>{description}</p>}</div>
                                    <div className={`card-icon d-flex justify-content-end`}>
                                        <div className='card-header-actions'>
                                            {configStatus && !goalType.comingSoon && (
                                                <>
                                                    <CButton className='action-open-menu' onClick={() => handleLinkToPageDetail(goalType.alias)} style={{ padding: 0 }}>
                                                        <CIcon icon='cil-settings'/>
                                                    </CButton>
                                                    <div style={{ position: 'relative' }}>
                                                        <ClickInfoIconTooltip placement='bottom' >
                                                            <p>{goalType.information}</p>
                                                        </ClickInfoIconTooltip>
                                                    </div>
                                                </>
                                            )}
                                            {/* {isLoading && ['eCommerce'].includes(goalType.name) && <CSpinner color='primary' size='sm' />} */}
                                            {goalType.comingSoon && <span className='coming-soon-text'>Coming soon</span>}
                                            <CSwitch
                                                className='float-right mb-0'
                                                color={status ? 'success' : 'light'}
                                                checked={!!status} // !!false = false, !!undefined = false, !!true = true
                                                value={status ? 'on' : 'off'}
                                                size='lg'
                                                tabIndex='0'
                                                shape='pill'
                                                // onChange={(e) => toggleListener(listener, e.target.value)}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </div>
                    );
                })}
                {/* <InstructionPopup show={learnVideoIndex > -1} onClose={() => setLearnVideoIndex(-1)} {...learnVideoPopupAttrs} />
                <SwitchPopup show={turningOnPopup} isLoading={isLoading} onAccept={acceptSwitching} onCancel={() => setTurningOnPopup(false)}>
                    <h2>Enable Listener</h2>
                    <div className='confirm-save-content'>You're about to enable this listener. You will still need to publish your changes before this goes live.</div>
                </SwitchPopup>
                <SwitchPopup show={turningOffPopup} onAccept={acceptSwitching} onCancel={() => setTurningOffPopup(false)}>
                    <h2>Disable Listener</h2>
                    <div className='confirm-save-content'>
                        Once a listener is disabled, all settings and rules for it will be lost and cannot be restored. Are you sure you want to make this change?
                    </div>
                </SwitchPopup>
                <VariableInRules show={relatedMessage ? true : false} onClose={() => setRelatedMessage('')}>
                    <p>{relatedMessage}</p>
                </VariableInRules> */}
            </div>
        </div>
    );
}

export default ManageGoalItems;
