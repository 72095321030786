import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CRow } from '@coreui/react';
import { Formik } from 'formik';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { setRuleHaveEditting, setViewedRule } from '../../../../../../actions/common';
import { fetchConversionRules, fetchTriggerRules, setConversionRules, setLoadings } from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_ACCOUNT_RULE, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../constants';
import { clearConversionDraft, toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { RuleContext } from '../EditLookupSetting';
import { CInput, CTextarea } from '../../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';

const EditStep2 = ({ stepsData, setStepsData }) => {
     const dispatch = useDispatch();
     const { activeStep, setActiveStep, setRule } = useContext(RuleContext);
     const [saveChangeModal, setSaveChangeModal] = useState(false);
     const [tempData, setTempData] = useState(false);
     const [saveLoading, setSaveLoading] = useState(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const rules = useSelector((state) => state.subscriber.conversionRules);
     const triggerRules = useSelector((state) => state.subscriber.triggerRules);
     const rulesLoading = useSelector((state) => state.subscriber.loadings.conversionRules);
     const initialValues = {
          name: stepsData.name,
          description: stepsData.description,
     };

     const fetchRules = () => {
          if (rules.length === 0 && activeStep === 2) {
               dispatch(fetchConversionRules(activeAccount.id));
          } else {
               dispatch(setLoadings({ conversionRules: false }));
          }

          if (triggerRules.length === 0 && activeStep === 2) {
               dispatch(fetchTriggerRules(activeAccount.id));
          }
     };

     useEffect(fetchRules, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

     const validationSchema = useCallback(() => {
          let ruleNames = rules.map((rule) => rule.name);

          // stepsData.name has value when editting rule
          if (stepsData.name) {
               // Find stepsData.name in ruleNames and remove it from ruleNames
               let ruleNameIndex = -1;

               ruleNames.some((ruleName, index) => {
                    if (stepsData.name === ruleName) {
                         ruleNameIndex = index;
                         return true;
                    }

                    return false;
               });

               if (ruleNameIndex !== -1) {
                    ruleNames.splice(ruleNameIndex, 1);
               }
          }

          return Yup.object().shape({
               name: Yup.string()
                    .trim()
                    .required('Name is required')
                    .max(200, 'Name cannot be more than 200 characters')
                    // .notOneOf(ruleNames, 'Existed name, please choose another name'),
          });
     }, [rules, stepsData.name]);

     const onSubmit = ({ name, description }, { setSubmitting }) => {
          setSubmitting(false);
          const data = {
               name: name.trim(),
               description: description.trim(),
          };

          if (JSON.stringify(initialValues) === JSON.stringify(data)) {
               dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
               setActiveStep(3);
          } else {
               setTempData(data);
               setSaveChangeModal(true);
          }
     };

     const onAccept = () => {
          setSaveLoading(true);
          const newStepsData = { ...stepsData, ...tempData };

          callTokenApi(API_CLIENT_ACCOUNT_RULE, 'PUT', newStepsData).then((response) => {
               setSaveLoading(false);
               setSaveChangeModal(false);

               if (response.status === 200) {
                    let newRules = [...rules];
                    let editedRuleIndex = findIndex(rules, { id: newStepsData.id });

                    if (newRules[editedRuleIndex].name !== newStepsData.name || newRules[editedRuleIndex].description !== newStepsData.description) {
                         // Update name for custom rules so we can validate rule name correctly
                         newRules[editedRuleIndex].name = newStepsData.name;
                         newRules[editedRuleIndex].description = newStepsData.description;
                         setConversionRules(newRules);
                    }

                    if (JSON.stringify(newRules[editedRuleIndex].customVariables) !== JSON.stringify(newStepsData.customVariables)) {
                         // Update name for custom rules so we can validate rule name correctly
                         newRules[editedRuleIndex].customVariables = newStepsData.customVariables;
                         setConversionRules(newRules);
                    }

                    setRule(newStepsData);
                    setStepsData(newStepsData);
                    toast.success('Successfully update rule');
                    dispatch(setViewedRule(null, true));
                    setActiveStep(3);
                    dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                    if (activeAccount.id) {
                         clearConversionDraft(activeAccount.id);
                    }
               } else {
                    toastError(response);
               }
          });
     };

     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ showLv2: true, typeLv2: TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP }));
     };

     return (
          <CCard className={`cvr-step-2 ${activeStep !== 2 ? 'difference-step' : 'cvr-step-card'}`}>
               <CCardHeader>
                    {activeStep !== 2 ? (
                         <div className="rule-step d-inline-flex justify-content-between w-100">
                              <h5 className="mb-0 inactive">Step 2: Conversion Name & Description</h5>
                              {stepsData.name && (
                                   <div className="d-inline-flex align-items-center">
                                        <span className="given-data mr-3">{stepsData.name}</span>
                                        <CButton className="btn-edit" onClick={() => setActiveStep(2)}>
                                             Edit
                                        </CButton>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5 className="">Step 2: Conversion Name & Description</h5>
                    )}
               </CCardHeader>
               {activeStep === 2 && (
                    <CCardBody>
                         {rulesLoading ? (
                              <CenterSpinner />
                         ) : (
                              <Formik
                                   initialValues={initialValues}
                                   validationSchema={validationSchema}
                                   onSubmit={onSubmit}
                                   validateOnChange={false}
                                   validateOnBlur={false}
                              >
                                   {({ values, errors, handleChange, handleSubmit, setErrors, isSubmitting }) => (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CRow>
                                                  <CCol md="7">
                                                       <CFormGroup>
                                                            <CLabel htmlFor="name">Name this rule set</CLabel>
                                                            <CInput
                                                                 id="name"
                                                                 placeholder="Rule set name"
                                                                 invalid={!!errors.name}
                                                                 disabled={isSubmitting}
                                                                 value={values.name}
                                                                 onChange={(e) => {
                                                                      handleChange(e);
                                                                      if (errors) {
                                                                           setErrors({});
                                                                      }
                                                                      handleHasChange();
                                                                 }}
                                                                 data-lpignore="true"
                                                            />
                                                            <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                       </CFormGroup>
                                                  </CCol>
                                                  <CCol md="12">
                                                       <CFormGroup>
                                                            <CLabel htmlFor="description">Describe this rule set</CLabel>
                                                            <CTextarea
                                                                 id="description"
                                                                 placeholder="Enter a description for your own reference..."
                                                                 disabled={isSubmitting}
                                                                 value={values.description}
                                                                 onChange={(e) => {
                                                                      handleChange(e);
                                                                      handleHasChange();
                                                                 }}
                                                                 data-lpignore="true"
                                                            />
                                                       </CFormGroup>
                                                  </CCol>
                                             </CRow>

                                             <CButton type="submit" className="px-4" color="primary">
                                                  {`SAVE & NEXT`}
                                             </CButton>
                                        </CForm>
                                   )}
                              </Formik>
                         )}
                    </CCardBody>
               )}
               <ConfirmSaveChange
                    show={saveChangeModal}
                    onClose={() => setSaveChangeModal(false)}
                    onAccept={onAccept}
                    title="Are You Sure You Want to Save This Change?"
                    isLoading={saveLoading}
               >
                    You are about to update the name/description of this rule. This will not effect any other rule settings.
               </ConfirmSaveChange>
          </CCard>
     );
};

EditStep2.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};

export default EditStep2;
