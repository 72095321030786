import {
     SF_FETCH_JOBS,
     PUSHER_UPDATE_JOBS,
     SF_GET_LIST_JOBS
} from '../actions/types';


const INITIAL_STATE = {
     SFJobs: {
          data: [],
          total: 0,
     },
     fetchJobs : true,
}

const getListJobs = (payload) => {
     return {
          data: payload.data,
          total: payload.total,
     }
}

const updateJobs = (state, payload) => {
     const { SFJobs } = state;
     const jobs = SFJobs.data;
     const newJobs = jobs.map((job) => {
          const jobUpdate = payload.find((_item) => _item.id === job.id);
          if (jobUpdate) {
               job.status = jobUpdate.status;
          }
          return job;
     })
     return {
          data: newJobs,
          total: SFJobs.total,
     }
}

const reducer = (state = INITIAL_STATE, action) => {
     switch (action.type) {
          case SF_GET_LIST_JOBS:
               return { ...state, SFJobs: getListJobs(action.payload)};
          case PUSHER_UPDATE_JOBS:
               return { ...state, SFJobs: updateJobs(state, action.payload)};
          case SF_FETCH_JOBS: 
               return { ...state, fetchJobs: action.payload };
          default:
               return state;
     }
}

export default reducer;