import React, { useEffect } from 'react';
import { CButton } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';

import { setIsFromGetStarted, setShowCreateAccountPopup } from '../../../actions/subscriber';
import { useCompletedPercentage } from '../../../helpers/customHooks';
import CIcon from '@coreui/icons-react';

const GetStarted = () => {
    const dispatch = useDispatch();
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    const showCreateAccountPopup = useSelector(state => state.subscriber.showCreateAccountPopup);
    const gtmTokenLoading = useSelector(state => state.subscriber.loadings.gtmToken);
    const percentage = useCompletedPercentage({ completedSteps: activeAccount.completedSteps });
    const displayGetStartedBar = (!activeAccount.isComplete && percentage > 0 && percentage < 100);
    const completeAccountSetup = () => {
        dispatch(setIsFromGetStarted(true));
        dispatch(setShowCreateAccountPopup(true));
    };

    const forceReachTheEndOfSetupOnce = () => {
        if (displayGetStartedBar && !activeAccount.reachTheEndOfSetup) {
            // Make it run last so it works correctly
            setTimeout(() => {
                // There are cases which set showCreateAccountPopup = true and waiting to make a API to set GTM token to current account
                // before displaying this GetStarted component, so don't need to do this if showCreateAccountPopup is already = true to avoid issues
                if (!showCreateAccountPopup && !gtmTokenLoading) {
                    dispatch(setIsFromGetStarted(true));
                    dispatch(setShowCreateAccountPopup(true));
                }
            }, 0);
        }
    }


    useEffect(forceReachTheEndOfSetupOnce, [gtmTokenLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {displayGetStartedBar && (
                <div className="get-started-bar">
                    <div className="iconFlag">
                        <CIcon icon="iconFlag" width={15} height={17} />
                    </div>
                    <div className="get-started">Get Started: {percentage}%</div>
                    <span>ListenLayer won't work until you complete your setup.</span>
                    <CButton className="complete-btn" onClick={completeAccountSetup}>
                        Complete Account Setup
                        <CIcon className="icon-arrow-bar" icon="iconChevronRight" width={6} height={7} />
                    </CButton>
                </div>
            )}
        </>
    );
}

export default React.memo(GetStarted)
