import React, { useContext, useState } from 'react';
import { CButton, CForm, CCard, CCardBody } from '@coreui/react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { callTokenApi } from '../../../../../apiCaller';
import { API_CLIENT_ACCOUNT_INFO, DORMANT_STATUSES } from '../../../../../constants';
import { toastError } from '../../../../../utils';
import Waiting from '../../../../general/Loadings/Waiting';
import { setShowAccountDormantPopup, setShowCreateAccountPopup } from '../../../../../actions/subscriber';
import { urlParams } from '../../../../../helpers/cms/subscriber';
import { useHistory } from 'react-router-dom';
import useSaveCurrentStep from './useSaveCurrentStep';
import { CInput } from '../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../migration/CInvalidFeedback';
import CFormGroup from '../../../../migration/CFormGroup';

const validationSchema = (setValidateOnChange) => {
     setValidateOnChange(true);
     return (
          Yup.object().shape({
               name: Yup.string()
                    .min(2, 'Name must be at least 2 characters')
                    .max(100, 'Name cannot be more than 100 characters')
                    .required('Name is required'),
          })
     )
};

const Name = () => {
     const dispatch = useDispatch();
     const history = useHistory();
     const { account, setAccount, setStepsData, stepsData, setCurrentStep, updateAccountCompletedSteps } = useContext(CreateAccountContext);
     const accounts = useSelector((state) => state.subscriber.accounts);
     const user = useSelector((state) => state.theme.user);
     const dormantAccount = useSelector((state) => state.subscriber.dormantAccount);
     const [validateOnChange, setValidateOnChange] = useState(false);
     const defaultPackageIdParam = urlParams('defaultPackageId');
     const _pathName = window.location.pathname.replace('/', '');

     useSaveCurrentStep();

     const _arrSlug = [
          'masterclass',
          'certification',
          'office-hours',
          'become-a-partner',
          'support-tickets',
          'profile',
          'email-notifications',
          'payment-methods',
     ];

     const activeAccounts = accounts ? accounts.filter(account => !DORMANT_STATUSES.includes(account.platformAccountStatus)) : [];
     const showBackBtn = !account.id && (activeAccounts.length > 0 || dormantAccount || user.registerMasterClass || !!_arrSlug.find(slug => _pathName.includes(slug)));
     const hideBackBtn = account.id && (activeAccounts.length > 0 || dormantAccount || user.registerMasterClass || !!_arrSlug.find(slug => _pathName.includes(slug)))
     const onBackBtnClicked = () => {
          if (showBackBtn) {
               if (dormantAccount) {
                    dispatch(setShowAccountDormantPopup(true));
               }
               dispatch(setShowCreateAccountPopup(false));
               if (defaultPackageIdParam) {
                    history.push('/');
               }
          }
          else {
               setCurrentStep(STEPS.createLogin);
          }
     };

     const goNext = () => {
          updateAccountCompletedSteps(STEPS.name);
          setCurrentStep(STEPS.name, CHANGE_STEP_TYPE.mainStepNext);
     };

     const updateName = (name, setIsLoading) => {
          callTokenApi(API_CLIENT_ACCOUNT_INFO, 'PUT', { accountId: account.id, name })
               .then(response => {
                    if (response.status === 200) {
                         const newAccount = { ...account, name };
                         setAccount(newAccount);
                         setStepsData({ name });
                         goNext();
                    } else {
                         setIsLoading(false);
                         toastError(response);
                    }
               })
     };

     const onSubmit = ({ name }, { setSubmitting }) => {
          if (account.id) {
               if (account.name === name) {
                    goNext();
               } else {
                    updateName(name, setSubmitting);
               }
          } else {
               setStepsData({ name });
               goNext();
          }
     };

     return (
          <CreateAccountBody onBackBtnClicked={!hideBackBtn ? onBackBtnClicked : null}>
               <div>
                    <Formik
                         initialValues={{ name: stepsData.name }}
                         validationSchema={() => validationSchema(setValidateOnChange)}
                         onSubmit={onSubmit}
                         validateOnChange={validateOnChange}
                         validateOnBlur={false}
                         enableReinitialize
                    >
                         {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                              <>
                                   <CCard className="account-name">
                                        <p className="number-of-step">0 of 2 answered</p>
                                        <CCardBody>
                                             <CForm onSubmit={handleSubmit}>
                                                  <CFormGroup>
                                                       <h3>Name your account</h3>
                                                       <p className="des">Use your company or website name.</p>
                                                       <CInput
                                                            name="name"
                                                            placeholder="Enter your account name"
                                                            invalid={!!errors.name}
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            data-lpignore="true"
                                                       />
                                                       <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                       <CButton
                                                            className="btn-save"
                                                            type="submit"
                                                            disabled={!values.name || isSubmitting} // Disabled when name has no value or is submitting
                                                       >
                                                            <Waiting isLoading={isSubmitting}>NEXT</Waiting>
                                                       </CButton>
                                                       <span className="foot-note">You can create and access multiple Accounts using your login.</span>
                                                  </CFormGroup>
                                             </CForm>
                                        </CCardBody>
                                   </CCard>
                                   {
                                        window._areAllAccountsInactive && (
                                             <span className="foot-note">
                                                  Your previous ListenLayer account was inactive and unused for an extended period of time.
                                                  To give you the best-upgraded experience, we're having you create a new account. Don't worry - this is fast and easy!
                                             </span>
                                        )
                                   }
                              </>
                         )}
                    </Formik>
               </div>
          </CreateAccountBody>
     );
};

export default Name;
