import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callTokenApi } from '../../../../../apiCaller';
import {
     ACCORDION_TYPES,
     API_CLIENT_GET_MAX_STEP_FORM_CATEGORY,
     API_RECORD_PROFILE,
     API_RECORD_PROFILE_CHART_FORM_ACTIVITY,
     API_RECORD_PROFILE_CHART_FORM_MULTI_STEP,
     DEFAULT_DATE_FORMAT,
     PROFILE_REPORT,
} from '../../../../../constants';
import { toastError } from '../../../../../utils';
import TableRecordProfile from './TableRecordProfile';
import {
     FORM_CATEGORY_FORM_LOCATION_RECORD_PROFILE,
     FORM_CATEGORY_RECORD_FORM_STEP,
     FORM_CATEGORY_REVEAL_PEOPLE_RECORD_PROFILE,
     FORM_CATEGORY_SOURCE_RECORD_PROFILE,
} from './utilsCompany';
import FunnelChart from '../FunnelChart';
import CustomDateRange from './CustomDateRange';
import isEmpty from 'lodash/isEmpty';
import { setDateRangeRecord } from '../../../../../actions/subscriber';
import dayjs from 'dayjs';
import TableRecordStepForm from './TableRecordStepForm';
import Accordion from '../../../../general/accordion';

const RecordProfileForm = ({ id, locationForm }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const dateRangeRecord = useSelector((state) => state.subscriber.dateRangeRecord);
     const dayjsNow = dayjs().tz(activeAccount.timeZone);

     let defaultDateRange = dateRangeReports
          ? dateRangeReports
          : {
                 selection: {
                      startDate: dayjsNow.subtract(1, 'day'),
                      endDate: dayjsNow.subtract(0, 'day'),
                 },
            };

     useEffect(() => {
          dispatch(setDateRangeRecord(defaultDateRange));
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const [initialValue, setInitialValue] = useState(null);
     const [isLoading, setIsLoading] = useState(true);
     const [isLoadingActivityChart, setIsLoadingActivityChart] = useState(true);
     const [isLoadingStepFlowChart, setIsLoadingStepFlowChart] = useState(false);
     const [accordionShow, setAccordionShow] = useState(ACCORDION_TYPES.RECORD_PROFILE_TABLE_SOURCE);
     const [isDoneGetMaxStep, setIsDoneGetMaxStep] = useState(null);
     const [metricsStepForm, setMetricsStepForm] = useState([]);
     const [placeShowDate, setPlaceShowDate] = useState(false);

     useEffect(() => {
          setPlaceShowDate([ACCORDION_TYPES.RECORD_PROFILE_FORM_STEP, ACCORDION_TYPES.RECORD_PROFILE_CHART].includes(accordionShow));
     }, [accordionShow]);

     const formInfo = initialValue && initialValue.newFormInfo ? initialValue.newFormInfo : null;
     const [funnelActivityData, setFunnelActivityData] = useState([]);
     const [funnelStepFlowData, setFunnelStepFlowData] = useState([]);

     // funnelStepFlowData.sort((a, b) => b.count - a.count);

     const handleGenerationMetricsStepForm = (number) => {
          const metricsStepForm = [];
          for (let i = 1; i <= number; i++) {
               metricsStepForm.push(
                    { value: `step${i}View`, label: `Step ${i} - View` },
                    { value: `step${i}Started`, label: `Step ${i} - Start` },
                    { value: `step${i}Completes`, label: `Step ${i} - Complete` }
               );
          }
          return metricsStepForm;
     };

     useEffect(() => {
          const data = {
               id,
               locationForm,
               accountId: activeAccount.id,
               typeProfile: PROFILE_REPORT.FORM,
               startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
               recordProfile: 'record-profile',
               formID: id,
          };

          callTokenApi(API_CLIENT_GET_MAX_STEP_FORM_CATEGORY, 'POST', data).then((response) => {
               if (response && response.status === 200) {
                    setIsDoneGetMaxStep(response.data.maxStepForm);
                    setMetricsStepForm(handleGenerationMetricsStepForm(response.data.maxStepForm));
               } else {
                    setIsDoneGetMaxStep(0);
               }
          });
          callTokenApi(API_RECORD_PROFILE, 'POST', data).then((response) => {
               if (response && response.status === 200) {
                    setInitialValue(response.data.response);
               } else {
                    toastError(response);
               }

               if (response && response.status) {
                    setIsLoading(false);
               }
          });
     }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          const newDateRange = {
               selection: {
                    startDate: isEmpty(dateRangeRecord)
                         ? defaultDateRange.selection.startDate.format(DEFAULT_DATE_FORMAT)
                         : dateRangeRecord.selection.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: isEmpty(dateRangeRecord)
                         ? defaultDateRange.selection.endDate.format(DEFAULT_DATE_FORMAT)
                         : dateRangeRecord.selection.endDate.format(DEFAULT_DATE_FORMAT),
               },
          };
          const bodyChartFormActivity = {
               accountId: activeAccount.id,
               formID: id,
               dateRanges: { ...newDateRange, dateScope: 'Event activity' },
               locationForm,
          };
          const bodyChartFormMultiple = {
               accountId: activeAccount.id,
               formID: id,
               dateRanges: newDateRange,
               locationForm,
          };
          if (isDoneGetMaxStep !== null) {
               setIsLoadingActivityChart(true);
               if (isDoneGetMaxStep > 0) {
                    setIsLoadingStepFlowChart(true);
                    bodyChartFormMultiple.maxStepForm = isDoneGetMaxStep;
                    callTokenApi(API_RECORD_PROFILE_CHART_FORM_MULTI_STEP, 'POST', bodyChartFormMultiple).then((response) => {
                         if (response && response.status === 200) {
                              const formMultiStepDataChart = response.data.chartStepForm
                                   .filter((item) => item.count)
                                   .sort((a, b) => b.count - a.count);
                              setFunnelStepFlowData(formMultiStepDataChart);
                         } else {
                              toastError(response);
                         }
                         if (response && response.status) {
                              setIsLoadingStepFlowChart(false);
                         }
                    });
               }
               callTokenApi(API_RECORD_PROFILE_CHART_FORM_ACTIVITY, 'POST', bodyChartFormActivity).then((response) => {
                    if (response && response.status === 200) {
                         const newFunnelActivityData =
                              response.data && response.data.newChartFormActivity && response.data.newChartFormActivity[0]
                                   ? [
                                          { stat: 'Impressions (Loads)', count: response.data.newChartFormActivity[0].impressionsLoad },
                                          { stat: 'Form Visible', count: response.data.newChartFormActivity[0].formVisible },
                                          { stat: 'Form Start', count: response.data.newChartFormActivity[0].formStart },
                                          { stat: 'Validation Errors', count: response.data.newChartFormActivity[0].validationErrors },
                                          { stat: 'Submissions', count: response.data.newChartFormActivity[0].submissions },
                                     ]
                                   : [];
                         const activityDataChart = newFunnelActivityData.filter((item) => item.count).sort((a, b) => b.count - a.count);

                         setFunnelActivityData(activityDataChart);
                    } else {
                         toastError(response);
                    }
                    if (response && response.status) {
                         setIsLoadingActivityChart(false);
                    }
               });
          }
     }, [dateRangeRecord, isDoneGetMaxStep]); // eslint-disable-line react-hooks/exhaustive-deps

     const LoadingChart = () => {
          return (
               <div className="record-profile loading-profile">
                    <div className="record-profile-info form-profile">
                         <p
                              className="event-profile-session"
                              style={{
                                   height: '250px',
                                   width: '300px',
                                   borderRadius: '20px',
                                   position: 'relative',
                                   left: '50%',
                                   transform: 'translate(-50%, 0)',
                              }}
                         >
                              Loading Chart:{' '}
                         </p>
                    </div>
               </div>
          );
     };
     const LoadingTable = () => {
          return (
               <div className="record-profile loading-profile">
                    <div className="record-profile-info form-profile">
                         <p className="event-profile-session" style={{ height: '120px', borderRadius: '20px' }}>
                              FormID:{' '}
                         </p>
                    </div>
               </div>
          );
     };

     function formatNumberWithCommas(number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
     }

     function formatNumber(value) {
          if (typeof value !== 'number') {
               return String(value);
          } else {
               return formatNumberWithCommas(Number(value));
          }
     }

     return (
          <div className="record-profile">
               {isLoading ? (
                    <div className="record-profile loading-profile">
                         <h1>Form Profile</h1>
                         <div className="record-profile-info form-profile">
                              {id && <p className="event-profile-session">FormID: </p>}
                              {locationForm && <p className="event-profile-session">Form Location: </p>}
                              <div className="d-flex">
                                   <div>
                                        <p className="mr-5 event-profile-session">Form Name: </p>
                                        <p className="event-profile-session">Form Category: </p>
                                   </div>
                                   <div>
                                        <p className="mr-5 event-profile-session">Identified Date: </p>
                                        <p className="event-profile-session">Last Submission Date: </p>
                                   </div>
                              </div>
                         </div>
                         <div className="list-count-data">
                              <div className="card box-count-data event-profile-session">
                                   <h4>Impressions (Loads)</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Validation Errors</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Submissions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Form Visible</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Form Start</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Session Views</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Session Submission</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>User Views</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Revealed People</h4>
                                   <p className="item-name"></p>
                              </div>
                         </div>
                    </div>
               ) : (
                    <div className="record-profile">
                         <h1>Form Profile</h1>
                         {formInfo && Object.keys(formInfo).length > 0 ? (
                              <>
                                   <div className="record-profile-info form-profile">
                                        {id && <p>FormID: {formInfo.formId}</p>}
                                        {locationForm && <p>Form Location: https://{locationForm}</p>}
                                        <div className="d-flex">
                                             <div>
                                                  <p className="mr-5">Form Name: {formInfo.formName}</p>
                                                  <p>Form Category: {formInfo.formCategory}</p>
                                             </div>
                                             <div>
                                                  <p className="mr-5">Identified Date: {formInfo.identifiedDate}</p>
                                                  <p>Last Submission Date: {formInfo.latestSubmissionDate}</p>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="list-count-data">
                                        <div className="card box-count-data">
                                             <h4>Impressions (Loads)</h4>
                                             <p className="item-name">{formatNumber(formInfo.impressionsLoad)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Validation Errors</h4>
                                             <p className="item-name">{formatNumber(formInfo.validationErrors)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Submissions</h4>
                                             <p className="item-name">{formatNumber(formInfo.submissions)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Form Visible</h4>
                                             <p className="item-name">{formatNumber(formInfo.formVisible)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Form Start</h4>
                                             <p className="item-name">{formatNumber(formInfo.formStart)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Session Views</h4>
                                             <p className="item-name">{formatNumber(formInfo.sessionView)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Session Submission</h4>
                                             <p className="item-name">{formatNumber(formInfo.sessionSubmission)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>User Views</h4>
                                             <p className="item-name">{formatNumber(formInfo.userView)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Revealed People</h4>
                                             <p className="item-name">{formatNumber(formInfo.revealedPeople)}</p>
                                        </div>
                                   </div>
                              </>
                         ) : (
                              <p className="message-undefine-form">
                                   For better data, this form should be categorized and named so we can accurately attribute activity to it.
                              </p>
                         )}
                    </div>
               )}

               {formInfo && Object.keys(formInfo).length > 0 ? (
                    isDoneGetMaxStep === null ? (
                         <LoadingTable />
                    ) : (
                         <>
                              <TableRecordProfile
                                   id={id}
                                   locationForm={locationForm}
                                   header={[...FORM_CATEGORY_SOURCE_RECORD_PROFILE, ...metricsStepForm]}
                                   reportType={'formCategorySource'}
                                   tableType={'table-source'}
                                   title={'Sources'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SOURCE}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                                   maxStepForm={isDoneGetMaxStep}
                              />
                              <TableRecordProfile
                                   id={id}
                                   locationForm={locationForm}
                                   header={[...FORM_CATEGORY_REVEAL_PEOPLE_RECORD_PROFILE, ...metricsStepForm]}
                                   reportType={'formCategoryRevealPeople'}
                                   tableType={'table-reveal-people'}
                                   title={'Revealed People'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_REVEAL_PEOPLE}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                                   maxStepForm={isDoneGetMaxStep}
                              />
                              {!locationForm && (
                                   <TableRecordProfile
                                        id={id}
                                        locationForm={locationForm}
                                        header={[...FORM_CATEGORY_FORM_LOCATION_RECORD_PROFILE, ...metricsStepForm]}
                                        reportType={'formCategoryFormLocation'}
                                        tableType={'table-form-location'}
                                        title={'Form Location'}
                                        accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_FORM_LOCATION}
                                        accordionShow={accordionShow}
                                        setAccordionShow={setAccordionShow}
                                        maxStepForm={isDoneGetMaxStep}
                                   />
                              )}
                         </>
                    )
               ) : (
                    <></>
               )}

               {formInfo && Object.keys(formInfo).length > 0 ? (
                    <>
                         <div className="date-range-record-profile">
                              <CustomDateRange placeShowDate={placeShowDate} />
                         </div>
                         <TableRecordStepForm
                              id={id}
                              header={FORM_CATEGORY_RECORD_FORM_STEP}
                              accordionShow={accordionShow}
                              setAccordionShow={setAccordionShow}
                              maxStepForm={isDoneGetMaxStep}
                              locationForm={locationForm}
                         />
                         {
                              <div className="accordion">
                                   <Accordion
                                        accordionType={ACCORDION_TYPES.RECORD_PROFILE_CHART}
                                        title={`Chart Form`}
                                        accordionShow={accordionShow}
                                        setAccordionShow={setAccordionShow}
                                   >
                                        <div style={{ display: 'flex', gap: '50px', justifyContent: 'center' }}>
                                             {!funnelActivityData.length &&
                                             !funnelStepFlowData.length &&
                                             !isLoadingActivityChart &&
                                             !isLoadingStepFlowChart ? (
                                                  <>
                                                       <p style={{ margin: 0, textAlign: 'center' }}>No data found</p>
                                                  </>
                                             ) : (
                                                  <></>
                                             )}
                                             {isLoadingActivityChart ? (
                                                  <LoadingChart />
                                             ) : funnelActivityData.length > 0 ? (
                                                  <FunnelChart title="Form Activity" data={funnelActivityData} />
                                             ) : (
                                                  <></>
                                             )}
                                             {isLoadingStepFlowChart ? (
                                                  <LoadingChart />
                                             ) : funnelStepFlowData.length > 0 ? (
                                                  <FunnelChart title="Step Flow" data={funnelStepFlowData} />
                                             ) : (
                                                  <></>
                                             )}
                                        </div>
                                   </Accordion>
                              </div>
                         }
                    </>
               ) : (
                    <></>
               )}
          </div>
     );
};

export default RecordProfileForm;
