import { REPORT_DATA_TYPES, REPORT_NAMES, SCOPED } from './analytics';

const getDateFunctionVariables = () => {
     const baseDateFunctionVariables = [
          {
               value: 'LL:fullDateTimestamp',
               description: 'full date and timestamp: Wed Aug 04 2021 21:59:14 GMT+0700 (Indochina Time)',
               hasTimeZone: true,
          },
          { value: 'LL:date', description: 'date: Aug 04 2021', hasTimeZone: true },
          { value: 'LL:dayOfWeek', description: 'day of week: Wed', hasTimeZone: true },
          { value: 'LL:timestamp', description: 'timestamp: 21:59:14', hasTimeZone: true },
          { value: 'LL:timezone', description: 'timezone: GMT+0700', hasTimeZone: true },
          { value: 'LL:timezoneName', description: 'timezone name: (Indochina Time)', hasTimeZone: true },
          { value: 'LL:fullDateTimestampISO', description: '' },
          { value: "LL:fullDateTimestampEpoch (User's Time Zone)", description: '' },
          { value: 'LL:fullDateTimestampEpoch (GMT)', description: '' },
          { value: 'LL:MM/dd/yyyy hh:mm:ss aa (GMT)', description: '' },
          { value: 'LL:yyyy-MM-dd HH:mm:ss (GMT)', description: '' },
          { value: 'LL:yyyy-MM-ddTHH:mm:ss (GMT)', description: '' },
          { value: "LL:yyyy-MM-ddTHH:mm:ss+z (User's Time Zone)", description: '' },
     ];
     let dateFunctionVariables = [];

     baseDateFunctionVariables.forEach((el) => {
          dateFunctionVariables.push(el);

          // if(el.hasTimeZone) {
          //      for(let i = -7; i <= 7; i++) {
          //           dateFunctionVariables.push({...el, value: `${el.value}(timezone:${i.toString()})`});
          //      }
          // }
     });

     return dateFunctionVariables;
};

export * from './apiRest';
export * from './path';
export * from './analytics';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const ADMIN_USER_FILTER = 'adminUserFilter';
export const ADMIN_EVENT_FILTER = 'adminEventFilter';
export const ADMIN_ACTIVITY_FILTER = 'adminActivityFilter';
export const ADMIN_NOTIFICATION_FILTER = 'adminNotificationFilter';
export const ADMIN_ACCOUNT_FILTER = 'adminAccountFilter';
export const ADMIN_LISTENER_FILTER = 'adminListenerFilter';
export const ADMIN_DESTINATION_FILTER = 'adminListenerFilter';
export const TODAY = new Date();

export const LISTENER_TYPE = {
     CONTENT: 'Content',
     USERS_AND_SOURCES: 'Users',
     CLICKS: 'Clicks',
     FORMS: 'Forms',
     CHAT: 'Chats',
     VIDEO: 'Video',
     ECOMMERCE: 'eCommerce',
     SCHEDULING: 'Scheduling',
     NATIVE_DATA: 'Native Data',
};

export const LISTENER_TYPES = Object.entries(LISTENER_TYPE).map(([_, value]) => value);
// export const TIME_ZONES = [
//     { VALUE: "Pacific/Honolulu", NAME: "Honolulu Time" }, // UTC offset: -10:00, UTC DST offset: -10:00
//     { VALUE: "America/Anchorage", NAME: "Anchorage Time" }, // UTC offset: -9:00, UTC DST offset: -8:00
//     { VALUE: "America/Los_Angeles", NAME: "Los Angeles Time" }, // UTC offset: -8:00, UTC DST offset: -7:00
//     { VALUE: "America/Boise", NAME: "Boise Time" }, // UTC offset: -7:00, UTC DST offset: -6:00
//     { VALUE: "America/Denver", NAME: "Denver Time" }, // UTC offset: -7:00, UTC DST offset: -6:00
//     { VALUE: "America/Phoenix", NAME: "Phoenix Time" }, // UTC offset: -7:00, UTC DST offset: -7:00
//     { VALUE: "America/Chicago", NAME: "Chicago Time" }, // UTC offset: -6:00, UTC DST offset: -5:00
//     { VALUE: "America/Detroit", NAME: "Detroit Time" }, // UTC offset: -5:00, UTC DST offset: -4:00
//     { VALUE: "America/New_York", NAME: "New York Time" } // UTC offset: -5:00, UTC DST offset: -4:00
// ]
export const DEFAULT_TIME_ZONE = 'EDT';
export const CARD_TYPES = {
     VISA: {
          NAME: 'Visa',
          REGEX_PREFIX: /^4[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/visa-card.svg',
     },
     MASTERCARD: {
          NAME: 'MasterCard',
          REGEX_PREFIX: /^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6]|27[01][0-9]|2720)[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-master-card.svg',
     },
     AMERICAN_EXPRESS: {
          NAME: 'American Express',
          REGEX_PREFIX: /^3[47][0-9]*$/,
          INPUT_MASK: '9999 999999 99999',
          CVC_MASK: '9999',
          CVC_PLACEHOLDER: '0000',
          IMAGE_SRC: '/assets/images/card-amex.svg',
     },
     DISCOVER: {
          NAME: 'Discover',
          REGEX_PREFIX: /^6(?:01[13]|5)[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-amex.svg',
     },
     DINERS_CLUB: {
          NAME: 'Diners Club',
          REGEX_PREFIX: /^3(?:0(?:[0-5]|95)|[8-9])[0-9]*$/,
          INPUT_MASK: '99999 9999 9999999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-diners.svg',
     },
     DINERS_CLUB_ENROUTE: {
          NAME: 'Diners Club',
          REGEX_PREFIX: /^2(?:014|149)[0-9]*$/,
          INPUT_MASK: '99999 9999 999999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-diners.svg',
     },
     DINERS_CLUB_INTERNATIONAL: {
          NAME: 'Diners Club',
          REGEX_PREFIX: /^36[0-9]*$/,
          INPUT_MASK: '99999 99999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-diners.svg',
     },
     DINERS_CLUB_USA_CANADA: {
          NAME: 'Diners Club',
          REGEX_PREFIX: /^5[4-5][0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-diners.svg',
     },
     JCB: {
          NAME: 'JCB',
          REGEX_PREFIX: /^(?:2131|1800|35)[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-jcb.svg',
     },
     UNIONPAY: {
          NAME: 'UnionPay',
          REGEX_PREFIX: /^62[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-unionpay.svg',
     },
     MAESTRO: {
          NAME: 'Maestro',
          REGEX_PREFIX: /^(?:5(?:0|[6-8])|6(?:1|[8-9]))[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-maestro.svg',
     },
     OTHER: {
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
     },
};
export const VARIABLE_OPERATORS = [
     { VALUE: 'ct', TEXT: 'contains' },
     { VALUE: 'nct', TEXT: 'not contain' },
     { VALUE: 'eq', TEXT: 'equals to' },
     { VALUE: 'neq', TEXT: 'not equal to' },
     { VALUE: 'exav', TEXT: 'exists with any value' },
     { VALUE: 'nex', TEXT: 'does not exist' },
     { VALUE: 'gt', TEXT: 'greater than' },
     { VALUE: 'lt', TEXT: 'less than' },
]; // Variable operators of rules
export const VARIABLE_OPERATORS_TRIGGERS = [
     { VALUE: 'ct', TEXT: 'contains' },
     { VALUE: 'nct', TEXT: 'not contain' },
     { VALUE: 'eq', TEXT: 'equals to' },
     { VALUE: 'neq', TEXT: 'not equal to' },
     { VALUE: 'gt', TEXT: 'greater than' },
     { VALUE: 'lt', TEXT: 'less than' },
]; // Variable operators of rules
export const VARIABLE_OPERATORS_REPORT = [
     { VALUE: 'ct', TEXT: 'contains' },
     { VALUE: 'nct', TEXT: 'not contain' },
     { VALUE: 'eq', TEXT: 'equals to' },
     { VALUE: 'neq', TEXT: 'not equal to' },
]; // Variable operators of rules
export const OPERATORS_REQUIRE_VALUE = ['ct', 'nct', 'eq', 'neq', 'gt', 'lt']; // Lookup table operators that require the user not to leave empty
export const TRIAL_DAYS = 15;
export const LIMITATION_TYPE = {
     LISTENER: 'listener',
     PREMIUM: 'premium', // Premium listeners & features
     DOMAIN: 'domain',
     CUSTOM_DATALAYER_VARIABLE: 'customDataLayerVariable',
     CUSTOM_VARIABLE_RULE: 'customVariableRule',
     BROWSER_CUSTOM_VARIABLE: 'browserCustomVariable',
     BROWSER_RULE: 'browserRule',
     USER: 'user',
     EVENT: 'event',
     OUT_OF_EVENT: 'outOfEvent',
     MONITORING_RULE: 'monitoringRule',
     UPGRADE_REPORTING: 'reporting',
     SAVE_REPORTING_VALUES: 'save_reporting_values',
     POPULATE_RULE: 'populateRule',
};
export const LOCAL_SHOW_SETUP_ACCOUNTS = 'showSetupAccounts';
export const LOCAL_DIRECT_PATH = 'directPath';
export const LOCAL_TEMP_LIMITATION = 'tempLimitation';
export const LOCAL_SETUP_INVITED_ACCOUNT = 'setupInvitedAccount';
export const LOCAL_SHOW_ONLY_ENABLED_LISTENERS = 'showOnlyEnabledListeners'; // Save filter show only enabled listeners on listener page
export const LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS = 'showOnlyEnabledDestinations'; // Save filter show only enabled listeners on listener page
export const LOCAL_ACCORDION_SHOW = 'accordionShow';
export const BROWSER_STORAGE_TYPE = {
     COOKIE: 'Cookie Storage',
     LOCAL_STORAGE: 'Local Storage',
     SESSION_STORAGE: 'Session Storage',
}; // Used for creating or editing a Browser Storage
export const EXISTING_VALUE_ACTION_OPTIONS = [
     { VALUE: 'overwrite', TEXT: 'Overwrite the existing value' },
     { VALUE: 'do not overwrite', TEXT: 'Do not overwrite existing value' },
     { VALUE: 'add', TEXT: 'Add the new value to the existing value (comma delimited)' },
]; // Used for creating or editing a Browser Storage step 4
export const NOTIFY_ME_OPTIONS = [
     { VALUE: 'every time true', TEXT: 'Every time this Condition is True' },
     { VALUE: 'has not been met', TEXT: 'If the Condition has not been met for a time period' },
]; // Used for creating or editing a Event Monitoring step 2
export const INITIAL_VARIABLE_ROW = {
     type: 'Variable',
     id: '',
     key: '',
     operator: '',
     value: '',
     conjunction: '',
}; // Used for creating or editing conditions of a rule
export const INITIAL_EVENT_ROW = {
     type: 'Event',
     id: '',
     key: '',
     operator: 'eq',
     value: '',
     conjunction: '',
}; // Used for creating or editing conditions of a rule
export const INITIAL_EVENT_NATIVE_ROW = {
     type: 'MyEventNative',
     id: '',
     key: '',
     operator: 'eq',
     value: '',
     conjunction: '',
}; // Used for creating or editing conditions of a rule
export const REGEX_PATTERNS = [
     { PATTERN: `(.*?)`, DESCRIPTION: 'Wildcard - matches any or no value' },
     {
          PATTERN: `^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$`,
          DESCRIPTION: 'Requires at least 1 number and 1 alphabetic character (no special characters, no spaces, no line breaks)',
     },
     { PATTERN: `^[0-9]+$`, DESCRIPTION: 'Only digits' },
     { PATTERN: `^[a-zA-Z]+$`, DESCRIPTION: 'Only alphabetic characters' },
     { PATTERN: `^[a-zA-Z ]+$`, DESCRIPTION: 'Only alphabetic characters and spaces' },
     { PATTERN: `^.{1,}$`, DESCRIPTION: 'Requires 1 or more any characters but line breaks' },
     { PATTERN: `^.{6,7}$`, DESCRIPTION: 'Requires 6 or 7 characters but line breaks' },
     // { PATTERN: `(?=.*\\W])`, DESCRIPTION: 'Requires at least one non-word character exists' },
     { PATTERN: `(?=^.{0,5}$)`, DESCRIPTION: 'String is between 0 and 5 characters but line breaks' },
     { PATTERN: `(?=^.{1,}$)`, DESCRIPTION: 'Matches any character but requires at least 1 character to exist, not including line breaks' },
     {
          PATTERN: `[^\\x00-\\x7F]+\\ *(?:[^\\x00-\\x7F]| )*`,
          DESCRIPTION: 'Matches all non-ASCII characters including spaces until reaching an ASCII character',
     },
];
export const SUN_EDITOR_OPTIONS = {
     mode: 'classic',
     katex: 'window.katex',
     height: 200,
     fontSize: [8, 10, 14, 18, 24, 36],
     fontSizeUnit: 'px',
     formats: ['p', 'blockquote', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
     lineHeights: [
          { text: 'Single', value: 1 },
          { text: 'Double', value: 2 },
     ],
     textStyles: [
          {
               name: 'Code',
               tag: 'code',
          },
     ],
     buttonList: [
          [
               'undo',
               'redo',
               'fontSize',
               'formatBlock',
               'bold',
               'underline',
               'italic',
               'subscript',
               'superscript',
               'fontColor',
               'hiliteColor',
               'textStyle',
               'outdent',
               'indent',
               'align',
               'list',
               'link',
               'fullScreen',
               'codeView',
          ],
     ],
};
export const SUN_EDITOR_ADVANCED_OPTIONS = {
     defaultTag: 'p',
     textTags: {
          bold: 'b',
          underline: 'u',
          italic: 'i',
          strike: 's',
     },
     value: '',
     height: 300,
     mode: 'classic',
     rtl: false,
     toolbarContainer: '#custom_toolbar',
     katex: 'window.katex',
     mathFontSize: [
          {
               text: '1',
               value: '1em',
          },
          {
               text: '2',
               value: '2em',
               default: true,
          },
     ],
     charCounter: true,
     charCounterType: 'char',
     charCounterLabel: 'Characters',
     font: ['Arial', 'tahoma', 'Courier New,Courier'],
     fontSize: [8, 10, 14, 18, 24, 36],
     fontSizeUnit: 'px',
     formats: ['p', 'blockquote', 'div', 'h1', 'h2', 'h3', 'h4', 'h5'],
     videoResizing: false,
     videoFileInput: false,
     tabDisable: false,
     lineHeights: [
          {
               text: 'Single',
               value: 1,
          },
          {
               text: 'Double',
               value: 2,
          },
     ],
     paragraphStyles: [
          'spaced',
          {
               name: 'Box',
               class: '__se__customClass',
          },
     ],
     textStyles: [
          'translucent',
          {
               name: 'Emphasis',
               style: '-webkit-text-emphasis: filled;',
               tag: 'span',
          },
          {
               name: 'Code',
               tag: 'code',
          },
     ],
     linkRelDefault: {},
     buttonList: [
          [
               'undo',
               'redo',
               'font',
               'fontSize',
               'formatBlock',
               'paragraphStyle',
               'blockquote',
               'bold',
               'underline',
               'italic',
               'strike',
               'subscript',
               'superscript',
               'fontColor',
               'hiliteColor',
               'textStyle',
               'removeFormat',
               'outdent',
               'indent',
               'align',
               'horizontalRule',
               'list',
               'lineHeight',
               'table',
               'link',
               'fullScreen',
               'showBlocks',
               'codeView',
               'preview',
          ],
     ],
};

export const INITIAL_INSTRUCTION_POPUP = {
     headline: '',
     text: '',
     videoEmbed: '',
     codeBlock: '',
     button: {
          text: '',
          link: '',
          openNewTab: false,
     },
};
export const NO_OPTION_MESSAGE_TYPE = {
     EVENT: 'event',
     AUTOMATIC_VARIABLES: 'automatic variable',
     CUSTOM_VARIABLE: 'custom variable',
     NATIVE_EVENT: 'native event',
     CUSTOM_BROWSER_VARIABLE: 'custom browser variable',
     NATIVE_BROWSER_STORAGE: 'native browser storage',
     TRIGGERS: 'trigger',
     VARIABLES_TRIGGERS: 'variables-triggers-conversions',
     FORM_CATEGORIES: 'form-categories',
};
export const CHART_COLORS = ['#008FFB', '#FF4560', '#775DD0', '#4CAF50', '#81D4FA', '#546E7A', '#A300D6', '#C7F464', '#2B908F', '#F9A3A4', '#F9C80E'];
export const CHART_VISITOR_SESSION_COLORS = ['#F86624', '#FEB019', '#00E396', '#90EE7E'];
export const VARIABLE_TABLE_LEVELS = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth'];

export const SEND_US_DATA_NOTIFICATION_VIDEO = {
     headline: 'Send Us Data',
     description: 'Watch this video to learn more about how our system receives data from your website.',
     videoLink: 'https://www.youtube.com/embed/gC68-bun4t4/?rel=0',
};

export const NEED_VARIABLE_NOTIFICATION_VIDEO = {
     headline: 'Define Your Global Variables',
     description: 'Watch this video to learn how to create global variables so that you can write Custom Variable Rules and Browser Storage Rules.',
     videoLink: 'https://www.youtube.com/embed/38wbWpnZk7c/?rel=0',
};
export const LOWERCASE_CLICK_FEATURES = ['outboundclick', 'documentlinkclick', 'internallinkclick'];
export const SPECIAL_FEATURE_LIST = [
     'myvalues',
     'customvalues',
     'browserstorage',
     'monitoring',
     'reporting',
     'populate',
     'selector',
     'usersource',
     ...LOWERCASE_CLICK_FEATURES,
];

export const INSTALLATION_SCRIPT_VIDEO = 'https://www.youtube.com/embed/SJlWCARpu5w/?rel=0';
export const INSTALLATION_VIDEO = 'https://player.vimeo.com/video/571501894';
export const INSTALLATION_OVERVIEW = 'https://www.youtube.com/embed/MSz9rDZ8PcY?rel=0&start=20';
export const PUBLISHING_VERSIONS = 'https://www.youtube.com/embed/zSn3qCgZFJE?rel=0';
export const TESTING_YOUR_LISTENER = 'https://www.youtube.com/embed/NZwAhcXy_f4?rel=0';
export const USER_ACCESS_OVERVIEW_VIDEO = 'https://www.youtube.com/embed/meFs7UTKcGY?rel=0';
export const INVOICES_CREDIT_CARD_CHARGES = 'https://www.youtube.com/embed/_Q9OuSUhYDI?rel=0';
export const INTRO_TO_DATA_LAYERS = 'https://www.youtube.com/embed/vVDVKW-p9ic?rel=0';

export const ROADMAP_LEARN_ABOUT_GOAL = 'https://player.vimeo.com/video/871860124';
export const ROADMAP_LEARN_ABOUT_ANALYTICS = 'https://player.vimeo.com/video/871676199';
export const ROADMAP_WEB_CONVERSIONS = 'https://player.vimeo.com/video/871859621';
export const ROADMAP_REVIEW_VISUALIZE_DATA = 'https://player.vimeo.com/video/872028350';
export const ROADMAP_REVEAL_PEOPLE = 'https://player.vimeo.com/video/872134597';
export const ROADMAP_CUSTOM_WEB_DATA = 'https://player.vimeo.com/video/872134569';
export const ROADMAP_ABOUT_CONSENT = 'https://player.vimeo.com/video/872416160';

export const WELCOME_VIDEO = 'https://player.vimeo.com/video/571722098';
export const WELCOME_VIDEO_NEW = 'https://player.vimeo.com/video/871666888?h=85504c7e3f';
export const SEE_HOW_IT_WORKS_VIDEO_LINK =
     'https://player.vimeo.com/video/872026006?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479';
export const DATE_FUNCTION_VARIABLES = getDateFunctionVariables();
export const GVP_FUNCTION_VARIABLES = [
     { value: 'LL:gvp-className', description: ':[Replace with css class of element]', showDes: true },
     { value: 'LL:gvp-id', description: ':[Replace with css id of element]', showDes: true },
     { value: 'LL:gvp-xpath', description: ':"replace with xpath value"', showDes: true },
     { value: 'LL:gvp-className:number', description: ':[Replace with css class of element]', showDes: true },
     { value: 'LL:gvp-id:number', description: ':[Replace with css id of element]', showDes: true },
     { value: 'LL:gvp-xpath:number', description: ':"replace with xpath value"', showDes: true },
];
export const NATIVE_BROWSER_CONDITIONS = {
     DATE_FUNCTION: [
          { VALUE: 'exav', TEXT: 'exists with any value' },
          { VALUE: 'nex', TEXT: 'does not exist' },
          { VALUE: 'itp', TEXT: 'is in the past' },
          { VALUE: 'itft', TEXT: 'is in the future' },
     ],
     DEFAULT: [
          // { VALUE: 'true', TEXT: 'true' },
          // { VALUE: 'false', TEXT: 'false' },
          { VALUE: 'ct', TEXT: 'contains' },
          { VALUE: 'nct', TEXT: 'not contain' },
          { VALUE: 'eq', TEXT: 'equals to' },
          { VALUE: 'neq', TEXT: 'not equal to' },
          { VALUE: 'exav', TEXT: 'exists with any value' },
          { VALUE: 'nex', TEXT: 'does not exist' },
     ],
};
export const SESSION_STORAGE_SESSION_EXPIRED = 'session_expired';
export const SESSION_STORAGE_GOOGLE_INFO = 'session_google_info';
export const SESSION_STORAGE_GOOGLE_REDIRECT_LOGIN = 'session_google_redirect_login';
export const SESSION_STORAGE_GOOGLE_REDIRECT_DATA_USER = 'session_google_redirect_data_user';
export const STEP_PREVIOUS_CREATE_TICKET = 'stepPreviousCreateTicket';
export const HIDE_TICKET_FEATURES = 'hideTicketFeatures';
export const TICKET_STATUSES = ['Open', 'Open', 'Response', 'Reopened', 'Closed'];
export const ACCOUNTS_TRIAL_STATUS = ['No', 'Yes', 'Past'];
export const LOCAL_SUPPORT_PUSHER_REDIRECT = 'support_pusher_redirect';
export const PARTNER_STATUSES = ['Waiting', 'Accepted', 'Rejected'];
export const USER_COURSE_STATUSES = ['In Progress', 'Completed'];
export const SUN_EDITOR_EMPTY_CONTENT = '<p><br></p>';
export const CUSTOM_FORM_TARGET_RULE_OPERATORS = [
     { VALUE: 'nct', TEXT: 'does not contain' },
     { VALUE: 'ct', TEXT: 'contains' },
     { VALUE: 'bw', TEXT: 'begins with' },
     { VALUE: 'em', TEXT: 'exactly matches' },
     { VALUE: 'ew', TEXT: 'ends with' },
];
export const CUSTOM_FORM_DEFAULT_RULES = [
     {
          conjunction: '',
          g0: {
               attribute: 'CSS Class',
               operator: 'ct',
               value: 'listenlayer',
               conjunction: 'or',
          },
          g1: {
               attribute: 'CSS ID',
               operator: 'ct',
               value: 'listenlayer',
               conjunction: '',
          },
     },
];

export const DEFAULT_TARGET_RULES = [
     {
          conjunction: '',
          g0: {
               attribute: '',
               operator: '',
               value: '',
               conjunction: '',
          },
     },
];

export const NOTIFICATION_STATUS = {
     READ: 'Read',
     UNREAD: 'Unread',
};

export const NOTIFICATION_CONFIG = {
     SAVE_NOTIFICATION: 'Save Notification',
     SEND_VIA_EMAIL: 'Send Via Email',
     SEND_VIA_SLACK: 'Send Via Slack',
};

export const LOCAL_GTM_ACCESS_TOKEN = 'gtmAccessToken';
export const LOCAL_GTM_USER_EMAIL = 'gtmUserEmail';

export const ACCOUNT_ANSWER_QUESTION = {
     userWork: 'User Work',
     userSizeCompany: 'User Size Company',
     userDesRole: 'User Describes Role',
};

export const ACCOUNT_DYN_ANSWER_QUESTION = {
     userRevenue: 'User Revenue',
     userIdentify: 'User Identify',
     userCompliant: 'User Compliant',
     userReplace: 'User Replace',
};

export const ACCOUNT_DYN = {
     userAutomates: 'User Automates',
     userCentralize: 'User Centralize',
     userAnalytics: 'User Analytics',
};

export const ACCOUNT_DATA_DYN = {
     userSessionReplay: 'User Session Replay',
     userConsent: 'User Consent',
};

export const ACCOUNT_INFO_STEPS = {
     userWork: 'User Work',
     userSizeCompany: 'User Size Company',
     userDesRole: 'User Describes Role',
     userRevenue: 'User Revenue',
     userIdentify: 'User Identify',
     userCompliant: 'User Compliant',
     userReplace: 'User Replace',
     userAutomates: 'User Automates',
     userCentralize: 'User Centralize',
     userAnalytics: 'User Analytics',
     // userSessionReplay: 'User Session Replay',
     // userConsent: 'User Consent',
};

export const ACCOUNT_DYN_QUESTION = {
     userRevenue: 'User Revenue',
     userIdentify: 'User Identify',
     userCompliant: 'User Compliant',
     userReplace: 'User Replace',
     userAutomates: 'User Automates',
     userCentralize: 'User Centralize',
     userAnalytics: 'User Analytics',
     // userSessionReplay: 'User Session Replay',
     // userConsent: 'User Consent',
};

export const CREATE_ACCOUNT_MAIN_STEPS = {
     ...ACCOUNT_ANSWER_QUESTION,
     ...ACCOUNT_DYN_ANSWER_QUESTION,
     name: 'Name',
     timeZone: 'Time Zone',
     ...ACCOUNT_DYN,
     domain: 'Domain',
     gtmConnection: 'GTM Connection',
     configureMeasurement: 'Configure Measurement',
     chooseDestination: 'Choose Destinations',
     // ...ACCOUNT_DATA_DYN,
     accountPackage: 'Account Package',
};
export const ACCOUNT_INFO_STEPS_ARR = Object.entries(ACCOUNT_INFO_STEPS).map(([_, value]) => value);
export const ACCOUNT_QUESTION_ARR = Object.entries(ACCOUNT_ANSWER_QUESTION).map(([_, value]) => value);
export const ACCOUNT_DYN_QUESTION_ARR = Object.entries(ACCOUNT_DYN_QUESTION).map(([_, value]) => value);
export const CREATE_ACCOUNT_MAIN_STEPS_ARR = Object.entries(CREATE_ACCOUNT_MAIN_STEPS).map(([_, value]) => value);

export const DEFAULT_FILTER_METRICS = [
     {
          label: 'New Users',
          value: 'visitorsStart',
     },
     // {
     //     label: "New Sessions",
     //     value: "sessionsStart"
     // },
     {
          label: 'Active Users',
          value: 'visitors',
     },
     {
          label: 'Sessions',
          value: 'sessions',
     },
];

export const CONSENT_METHOD = [
     {
          title: 'Explicit Consent',
          value: 'explicitConsent',
          description:
               "Explicit Consent means that users must grant you certain rights to track them or collect data. We can listen for a user's consent to be defined or changed - configure the settings under Consent Listener.",
          tooltip: "User's must provide explicit consent for analytics storage when they visit your website.",
     },
     {
          title: 'Implied Consent',
          value: 'impliedConsent',
          description:
               "Implied consent means that users who use your website grant implied permission for you to track their activity. In most cases, you must still give them the ability to remove their consent. We can listen for a user's consent to be defined or changed - configure the settings under Consent Listener.",
          tooltip: 'Consent for analytics storage is implied based on a user visiting and engaging with your website; however, you may be required to provide them with notice and a way to opt out.',
     },
     {
          title: 'Open Consent',
          value: 'openConsent',
          description:
               "Open Consent means that you're free to track user behavior and collect data without adhering to regional consent and data requirements. We encourage you to respect all user's privacy, which is why we provide flexibility in your configuration.",
          tooltip: "User's may be tracked freely without consent requirements.",
     },
];

export const CONSENT_IDENTIFICATION_BUTTON = [
     {
          title: 'Identify Users',
          value: 'identifyVisitors',
          // tooltip: "ListenLayer will identify each unique user, assign them a user ID, and use cookies and other identifiers to measure their activity over time."
     },
     {
          title: 'Anonymize Users',
          value: 'anonymizeVisitors',
          // tooltip: 'Cookies will not be used to identify users over time and all Personal Data features will be disabled. A time-limited hash of characteristics will be used to group multiple events and sessions anonymously against a "user." Each identified "user" will last for no more than 48 hours. All data collected will be anonymous and cannot be traced back to a real person.'
     },
     {
          title: "Don't Track Users",
          value: 'dontTrackVisitors',
          // tooltip: "ListenLayer will not track individual users and will not use cookies or identifiers. Data layer events will fire in the user's browser and events will be counted for aggregate reporting; however some Listeners' data layer activity will generate excessive events. We’ll calculate website users based on the average events from tracked individuals on your website. We do not recommend this setting because we believe privacy compliance can be achieved by using anonymous tracking."
     },
];

export const CONSENT_IDENTIFICATION_LEVEL = [
     {
          title: 'Strong',
          value: 'identifyStrong',
          tooltip: "ListenLayer will identify users based on a first-party cookie made redundant through unique identifiers from the user's device. This allows you to identify users more accurately over time, even in some situations where cookies are not present.",
     },
     {
          title: 'Moderate',
          value: 'identifyModerate',
          tooltip: 'ListenLayer will idenfity users using cookies. If the cookie is removed the user will be assigned a new user ID.',
     },
];

export const CONSENT_REGION_TYPE = [
     { label: 'Country Subregion', value: 'countrySubregion' },
     { label: 'Country', value: 'country' },
     { label: 'Economic Region', value: 'economicRegion' },
     { label: 'Continent', value: 'continent' },
     { label: 'World Wide', value: 'worldWide' },
];

export const CONSENT_TIME_REGIONS = [
     { label: 'United States', value: 'unitedStates' },
     { label: 'North America', value: 'northAmerica' },
     { label: 'Europe', value: 'europe' },
     { label: 'Canada', value: 'canada' },
     { label: 'Australia', value: 'australia' },
];

export const CONSENT_REGION_TYPES = [
     {
          label: 'State/Province',
          value: 'stateProvinces',
     },
     {
          label: 'Country',
          value: 'country',
     },
     {
          label: 'Regulatory Region',
          value: 'regulatoryRegion',
     },
     {
          label: 'Continent',
          value: 'continent',
     },
     {
          label: 'Worldwide',
          value: 'worldWide',
     },
];

export const DATAS_CONTINENT = [
     {
          label: 'Asia',
          value: 'Asia',
     },
     {
          label: 'Africa',
          value: 'Africa',
     },
     {
          label: 'Antarctica',
          value: 'Antarctica',
     },
     {
          label: 'Australia',
          value: 'Australia',
     },
     {
          label: 'Europe',
          value: 'Europe',
     },
     {
          label: 'North America',
          value: 'North America',
     },
     {
          label: 'South America',
          value: 'South America',
     },
];

export const DATAS_COUNTRIES_REGION = [
     'Austria',
     'Belgium',
     'Bulgaria',
     'Croatia',
     'Cyprus',
     'Czechia (Czech Republic)',
     'Denmark',
     'Estonia',
     'Finland',
     'France',
     'Germany',
     'Greece',
     'Hungary',
     'Ireland',
     'Italy',
     'Latvia',
     'Lithuania',
     'Luxembourg',
     'Malta',
     'Netherlands',
     'Poland',
     'Portugal',
     'Romania',
     'Slovakia',
     'Slovenia',
     'Spain',
     'Sweden',
     'United Kingdom',
];
export const LATEST_PACKAGE_VERSION = 3;
export const CONSENT_LISTENER_OPTIONS = [
     // { label: 'Complianz', value: 'complianz' },
     { label: 'Consent Manager', value: 'consentManager' },
     { label: 'Cookiebot', value: 'cookiebot' },
     { label: 'CookieYes', value: 'cookieYes' },
     { label: 'Cookie Notice', value: 'cookieNotice' },
     // { label: 'Ketch', value: 'ketch' },
     { label: 'OneTrust', value: 'oneTrust' },
     // { label: 'TrustArc', value: 'trustArc' },
     // { label: 'Osana', value: 'osana' },
     // { label: 'UserCentrics', value: 'userCentrics' },
     // { label: 'EU Cookie Law', value: 'euCookieLaw' },
];

export const LOCAL_PREVIEW = 'llPreview';
export const LOCAL_BEGIN_TEST = 'llBeginTest';
export const LOCAL_PREVIEW_DOMAIN = 'previewDomain';
export const SCHEDULE_DEMO = 'scheduleDemo';
export const STORE_IN_BROWSER = {
     KEY: 'Store in Browser',
     STORED: 'Stored',
     COOKIE: 'Store in Cookie',
     SESSION: 'Store in Session',
     LOCAL_STORAGE: 'Store in Local Storage',
};
export const COPY_TO_CLIPBOARD = {
     KEY: 'Copy to Clipboard',
     COPIED: 'Copied',
     NAME: 'Copy Name',
     VALUE: 'Copy Value',
     PATH: 'Copy Path',
     OBJECT: 'Copy Object',
};
export const BROWSER_ACTION = {
     KEY: 'browserAction',
     BACK: 'back',
     FORWARD: 'forward',
};

export const CONSENT_TYPE_INIT = [
     { value: 'ad_storage', label: 'ad_storage' },
     { value: 'analytics_storage', label: 'analytics_storage' },
     { value: 'functionality_storage', label: 'functionality_storage' },
     { value: 'personalization_storage', label: 'personalization_storage' },
     { value: 'security_storage', label: 'security_storage' },
];

export const POPUP_TYPE = {
     ENABLE_FEATURE: 'enable-feature',
     DISABLE_FEATURE: 'disable-feature',
};

export const OPTION = {
     FIRST_OPTION: 'first-option',
     SECOND_OPTION: 'second-option',
};

export const ACCORDION_TYPES = {
     CONSENT_LISTENER: 'consent-listener',
     CONSENT_RULES: 'consent-rules',
     CONSENT_PRIVACY: 'consent-privacy',
     CONSENT_SELL: 'consent-sell',
     DATA_DELETION_REQUESTS: 'data-deletion-requests',
     TARGET_FORMS: 'target-forms',
     TARGET_ELEMENT_CLICK: 'target-element-click',
     IFRAME_FORMS: 'iframe-forms',
     GEOGRAPHIC_ACCURACY: 'geographic-accuracy',
     USER_SOURCE_RULES: 'user-source-rules',
     CUSTOM_USER_SOURCES: 'custom-user-sources',
     ADDITIONAL_CODE_REQUIRED: 'additional-code-required',
     NATIVE_DATA_LAYER: 'native-data-layers',
     NATIVE_BROWSER_STORAGE: 'native-browser-storage',
     CLICK_SETTINGS: 'click-settings',
     WEATHER_SETTINGS: 'weather-settings',
     VIDEO_SETTINGS: 'video-settings',
     EVENTS: 'events',
     VARIABLES: 'variables',
     SELECTOR_VARIABLES: 'selector-variables',
     POPULATE_FIELDS: 'populate-fields',
     SELECTOR_VALUES: 'selector-values',
     CUSTOM_VARIABLES: 'custom-variables',
     CUSTOM_VALUE_RULES: 'custom-value-rules',
     BROWSER_STORAGE_VARIABLES: 'browser-storage-variables',
     CONVERSIONS: 'conversions',
     TRIGGERS: 'triggers',
     CUSTOM_MONITORING_RULES: 'custom-monitoring-rules',
     CATEGORIZE_PAGES: 'categorize-pages',
     TRACK_YOUR_ORDER_STATUS_PAGE: 'track-your-order-status-page',
     CLICK_ID_SETTINGS: 'click-id-settings',
     PERSONAL_DATA: 'personal-data',
     ENGAGEMENT_SETTINGS: 'engagement-settings',
     SCROLL_DEPTH_SETTINGS: 'scroll-depth-settings',
     DESTINATION_SEND_DATA_USING: 'destination-send-data-using',
     DESTINATION_GENERAL_SETTINGS: 'destination-general-settings',
     DESTINATION_DATA_ACTIONS: 'destination-data-actions',
     DESTINATION_CONVERSIONS: 'destination-conversions',
     DESTINATION_ECOMMERCE: 'destination-eCommerce',
     GTM_SETTINGS: 'gtm-settings',
     GTM_VARIABLES: 'gtm-variables',
     GTM_TRIGGERS: 'gtm-triggers',
     GTM_TAGS: 'gtm-tags',
     GTM_CHANGELOG: 'gtm-changelog',
     PERSONAL_DATA_GENERAL_SETTINGS: 'personal-data-general-settings',
     PERSONAL_DATA_DATA_ELEMENTS: 'personal-data-data-elements',
     FORM_REQUIRING_ACTION: 'form-requiring-action',
     FORM_CATEGORIES: 'form-categories',
     FORM_INVENTORY: 'form-inventory',
     FORM_RULES: 'form-rules',
     FORM_DEFAULT: 'form-default',
     OTHER_DETAIL_EVENT: 'record-profile-other-detail-event',
     OTHER_DETAIL_PERSON: 'record-profile-other-detail-person',
     REVEAL_PEOPLE: 'reveal-people',
     REVEAL_COMPANIES: 'reveal-companies',
     DIMENSIONS: 'dimensions',
     METRICS: 'metrics',
     COMPANY_DESCRIPTION: 'company-description',
     COMPANY_SIZE: 'company_size',
     COMPANY_INDUSTRY_DETAILS: 'company_industry_details',
     COMPANY_NAICS_DETAILS: 'company-naics-details',
     COMPANY_LOCATION: 'company_location',
     COMPANY_SOCIAL: 'company_social',
     COMPANY_OTHER_DATA: 'company_other_data',
     RECORD_PROFILE_TABLE_COMPANY: 'record_profile_table_company',
     RECORD_PROFILE_TABLE_ALL_PAGE: 'record_profile_table_all_page',
     RECORD_PROFILE_TABLE_SOURCE: 'record_profile_table_source',
     RECORD_PROFILE_TABLE_CONVERSION: 'record_profile_table_conversion',
     RECORD_PROFILE_TABLE_REVEAL_PEOPLE: 'record_profile_table_reveal_people',
     RECORD_PROFILE_TABLE_FORM_LOCATION: 'record_profile_table_form_location',
     PEOPLE_OTHER_DATA: 'people_other_data',
     RECORD_PROFILE_TABLE_EVENT: 'record_profile_table_event',
     RECORD_PROFILE_TABLE_SESSION: 'record_profile_table_session',
     RECORD_PROFILE_TABLE_USER: 'record_profile_table_user',
     RECORD_PROFILE_TABLE_PEOPLE: 'record_profile_table_people',
     EXTERNAL_DATA_CONNECTED_ACCOUNTS: 'external_data_connected_accounts',
     EXTERNAL_DATA_LOGS: 'external_data_logs',
     EXTERNAL_DATA_OBJECT_IMPORTS: 'external_data_object_imports',
     RECORD_PROFILE_CHART: 'record_profile_chart',
     RECORD_PROFILE_FORM_STEP: 'record_profile_form_step',
     SEGMENT: 'segment',
};

export const GTM_WIZARD_STEPS = {
     gtmConnection: 'GTM Connection',
     gtmSelectAccount: 'GTM Select Account',
     gtmSelectContainer: 'GTM Select Container',
     gtmCreateData: 'GTM Create Data', // Inform user we're about to creating variable, trigger or tag in GTM
     gtmRemoveData: 'GTM Remove Data',
     gtmPublishData: 'GTM Publish Data',
     gtmVerifyChanges: 'GTM Verify Changes',
     gtmMakeChanges: 'GTM Make Changes',
     gtmExists: 'GTM Exists',
     gtmSuccess: 'GTM Success',
     gtmUnknownFailure: 'GTM Unknown Failure',
     gtmNoPublishingRights: 'GTM No Publishing Rights',
     gtmPauseTag: 'GTM Pause Tag',
};

export const GTM_WIZARD_TYPE = {
     VARIABLE: 'Variable',
     TRIGGER: 'Trigger',
     TRIGGER_CONVERSION: 'Trigger Conversion',
     DATA_ACTION: 'Data Action',
     EACH_DATA_ACTION: 'Each Data Action',
     DESTINATION_CONVERSION: 'Destination Conversion',
     DESTINATION_ECOMMERCE: 'Destination eCommerce',
};

export const GTM_VARIABLE_IN_LL_TYPE = {
     EVENT: 'Event',
     SPECIFIC_TO_A_LISTENER: 'Specific to a Listener',
     CUSTOM: 'Custom',
     USER_PERSON: 'User Person',
     TRIGGER: 'Trigger',
};
export const DATA_ACTION_TEMPLATES = {
     LINKEDIN_INSIGHTS_TAG: 'Linkedin Insights Tag',
     LINKEDIN_CONVERSION_EVENT: 'Linkedin Conversion Event',
     CUSTOM_SCRIPT: 'Custom Script',
     GADS_REMARKETING_TAG: 'GAds Remarketing Tag',
     CONVERSION_LINKER: 'Conversion Linker',
     GADS_CONVERSION_TRACKING_TAG: 'GAds Conversion Tracking Tag',
     GOOGLE_ANALYTICS_GA4_CONFIGURATION: 'Google Analytics GA4 Configuration',
     GOOGLE_ANALYTICS_GA4_EVENT: 'Google Analytics GA4 Event',
     FACEBOOK_PIXEL: 'Facebook Pixel',
     FACEBOOK_EVENT: 'Facebook Event',
     FACEBOOK_PURCHASE_EVENT: 'Facebook Purchase Event',
     MICROSOFT_UET_TAG: 'MsAds UET Tag',
     MICROSOFT_EVENT_TAG: 'MsAds Event Tag',
     MICROSOFT_PURCHASE_EVENT_TAG: 'MsAds Purchase Event Tag',
};
export const TEMPLATE_DATA_ACTION_OPTIONS = {
     linkedInAds: [
          { value: DATA_ACTION_TEMPLATES.LINKEDIN_INSIGHTS_TAG, label: DATA_ACTION_TEMPLATES.LINKEDIN_INSIGHTS_TAG },
          { value: DATA_ACTION_TEMPLATES.LINKEDIN_CONVERSION_EVENT, label: DATA_ACTION_TEMPLATES.LINKEDIN_CONVERSION_EVENT },
          { value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT },
     ],
     googleAds: [
          { value: DATA_ACTION_TEMPLATES.GADS_REMARKETING_TAG, label: DATA_ACTION_TEMPLATES.GADS_REMARKETING_TAG },
          { value: DATA_ACTION_TEMPLATES.CONVERSION_LINKER, label: DATA_ACTION_TEMPLATES.CONVERSION_LINKER },
          { value: DATA_ACTION_TEMPLATES.GADS_CONVERSION_TRACKING_TAG, label: DATA_ACTION_TEMPLATES.GADS_CONVERSION_TRACKING_TAG },
          { value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT },
     ],
     googleAnalytics4: [
          { value: DATA_ACTION_TEMPLATES.GOOGLE_ANALYTICS_GA4_CONFIGURATION, label: DATA_ACTION_TEMPLATES.GOOGLE_ANALYTICS_GA4_CONFIGURATION },
          { value: DATA_ACTION_TEMPLATES.GOOGLE_ANALYTICS_GA4_EVENT, label: DATA_ACTION_TEMPLATES.GOOGLE_ANALYTICS_GA4_EVENT },
          { value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT },
     ],
     facebookAds: [
          { value: DATA_ACTION_TEMPLATES.FACEBOOK_PIXEL, label: DATA_ACTION_TEMPLATES.FACEBOOK_PIXEL },
          { value: DATA_ACTION_TEMPLATES.FACEBOOK_EVENT, label: DATA_ACTION_TEMPLATES.FACEBOOK_EVENT },
          { value: DATA_ACTION_TEMPLATES.FACEBOOK_PURCHASE_EVENT, label: DATA_ACTION_TEMPLATES.FACEBOOK_PURCHASE_EVENT },
     ],
     microsoftAds: [
          { value: DATA_ACTION_TEMPLATES.MICROSOFT_UET_TAG, label: DATA_ACTION_TEMPLATES.MICROSOFT_UET_TAG },
          { value: DATA_ACTION_TEMPLATES.MICROSOFT_EVENT_TAG, label: DATA_ACTION_TEMPLATES.MICROSOFT_EVENT_TAG },
          { value: DATA_ACTION_TEMPLATES.MICROSOFT_PURCHASE_EVENT_TAG, label: DATA_ACTION_TEMPLATES.MICROSOFT_PURCHASE_EVENT_TAG },
          { value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT },
     ],
     S3: [{ value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT }],
     slack: [{ value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT }],
     Zapier: [{ value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT }],
};
export const CUSTOM_DESTINATION_TEMPLATES = [{ value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT }];

export const SCOPES_OBJ_REPORT = [
     {
          value: 'Event',
          label: 'Event',
     },
     {
          value: 'Session',
          label: 'Session',
     },
     {
          value: 'User',
          label: 'User',
     },
     {
          value: 'People',
          label: 'People',
     },
];

export const SCOPES_OBJ_DATA_SETTINGS = [
     {
          value: 'Event',
          label: 'Event',
     },
     {
          value: 'Session',
          label: 'Session',
     },
     {
          value: 'User',
          label: 'User',
     },
     {
          value: 'People',
          label: 'People',
     },
     {
          value: 'Eternal Object',
          label: 'Eternal Object',
     },
     {
          value: 'Revealed Companies',
          label: 'Revealed Companies',
     },
];

export const REPORT_TARGET_OPTIONS = [
     {
          value: 'FOL',
          label: "First value in object's life",
     },
     {
          value: 'LOL',
          label: "Latest value in object's life",
     },
     {
          value: 'POL',
          label: "Path of all values in object's life",
     },
     {
          value: 'AOL',
          label: "All of the values in object's life",
     },
];

export const REPORT_TARGET_CONVERSION_OPTION = [
     {
          value: 'LOL_EVENT',
          label: "The Value when the Conversion occurs",
     },
     {
          value: 'FOL',
          label: "The First Value Leading to the Conversion",
     },
     {
          value: 'LOL',
          label: "The Last Value Leading to the Conversions",
     },
     {
          value: 'POL',
          label: "All Values Leading to the Conversion",
     },
];

export const TEMPLATE_GA4_EVENT = [{ value: 'none', label: 'None-Manually Set ID' }];

export const TEMPLATE_FACEBOOK_EVENT = [
     {
          value: 'AddPaymentInfo',
          label: 'AddPaymentInfo',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_ids', label: 'content_ids' },
               { value: 'contents', label: 'contents' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'AddToCart',
          label: 'AddToCart',
          parameters: [
               { value: 'content_ids', label: 'content_ids' },
               { value: 'content_name', label: 'content_name' },
               { value: 'content_type', label: 'content_type' },
               { value: 'contents', label: 'contents' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'AddToWishlist',
          label: 'AddToWishlist',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_name', label: 'content_name' },
               { value: 'content_ids', label: 'content_ids' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'CompleteRegistration',
          label: 'CompleteRegistration',
          parameters: [
               { value: 'content_name', label: 'content_name' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
               { value: 'status', label: 'status' },
          ],
     },
     {
          value: 'Contact',
          label: 'Contact',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'CustomizeProduct',
          label: 'CustomizeProduct',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     { value: 'Donate', label: 'Donate', parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }] },
     {
          value: 'FindLocation',
          label: 'FindLocation',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'InitiateCheckout',
          label: 'InitiateCheckout',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_ids', label: 'content_ids' },
               { value: 'contents', label: 'contents' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
               { value: 'num_items', label: 'num_items' },
          ],
     },
     {
          value: 'Lead',
          label: 'Lead',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_name', label: 'content_name' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'Purchase',
          label: 'Purchase',
          parameters: [
               { value: 'content_name', label: 'content_name' },
               { value: 'content_ids', label: 'content_ids' },
               { value: 'content_type', label: 'content_type' },
               { value: 'contents', label: 'contents' },
               { value: 'currency', label: 'currency', required: true },
               { value: 'value', label: 'value', required: true },
               { value: 'num_items', label: 'num_items' },
          ],
     },
     {
          value: 'Schedule',
          label: 'Schedule',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'Search',
          label: 'Search',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_ids', label: 'content_ids', required: true },
               { value: 'content_type', label: 'content_type', required: true },
               { value: 'search_string', label: 'search_string' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'StartTrial',
          label: 'StartTrial',
          parameters: [
               { value: 'predicted_ltv', label: 'predicted_ltv' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'SubmitApplication',
          label: 'SubmitApplication',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'Subscribe',
          label: 'Subscribe',
          parameters: [
               { value: 'predicted_ltv', label: 'predicted_ltv' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'ViewContent',
          label: 'ViewContent',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_name', label: 'content_name' },
               { value: 'content_ids', label: 'content_ids', required: true },
               { value: 'content_type', label: 'content_type', required: true },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'custom',
          label: 'Custom',
          eventName: '',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
];

export const DEFAULT_VALUE_FACEBOOK_PIXEL = [
     { name: 'email', label: 'email', type: 'automatic', value: '{{personValues.primary.email}}', code: 'em' },
     { name: 'phone', label: 'phone', type: 'automatic', value: '{{personValues.primary.phone}}', code: 'ph' },
     // { name: 'firstName', label: 'first name', type: 'custom', value: '', code: 'fn' },
     // { name: 'lastName', label: 'last name', type: 'custom', value: '', code: 'ln' },
     // { name: 'addressPostalCode', label: 'zip code', type: 'custom', value: '', code: 'zp' },
     // { name: 'addressCountry', label: 'country', type: 'custom', value: '', code: 'country' },
     // { name: 'addressRegion', label: 'state or province', type: 'custom', value: '', code: 'st' },
     // { name: 'addressCity', label: 'city', type: 'custom', value: '', code: 'ct' },
];

export const FACEBOOK_CONVERSION_EVENTS = [
     {
          value: 'CompleteRegistration',
          label: 'CompleteRegistration',
          parameters: [
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
               { value: 'status', label: 'status', customValue: '', type: 'custom' },
          ],
     },
     {
          value: 'Contact',
          label: 'Contact',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'FindLocation',
          label: 'FindLocation',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'Lead',
          label: 'Lead',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
          ],
     },
     {
          value: 'Schedule',
          label: 'Schedule',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'Search',
          label: 'Search',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', required: true, type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'search_string', label: 'search_string', customValue: '', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
          ],
     },
     {
          value: 'StartTrial',
          label: 'StartTrial',
          parameters: [
               { value: 'predicted_ltv', label: 'predicted_ltv', customValue: '', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
          ],
     },
     {
          value: 'SubmitApplication',
          label: 'SubmitApplication',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'ViewContent',
          label: 'ViewContent',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', required: true, customValue: '', type: 'custom' },
               { value: 'content_type', label: 'content_type', required: true, customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
          ],
     },
     {
          value: 'Purchase',
          label: 'Purchase',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'content_type', label: 'content_type', customValue: '', type: 'custom' },
               { value: 'num_items', label: 'num_items', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'custom', required: true },
               { value: 'value', label: 'value', type: 'custom', required: true },
          ],
     },
     {
          value: 'AddToCart',
          label: 'AddToCart',
          parameters: [
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_type', label: 'content_type', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'custom' },
               { value: 'value', label: 'value', type: 'custom' },
          ],
     },
     {
          value: 'InitiateCheckout',
          label: 'InitiateCheckout',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'num_items', label: 'num_items', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'custom' },
               { value: 'value', label: 'value', type: 'custom' },
          ],
     },
     {
          value: 'ViewCart',
          label: 'ViewCart',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_type', label: 'content_type', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'custom' },
               { value: 'value', label: 'value', type: 'custom' },
          ],
     },
     {
          value: 'ViewProduct',
          label: 'ViewProduct',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_type', label: 'content_type', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'custom' },
               { value: 'value', label: 'value', type: 'custom' },
          ],
     },
     {
          value: 'Send Custom Event',
          label: 'Send Custom Event',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
];

export const FACEBOOK_CONVERSION_PARAMETERS = [
     { value: 'content_name', label: 'content_name' },
     { value: 'content_type', label: 'content_type' },
     { value: 'currency', label: 'currency' },
     { value: 'value', label: 'value' },
     { value: 'status', label: 'status' },
     { value: 'content_category', label: 'content_category' },
     { value: 'predicted_ltv', label: 'predicted_ltv' },
     { value: 'content_ids', label: 'content_ids' },
     { value: 'contents', label: 'contents' },
     { value: 'search_string', label: 'search_string' },
     { value: 'num_items', label: 'num_items' },
];
export const MICROSOFT_CONVERSION_PARAMETERS = [
     { value: '', label: 'select' },
     { value: 'event_category', label: 'event_category' },
     { value: 'event_label', label: 'event_label' },
     { value: 'event_value', label: 'event_value' },
     { value: 'currency', label: 'currency' },
];

export const FACEBOOK_TYPE_VALUE = [
     { value: 'automatic', label: 'automatic' },
     { value: 'custom', label: 'custom' },
];
export const FACEBOOK_VALUE = [
     { value: '', label: 'select', code: '' },
     { value: 'email', label: 'email', code: 'em' },
     { value: 'phone', label: 'phone', code: 'ph' },
     { value: 'firstName', label: 'first name', code: 'fn' },
     { value: 'lastName', label: 'last name', code: 'ln' },
     { value: 'gender', label: 'gender', code: 'ge' },
     { value: 'birthdate', label: 'birthdate', code: 'db' },
     { value: 'addressPostalCode', label: 'zip code', code: 'zp' },
     { value: 'addressCountry', label: 'country', code: 'country' },
     { value: 'addressRegion', label: 'state or province', code: 'st' },
     { value: 'addressCity', label: 'city', code: 'ct' },
];
export const MICROSOFT_PARAMETER_OPTIONS = [
     { label: 'select', value: '' },
     { label: 'event_category', value: 'event_category' },
     { label: 'event_label', value: 'event_label' },
];
export const MICROSOFT_PARAMETER_OPTIONS_HAVE_PURCHASE = [
     { label: 'select', value: '' },
     { label: 'event_category', value: 'event_category' },
     { label: 'event_label', value: 'event_label' },
     { label: 'revenue_value', value: 'revenue_value' },
];
export const DEFAULT_CURRENCIES_AND_CODES = [
     { value: 'USD', label: 'USD - US Dollars' },
     { value: 'AED', label: 'AED - United Arab Emirates Dirham' },
     { value: 'ARS', label: 'ARS - Argentine Pesos' },
     { value: 'AUD', label: 'AUD - Australian Dollars' },
     { value: 'BGN', label: 'BGN - Bulgarian Lev' },
     { value: 'BOB', label: 'BOB - Bolivian Boliviano' },
     { value: 'BRL', label: 'BRL - Brazilian Real' },
     { value: 'CAD', label: 'CAD - Canadian Dollars' },
     { value: 'CHF', label: 'CHF - Swiss Francs' },
     { value: 'CLP', label: 'CLP - Chilean Peso' },
     { value: 'CNY', label: 'CNY - Yuan Renminbi' },
     { value: 'COP', label: 'COP - Colombian Peso' },
     { value: 'CZK', label: 'CZK - Czech Koruna' },
     { value: 'DKK', label: 'DKK - Denmark Kroner' },
     { value: 'EGP', label: 'EGP - Egyptian Pound' },
     { value: 'EUR', label: 'EUR - Euros' },
     { value: 'FRF', label: 'FRF - French Francs' },
     { value: 'GBP', label: 'GBP - British Pounds' },
     { value: 'HKD', label: 'HKD - Hong Kong Dollars' },
     { value: 'HRK', label: 'HRK - Croatian Kuna' },
     { value: 'HUF', label: 'HUF - Hungarian Forint' },
     { value: 'IDR', label: 'IDR - Indonesian Rupiah' },
     { value: 'ILS', label: 'ILS - Israeli Shekel' },
     { value: 'INR', label: 'INR - Indian Rupee' },
     { value: 'JPY', label: 'JPY - Japanese Yen' },
     { value: 'KRW', label: 'KRW - South Korean Won' },
     { value: 'LTL', label: 'LTL - Lithuanian Litas' },
     { value: 'MAD', label: 'MAD - Moroccan Dirham' },
     { value: 'MXN', label: 'MXN - Mexican Peso' },
     { value: 'MYR', label: 'MYR - Malaysian Ringgit' },
     { value: 'NOK', label: 'NOK - Norway Kroner' },
     { value: 'NZD', label: 'NZD - New Zealand Dollars' },
     { value: 'PEN', label: 'PEN - Peruvian Nuevo Sol' },
     { value: 'PHP', label: 'PHP - Philippine Peso' },
     { value: 'PKR', label: 'PKR - Pakistan Rupee' },
     { value: 'PLN', label: 'PLN - Polish New Zloty' },
     { value: 'RON', label: 'RON - New Romanian Leu' },
     { value: 'RSD', label: 'RSD - Serbian Dinar' },
     { value: 'RUB', label: 'RUB - Russian Ruble' },
     { value: 'SAR', label: 'SAR - Saudi Riyal' },
     { value: 'SEK', label: 'SEK - Sweden Kronor' },
     { value: 'SGD', label: 'SGD - Singapore Dollars' },
     { value: 'THB', label: 'THB - Thai Baht' },
     { value: 'TRY', label: 'TRY - Turkish Lira' },
     { value: 'TWD', label: 'TWD - New Taiwan Dollar' },
     { value: 'UAH', label: 'UAH - Ukrainian Hryvnia' },
     { value: 'VEF', label: 'VEF - Venezuela Bolivar Fuerte' },
     { value: 'VND', label: 'VND - Vietnamese Dong' },
     { value: 'ZAR', label: 'ZAR - South African Rand' },
];

export const CONVERSION_TYPE = [
     { value: 'primary', label: 'Primary' },
     { value: 'secondary', label: 'Secondary' },
];

export const RULE_GROUP = {
     DATA_LAYER: 'rule',
     BROWSER_STORAGE: 'browser',
     MONITORING: 'monitoring',
     ENGAGEMENT: 'engagement',
     FORM_CATEGORIES: 'formRule',
};

export const NOTICE_TYPE = {
     LISTENER_WARNING: 'listenerWarning',
     LISTENER_COMPLETED: 'listenerCompleted',
     DOMAIN_WARNING: 'domainWarning',
     SCRIPT_WARNING: 'scriptWarning',
};

export const GTM_STATUSES_IN_LL = {
     REGISTERED: 'Registered',
     MODIFIED_IN_LL: 'Modified in LL',
     UNPUBLISHED: 'Unpublished',
     REMOVE_FROM_GTM: 'Remove from GTM',
     PAUSE_FROM_GTM: 'Pause from GTM',
};

export const GTM_ACTIONS = { VERIFY_CHANGES: 'Verify Changes', MAKE_CHANGES: 'Make Changes' };

export const RULE_TYPE = {
     SIMPLE_RULE: 'Simple Rule',
     LOOKUP_RULE: 'Lookup Rule',
};

export const LISTENER_CODE = {
     NATIVE_DATA_LAYERS: 'nativeDataLayers',
     NATIVE_BROWSER_STORAGE: 'nativeBrowserStorage',
     PARDOT_FORM: 'pardotForms',
     ACUITY_SCHEDULING: 'acuityScheduling',
     OUTBOUND_CLICKS: 'outboundClicks',
     INTERNAL_CLICKS: 'internalClicks',
     PDF_CLICKS: 'pdfClicks',
     WOOCOMMERCE: 'woocommerce',
     SHOPIFY: 'shopify',
     CUSTOM_FORMS: 'customForms',
     GRAVITY_FORMS: 'gravityForms',
     CLICK_IDS: 'clickIDs',
     PAGE_VIEW: 'pageView',
     USER_SOURCE: 'userSource',
     HUBSPOT_CTA: 'hubspotCTA',
     URL_PARAMETERS: 'urlParameters',
     USER_GEOLOCATION: 'userGeolocation',
     USER_WEATHER: 'userWeather',
     SESSION_ENGAGEMENT: 'sessionEngagement',
     ELEMENT_CLICK: 'elementClick',
};

export const DESTINATION_CODE = {
     LINKEDIN_ADS: 'linkedInAds',
     GOOGLE_ADS: 'googleAds',
     GA4: 'googleAnalytics4',
     FACEBOOK_ADS: 'facebookAds',
     MICROSOFT_ADS: 'microsoftAds',
};

export const IMAGE_ORDER_STATUS_PAGE = [
     {
          name: 'Step1 Order Status Page',
          url: '/assets/images/Step1OrderStatusPage.png',
     },
     {
          name: 'Step2 Order Status Page',
          url: '/assets/images/Step2OrderStatusPage.png',
     },
     {
          name: 'Step3 Order Status Page',
          url: '/assets/images/Step3OrderStatusPage.png',
     },
];

export const ALLOW_OPTIONS = [
     { label: 'allowed', value: true },
     { label: 'not allowed', value: false },
];

export const PERSONAL_DATA_SETTING_OPTIONS_PLACEHOLDER = {
     cookie: 'enter cookie name',
     'session storage': 'enter session storage key',
     'local storage': 'enter local storage key',
};

export const VIEW_ALL = 'viewAll';

export const CUSTOM_EVENT_NAMES = {
     //woocommerce
     wooAddPaymentGA4: 'add_payment_info',
     wooAddShippingGA4: 'add_shipping_info',
     wooViewProductPageGA4: 'view_item',
     wooClickProductGA4: 'select_item',
     wooViewProductListGA4: 'view_item_list',
     wooRemoveFromCartGA4: 'remove_from_cart',
     wooBeginCheckoutGA4: 'begin_checkout',
     wooPurchaseGA4: 'purchase',
     wooAddToCartGA4: 'add_to_cart',
     wooViewCartGA4: 'view_cart',
     //shopify
     shopifyAddPaymentGA4: 'add_payment_info',
     shopifyAddShippingGA4: 'add_shipping_info',
     shopifyViewProductPageGA4: 'view_item',
     shopifyClickProductGA4: 'select_item',
     shopifyViewProductListGA4: 'view_item_list',
     shopifyRemoveFromCartGA4: 'remove_from_cart',
     shopifyBeginCheckoutGA4: 'begin_checkout',
     shopifyPurchaseGA4: 'purchase',
     shopifyAddToCartGA4: 'add_to_cart',
     shopifyViewCartGA4: 'view_cart',
};

export const CUSTOM_EVENT_NAMES_V2 = {
    //woocommerce
    wooRemoveFromCart: 'remove_from_cart',
    wooViewItemPage: 'view_item',
    wooAddShipping: 'add_shipping_info',
    wooViewItemList: 'view_item_list',
    wooAddPayment: 'add_payment_info',
    wooViewCart: 'view_cart',
    wooAddToCart: 'add_to_cart',
    wooClickItem: 'select_item',
    wooBeginCheckout: 'begin_checkout',
    wooPurchase: 'purchase',
    //shopify
    shopifyRemoveFromCart: 'remove_from_cart',
    shopifyViewItemPage: 'view_item',
    shopifyAddShipping: 'add_shipping_info',
    shopifyViewItemList: 'view_item_list',
    shopifyAddPayment: 'add_payment_info',
    shopifyViewCart: 'view_cart',
    shopifyAddToCart: 'add_to_cart',
    shopifyClickItem: 'select_item',
    shopifyPurchase: 'purchase',
    shopifyBeginCheckout: 'begin_checkout',
};

export const E_COMMERCE_LISTENER_CODES = ['woocommerce', 'shopify'];

export const E_COMMERCE_EVENT_NATIVE = [
     {label: 'View Product List',value: 'view_item_list'},
     {label: 'Select Product',value: 'select_item'},
     {label: 'View Product',value: 'view_item'},
     {label: 'Add to Cart',value: 'add_to_cart'},
     {label: 'View Cart',value: 'view_cart'},
     {label: 'Remove from Cart',value: 'remove_from_cart'},
     {label: 'Begin Checkout',value: 'begin_checkout'},
     {label: 'Add Shipping Info',value: 'add_shipping_info'},
     {label: 'Add Payment Info',value: 'add_payment_info'},
     {label: 'Purchase',value: 'purchase'},
]

export const SESSION_ENGAGEMENT_DEFAULT_RULES = [
     {
          name: 'Conversions',
          description: 'Session with 1 or more conversions are considered engaged.',
          conditions: [
               {
                    conjunction: '',
                    g0: {
                         conjunction: '',
                         type: 'Conversion Count',
                         operator: 'gt',
                         value: 0,
                         key: '',
                    },
               },
          ],
     },
     {
          name: 'Page Views',
          description: 'Sessions with more than 1 pageView are considered engaged.',
          conditions: [
               {
                    conjunction: '',
                    g0: {
                         conjunction: '',
                         type: 'Page View Count',
                         operator: 'gt',
                         value: 1,
                         key: '',
                    },
               },
          ],
     },
     {
          name: 'Page Engagement',
          description: 'Sessions scroll depth of 20% or more and a visible tab of 30 seconds or more are considered engaged.',
          conditions: [
               {
                    conjunction: '',
                    g0: {
                         conjunction: 'and',
                         type: 'Tab Visible',
                         operator: 'gt',
                         value: 29,
                         key: '',
                    },
                    g1: {
                         conjunction: '',
                         type: 'Scroll Depth',
                         operator: 'gt',
                         value: 19,
                         key: '',
                    },
               },
          ],
     },
];

export const PURCHASE_ECOMMERCE_GA4_FEATURES = ['wooPurchaseGA4', 'shopifyPurchaseGA4'];
export const PURCHASE_ECOMMERCE_GA4_FEATURES_V2 = ['wooPurchase', 'shopifyPurchase'];
export const PERSONAL_DATA_SETTING_OPTIONS = [
     { label: 'cookie', value: 'cookie' },
     { label: 'session storage', value: 'session storage' },
     { label: 'local storage', value: 'local storage' },
];

export const PERSONAL_DATA_SETTINGS = [
     {
          label: 'External ID',
          name: 'externalID',
          hasListening: false,
          hasProvideManually: true,
     },
     {
          label: 'Email Address',
          name: 'email',
          hasListening: true,
          hasProvideManually: true,
     },
     {
          label: 'Phone Number',
          name: 'phone',
          hasListening: true,
          hasProvideManually: false,
     },
     {
          label: 'First Name',
          name: 'firstName',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Last Name',
          name: 'lastName',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: Street',
          name: 'addressStreet',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: City',
          name: 'addressCity',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: Region',
          name: 'addressRegion',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: Country',
          name: 'addressCountry',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: Postal Code',
          name: 'addressPostalCode',
          hasListening: false,
          hasProvideManually: false,
     },
];

export const FACEBOOK_PARAMETER_OPTIONS = [
     { label: 'select', value: '' },
     { label: 'content_category', value: 'content_category' },
     { label: 'content_ids', value: 'content_ids' },
     { label: 'contents', value: 'contents' },
     { label: 'currency', value: 'currency' },
     { label: 'value', value: 'value' },
     { label: 'content_name', value: 'content_name' },
     { label: 'content_type', value: 'content_type' },
     { label: 'status', value: 'status' },
     { label: 'num_items', value: 'num_items' },
     { label: 'search_string', value: 'search_string' },
     { label: 'predicted_ltv', value: 'predicted_ltv' },
     { label: 'custom', value: 'custom', customValue: { parameterName: '', parameterValue: '' } },
];

export const CUSTOM_DESTINATION_MODAL_STEPS = {
     INTRO: 'Intro',
     NAME_DESTINATION: 'Name Destination',
};

export const DESTINATION_TYPES = ['Ads', 'Analytics', 'Cloud', 'Chat', 'Other'];

export const INIT_PERSONAL_DATA_DEFAULT = {
     status: false,
     hashedData: false,
     events: {
          identifyPerson: {
               customEventName: '',
               pushEveryTime: false,
          },
     },
     settings: {
          externalID: {
               listening: undefined,
               provideManually: false,
               type: '',
               key: '',
          },
          email: {
               listening: true,
               provideManually: false,
               type: '',
               key: '',
          },
          phone: {
               listening: true,
               provideManually: undefined,
               type: '',
               key: '',
          },
          firstName: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          lastName: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressStreet: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressCity: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressRegion: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressCountry: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressPostalCode: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
     },
};

export const RULE_GROUPS = {
     TRIGGERS: 'triggers',
     CONVERSIONS: 'conversions',
     DATA_ACTION: 'dataAction',
};

export const PLATFORM_ACCOUNT_STATUSES = {
     DRAFT: 'Draft',
     SANDBOX: 'Sandbox',
     IN_TRIAL: 'In Trial',
     PAID: 'Paid',
     DORMANT_PERMANENT: 'Dormant (permanent)',
     DORMANT_TEMPORARY: 'Dormant (temporary)',
     CANCELED: 'Canceled',
     CONTRACT_PLAN: 'Contract Plan',
};

export const DORMANT_STATUSES = [PLATFORM_ACCOUNT_STATUSES.DORMANT_PERMANENT, PLATFORM_ACCOUNT_STATUSES.DORMANT_TEMPORARY];

export const FILTER_OPTIONS = {
     SHOW_ALL: 'Show All',
     SHOW_ENABLED: 'Show Enabled',
};

export const DROPDOWN_TOOLTIPS = {
     'Custom Web Data': 'Write rules to generate custom data in your website’s data layer and browser storage.',
     Clicks: 'Automatically track outbound, internal, document, and phone clicks.',
     Content: 'Track page views, routes, and overall user engagement.',
     'Native Data': 'Use custom data and browser storage values inside ListenLayer.',
     Scheduling: 'Track apps that are used for scheduling events and meetings.',
     'Users & Sources': 'Collect data about your website users (traffic source, geo, device, weather, and click ID like gclid and fbclid)',
};

export const COMPONENT_NAME = {
     LISTENER_LIBRARY: 'listener-library',
     DESTINATION_LIBRARY: 'destination-library',
     DATA_ACTION: 'data-action',
     MONITORING_RULE: 'monitoring-rule',
     CUSTOM_DATA_LAYER_RULE: 'custom-data-layer-rule',
     CUSTOM_DATA_LOOKUP_TABLE: 'custom-data-lookup-table',
     CUSTOM_DATA_LOOKUP_SETTING: 'custom-data-lookup-setting',
     CUSTOM_REPORTS: 'custom-reports',
     BROWSER_STORAGE_RULE: 'browser-storage-rule',
     TRIGGER_RULE: 'trigger-rule',
     CONVERSION_RULE: 'conversion-rule',
     CONSENT_RULES: 'consent-rules',
     FORM_REQUIRING_ACTION: 'form-requiring-action',
     FORM_INVENTORY: 'form-inventory',
     FORM_RULE: 'form-rule',
     FORM_RULE_SETTING: 'form-rule-setting',
     FORM_RULE_TABLE: 'form-rule-table',
     USER_SOURCE_RULE: 'user-source-rule',
     USER_SOURCE_RULE_CUSTOM_SETTING: 'user-source-rule-custom-setting',
     USER_SOURCE_RULE_CUSTOM_TABLE: 'user-source-rule-custom-table',
     POPULATE_FIELDS_RULE: 'populate-fields-rule',
     SESSION_ENGAGEMENT_RULE: 'session-engagement-rule',
     CLICK_SETTING_RULE: 'click-setting-rule',
     CLICK_LOOKUP_TABLE: 'click-lookup-table',
     TRIGGER_LOOKUP_TABLE: 'trigger-lookup-table',
     TRIGGER_LOOKUP_SETTING: 'trigger-lookup-setting',
     CONVERSION_LOOKUP_TABLE: 'conversion-lookup-table',
     CONVERSION_LOOKUP_SETTING: 'conversion-lookup-setting',
     PAGE_DETAIL_MODAL: 'page-detail-modal',
     RECORD_PROFILE_USER: 'record-profile-user',
     RECORD_PROFILE_EVENT: 'record-profile-event',
     RECORD_PROFILE_EVENT_E_COMMERCE: 'record-profile-event-ecommerce',
     RECORD_PROFILE_SESSION: 'record-profile-session',
     RECORD_PROFILE_PERSON: 'record-profile-person',
     RECORD_PROFILE_COMPANY: 'record-profile-company',
     GOAL_MANAGE_TYPES: 'goals-manage-types',
     RECORD_PROFILE_FORM: 'record-profile-form',
     DIMENSION_DETAIL: 'dimension-detail',
     METRIC_DETAIL: 'metric-detail',
     CHANNELS: 'channels',
     EXTERNAL_DATA: 'external-data',
     EXTERNAL_DATA_CONNECTED_ACCOUNTS: 'external-data-connect-account',
     SALESFORCE_CONNECTED_ACCOUNTS: 'salesforce-connect-account',
     EXTERNAL_DATA_OBJECT_IMPORTS: 'external-data-object-imports',
     SEGMENT_DETAIL: 'segment-detail',
};
export const TYPE_SHOW_UNSAVE_CHANGE = {
     CREATE_SIMPLE: 'create-simple',
     EDIT_SIMPLE: 'edit-simple',
     CREATE_LOOKUP: 'create-lookup',
     EDIT_LOOKUP: 'edit-lookup',
     EDIT_TABLE: 'edit-table',
};
export const INVALID_MATCH_BRACKETS_REGEX = /{{([^]*)}/g;

export const ENABLE_SERVER_SIDE = [DESTINATION_CODE.LINKEDIN_ADS, DESTINATION_CODE.GA4, DESTINATION_CODE.FACEBOOK_ADS, DESTINATION_CODE.GOOGLE_ADS];

export const HTMLHINT_RULE_SETS = {
     'tagname-lowercase': true,
     'attr-lowercase': true,
     'tag-pair': true,
     'spec-char-escape': true,
     'id-unique': true,
     'src-not-empty': true,
     'attr-no-duplication': true,
     'title-require': true,
     // "jshint": {}
};
export const TYPE_RULE = {
     CONVERSION_RULE: 'conversion-rule',
     USER_SOURCE_RULE: 'user-source-rule',
     CUSTOM_RULE: 'custom-rule',
};

export const STEPS_WIZARD_CATEGORIES = {
     defineYourFormCategories: 'DefineYourFormCategories',
     categorizingYourForms: 'CategorizingYourForms',
     chooseYourCategories: 'ChooseYourCategories',
     chooseYourFallback: 'ChooseYourFallback',
     selectYourFormListener: 'selectYourFormListener',
     identifyYourForms: 'identifyYourForms',
     categorizeAFewForms: 'CategorizeAFewForms',
     manageFormCategories: 'ManageFormCategories',
     yourDone: 'YourDone',
};

export const STEPS_CONFIG_CONVERSION = {
     startedConfigConversion: 'StartedConfigConversion',
     startConfigConversion: 'StartConfigConversion',
     configListenerChat: 'ConfigListenerChat',
     configListenereComnerce: 'ConfigListenereComnerce',
     selectConversions: 'SelectConversions',
     createConversion: 'CreateConversion',
     buildingConversion: 'BuildingConversion',
     completeCreation: 'CompleteCreation',
     configConversionDone: 'ConfigConversionDone',
};

export const STEPS_CUSTOM_FORM = {
     configureCustomForms: 'ConfigureCustomForms',
     identifyYourForms: 'IdentifyYourForms',
     identifyYourFormsTwo: 'IdentifyYourFormsTwo',
     identifyYourFormsThree: 'IdentifyYourFormsThree',
     identifyYourFormsFour: 'IdentifyYourFormsFour',
     identifyTargetForms: 'IdentifyTargetForm',
     IframeForms: 'IframeForms',
     letTest: 'LetTest',
     waitUp: 'WaitUp',
     waitingForData: 'WaitingForData',
     yourAreAlmostDone: 'YouAreAlmostDone',
     congratulations: 'Congratulations',
     manageCustomForms: 'ManageCustomForms',
};

export const IS_LAYOUT_FORM_CATEGORIES = 'isLayoutFormCategories';

export const CUSTOM_PRICING_PACKAGE = {
     id: null,
     friendlyName: 'Business & Enterprise',
     friendlyNameNew: 'Business',
     pricePerYear: 'Custom Pricing',
     pricePerMonth: 348.75,
     shortDescription: `For organizations serious about better insights, attribution, and outcomes.`,
     shortDescriptionDes: `Server-side tagging, supercharged analytics, and attribution.`,
     customPlan: true,
};

export const dataPackage = [
     {
          id: null,
          friendlyName: 'Sandbox',
          pricePerYear: 0,
          pricePerMonth: 0,
          shortDescription: `Perfect for proofs of concept, learning, and testing.`,
     },
     {
          id: null,
          friendlyName: 'Pro 10K',
          pricePerYear: 468,
          pricePerMonth: 39,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 10000,
     },
     {
          id: null,
          friendlyName: 'Pro 25K',
          pricePerYear: 708,
          pricePerMonth: 73.75,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 25000,
     },
     {
          id: null,
          friendlyName: 'Pro 50K',
          pricePerYear: 1188,
          pricePerMonth: 123.75,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 50000,
     },
     {
          id: null,
          friendlyName: 'Pro 75K',
          pricePerYear: 1668,
          pricePerMonth: 173.75,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 75000,
     },
     {
          id: null,
          friendlyName: 'Pro 100K',
          pricePerYear: 2148,
          pricePerMonth: 223.75,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 100000,
     },
     {
          id: null,
          friendlyName: 'Pro 150K',
          pricePerYear: 2988,
          pricePerMonth: 311.25,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 150000,
     },
     {
          id: null,
          friendlyName: 'Pro 200K',
          pricePerYear: 3948,
          pricePerMonth: 411.25,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 200000,
     },
];

export const SWITCH_TYPE_WIZARD_STEP = {
     gadConnection: 'GoogleAds Connection',
     getStarted: 'Get Started',
     migrate: 'Migrate',
     migrateMakeChanges: 'Migrate Make Changes',
     migrateSuccess: 'Migrate Success',
     gtmConnection: 'GTM Connection',
     gtmSelectAccount: 'GTM Select Account',
     gtmSelectContainer: 'GTM Select Container',
     gtmVerifyChanges: 'GTM Verify Changes',
     gtmSendObject: 'GTM Send Object',
     gtmRemoveObject: 'GTM Remove Object',
     eachMakeChanges: 'Each Make Changes',
     gtmMakeChanges: 'GTM Make Changes',
     gtmUnknownFailure: 'GTM Unknown Failure',
     gtmNoPublishingRights: 'GTM No Publishing Rights',
     gtmSuccess: 'GTM Success',
     finally: 'Finally',
     gadsSelectConversion: 'GoggleAds Select Conversion',
     gadsMakeChange: 'GoggleAds Make Change',
};

export const SETUP_GUIDE_STEP = [
     'guideGetStarted',
     'installAndLearn',
     'customImplementation',
     'forCategorization',
     'dataEnrichment',
     'sendingData',
     // 'destination',
     'endRoadmap',
];

export const ROADMAP_GUIDESTEPS = {
     guideGetStarted: {
          orderNumber: 0,
          name: 'guideGetStarted',
     },
     installAndLearn: {
          orderNumber: 1,
          name: 'installAndLearn',
     },
     customImplementation: {
          orderNumber: 2,
          name: 'customImplementation',
     },
     forCategorization: {
          orderNumber: 3,
          name: 'forCategorization',
     },
     dataEnrichment: {
          orderNumber: 4,
          name: 'dataEnrichment',
     },
     sendingData: {
          orderNumber: 5,
          name: 'sendingData',
     },
     // destination: {
     //     orderNumber: 6,
     //     name: 'destination'
     // },
};
export const PAYMENT_CYCLE = {
     ANNUALLY: 'annually',
     EVERY_6_MONTHS: 'every 6 months',
     QUARTERLY: 'quarterly',
};

export const PAYMENT_TERMS = {
     DUE_UPON_RECEIPT: 'Due upon receipt',
     NET_15: 'net 15',
     NET_30: 'net 30',
};

export const UNIQUE_ID_DIMENSION = {
     EVENT_ID: 'Event ID',
     SESSION_ID: 'Session ID',
     USER_ID: 'User ID',
     PERSON_ID: 'Person ID',
     REVEALED_COMPANY_ID: 'Company ID',
};

export const UNIQUE_ID_PROPERTIES = {
     'Event ID': REPORT_DATA_TYPES[REPORT_NAMES.EVENT_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
     'Event Conversion': REPORT_DATA_TYPES[REPORT_NAMES.CONVERSION_EVENTS].properties.filter((item) => item.key !== 'viewDetail'),
     'Session ID': REPORT_DATA_TYPES[REPORT_NAMES.SESSION_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
     'User ID': REPORT_DATA_TYPES[REPORT_NAMES.USER_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
     'Person ID': REPORT_DATA_TYPES[REPORT_NAMES.PERSON_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
     'Company ID': REPORT_DATA_TYPES[REPORT_NAMES.COMPANY_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
};

export const PROPERTIES_WEB_CONVERSION = [
     { key: 'conversionName', label: 'Conversion Name', isDefault: true },
     { key: 'userSourceFirst', label: 'User Source - First (conversion)', isDefault: true },
     { key: 'userSourcePath', label: 'User Source - Path (conversion)', isDefault: true },
     { key: 'userSourceLast', label: 'User Source - Last (conversion)', isDefault: true },
     { key: 'externalId', label: 'External ID', isDefault: true },
     { key: 'email', label: 'Email', isDefault: true },
     { key: 'phone', label: 'Phone', isDefault: true },
     { key: 'firstName', label: 'First Name', isDefault: true },
     { key: 'lastName', label: 'Last Name', isDefault: true },
     { key: 'conversionValue', label: 'Conversion Value', isDefault: true },
     { key: 'conversionCurrency', label: 'Conversion Currency', isDefault: true }
];

export const UNIQUE_ID_SCOPED = {
     'Event ID': SCOPED.EVENT,
     'Session ID': SCOPED.SESSION,
     'User ID': SCOPED.USER,
     'Person ID': SCOPED.PEOPLE,
     'Company ID': SCOPED.COMPANY,
};

export const LIST_PROPERTIES = [
     ...REPORT_DATA_TYPES[REPORT_NAMES.SESSION_DETAILS].properties,
     ...REPORT_DATA_TYPES[REPORT_NAMES.USER_DETAILS].properties,
     ...REPORT_DATA_TYPES[REPORT_NAMES.PERSON_DETAILS].properties,
     ...REPORT_DATA_TYPES[REPORT_NAMES.COMPANY_DETAILS].properties,
     ...REPORT_DATA_TYPES[REPORT_NAMES.EVENT_DETAILS].properties,
];

export const UNIQUE_ID_DIMENSIONS = Object.entries(UNIQUE_ID_DIMENSION).map(([_, value]) => value);
export const UNIQUE_ID_DIMENSION_NAMES = ['Event ID', 'Session ID', 'User ID', 'Person ID', 'Company ID'];

export const NATIVE_EVENT_LISTENER_ID = 'c2fb5436-586d-43e5-9752-5223da4c520e'

export const HIDE_CONTRACT_PLANS = [
     '61a32c39-c888-4be5-a5ba-59acbd416b9f',
     '31b074b1-a5db-4dd6-bbb2-ad8056e3270a',
     '63ed3638-e2c0-4032-943b-6918148e8d4e',
];

export const EXTERNAL_DATA_CONNECTION_TYPE = {
     googleAds: 'GoogleAds',
};

export const EXTERNAL_DATA_CONNECTION_CODE = {
     GOOGLE_ADS: 'googleAds',
};

export const EXTERNAL_DATA_CONNECTION_STATUS = {
     active: 'Active',
     connected: 'Connected',
     noConnect: 'NoConnect',
     paused: 'Paused',
};

export const JOB_STATUS = {
     RUNNING: 'running',
     PAUSED: 'paused',
     CANCELED: 'canceled',
     DONE: 'done',
};

export const BQ_JOB_STATUS = {
     IN_PROGRESS: 'in-progress',
     FAILED: 'failed',
     CANCELED: 'canceled',
     DONE: 'done',
};

export const BQ_JOB_ACTION = {
     QUERY_REPORT: 'query-report',
     QUERY_REPORT_WITH_SEGMENT: 'query-report-with-segment',
     CREATE_SEGMENT: 'create-segment',
};

// export const SALESFORCE_CONNECTION_STATUS = {
//      active: 'Active',
//      broken: 'Broken!',
//      paused: 'Paused',
//      closed: 'Closed',
// };

//Segment
export const VARIABLE_MAIN_OPERATOR = [
     { value: 'exclude', label: 'Exclude' },
     { value: 'include', label: 'Include' },
];

export const VARIABLE_CONDITION_FOCUS = [
     { value: 'property', label: 'Property' },
     { value: 'metric', label: 'Metric' },
     { value: 'web-conversion-event', label: 'Web Conversion Event' },
     { value: 'ecommerce-event', label: 'Ecommerce Event' },
     { value: 'relationship', label: 'Relationship' },
     { value: 'other-event', label: 'Other Event' },
];
export const VARIABLE_SEGMENT_OBJECT = [
     { value: 'event', label: 'Event' },
     { value: 'session', label: 'Session' },
     { value: 'user', label: 'User' },
     { value: 'people', label: 'People' },
     { value: 'revealed-company', label: 'Revealed Company' },
];

//Segment property
export const VARIABLE_CONDITION_PROPERTY_SEGMENT = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'exav', label: 'exists with any value' },
];

export const VARIABLE_CONDITION_REVEALED_DATE_SEGMENT = [
     { value: 'on', label: 'Is on' },
     { value: 'bf', label: 'Is before' },
     { value: 'af', label: 'Is after' },
     { value: 'prenod', label: 'is in some previous number of days' },
];

//Segment relationship
export const VARIABLE_RELATIONSHIP_SEGMENT = [
     { value: 'event', label: 'Event' },
     { value: 'session', label: 'Session' },
     { value: 'user', label: 'User' },
     { value: 'people', label: 'People' },
     { value: 'revealed-company', label: 'Revealed Company' },
];

export const VARIABLE_CONDITION_RELATIONSHIP_SEGMENT = [
     { value: 'gt', label: 'more than' },
     { value: 'lt', label: 'less than' },
     { value: 'eq', label: 'equals to' },
     { value: 'exav', label: 'exists' },
     { value: 'nex', label: 'does not exist' },
];

//Segment metric
export const VARIABLE_METRIC_SEGMENT = [
     { value: 'employee-exact-estimate', label: 'Employee Exact Estimate' },
     { value: 'lifetime-engagement-score', label: 'Lifetime Engagement Score' },
];

export const VARIABLE_CONDITION_METRIC_SEGMENT = [
     // { value: 'ct', label: 'contains' },
     // { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'gt', label: 'greater than' },
     { value: 'lt', label: 'less than' },
     { value: 'gtoet', label: 'greater than or equal to' },
     { value: 'ltoet', label: 'less than or equal to' },
];

export const VARIABLE_CONDITION_METRIC_SEGMENT_CONTAIN = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'gt', label: 'greater than' },
     { value: 'lt', label: 'less than' },
     { value: 'gtoet', label: 'greater than or equal to' },
     { value: 'ltoet', label: 'less than or equal to' },
];

//Segment web conversion event

export const VARIABLE_TYPE_WEB_CONVERSION_SEGMENT = [
     { value: 'event-count', label: 'Event count is' },
     { value: 'has-property', label: 'Has property' },
     { value: 'event-status', label: 'Event status' },
];

export const VARIABLE_CONDITION_WEB_CONVERSION_SEGMENT = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
     { value: 'gt', label: 'greater than' },
     { value: 'lt', label: 'less than' },
     { value: 'exav', label: 'exists with any value' },
     { value: 'gtoet', label: 'greater than or equal to' },
     { value: 'ltoet', label: 'less than or equal to' },
];

//segment date range filter
export const VARIABLE_DATE_RANGE_FILTER_TYPE_SEGMENT = [
     { value: 'lifetime-of-the-object', label: 'Lifetime of the object' },
     { value: 'previous-period', label: 'Previous period' },
     { value: 'specific-date-range', label: 'Specific date range' },
];

//segment other event
export const VARIABLE_TYPE_OTHER_EVENT_SEGMENT = [
     { value: 'event', label: 'Event' },
     { value: 'variable', label: 'Variable' },
];

export const VARIABLE_CONDITION_OTHER_EVENT_SEGMENT = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
];

export const VARIABLE_VALUE_SUPPORT_DATA = [
     { value: 'true', label: 'True' },
     { value: 'false', label: 'False' },
];

export const VARIABLE_CONDITION_DATE = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'on', label: 'on' },
     { value: 'bf', label: 'before' },
     { value: 'af', label: 'after' },
];

export const VARIABLE_CONDITION_TIME = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
];
export const VARIABLE_LIST_MONTH = [
     { value: 'January', label: 'January' },
     { value: 'February', label: 'February' },
     { value: 'March', label: 'March' },
     { value: 'April', label: 'April' },
     { value: 'May', label: 'May' },
     { value: 'June', label: 'June' },
     { value: 'July', label: 'July' },
     { value: 'August', label: 'August' },
     { value: 'September', label: 'September' },
     { value: 'October', label: 'October' },
     { value: 'November', label: 'November' },
     { value: 'December', label: 'December' },
]

export const VARIABLE_LIST_DAY_OF_WEEK = [
     { value: 'Sunday', label: 'Sunday' },
     { value: 'Monday', label: 'Monday' },
     { value: 'Tuesday', label: 'Tuesday' },
     { value: 'Wednesday', label: 'Wednesday' },
     { value: 'Thursday', label: 'Thursday' },
     { value: 'Friday', label: 'Friday' },
     { value: 'Saturday', label: 'Saturday' },
]

export const VARIABLE_LIST_HOUR = [
     { value: '12 AM', label: '0' },
     { value: '1 AM', label: '1' },
     { value: '2 AM', label: '2' },
     { value: '3 AM', label: '3' },
     { value: '4 AM', label: '4' },
     { value: '5 AM', label: '5' },
     { value: '6 AM', label: '6' },
     { value: '7 AM', label: '7' },
     { value: '8 AM', label: '8' },
     { value: '9 AM', label: '9' },
     { value: '10 AM', label: '10' },
     { value: '11 AM', label: '11' },
     { value: '12 PM', label: '12' },
     { value: '1 PM', label: '13' },
     { value: '2 PM', label: '14' },
     { value: '3 PM', label: '15' },
     { value: '4 PM', label: '16' },
     { value: '5 PM', label: '17' },
     { value: '6 PM', label: '18' },
     { value: '7 PM', label: '19' },
     { value: '8 PM', label: '20' },
     { value: '9 PM', label: '21' },
     { value: '10 PM', label: '22' },
     { value: '11 PM', label: '23' },
]

export const SEGMENT_OBJECT = {
     EVENT: 'event',
     SESSION: 'session',
     USER: 'user',
     PEOPLE: 'people',
     COMPANY: 'revealed-company',
};

export const CONDITION_FOCUS = {
     PROPERTY: 'property',
     METRIC: 'metric',
     WEB_CONVERSION_EVENT: 'web-conversion-event',
     ECOMMERCE_EVENT: 'ecommerce-event',
     RELATION_SHIP: 'relationship',
     OTHER_EVENT: 'other-event',
};

export const VARIABLE_CONDITION_FOCUS_OPTION = {
     [CONDITION_FOCUS.METRIC]: ['conversionNameMetrics'],
     [CONDITION_FOCUS.WEB_CONVERSION_EVENT]: ['conversionName'],
     [CONDITION_FOCUS.ECOMMERCE_EVENT]: ['eCommerceName'],
     [CONDITION_FOCUS.OTHER_EVENT]: ['eventName', 'reactSelectVariableOptions'],
};

export const SECTION_OPTION_SEGMENT = {
     IDENTIFIER_RELATIONSHIPS: 'Identifiers & Relationships',
     DATE_ACCOUNT_TIMEZONE: 'Date',
     CONVERSIONS: 'Conversions',
     CONVERSION_SOURCE_SUMMARY: 'Conversion Source Summary',
     CONVERSION_SOURCE_DETAILS: 'Conversion Source Details',
     ECOMMERCE_EVENT_DETAILS: 'eCommerce Event Details',
     ECOMMERCE_ITEMS: 'eCommerce Items',
     ECOMMERCE_SOURCE_DETAILS: 'eCommerce Event Source Details',
     ECOMMERCE_SOURCE_SUMMARY: 'eCommerce Event Source Summary',
     GEOGRAPHY: 'Geography',
     DEVICE: 'Device',
     BROWSER: 'Browser',
     OS: 'OS',
     SCREEN: 'Screen',
     ENGAGEMENT: 'Engagement',
     FORM_ACTIVITY: 'Form Activity',
     SOURCE: 'Source',
     PAGE_ACTIVITY: 'Page Activity',
     PEOPLE: 'People',
     ECOMMERCE: 'eCommerce',
     TRIGGER: 'Triggers',
     COMPANY_DETAILS: 'Company Details',
     LIFETIME_SOURCE_SUMMARY: 'Lifetime Source Summary',
     ENGAGEMENT_ACTIVITY: 'Engagement & Activity',
     PERSONAL_DATA: 'Personal Data',
     PERSON_IDENTIFIED_SOURCE_SUMMARY: 'Person Identified Source Summary',
     PERSON_IDENTIFIED_SOURCE_DETAILS: 'Person Identified Source Details',
     CUSTOM_PROPERTIES: 'Custom Properties',
     LOCATION: 'Location',
     SIZE: 'Size',
     INDUSTRY: 'Industry',
     REVEALED_SOURCE_SUMMARY: 'Revealed Source Summary',
     TRACKING_LEVEL: 'Tracking Level',
     EVENT_DATA: 'Event Data',
}

export const SEGMENT_OBJECT_DATA = {
     [SEGMENT_OBJECT.EVENT]: {
          property: [
               { value: 'eventName', label: 'Event Name', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'eventLocation', label: 'Event Location', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'creationDate', label: 'Creation Date', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'isConversion', label: 'Is Conversion?', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'conversionType', label: 'Conversion Type', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'userId', label: 'User ID', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'sessionId', label: 'Session ID', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'personId', label: 'Person ID', section: SECTION_OPTION_SEGMENT.EVENT_DATA  },
               { value: 'consentMethod', label: 'Consent Method', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'trackingLevel', label: 'Tracking Level', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'consentRegionalRule', label: 'Consent Regional Rule', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'adStorage', label: 'ad_storage', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'analyticsStorage', label: 'analytics_storage', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL  },
               { value: 'identifyStrength', label: 'Identify Strength', section: SECTION_OPTION_SEGMENT.TRACKING_LEVEL   }, 

          ],
          metric: [{ value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.EVENT_DATA  }],
     },
     [SEGMENT_OBJECT.SESSION]: {
          property: [
               { value: 'landingPage', label: 'Landing Page', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'exitPage', label: 'Exit Page', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'engaged', label: 'Engaged', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'userSource', label: 'User Source', section: SECTION_OPTION_SEGMENT.SOURCE },
               { value: 'creationDate', label: 'Creation Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'sessionNumber', label: 'Session Number', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },

               { value: 'sessionID', label: 'Session ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               { value: 'userID', label: 'User ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               // { value: 'date', label: 'Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'hour', label: 'Hour', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'dayOfWeek', label: 'Day of Week', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'month', label: 'Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'yearMonth', label: 'Year Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'quarter', label: 'Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'yearQuarter', label: 'Year Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               // { value: 'regionalRule', label: 'Regional Rule' }, 
               // { value: 'consentMethod', label: 'Consent Method' }, 
               // { value: 'trackingLevel', label: 'Tracking Level' }, 
               // { value: 'identifyStrength', label: 'Identify Strength' }, 
               // { value: 'country', label: 'Country' }, 
               // { value: 'continent', label: 'Continent' }, 
               // { value: 'region', label: 'Region' }, 
               { value: 'city', label: 'City', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 
               // { value: 'postalCode', label: 'Postal Code' }, 
               { value: 'dmaCode', label: 'DMA Code', section: SECTION_OPTION_SEGMENT.GEOGRAPHY  }, 
               { value: 'dmaName', label: 'DMA Name', section: SECTION_OPTION_SEGMENT.GEOGRAPHY  }, 
               // { value: 'deviceType', label: 'Device Type' }, 
               { value: 'deviceFamily', label: 'Device Family', section: SECTION_OPTION_SEGMENT.DEVICE }, 
               { value: 'deviceManufacturer', label: 'Device Manufacturer', section: SECTION_OPTION_SEGMENT.DEVICE }, 
               // { value: 'browserFamily', label: 'Browser Family' }, 
               { value: 'browserVersion', label: 'Browser Version', section: SECTION_OPTION_SEGMENT.BROWSER  }, 
               { value: 'browserDetailedVersion', label: 'Browser Detailed Version', section: SECTION_OPTION_SEGMENT.BROWSER }, 
               { value: 'osFamily', label: 'OS Family', section: SECTION_OPTION_SEGMENT.OS }, 
               { value: 'osVersion', label: 'OS Version', section: SECTION_OPTION_SEGMENT.OS }, 
               { value: 'osDetailedVersion', label: 'OS Detailed Version', section: SECTION_OPTION_SEGMENT.OS }, 
               { value: 'screenColors', label: 'Screen Colors', section: SECTION_OPTION_SEGMENT.SCREEN }, 
               { value: 'screenSize', label: 'Screen Size', section: SECTION_OPTION_SEGMENT.SCREEN }, 
               { value: 'lastEventDate', label: 'Last Event Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               { value: 'lastConversionDate', label: 'Last Conversion Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
               // { value: 'conversions.conversionID', label: 'Conversion Events (Conversion ID)' }, 

               { value: 'landingPageURL', label: 'Landing Page URL', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY }, 
               { value: 'landingPagePath', label: 'Landing Page Path', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY }, 
               { value: 'exitPageURL', label: 'Exit Page URL', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY }, 
               { value: 'exitPagePath', label: 'Exit Page Path', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY }, 

               // { value: 'source.source', label: 'Source' }, // Unnecessary
               { value: 'source.referrerHostname', label: 'Referrer Hostname' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.referrerOriginPath', label: 'Referrer Origin Path' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.referrerPath', label: 'Referrer Path' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_medium', label: 'utm_medium' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_source', label: 'utm_source' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_campaign', label: 'utm_campaign' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_term', label: 'utm_term' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.utm_content', label: 'utm_content' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.gclid', label: 'Click IDs (gclid)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.fbclid', label: 'Click IDs (fbclid)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.fbp', label: 'Click IDs (fbp)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.fbc', label: 'Click IDs (fbc)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.msclkid', label: 'Click IDs (msclkid)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.li_fat_id', label: 'Click IDs (li_fat_id)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.ttclid', label: 'Click IDs (ttclid)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.dclid', label: 'Click IDs (dclid)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.srsltid', label: 'Click IDs (srsltid)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source._ga', label: 'Click IDs (_ga)' , section: SECTION_OPTION_SEGMENT.SOURCE},
               { value: 'source.calltrk_user', label: 'Click IDs (calltrk_user)' , section: SECTION_OPTION_SEGMENT.SOURCE},

               { value: 'forms.assignedID', label: 'Assigned ID', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.locationOriginPathname', label: 'Form Location', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.locationHostnamePath', label: 'Form Hostname Path', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'personCreated', label: 'Person ID', section: SECTION_OPTION_SEGMENT.PEOPLE },
          ],
          metric: [
               { value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'engagementScore', label: 'Engagement Score', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'pageViewCount', label: 'Pageviews', section: SECTION_OPTION_SEGMENT.PAGE_ACTIVITY },
               { value: 'sessionDuration', label: 'Session Duration', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'personCreatedCount', label: 'Person Created', section: SECTION_OPTION_SEGMENT.PEOPLE },
               { value: 'conversionCount', label: 'All Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConversionCount', label: 'Primary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConversionCount', label: 'Secondary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },

               { value: 'visibleTime', label: 'Total Visible Time', section: SECTION_OPTION_SEGMENT.ENGAGEMENT },
               { value: 'conversionValue', label: 'Conversion Value', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConvValue', label: 'Primary Conversion Value', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConvValue', label: 'Secondary Conversion Value', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               
               { value: 'ecomEventValue.addToCart', label: 'Add to Cart Value', section: SECTION_OPTION_SEGMENT.ECOMMERCE },
               { value: 'ecomEventValue.removeFromCart', label: 'Remove from Cart Value', section: SECTION_OPTION_SEGMENT.ECOMMERCE },
               { value: 'ecomEventValue.netCart', label: 'Net Cart Value', section: SECTION_OPTION_SEGMENT.ECOMMERCE },
               { value: 'ecomEventValue.beginCheckoutUnique', label: 'Begin Checkout Unique', section: SECTION_OPTION_SEGMENT.ECOMMERCE },
               { value: 'ecomEventRevenue.purchase', label: 'Purchase Purchase', section: SECTION_OPTION_SEGMENT.ECOMMERCE },

               { value: 'forms.impressionCount', label: 'Impression Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.visibleCount', label: 'Form Visible Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.startCount', label: 'Form Start Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.errorCount', label: 'Form Error Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.submissions', label: 'Submissions Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               { value: 'forms.revealedPeopleCount', label: 'Revealed People Count', section: SECTION_OPTION_SEGMENT.FORM_ACTIVITY },
               // { value: 'createdPersonCount', label: 'Created Person Count' }, // Unnecessary
          ],
     },
     [SEGMENT_OBJECT.USER]: {
          property: [
               // { value: '', label: 'User Source - First' },
               // { value: '', label: 'User Source - Last' },
               // { value: '', label: 'User Source - Path' },
               { value: 'userSourceFirst', label: 'User Source - First' , section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'userSourcePath', label: 'User Source - Path' , section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'noneDirectSourcePath', label: 'Non-Direct User Source - Path' , section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'userSourceLast', label: 'User Source - Last' , section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'creationDate', label: 'Creation Date' , section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE},
               { value: 'lastConversionDate', label: 'Last Conversion Date' , section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE},
               { value: 'lastEventDate', label: 'Last Event Date' , section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE},

               { value: 'userID', label: 'User ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               { value: 'personID', label: 'Person ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               // { value: 'regionalRule', label: 'Regional Rule' }, 
               // { value: 'consentMethod', label: 'Consent Method' }, 
               // { value: 'trackingLevel', label: 'Tracking Level' }, 
               // { value: 'identifyStrength', label: 'Identify Strength' }, 
               // { value: 'country', label: 'Country' }, 
               // { value: 'continent', label: 'Continent' }, 
               // { value: 'region', label: 'Region' }, 
               { value: 'city', label: 'City', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 
               // { value: 'postalCode', label: 'Postal Code' }, 
               { value: 'dmaCode', label: 'DMA Code', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 
               { value: 'dmaName', label: 'DMA Name', section: SECTION_OPTION_SEGMENT.GEOGRAPHY }, 
               // { value: 'deviceType', label: 'Device Type' }, 
               { value: 'deviceFamily', label: 'Device Family', section: SECTION_OPTION_SEGMENT.DEVICE }, 
               { value: 'deviceManufacturer', label: 'Device Manufacturer', section: SECTION_OPTION_SEGMENT.DEVICE }, 
               // { value: 'browserFamily', label: 'Browser Family' }, 
               { value: 'browserVersion', label: 'Browser Version', section: SECTION_OPTION_SEGMENT.BROWSER }, 
               { value: 'browserDetailedVersion', label: 'Browser Detailed Version', section: SECTION_OPTION_SEGMENT.BROWSER }, 
               { value: 'osFamily', label: 'OS Family', section: SECTION_OPTION_SEGMENT.OS }, 
               { value: 'osVersion', label: 'OS Version', section: SECTION_OPTION_SEGMENT.OS }, 
               { value: 'osDetailedVersion', label: 'OS Detailed Version', section: SECTION_OPTION_SEGMENT.OS }, 
               { value: 'screenColors', label: 'Screen Colors', section: SECTION_OPTION_SEGMENT.SCREEN }, 
               { value: 'screenSize', label: 'Screen Size', section: SECTION_OPTION_SEGMENT.SCREEN }, 
               // { value: 'isEngaged', label: 'Engaged', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY }, 

               // { value: 'sourceFirstND', label: 'Source (first non-direct)' }, 
               // { value: 'sourceLastND', label: 'Source (last non-direct)' }, 
               { value: 'sources.sessionID', label: 'Sources <br>(Source Session ID)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.timestamp', label: 'Sources (Timestamp)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               // { value: 'sources.source', label: 'Source' },
               { value: 'sources.referrerHostname', label: 'Referrer Hostname', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.referrerOriginPath', label: 'Referrer Origin Path', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.referrerPath', label: 'Referrer Path', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.utm_medium', label: 'utm_medium', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.utm_source', label: 'utm_source', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.utm_campaign', label: 'utm_campaign', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.utm_term', label: 'utm_term', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.utm_content', label: 'utm_content', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.gclid', label: 'Click IDs (gclid)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.fbclid', label: 'Click IDs (fbclid)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.fbp', label: 'Click IDs (fbp)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.fbc', label: 'Click IDs (fbc)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.msclkid', label: 'Click IDs (msclkid)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.li_fat_id', label: 'Click IDs (li_fat_id)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.ttclid', label: 'Click IDs (ttclid)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.dclid', label: 'Click IDs (dclid)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.srsltid', label: 'Click IDs (srsltid)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources._ga', label: 'Click IDs (_ga)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'sources.calltrk_user', label: 'Click IDs (calltrk_user)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
          ],
          metric: [
               { value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'sessionCount', label: 'Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'avgSessionDuration', label: 'Avg Session Duration', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'engagedSessionCount', label: 'Engaged Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'engagementRate', label: 'Engagement Rate', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'engagementScore', label: 'Lifetime Engagement Score', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'bouncedSessionCount', label: 'Bounced Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'bounceRate', label: 'Bounce Rate', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'pageViewCount', label: 'Pageviews', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY},
               { value: 'sourcePathCount', label: 'Source Path Count', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'noneDirectSourcePathCount', label: 'Non-Direct Source Path Count', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY},
               { value: 'conversionCount', label: 'All Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'primaryConversionCount', label: 'Primary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'secondaryConversionCount', label: 'Secondary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'conversionRateAll', label: 'Conv Rate <br>(All Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'conversionRatePrimary', label: 'Conv Rate <br>(Primary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS},
               { value: 'conversionRateSecondary', label: 'Conv Rate <br>(Secondary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS},

               { value: 'revealedCompanyCount', label: 'Revealed Company Count', section: SECTION_OPTION_SEGMENT.COMPANY_DETAILS },
               // { value: 'engagementScore.10Days', label: 'Previous 10 Days\' Engagement Score' },
               // { value: 'engagementScore.30Days', label: 'Previous 30 Days\' Engagement Score' },
               { value: 'lifetimeVisibleTime', label: 'Total Visible Time <br>(Lifetime)', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'conversionValue', label: 'Conversion Value', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConvValue', label: 'Primary Conversion Value <br>(Lifetime)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConvValue', label: 'Secondary Conversion Value <br>(Lifetime)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },

          ],
     },
     [SEGMENT_OBJECT.COMPANY]: {
          property: [
               { value: 'companyName', label: 'Company Name', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               { value: 'domain', label: 'Domain', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               { value: 'revenue', label: 'Revenue', section: SECTION_OPTION_SEGMENT.SIZE },
               { value: 'employees', label: 'Total Employees', section: SECTION_OPTION_SEGMENT.SIZE },
               { value: 'primaryIndustry', label: 'Primary Industry', section: SECTION_OPTION_SEGMENT.INDUSTRY },
               { value: 'industries', label: 'Products & Services Tags', section: SECTION_OPTION_SEGMENT.INDUSTRY },
               { value: 'companyCountry', label: 'Country', section: SECTION_OPTION_SEGMENT.LOCATION },
               { value: 'companyState', label: 'State', section: SECTION_OPTION_SEGMENT.LOCATION },
               { value: 'createdDate', label: 'Creation date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'latestUpdateDate', label: 'Latest update date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'lastConversionDate', label: 'Last Conversion Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'userSourceFirst', label: 'User Source - First', section: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'userSourcePath', label: 'User Source - Path', section: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'noneDirectSourcePath', label: 'Non-Direct User Source - Path', section: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'userSourceLast', label: 'User Source - Last', section: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },

               // { value: 'companyID', label: 'Company ID' },
               // { value: 'revealedDate', label: 'Revealed Date' },
               // { value: 'lastActiveDate', label: 'Last Active Date' },
               // { value: 'revealedHour', label: 'Revealed Hour' },
               // { value: 'revealedDayOfWeek', label: 'Revealed Day of Week' },
               // { value: 'revealedMonth', label: 'Revealed Month' },
               // { value: 'revealedYearMonth', label: 'Revealed Year Month' },
               // { value: 'revealedQuarter', label: 'Revealed Quarter' },
               // { value: 'revealedYearQuarter', label: 'Revealed Year Quarter' },
               // { value: 'descriptionShort', label: 'Short Description' },
               // { value: 'description', label: 'Long Description' },
               // { value: 'yearFounded', label: 'Year Founded' },

               // { value: 'country', label: 'Country' }, 
               // { value: 'countryCode', label: 'Country Code' }, 
               // { value: 'region', label: 'Region' }, 
               // { value: 'regionCode', label: 'Region Code' }, 
               // { value: 'city', label: 'City' }, 
               // { value: 'postalCode', label: 'Postal Code' },
               // { value: 'timezoneName', label: 'Timezone Name' },
               // { value: 'alexaRange', label: 'Alexa Rank' },
               // { value: 'monthlyVisitors', label: 'Monthly Visitors' },
               // { value: 'businessType', label: 'Business Type' },
               // { value: 'stockExchange', label: 'Stock Exchange' },
               // { value: 'tickerSymbol', label: 'Ticker Symbol' },
               // { value: 'naicsCode', label: 'NAICS Code' },
               // { value: 'naicsSector', label: 'NAICS Sector' },
               // { value: 'naicsSubsector', label: 'NAICS Subsector' },
               // { value: 'naicsIndustryGroup', label: 'NAICS Industry Group' },
               // { value: 'naicsIndustry', label: 'NAICS Industry' },
               // { value: 'naicsDescription', label: 'NAICS Description' },
               // { value: 'sicCode', label: 'SIC Code' },
               // { value: 'sicIndustryGroup', label: 'SIC Industry Group' },
               // { value: 'sicIndustry', label: 'SIC Industry' },
               // { value: 'isEngaged', label: 'Engaged' },
               // { value: 'revealedSessionID', label: 'Revealed Session ID' },
               // { value: 'createdSourceFirst', label: 'Created Source (first)' },
               // { value: 'createdSourceFirstND', label: 'Created Source (first non-direct)' },
               // { value: 'createdSourceLast', label: 'Created Source (last)' },
               // { value: 'createdSourceLastND', label: 'Created Source (last non-direct)' },
               // { value: 'createdSourcePath', label: 'Created Source (path)' },
               // { value: 'createdSourcePathND', label: 'Created Source (path non-direct)' },
               
               // { value: 'createdSources.sessionID', label: 'Created Sources (Source Session ID)' },
               // { value: 'createdSources.source', label: 'Created Sources (Source)' },
               // { value: 'createdSources.timestamp', label: 'Created Sources (Timestamp)' },
               // { value: 'createdSources.referrerHostname', label: 'Created Sources (Referrer Hostname)' },
               // { value: 'createdSources.referrerOriginPath', label: 'Created Sources (Referrer Origin Path)' },
               // { value: 'createdSources.referrerPath', label: 'Created Sources (Referrer Path)' },
               // { value: 'createdSources.utm_medium', label: 'Created Sources (utm_medium)' },
               // { value: 'createdSources.utm_source', label: 'Created Sources (utm_source)' },
               // { value: 'createdSources.utm_campaign', label: 'Created Sources (utm_campaign)' },
               // { value: 'createdSources.utm_term', label: 'Created Sources (utm_term)' },
               // { value: 'createdSources.utm_content', label: 'Created Sources (utm_content)' },
               // { value: 'createdSources.gclid', label: 'Created Sources Click IDs (gclid)' },
               // { value: 'createdSources.fbclid', label: 'Created Sources Click IDs (fbclid)' },
               // { value: 'createdSources.fbp', label: 'Created Sources Click IDs (fbp)' },
               // { value: 'createdSources.fbc', label: 'Created Sources Click IDs (fbc)' },
               // { value: 'createdSources.msclkid', label: 'Created Sources Click IDs (msclkid)' },
               // { value: 'createdSources.li_fat_id', label: 'Created Sources Click IDs (li_fat_id)' },
               // { value: 'createdSources.ttclid', label: 'Created Sources Click IDs (ttclid)' },
               // { value: 'createdSources.dclid', label: 'Created Sources Click IDs (dclid)' },
               // { value: 'createdSources.srsltid', label: 'Created Sources Click IDs (srsltid)' },
               // { value: 'createdSources._ga', label: 'Created Sources Click IDs (_ga)' },
               // { value: 'createdSources.calltrk_user', label: 'Created Sources Click IDs (calltrk_user)' },
               // { value: 'createdSources.weights.engagement', label: 'Created Sources Weights (Engagement)' },
               // { value: 'createdSources.weights.engagementND', label: 'Created Sources Weights (Engagement Non Direct)' },
               // { value: 'createdSources.weights.firstTouch', label: 'Created Sources Weights (First Touch)' },
               // { value: 'createdSources.weights.firstTouchND', label: 'Created Sources Weights (First Touch Non Direct)' },
               // { value: 'createdSources.weights.lastTouch', label: 'Created Sources Weights (Last Touch)' },
               // { value: 'createdSources.weights.lastTouchND', label: 'Created Sources Weights (Last Touch Non Direct)' },
               // { value: 'createdSources.weights.uShaped', label: 'Created Sources Weights (U-Shaped)' },
               // { value: 'createdSources.weights.uShapedND', label: 'Created Sources Weights (U-Shaped Non Direct)' },
               // { value: 'createdSources.weights.linear', label: 'Created Sources Weights (Linear)' },
               // { value: 'createdSources.weights.linearND', label: 'Created Sources Weights (Linear Non Direct)' },

               // { value: 'sourceFirstND', label: 'Source (first non-direct)' }, 
               // { value: 'sourceLastND', label: 'Source (last non-direct)' }, 
               // { value: 'sources.sessionID', label: 'Sources (Source Session ID)' },
               // { value: 'sources.timestamp', label: 'Sources (Timestamp)' },
               // { value: 'sources.source', label: 'Source' },
               // { value: 'sources.referrerHostname', label: 'Referrer Hostname' },
               // { value: 'sources.referrerOriginPath', label: 'Referrer Origin Path' },
               // { value: 'sources.referrerPath', label: 'Referrer Path' },
               // { value: 'sources.utm_medium', label: 'utm_medium' },
               // { value: 'sources.utm_source', label: 'utm_source' },
               // { value: 'sources.utm_campaign', label: 'utm_campaign' },
               // { value: 'sources.utm_term', label: 'utm_term' },
               // { value: 'sources.utm_content', label: 'utm_content' },
               // { value: 'sources.gclid', label: 'Click IDs (gclid)' },
               // { value: 'sources.fbclid', label: 'Click IDs (fbclid)' },
               // { value: 'sources.fbp', label: 'Click IDs (fbp)' },
               // { value: 'sources.fbc', label: 'Click IDs (fbc)' },
               // { value: 'sources.msclkid', label: 'Click IDs (msclkid)' },
               // { value: 'sources.li_fat_id', label: 'Click IDs (li_fat_id)' },
               // { value: 'sources.ttclid', label: 'Click IDs (ttclid)' },
               // { value: 'sources.dclid', label: 'Click IDs (dclid)' },
               // { value: 'sources.srsltid', label: 'Click IDs (srsltid)' },
               // { value: 'sources._ga', label: 'Click IDs (_ga)' },
               // { value: 'sources.calltrk_user', label: 'Click IDs (calltrk_user)' },

          ],
          metric: [
               { value: 'peopleCount', label: 'People', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'userCount', label: 'Users', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'sessionCount', label: 'Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagedSessionCount', label: 'Engaged Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagementScore', label: 'Lifetime Engagement Score', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgSessionDuration', label: 'Avg Session Duration', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'sourcePathCount', label: 'Source Path Count', section: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'noneDirectSourcePathCount', label: 'Non-Direct Source Path Count', section: SECTION_OPTION_SEGMENT.REVEALED_SOURCE_SUMMARY },
               { value: 'conversionCount', label: 'All Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConversionCount', label: 'Primary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConversionCount', label: 'Secondary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRateAll', label: 'Conv Rate (All Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRatePrimary', label: 'Conv Rate <br>(Primary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRateSecondary', label: 'Conv Rate <br>(Secondary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               
               // { value: 'employeeCountEstimate', label: 'Employee Exact Estimate' },
               // { value: 'engagementScore.10Days', label: 'Previous 10 Days\' Engagement Score' },
               // { value: 'engagementScore.30Days', label: 'Previous 30 Days\' Engagement Score' },
               // { value: 'revealedCompanyCount', label: 'Revealed Company Count' },
               // { value: 'sessionDuration', label: 'Session Duration' },
               // { value: 'pageViewCount', label: 'Pageview Count' },
               // { value: 'conversionValue', label: 'Conversion Value' },
               // { value: 'primaryConvValue', label: 'Primary Conversion Value (Lifetime)' },
               // { value: 'secondaryConvValue', label: 'Secondary Conversion Value (Lifetime)' },

          ],
     },
     [SEGMENT_OBJECT.PEOPLE]: {
          property: [
               // { value: '', label: 'User Source - First' },
               // { value: '', label: 'User Source - Last' },
               // { value: '', label: 'User Source - Path' },
               { value: 'email', label: 'Email', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'emailList', label: 'Email List', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'emailType', label: 'Email Type', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'externalId', label: 'External ID', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'name', label: 'Name', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'firstName', label: 'First Name', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'lastName', label: 'Last Name', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'address', label: 'Address', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'phone', label: 'Phone', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'phoneList', label: 'Phone List', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },
               { value: 'domain', label: 'Email Domain', section: SECTION_OPTION_SEGMENT.PERSONAL_DATA },

               { value: 'userSourceFirst', label: 'User Source - First <br>(person)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'userSourcePath', label: 'User Source - Path <br>(person)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'noneDirectSourcePath', label: 'Non-Direct User Source - Path <br>(person)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'userSourceLast', label: 'User Source - Last <br>(person)', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'userSourceFirstPersonCreated', label: 'User Source - First <br>(person created)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_SUMMARY },
               { value: 'userSourcePathPersonCreated', label: 'User Source - Path <br>(person created)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_SUMMARY },
               { value: 'userSourceLastPersonCreated', label: 'User Source - Last <br>(person created)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_SUMMARY },
               { value: 'identifiedSourcePathND', label: 'Identified Source <br>(path non-direct)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_SUMMARY }, 
              
               { value: 'creationDate', label: 'Creation Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'lastConversionDate', label: 'Last Conversion Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'lastEventDate', label: 'Last Event Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'firstActiveAt', label: 'First Active Timestampe', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedHour', label: 'Identified Hour', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedDayOfWeek', label: 'Identified Day of Week', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedMonth', label: 'Identified Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedYearMonth', label: 'Identified Year Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedQuarter', label: 'Identified Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'identifiedYearQuarter', label: 'Identified Year Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
               { value: 'userId', label: 'User ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
             
               { value: 'personID', label: 'Person ID', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
               { value: 'identifiedAt', label: 'Identified Timestamp', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },

               // { value: 'identifiedSourceLastND', label: 'Identified Source (first non-direct)' }, 
               // { value: 'identifiedSourceFirstND', label: 'Identified Source (last non-direct)' }, 

               { value: 'identifiedSources.sessionID', label: 'Identified Sources <br>(Source Session ID)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.source', label: 'Identified Sources (Source)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.timestamp', label: 'Identified Sources (Timestamp)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.referrerHostname', label: 'Identified Sources <br>(Referrer Hostname)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.referrerOriginPath', label: 'Identified Sources <br>(Referrer Origin Path)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.referrerPath', label: 'Identified Sources <br>(Referrer Path)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.utm_medium', label: 'Identified Sources <br>(utm_medium)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.utm_source', label: 'Identified Sources <br>(utm_source)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.utm_campaign', label: 'Identified Sources <br>(utm_campaign)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.utm_term', label: 'Identified Sources (utm_term)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.utm_content', label: 'Identified Sources <br>(utm_content)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.gclid', label: 'Identified Sources Click IDs <br>(gclid)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.fbclid', label: 'Identified Sources Click IDs <br>(fbclid)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.fbp', label: 'Identified Sources Click IDs <br>(fbp)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.fbc', label: 'Identified Sources Click IDs <br>(fbc)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.msclkid', label: 'Identified Sources Click IDs <br>(msclkid)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.li_fat_id', label: 'Identified Sources Click IDs <br>(li_fat_id)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.ttclid', label: 'Identified Sources Click IDs <br>(ttclid)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.dclid', label: 'Identified Sources Click IDs <br>(dclid)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.srsltid', label: 'Identified Sources Click IDs <br>(srsltid)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources._ga', label: 'Identified Sources Click IDs <br>(_ga)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.calltrk_user', label: 'Identified Sources Click IDs <br>(calltrk_user)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               { value: 'identifiedSources.weights.engagement', label: 'Identified Sources Weights <br>(Engagement)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               // { value: 'identifiedSources.weights.engagementND', label: 'Identified Sources Weights (Engagement Non Direct)' },
               { value: 'identifiedSources.weights.firstTouch', label: 'Identified Sources Weights <br>(First Touch)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               // { value: 'identifiedSources.weights.firstTouchND', label: 'Identified Sources Weights (First Touch Non Direct)' },
               { value: 'identifiedSources.weights.lastTouch', label: 'Identified Sources Weights <br>(Last Touch)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               // { value: 'identifiedSources.weights.lastTouchND', label: 'Identified Sources Weights (Last Touch Non Direct)' },
               { value: 'identifiedSources.weights.uShaped', label: 'Identified Sources Weights <br>(U-Shaped)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               // { value: 'identifiedSources.weights.uShapedND', label: 'Identified Sources Weights (U-Shaped Non Direct)' },
               { value: 'identifiedSources.weights.linear', label: 'Identified Sources Weights <br>(Linear)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_DETAILS },
               // { value: 'identifiedSources.weights.linearND', label: 'Identified Sources Weights (Linear Non Direct)' },
          ],
          metric: [
               { value: 'eventCount', label: 'Events', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'sessionCount', label: 'Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgSessionDuration', label: 'Avg Session Duration', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagedSessionCount', label: 'Engaged Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagementRate', label: 'Engagement Rate', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'engagementScore', label: 'Lifetime Engagement Score', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'bouncedSessionCount', label: 'Bounced Sessions', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'bounceRate', label: 'Bounce Rate', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'pageViewCount', label: 'Pageviews', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgPageViewsPerSession', label: 'Avg Pageviews / Session', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'avgVisibleTimePerPage', label: 'Avg Visible Time / Page', section: SECTION_OPTION_SEGMENT.ENGAGEMENT_ACTIVITY },
               { value: 'sourcePathCount', label: 'Source Path Count', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'noneDirectSourcePathCount', label: 'Non-Direct Source Path Count', section: SECTION_OPTION_SEGMENT.LIFETIME_SOURCE_SUMMARY },
               { value: 'conversionCount', label: 'All Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'primaryConversionCount', label: 'Primary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'secondaryConversionCount', label: 'Secondary Conversions', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRateAll', label: 'Conv Rate (All Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRatePrimary', label: 'Conv Rate <br>(Primary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
               { value: 'conversionRateSecondary', label: 'Conv Rate <br>(Secondary Conversions)', section: SECTION_OPTION_SEGMENT.CONVERSIONS },

               { value: 'revealedCompanyCount', label: 'Revealed Company Count', section: SECTION_OPTION_SEGMENT.COMPANY_DETAILS },
               { value: 'identifiedSourcePathCount', label: 'Identified Source Path Count', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_SUMMARY }, 
               { value: 'identifiedSourcePathCountND', label: 'Identified Source Path Count <br>(non-direct)', section: SECTION_OPTION_SEGMENT.PERSON_IDENTIFIED_SOURCE_SUMMARY }, 

          ],
     },
};


export const VARIABLE_PROPERTY_WEB_CONVERSION_SEGMENT = [
     { value: 'conversionID', label: 'Conversion ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'sessionID', label: 'Session ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'userID', label: 'User ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'date', label: 'Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'hour', label: 'Hour', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'dayOfWeek', label: 'Day of Week', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'month', label: 'Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
     { value: 'yearMonth', label: 'Year Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
     { value: 'quarter', label: 'Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },

     { value: 'conversionType', label: 'Conversion Type', section: SECTION_OPTION_SEGMENT.CONVERSIONS },

     { value: 'conversionValue', label: 'Conversion Value', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
     { value: 'conversionCurrency', label: 'Conversion Currency', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
     { value: 'conversionLocationHostname', label: 'Conversion Location (Hostname)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
     { value: 'conversionLocationOriginPath', label: 'Conversion Location <br>(Origin Path)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
     { value: 'conversionLocationURL', label: 'Conversion Location (URL)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSIONS },
     { value: 'createdSessionIDFirst', label: 'Created Session ID (first)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     { value: 'createdSourceFirst', label: 'Created Source (first)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     // { value: 'createdSessionIDFirstND', label: 'Created Session ID (first non-direct)', type:'dimension' },
     // { value: 'createdSourceFirstND', label: 'Created Source (first non-direct)', type:'dimension' },
     { value: 'createdSessionIDLast', label: 'Created Session ID <br>(last)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     { value: 'createdSourceLast', label: 'Created Source (last)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     // { value: 'createdSessionIDLastND', label: 'Created Session ID (last non-direct)', type:'dimension' },
     // { value: 'createdSourceLastND', label: 'Created Source (last non-direct)', type:'dimension' },
     { value: 'createdSessionIDPath', label: 'Created Session ID <br>(path)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     { value: 'createdSourcePath', label: 'Created Source (path)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     { value: 'createdSourcePathCount', label: 'Created Source Path Count', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_SUMMARY },
     // { value: 'createdSessionIDPathND', label: 'Created Session ID (path non-direct)', type:'dimension' },
     // { value: 'createdSourcePathND', label: 'Created Source (path non-direct)', type:'dimension' },
     // { value: 'createdSourcePathCountND', label: 'Created Source Path Count (non-direct)', type:'dimension' },
     { value: 'createdSources.sessionID', label: 'Created Sources <br>(Source Session ID)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.timestamp', label: 'Created Sources <br>(Source Timestamp)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.source', label: 'Created Sources (Source)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     // { value: 'createdSources.referrerHostname', label: 'Created Sources (Referrer Hostname)', type:'dimension' },
     // { value: 'createdSources.referrerOriginPath', label: 'Created Sources (Referrer Origin Path)', type:'dimension' },
     // { value: 'createdSources.referrerPath', label: 'Created Sources (Referrer Path)', type:'dimension' },
     { value: 'createdSources.landingPageOriginPath', label: 'Created Sources <br>(Landing Page)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.utmMedium', label: 'Created Sources <br>(utm_medium)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.utmSource', label: 'Created Sources (utm_source)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.utmSourceMedium', label: 'Created Sources <br>(utm_source / utm_medium)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.utmCampaign', label: 'Created Sources <br>(utm_campaign)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.utmTerm', label: 'Created Sources <br>(utm_term)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.utmContent', label: 'Created Sources <br>(utm_content)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.otherUTMs', label: 'Created Sources <br>(Other UTMs)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.gclid', label: 'Created Sources Click IDs <br>(gclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.fbclid', label: 'Created Sources Click IDs <br>(fbclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.fbp', label: 'Created Sources Click IDs <br>(fbp)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.fbc', label: 'Created Sources Click IDs <br>(fbc)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.msclkid', label: 'Created Sources Click IDs <br>(msclkid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.li_fat_id', label: 'Created Sources Click IDs <br>(li_fat_id)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.ttclid', label: 'Created Sources Click IDs <br>(ttclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.dclid', label: 'Created Sources Click IDs <br>(dclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.srsltid', label: 'Created Sources Click IDs <br>(srsltid)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs._ga', label: 'Created Sources Click IDs <br>(_ga)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.calltrk_user', label: 'Created Sources Click IDs <br>(calltrk_user)', type:'dimension', section: SECTION_OPTION_SEGMENT.CONVERSION_SOURCE_DETAILS },
  
     // { value: 'createdSources.engagementScore', label: 'Created Source Engagement Score', type:'metric' },
     // { value: 'createdSources.weights.engagement', label: 'Created Sources Weights (Engagement)', type:'metric' },
     // { value: 'createdSources.weights.engagementND', label: 'Created Sources Weights (Engagement Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.firstTouch', label: 'Created Sources Weights (First Touch)', type:'metric' },
     // { value: 'createdSources.weights.firstTouchND', label: 'Created Sources Weights (First Touch Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.lastTouch', label: 'Created Sources Weights (Last Touch)', type:'metric' },
     // { value: 'createdSources.weights.lastTouchND', label: 'Created Sources Weights (Last Touch Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.uShaped', label: 'Created Sources Weights (U-Shaped)', type:'metric' },
     // { value: 'createdSources.weights.uShapedND', label: 'Created Sources Weights (U-Shaped Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.linear', label: 'Created Sources Weights (Linear)', type:'metric' },
     // { value: 'createdSources.weights.linearND', label: 'Created Sources Weights (Linear Non Direct)', type:'metric' },
];

export const VARIABLE_PROPERTY_ECOMMERCE_SEGMENT = [
     { value: 'eventID', label: 'Event ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'sessionID', label: 'Session ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },
     { value: 'userID', label: 'User ID', type:'dimension', section: SECTION_OPTION_SEGMENT.IDENTIFIER_RELATIONSHIPS },

     { value: 'date', label: 'Date', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'hour', label: 'Hour', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'dayOfWeek', label: 'Day of Week', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE },
     { value: 'month', label: 'Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
     { value: 'yearMonth', label: 'Year Month', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 
     { value: 'quarter', label: 'Quarter', section: SECTION_OPTION_SEGMENT.DATE_ACCOUNT_TIMEZONE }, 

     // { value: 'eventType', label: 'Event Type', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceValue', label: 'Total Value', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommercePurchaseRevenue', label: 'Purchase Revenue', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     // { value: 'eCommerceRefundValue', label: 'Refund Value', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceShippingValue', label: 'Shipping Value', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     // { value: 'ecommerceCartLevelCoupon', label: 'Cart Level Coupon', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'ecommerceCoupon', label: 'Coupon', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     // { value: 'ecommerceCartLevelDiscount', label: 'Cart Level Discount', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'ecommerceDiscount', label: 'Discount', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceTaxValue', label: 'Tax Value', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceCurrency', label: 'Currency', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceTotalItemQuantity', label: 'Total Item Quantity', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     // { value: 'eCommerceUniqueItemCount', label: 'Unique Item Count', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceTransactionID', label: 'Transaction ID', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceItemListID', label: 'Item List ID', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceItemListName', label: 'Item List Name', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eventLocationHostname', label: 'Event Location (Hostname)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eventLocationOriginPath', label: 'Event Location (Origin Path)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eventLocationURL', label: 'Event Location (URL)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_EVENT_DETAILS },
     { value: 'eCommerceItems.itemID', label: 'Item ID', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemName', label: 'Item Name', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemBrand', label: 'Item Brand', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemVariants.item_variant', label: 'Item Variant', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category', label: 'Item Category', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category1', label: 'Item Category 1', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category2', label: 'Item Category 2', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category3', label: 'Item Category 3', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemCategories.item_category4', label: 'Item Category 4', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemPrice', label: 'Item Price', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemQuantity', label: 'Item Quantity', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemRevenue', label: 'Item Revenue', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.coupon', label: 'Item Coupon', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.discount', label: 'Item Discount', type:'metric', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemListID', label: 'Item List ID', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'eCommerceItems.itemListName', label: 'Item List Name', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_ITEMS },
     { value: 'createdSourceFirst', label: 'Created Source (first)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_SUMMARY },
     { value: 'createdSessionIDFirst', label: 'Created Session ID (first)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_SUMMARY },
     // { value: 'createdSourceFirstND', label: 'Created Source (first non-direct)', type:'dimension' },
     // { value: 'createdSessionIDFirstND', label: 'Created Session ID (first non-direct)', type:'dimension' },
     { value: 'createdSourceLast', label: 'Created Source (last)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_SUMMARY },
     { value: 'createdSessionIDLast', label: 'Created Session ID (last)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_SUMMARY },
     // { value: 'createdSourceLastND', label: 'Created Source (last non-direct)', type:'dimension' },
     // { value: 'createdSessionIDLastND', label: 'Created Session ID (last non-direct)', type:'dimension' },
     { value: 'createdSourcePath', label: 'Created Source (path)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_SUMMARY },
     { value: 'createdSessionIDPath', label: 'Created Session ID (path)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_SUMMARY },
     // { value: 'createdSourcePathND', label: 'Created Source (path non-direct)', type:'dimension' },
     // { value: 'createdSessionIDPathND', label: 'Created Session ID (path non-direct)', type:'dimension' },
     { value: 'createdSources.source', label: 'Created Sources (Source)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     // { value: 'createdSources.timestampUTC', label: 'Created Sources (Source Timestamp UTC)', type:'dimension' },
     { value: 'createdSources.timestamp', label: 'Created Sources (Timestamp)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.sessionID', label: 'Created Sources <br>(Source Session ID)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     // { value: 'createdSources.referrerHostname', label: 'Created Sources (Referrer Hostname)', type:'dimension' },
     // { value: 'createdSources.referrerOriginPath', label: 'Created Sources (Referrer Origin Path)', type:'dimension' },
     // { value: 'createdSources.referrerPath', label: 'Created Sources (Referrer Path)', type:'dimension' },
     { value: 'createdSources.landingPageOriginPath', label: 'Created Sources <br>(Landing Page)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.utmMedium', label: 'Created Sources (utm_medium)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.utmSource', label: 'Created Sources (utm_source)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.utmSourceMedium', label: 'Created Sources <br>(utm_source / utm_medium)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.utmCampaign', label: 'Created Sources <br>(utm_campaign)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.utmTerm', label: 'Created Sources (utm_term)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.utmContent', label: 'Created Sources <br>(utm_content)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.otherUTMs', label: 'Created Sources <br>(Other UTMs)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.gclid', label: 'Created Sources Click IDs <br>(gclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.fbclid', label: 'Created Sources Click IDs <br>(fbclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.fbp', label: 'Created Sources Click IDs <br>(fbp)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.fbc', label: 'Created Sources Click IDs <br>(fbc)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.msclkid', label: 'Created Sources Click IDs <br>(msclkid)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.li_fat_id', label: 'Created Sources Click IDs <br>(li_fat_id)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.ttclid', label: 'Created Sources Click IDs <br>(ttclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.dclid', label: 'Created Sources Click IDs <br>(dclid)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.srsltid', label: 'Created Sources Click IDs <br>(srsltid)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs._ga', label: 'Created Sources Click IDs <br>(_ga)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },
     { value: 'createdSources.clickIDs.calltrk_user', label: 'Created Sources Click IDs <br>(calltrk_user)', type:'dimension', section: SECTION_OPTION_SEGMENT.ECOMMERCE_SOURCE_DETAILS },

     // { value: 'createdSources.engagementScore', label: 'Created Source Engagement Score', type:'metric' },
     // { value: 'createdSources.weights.engagement', label: 'Created Sources Weights (Engagement)', type:'metric' },
     // { value: 'createdSources.weights.engagementND', label: 'Created Sources Weights (Engagement Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.firstTouch', label: 'Created Sources Weights (First Touch)', type:'metric' },
     // { value: 'createdSources.weights.firstTouchND', label: 'Created Sources Weights (First Touch Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.lastTouch', label: 'Created Sources Weights (Last Touch)', type:'metric' },
     // { value: 'createdSources.weights.lastTouchND', label: 'Created Sources Weights (Last Touch Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.uShaped', label: 'Created Sources Weights (U-Shaped)', type:'metric' },
     // { value: 'createdSources.weights.uShapedND', label: 'Created Sources Weights (U-Shaped Non Direct)', type:'metric' },
     // { value: 'createdSources.weights.linear', label: 'Created Sources Weights (Linear)', type:'metric' },
     // { value: 'createdSources.weights.linearND', label: 'Created Sources Weights (Linear Non Direct)', type:'metric' },
];

export const SF_CONNECTION_STATUS = {
     ENABLED: 'enabled',
     DISABLED: 'disabled',
     PAUSED: 'paused',
     CLOSED: 'closed',
     DRAFT: 'draft',
};

export const SF_JOB_STATUS = {
     WAITING: 'waiting',
     RUNNING: 'running',
     FINISHED: 'finished',
     FAILED: 'failed',
     CANCEL: 'cancel',
     PAUSED: 'paused',
};

export const SALESFORCE_STATUS_NUMBER_TO_TEXT = {
     [SF_CONNECTION_STATUS.ENABLED]: 'Active',
     [SF_CONNECTION_STATUS.DISABLED]: 'Disabled',
     [SF_CONNECTION_STATUS.PAUSED]: 'Paused',
     [SF_CONNECTION_STATUS.CLOSED]: 'Closed',
     [SF_CONNECTION_STATUS.DRAFT]: 'Draft',
};

export const SALESFORCE_STATUS_LOG = {
     0: 'Running',
     1: 'Stopped',
     2: 'Failed',
};

export const SHOW_SAVED_VIEW = 'show-saved-view';

export const SANDBOX_PAGE_LIMIT = process.env.REACT_APP_ENV === 'prod' ? 500 : 100;

export const PAID_PAGE_LIMIT = 10000;

export const MAPPING_TYPE_SELECT_FIELD = {
     string: ['id', 'string', 'textarea', 'picklist', 'address', 'currency', 'datetime'],
     phone: ['phone'],
     email: ['email'],
     url: ['url'],
     reference: ['reference'],
     number: ['int', 'double'],
     timestamp: ['date', 'datetime'],
     boolean: ['boolean'],
};

export const FIELDS_MAPPING_REPLATIONSHIP = [
     {label: 'MasterRecordID', value: 'MasterRecordID'},
     {label: 'ConvertedDate', value: 'ConvertedDate'},
     {label: 'AccountID', value: 'AccountID'},
     {label: 'ContactID', value: 'ContactID'},
     {label: 'QuoteID', value: 'QuoteID'},
     {label: 'IsPersonAccount', value: 'IsPersonAccount'},
     {label: 'OpportunityID', value: 'OpportunityID'},
     {label: 'ContractID', value: 'ContractID'},
     {label: 'PersonID', value: 'PersonID'},
     {label: 'UserID', value: 'UserID'},
     {label: 'SessionID', value: 'SessionID'},
     {label: 'EventID', value: 'EventId'},
     {label: 'RevealedCompanyID', value: 'RevealedCompanyID'},
];
