import { CCollapse } from '@coreui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import CenterSpinner from './Loadings/CenterSpinner';

const Accordion = ({ accordionShow, setAccordionShow, accordionType, title, description, isLoading, children, className, classNameBody }) => {
     const listenersLoading = useSelector((state) => state.subscriber.loadings.listeners);

     if (!isLoading) {
          isLoading = listenersLoading;
     }

     return (
          <div className={`accordion-item${accordionShow === accordionType ? ' show' : ''} ${className ? className : ''}`}>
               <button type='button' className='accordion-header' onClick={() => setAccordionShow(accordionShow === accordionType ? null : accordionType)}>
                    <strong className='title'>{title}</strong>
                    {description && <p className='description'>{description}</p>}
               </button>
               <CCollapse visible={accordionShow === accordionType}>
                    <div className={`accordion-body ${classNameBody ? classNameBody : ''}`}>{isLoading ? <CenterSpinner /> : children}</div>
               </CCollapse>
          </div>
     );
};

export default Accordion;
