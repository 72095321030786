import React, { useCallback, useRef, useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { setListSegmentReport } from '../../../../../actions/subscriber';
import { API_CLIENT_SEGMENT_REPORT, BQ_JOB_STATUS } from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { useOutsideHandling } from '../../../../../utils';
import SegmentDropdown from './SegmentDropdown';
import { EventExplorerContext, handleGetSortSegment } from '../EventExplorer';

const AddSegments = () => {
     const dispatch = useDispatch();
     const { reportName, segmentSticky, setSort, filterMetric, disableSort, listSegmentReportApply, setListSegmentReportApply } = useContext(EventExplorerContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const listSegmentReport = useSelector((state) => state.subscriber.listSegmentReport);

     const [showPopup, setShowPopup] = useState(false);
     const [segmentsSelected, setSegmentsSelected] = useState();
     const [saveLoading, setSaveLoading] = useState(false);
     const [segmentsEdit, setSegmentsEdit] = useState([]);
    const accountId = activeAccount.id;

     useEffect(() => {
          setSegmentsSelected(listSegmentReportApply);
     }, [listSegmentReportApply]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleClickBubble = () => {
          setTimeout(() => {
               if (!showPopup) {
                    setShowPopup(true);
               }
          }, 100);
     };

     const clickOutside = useCallback(() => {
          setShowPopup(false);
          setSegmentsEdit([]);
     }, []);

     const wrapperRef = useRef(null);
     useOutsideHandling(wrapperRef, clickOutside);

     const handleConfirmDelete = (id) => {
          const segmentToClose = segmentsSelected.find((item) => item.id === id);
          const segmentsEdit = [
               {
                    id: segmentToClose.id,
                    segmentReportId: segmentToClose.segmentReportId,
                    apply: false,
                    applyChart: false,
                    type: 'update',
               },
          ];
          const data = { reportName, accountId, segmentsEdit, segmentSticky }; 
          if (segmentsEdit && segmentsEdit.length) {
               setSaveLoading(true);
               callTokenApi(`${API_CLIENT_SEGMENT_REPORT}`, 'PUT', data).then((response) => {
                    if (response.status === 200) {
                         const { segmentReport } = response.data;
                         const newUpdate = [...listSegmentReport].map((seg) => {
                              const existed = segmentReport.find((item) => item.segmentId === seg.id);
                              if (existed) {
                                   seg.apply = existed.apply;
                              }
                              return seg;
                         });
                         const newListSegmentApply = newUpdate.filter((item) => item.apply);
                         if(newListSegmentApply.length > 0) {
                              setSort(
                                   handleGetSortSegment({
                                        listSegmentReportApply: newUpdate.filter(
                                             (item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE
                                        ),
                                        filterMetric,
                                   })
                              );
                         } else if(filterMetric[0] && !disableSort){
                              setSort({
                                   fieldName: filterMetric[0].value,
                                   isDesc: true,
                              });
                         }               
                         dispatch(setListSegmentReport(newUpdate));
                         setSegmentsSelected(newUpdate.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE));
                         setListSegmentReportApply(newUpdate.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE))
                         setSaveLoading(false);
                         toast.success('Remove segment success!');
                    }
               });
          }
     };

     return (
          <div className="custom-segment-dropdown d-flex align-items-center">
               <div className="segment-action d-flex align-items-center">
                    <div className="d-flex align-item-center flex-wrap justify-content-end box-segment">
                         {segmentsSelected && segmentsSelected.length > 0 ? (
                              segmentsSelected.map((item, index) => (
                                   <div className="rule-segment d-flex align-item-center" key={index}>
                                        <div className="rule-segment-item d-flex align-items-center">
                                             <div className="mr-2 rule-segment-item-icon d-flex align-items-center">
                                                  <CIcon
                                                       icon="iconChartPie"
                                                       style={{ cursor: 'pointer', width: '16px', height: '16px', marginRight: '5px' }}
                                                  />
                                                  <span className="rule-segment-item-level">S{index + 1}</span>
                                             </div>
                                             <div className="d-flex align-items-center rule-segment-item-info">
                                                  <p className="mr-3 mb-0">{item.name}</p>
                                                  <CIcon
                                                       icon="iconClose"
                                                       className="icon-close"
                                                       style={{ 
                                                            cursor: saveLoading ? 'not-allowed' : 'pointer',
                                                            width: '9px',
                                                            height: '9px',
                                                            pointerEvents: saveLoading ? 'none' : 'auto',
                                                            opacity: saveLoading ? 0.5 : 1
                                                       }}
                                                       onClick={() => handleConfirmDelete(item.id)}
                                                  />
                                             </div>
                                        </div>
                                   </div>
                              ))
                         ) : (
                              <span className="mr-3 no-segment">No segments added</span>
                         )}
                    </div>
                    <div className="btn-add-segments">
                         <CButton className="text-capitalize" onClick={handleClickBubble}>
                              <i className="fal fa-plus mr-1"></i>
                              Add Segments
                         </CButton>
                    </div>
               </div>
               {showPopup && (
                    <div className="segment-dropdown" ref={wrapperRef}>
                         <SegmentDropdown
                              accountId={accountId}
                              segmentsEdit={segmentsEdit}
                              setSegmentsEdit={setSegmentsEdit}
                              setSegmentsSelected={setSegmentsSelected}
                              setShowPopup={setShowPopup}
                         />
                    </div>
               )}
          </div>
     );
};

export default AddSegments;
