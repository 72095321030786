import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { CButton, CForm, CInputGroup } from '@coreui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { DEFAULT_TIME_ZONE, GOOGLE_ONE_TAP_LOGIN, SIGNUP } from '../../../../../constants';
import { useScript } from '../../../../../helpers/customHooks';
import { authenticate, handleInvitedUser, isAuth } from '../../../../../helpers/auth';
import { handleLoginPopup } from '../../../../../helpers/cms/subscriber';
import { callApi } from '../../../../../apiCaller';
import Google from '../../../../auth/Google';
import { toastError } from '../../../../../utils';
import { CInput } from '../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../migration/CInvalidFeedback';
import Cookies from 'js-cookie';
import CIcon from '@coreui/icons-react';

const validationSchema = (setValidateOnChange) => {
     setValidateOnChange(true);

     return Yup.object().shape({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: Yup.string().required('Email is required').email('Not a valid email'),
          password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
     });
};

let initialValues = {
     firstName: '',
     lastName: '',
     timeZone: DEFAULT_TIME_ZONE,
     password: '',
};

const Register = () => {
     const [validateOnChange, setValidateOnChange] = useState(false);
     const scriptFlag = '__googleOneTapScript__';
     const script = useScript(process.env.REACT_APP_ACCOUNTS_GOOGLE_CLIENT);
     const [googleOneTap, setGoogleOneTap] = useState(false);
     const { setCurrentStep, stepsData } = useContext(CreateAccountContext);
     const dataUser = JSON.parse(stepsData.dataUser);
     const informParent = useCallback((response) => {
          authenticate(response, () => {
               if (isAuth()) {
                    handleLoginPopup(response.data.user.id); // Handle if it'll show setup account again or not
                    window.location.replace('/');
               }
          });
     }, []);

     const limitedTimeGoogleOneTap = Cookies.get('limitedTimeGoogleOneTap')

     const onOneTapSignedIn = useCallback(
          (response) => {
               callApi(GOOGLE_ONE_TAP_LOGIN, 'POST', { oneTapToken: response.credential, dataUser }).then((response) => {
                    if (response.status === 200) {
                         informParent(response);
                    } else {
                         toastError(response);
                    }
               });
          },
          [informParent] // eslint-disable-line react-hooks/exhaustive-deps
     );

     const url = process.env.REACT_APP_LISTENLAYER_DOMAIN;

     useEffect(() => {
          if (typeof window !== 'undefined' && script === 'ready') {
               window.google.accounts.id.initialize({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    // prompt_parent_id: 'google-one-tap',
                    use_fedcm_for_prompt: true,
                    cancel_on_tap_outside: false,
                    callback: onOneTapSignedIn,
                    context: 'signup',
               });
               window[scriptFlag] = true;
               if (limitedTimeGoogleOneTap) {
                    setGoogleOneTap(false);
               }else {
                    setGoogleOneTap(true);
               }
          }
          if (window[scriptFlag] && script === 'ready') {
               window.google.accounts.id.prompt((notification) => {
                    //Debuging
                    if (notification.isSkippedMoment()) {
                         setGoogleOneTap(false);
                         if (!limitedTimeGoogleOneTap) {
                              Cookies.set(`limitedTimeGoogleOneTap`, true, { expires: 1 });
                         }
                    }
               });
          }
          return () => {
               if (typeof window !== 'undefined' && script === 'ready') {
                    window.google.accounts.id.cancel();
               }
          };
     }, [script, onOneTapSignedIn]); //eslint-disable-line

     const clickCreateAccount = async (values) => {
          const data = {
               referrerId: null,
               firstName: values.firstName,
               lastName: values.lastName,
               name: `${values.firstName} ${values.lastName}`,
               company: values.company,
               email: values.email,
               timeZone: values.timeZone,
               password: values.password,
               dataUser,
          };

          const response = await callApi(SIGNUP, 'POST', data);
          if (response.status === 200) {
               if (response.data.userPending) {
                    handleInvitedUser(response.data.user.id);
               }
               informParent(response);
          } else {
               toastError(response);
          }
     };

     const onBackBtnClicked = () => {
          setCurrentStep(STEPS.createLogin);
     };

     return (
          <CreateAccountBody hideStepProgress={true} onBackBtnClicked={onBackBtnClicked}>
               <div className="login-section">
                    {isAuth() && <Redirect to="/" />}
                    <div>
                         <div className="login-section-main p-0">
                              <div className={`login-section-card create-an-account${googleOneTap ? ' has-google-one-tap' : ''}`}>
                                   <div className="login-section-card-title">
                                        <h1>Create an Account</h1>
                                   </div>
                                   <div className="login-section-card-description">
                                        <p>Create a ListenLayer account to supercharge your website data with powerful Listeners.</p>
                                   </div>
                                   <Formik
                                        initialValues={initialValues}
                                        validationSchema={() => validationSchema(setValidateOnChange)}
                                        onSubmit={clickCreateAccount}
                                        validateOnChange={validateOnChange}
                                        validateOnBlur={false}
                                   >
                                        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                                             <CForm className="app-create-account" onSubmit={handleSubmit} noValidate>
                                                  <div className="input-fullname">
                                                       <CInputGroup>
                                                            <CInput
                                                                 name="firstName"
                                                                 placeholder="First name"
                                                                 autoComplete="firstname"
                                                                 invalid={!!errors.firstName}
                                                                 value={values.firstName}
                                                                 onChange={handleChange}
                                                                 disabled={isSubmitting}
                                                            />
                                                            <CInvalidFeedback>{errors.firstName}</CInvalidFeedback>
                                                       </CInputGroup>
                                                       <CInputGroup>
                                                            <CInput
                                                                 name="lastName"
                                                                 placeholder="Last name"
                                                                 autoComplete="lastname"
                                                                 invalid={!!errors.lastName}
                                                                 value={values.lastName}
                                                                 onChange={handleChange}
                                                                 disabled={isSubmitting}
                                                            />
                                                            <CInvalidFeedback>{errors.lastName}</CInvalidFeedback>
                                                       </CInputGroup>
                                                  </div>
                                                  <CInputGroup>
                                                       <CInput
                                                            name="email"
                                                            placeholder="Email address"
                                                            autoComplete="email"
                                                            invalid={!!errors.email}
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting}
                                                       />
                                                       <CInvalidFeedback>{errors.email}</CInvalidFeedback>
                                                  </CInputGroup>
                                                  <CInputGroup>
                                                       <CInput
                                                            name="password"
                                                            type="password"
                                                            placeholder="Password"
                                                            autoComplete="new-password"
                                                            invalid={!!errors.password}
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            disabled={isSubmitting}
                                                       />
                                                       <CInvalidFeedback>{errors.password}</CInvalidFeedback>
                                                  </CInputGroup>

                                                  <div className="login-section-card-button">
                                                       <CButton shape="rounded-pill" type="submit" color="success" block disabled={isSubmitting} className="btn-pill">
                                                            {isSubmitting ? (
                                                                 <>
                                                                      Waiting<span className="dots-waiting"></span>
                                                                 </>
                                                            ) : (
                                                                 'Get Started'
                                                            )}
                                                       </CButton>
                                                  </div>
                                             </CForm>
                                        )}
                                   </Formik>
                                   <div className="login-card-with-google">
                                        {/* <div id="google-one-tap"></div> */}
                                        {!googleOneTap ? (
                                             <Google
                                                  informParent={informParent}
                                                  className="btn-google-outline btn-pill"
                                                  isIconColor={true}
                                                  labelButton="Sign Up With Google"
                                                  dataUser={dataUser}
                                                  isFromLogin={false}
                                             />
                                        ) : (
                                             <div className="google-one-tap" style={{justifyContent: 'center', height: '46px', borderRadius: '100px'}}>
                                                  <CIcon icon="iconGoogleLogin" width={20} height={20} />
                                                  <div className="google-one-tap-title">Use the popup on the page to sign up with Google</div>
                                             </div>
                                        )}
                                   </div>
                                   <div className="text-muted mt-4" style={{fontSize: '.875rem'}}>
                                        By registering, you agree to our{' '}
                                        <a href={`${url}terms-of-use/`} target="_blank" rel="noopener noreferrer">
                                             Terms of Service
                                        </a>{' '}
                                        and{' '}
                                        <a href={`${url}privacy-policy/`} target="_blank" rel="noopener noreferrer">
                                             Privacy Policy
                                        </a>
                                        .
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </CreateAccountBody>
     );
};

export default Register;
