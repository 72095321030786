import React, { useContext, useEffect, useState } from 'react';
import { CButton, CForm, CCard, CCardBody, CBadge } from '@coreui/react';
import { Formik } from 'formik';
import CIcon from '@coreui/icons-react';
import { useSelector } from 'react-redux';

import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_LISTENERS } from '../../../../../../constants';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { toastError } from '../../../../../../utils';
import { getListenerColorIcon } from '../../../../../../helpers/cms/subscriber';
import Waiting from '../../../../../general/Loadings/Waiting';
import useSaveCurrentStep from '../useSaveCurrentStep';
import useSaveListenerProgress from './useSaveListenerProgress';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../../migration/CInput';
import CTooltip from '../../../../../migration/CTooltip';

export const getDefaultListeners = (listeners) => {
    let defaultListeners = [];
    const defaultListenerCodes = ['userSource', 'urlParameters', 'userDevice', 'userGeolocation', 'outboundClicks', 'internalClicks', 'pdfClicks', 'pageView', 'clickIDs', 'sessionEngagement', 'clickToCall'];

    listeners.forEach(listener => {
        if (defaultListenerCodes.includes(listener.code)) {
            defaultListeners.push(listener);
        }
    });

    return defaultListeners;
}

export const useListeners = () => {
    const { stepsData, setStepsData, setCurrentStep } = useContext(CreateAccountContext);
    const accountListeners = useSelector(state => state.subscriber.accountListeners);
    const isFromGetStarted = useSelector(state => state.subscriber.isFromGetStarted);
    const [fetchListenersLoading, setFetchListenersLoading] = useState(true);

    const handleFirstLoad = () => {
        const fetchListener = async () => {
            let listeners;

            // if (isFromGetStarted) {
            //     listeners = accountListeners.filter(listener => !listener.configStatus);
            // } else {
            listeners = stepsData.listeners || accountListeners;
            // }

            if (!listeners || (Array.isArray(listeners) && listeners.length === 0)) {
                await callTokenApi(API_CLIENT_LISTENERS, 'GET', null)
                    .then(response => {
                        if (response.status === 200) {
                            listeners = response.data.listeners;
                        } else {
                            toastError(response);
                        }
                    })
            }

            let defaultListeners = getDefaultListeners(listeners);

            listeners = listeners.map(listener => ({ ...listener, logo: getListenerColorIcon(listener) }));

            let newStepsData = { listeners, allSelectedListeners: { ...stepsData.allSelectedListeners, defaultListeners } }

            if (stepsData.listenerCurrentStep) {
                newStepsData.listenerCurrentStep = '';
                setStepsData(newStepsData);
                setCurrentStep(stepsData.listenerCurrentStep);
                return;
            };

            setStepsData(newStepsData);
            setFetchListenersLoading(false);
        }

        fetchListener();
    };

    useEffect(handleFirstLoad, [isFromGetStarted, accountListeners]); // eslint-disable-line react-hooks/exhaustive-deps

    return { fetchListenersLoading };
};

const ConfigureMeasurement = () => {
    const { stepsData, setStepsData, setCurrentStep } = useContext(CreateAccountContext);
    const { fetchListenersLoading } = useListeners();
    const { tempSelectedListenerTopics, allSelectedListeners, selectedWeatherFeatures } = stepsData;
    useSaveListenerProgress(true);
    useSaveCurrentStep();

    const topics = [
        {
            label: 'Form Activity & Scheduling Tools',
            value: STEPS.formListener,
            description: 'Measure real form submissions, impressions, starts, and validation errors (not button clicks or “thank you” pages) and scheduling activity (from calendar tools like Acuity).'
        },
        {
            label: 'Chat Activity',
            value: STEPS.chatListener,
            description: 'Capture detailed chat message activity, counts, and email conversions. Understand bot vs human interaction.'
        },
        {
            label: 'Ecommerce & Shopping Behavior',
            value: STEPS.ecommerceListener,
            description: 'Measure shopping behavior and transaction details. All data is pre-structured for GA4, Universal Analytics, and other platforms.'
        },
        {
            label: 'Video Engagement',
            value: STEPS.videoListener,
            description: 'Measure detailed engagement with video content on your website.',
        },
        {
            label: 'User’s Local Weather',
            value: STEPS.weatherListener,
            description: 'Capture local weather data from website users (recent, current, and forecast weather).'
        }
    ];

    const defaultListeners = [
        {
            label: 'Identify Users & Traffic Sources',
            description: 'Identify the traffic source that brought a user to your website in real time. Customize the rules to build your own traffic source structure.',
        },
        {
            label: 'Capture URL Parameters',
            description: 'Capture URL parameters into the data layer automatically so you can use them in rules, store them in the browser, or capture the values over time.',
        },
        {
            label: 'Identify Users’ Device & Geolocation',
            description: 'Capture user device details and general geolocation information. You can enable to feature to ask users for their precise location inside your account.',
        },
        {
            label: 'Advanced Click Tracking',
            description: 'Track internal, outbound, document/pdf, and navigation clicks accurately and automatically.',
        },
        {
            label: 'Content Engagement',
            description: 'Understand and segment content views and engagement across your website or single page application.',
        },
    ];

    const onBackBtnClicked = () => {
        setCurrentStep(STEPS.almostDone);
    };

    const keepSelectedListeners = (selectedListenerTopics) => {
        let newSelectedListeners = {
            defaultListeners: allSelectedListeners.defaultListeners
        };

        selectedListenerTopics.forEach(topic => {
            switch (topic) {
                case STEPS.formListener:
                    newSelectedListeners.form = allSelectedListeners.form;
                    break;
                case STEPS.chatListener:
                    newSelectedListeners.chat = allSelectedListeners.chat;
                    break;
                case STEPS.ecommerceListener:
                    newSelectedListeners.ecommerce = allSelectedListeners.ecommerce;
                    break;
                case STEPS.videoListener:
                    newSelectedListeners.video = allSelectedListeners.video;
                    break;
                case STEPS.weatherListener:
                    newSelectedListeners.weather = allSelectedListeners.weather;
                    break;
                default:
            }
        })

        return newSelectedListeners;
    }

    const onSubmit = ({ tempSelectedListenerTopics }) => {
        const selectedListenerTopics = tempSelectedListenerTopics.filter(topic => !!topic);

        if (selectedListenerTopics.length === 0) { // No selected topics
            setStepsData({
                tempSelectedListenerTopics,
                allSelectedListeners: {
                    defaultListeners: allSelectedListeners.defaultListeners
                },
                selectedListenerTopics,
                selectedWeatherFeatures: undefined,
                totalConfigureMeasurementSteps: selectedListenerTopics.length // + 1 for consent configuration
            });
            setCurrentStep(null, CHANGE_STEP_TYPE.mainStepNext);
        } else { // Has any selected topics
            let newSelectedListeners = keepSelectedListeners(selectedListenerTopics);
            setStepsData({
                tempSelectedListenerTopics,
                selectedListenerTopics,
                allSelectedListeners: newSelectedListeners,
                selectedWeatherFeatures: selectedListenerTopics.find(topic => topic === STEPS.weatherListener) ? selectedWeatherFeatures : undefined,
                totalConfigureMeasurementSteps: selectedListenerTopics.length // + 1 for consent configuration
            });
            setCurrentStep(selectedListenerTopics[0], CHANGE_STEP_TYPE.subStepNext);
        }
    };

    return (
        <Formik
            initialValues={{ tempSelectedListenerTopics }}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
                const handleSelectTopic = (checked, topic, index) => {
                    let newSelectedTopics = [...values.tempSelectedListenerTopics];

                    if (checked) {
                        newSelectedTopics[index] = topic.value;
                    } else {
                        newSelectedTopics[index] = undefined;
                    }

                    setFieldValue('tempSelectedListenerTopics', newSelectedTopics);
                };

                return (
                    <CreateAccountBody onBackBtnClicked={onBackBtnClicked} backBtnDisabled={fetchListenersLoading || isSubmitting}>
                        <CCard className="account-configure-measurement">
                            <CCardBody>
                                {fetchListenersLoading ? (
                                    <CenterSpinner />
                                ) : (
                                    <CForm onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <h3>Configure Measurement</h3>
                                            <p className="des">
                                                Select the topics that interest you. We'll quickly configure your measurement for fast results.
                                            </p>
                                            <span className="foot-note">
                                                Nothing you change here will effect the user experience on your website.
                                            </span>
                                            <div className="list-measurement">
                                                {
                                                    topics.map((topic, index) => {
                                                        let clickAdded = {};

                                                        let tooltipAttrs = {
                                                            placement: "bottom",
                                                            advancedOptions: {
                                                                onShow(instance) {
                                                                    instance.popper.className = 'custom-tooltip';
                                                                },
                                                                onTrigger(e) {
                                                                    if (!clickAdded[e.id]) {
                                                                        e.reference.addEventListener('click', function (e) {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                        });
                                                                        clickAdded[e.id] = true;
                                                                    }
                                                                },
                                                                hideOnClick: false,
                                                            }
                                                        };

                                                        return (
                                                            <CLabel className="measurement" key={topic.value}>
                                                                <div className="d-flex align-items-center label">
                                                                    <div className="topic-label" htmlFor={`_${topic.value}`}>
                                                                        {topic.label}
                                                                    </div>
                                                                    <CTooltip
                                                                        content={topic.description}
                                                                        {...tooltipAttrs}
                                                                        visible={false}
                                                                    >
                                                                        <CIcon icon="iconInfo" width={12} height={12} />
                                                                    </CTooltip>
                                                                    {
                                                                        topic.value === STEPS.weatherListener && (
                                                                            <CTooltip
                                                                                content="Enabling this will require you to upgrade to a Team plan starting at $49/mo."
                                                                                {...tooltipAttrs}
                                                                            >
                                                                                <CBadge className="label-top-card color-premium">Premium</CBadge>
                                                                            </CTooltip>
                                                                        )
                                                                    }
                                                                </div>
                                                                <CInputCheckbox
                                                                    id={`_${topic.value}`}
                                                                    checked={!!values.tempSelectedListenerTopics[index]}
                                                                    onChange={(e) => handleSelectTopic(e.target.checked, topic, index)}
                                                                    disabled={isSubmitting}
                                                                />
                                                            </CLabel>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <CButton className="btn-save next" type="submit" disabled={isSubmitting}>
                                                <Waiting isLoading={isSubmitting}>NEXT</Waiting>
                                            </CButton>
                                            <div className="notification">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <CIcon icon="iconCheckCircleBlack" width={18} height={17} />
                                                    <h4 className="ml-2 pt-2">Enabled by Default</h4>
                                                </div>
                                                <p className="des">
                                                    We'll enable Listeners by default to accomplish the following. You can always turn these off from
                                                    inside your account.
                                                </p>
                                                <div className="list-measurement">
                                                    {defaultListeners.map((m) => {
                                                        let flag = false;
                                                        return (
                                                            <div className="measurement default" key={m.label}>
                                                                <p className="headline">{m.label}</p>
                                                                <CTooltip
                                                                    content={m.description}
                                                                    placement="top"
                                                                    advancedOptions={{
                                                                        onShow(instance) {
                                                                            instance.popper.className = 'custom-tooltip';
                                                                        },
                                                                        onTrigger(e) {
                                                                            if (!flag) {
                                                                                e.reference.addEventListener('click', function (e) {
                                                                                    e.stopPropagation();
                                                                                    e.preventDefault();
                                                                                });
                                                                                flag = true;
                                                                            }
                                                                        },
                                                                        hideOnClick: false,
                                                                    }}
                                                                >
                                                                    <CIcon icon="iconInfo" width={12} height={12} />
                                                                </CTooltip>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </CFormGroup>
                                    </CForm>
                                )}
                            </CCardBody>
                        </CCard>
                    </CreateAccountBody>
                );
            }}
        </Formik>

    );
};

export default ConfigureMeasurement;
