import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Select from 'react-select';
import { SalesforceDetailDetailContext } from '../Popup/SalesforceConnectAccount';
import {
     setShowHistoricalPopup,
     setDataHistoricalSalesforce,
     fetchSalesforceRulesRequest,
} from '../../../../../../../../actions/subscriber';
import { callSalesforceApi } from '../../../../../../../../apiCaller';
import { API_SALESFORCE_CONNECTION, API_SALESFORCE_GET_CUSTOM_OBJECT, COMPONENT_NAME, SF_CONNECTION_STATUS } from '../../../../../../../../constants';
import { toast } from 'react-toastify';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../../../actions/common';
import CFormGroup from '../../../../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../../../../migration/CInput';
import CLabel from '../../../../../../../migration/CLabel';

const ObjectsToImportSalesforce = ({ idEdit }) => {
     const dispatch = useDispatch();
     const { accountId } = useSelector((state) => state.subscriber.activeAccount);
     const { activeStep, setActiveStep, stepsData, initStepsData } = useContext(SalesforceDetailDetailContext);
     const [isLoadingWidthOut, setIsLoadingWithOut] = useState(false);

     const defaultObjects = ['Lead', 'Contact', 'Account', 'Opportunity', 'Quote', 'Order', 'Contract', 'Campaign', 'Event'];
     const listObjectAutoImport = ['AccountContactRelation', 'OpportunityContactRole', 'CampaignMember', 'ContractContactRole', 'EventRelation'];
     const objects = typeof stepsData.objects === 'string' ? JSON.parse(stepsData.objects) : stepsData.objects;
     const customObjects = stepsData.objects ? objects.filter((item) => !defaultObjects.includes(item) && !listObjectAutoImport.includes(item)) : [];
     const initialValues = objects && objects.length > 0 && idEdit && stepsData.status !== SF_CONNECTION_STATUS.DRAFT
          ? [
               ...defaultObjects.map(item => ({
                    name: item, 
                    checked: objects.includes(item), 
                    type: 'default'
                })),
               ...customObjects.map(item => ({
                   name: item, 
                   checked: true, 
                   type: 'custom'
               }))
            ]
          : [
                 { name: 'Lead', checked: true, type: 'default' },
                 { name: 'Contact', checked: true, type: 'default' },
                 { name: 'Account', checked: true, type: 'default' },
                 { name: 'Opportunity', checked: true, type: 'default' },
                 { name: 'Quote', checked: true, type: 'default' },
                 { name: 'Order', checked: false, type: 'default' },
                 { name: 'Contract', checked: false, type: 'default' },
                 { name: 'Campaign', checked: true, type: 'default' },
                 { name: 'Event', checked: true, type: 'default' },
            ];

     const [optionsCustomObject, setOptionsCustomObject] = useState([]);

     useEffect(() => {
          if (activeStep === 4 && (optionsCustomObject.length <= 0)) {
               callSalesforceApi(`${API_SALESFORCE_GET_CUSTOM_OBJECT}?connectionId=${stepsData.id}`, 'GET').then((response) => {
                    if (response && response.status === 200) {
                         const listCustomObject = response.data.data
                              .filter((i) => !customObjects.includes(i.name))
                              .map((item) => ({ value: item.name, label: item.name }));
                         setOptionsCustomObject((prev) => [...prev, ...listCustomObject]);
                    } else {
                         setOptionsCustomObject((prev) => [...prev]);
                         toast.error('Get custom object failed!');
                    }
               });
          }
     }, [activeStep]); //eslint-disable-line

     const autoImportObject = (ojs) => {
          const newOjs = ojs.filter((item) => item.checked).map((i) => i.name);
          const newListObject = [];

          newOjs.forEach((item) => {
               if (listObjectAutoImport.includes(item)) {
                    return;
               } else if (item === 'Account') {
                    newListObject.push('Account', 'AccountContactRelation');
               } else if (item === 'Opportunity') {
                    newListObject.push('Opportunity', 'OpportunityContactRole');
               } else if (item === 'Campaign') {
                    newListObject.push('Campaign', 'CampaignMember');
               } else if (item === 'Contract') {
                    newListObject.push('Contract', 'ContractContactRole');
               } else if (item === 'Event') {
                    newListObject.push('Event', 'EventRelation');
               } else {
                    newListObject.push(item);
               }
          });
          return newListObject;
     };

     const onSubmit = (values) => {
          const newValues = {
               data: {
                    ...stepsData,
                    objects: autoImportObject(values.checkboxes),
               },
          };

          dispatch(setShowHistoricalPopup(true));
          if (idEdit) {
               dispatch(setDataHistoricalSalesforce({ ...newValues, type: 'edit' }));
          } else {
               dispatch(setDataHistoricalSalesforce({ ...newValues, type: 'create' }));
          }
     };

     const handleRemoveCustomObject = (index, setFieldValue, values) => {
          setOptionsCustomObject((prev) => [...prev, { value: values.checkboxes[index].name, label: values.checkboxes[index].name }]);
          const updatedCheckboxes = [...values.checkboxes];
          updatedCheckboxes.splice(index, 1);
          setFieldValue('checkboxes', updatedCheckboxes);
     };

     const handleChangeOption = (option, setFieldValue, values) => {
          setOptionsCustomObject((prev) => prev.filter((i) => i.value !== option.value));
          const updatedCheckboxes = [...values.checkboxes];
          updatedCheckboxes.push({ name: option.label, checked: true, type: 'custom' });
          setFieldValue('checkboxes', updatedCheckboxes);
     };

     const handleSaveWithoutHistorical = (values) => {
          setIsLoadingWithOut(true);
          const newValues = {
               isPauseHistoricalSync: true,
               isPauseDailySync: false,
               objects: autoImportObject(values.checkboxes),
               status: stepsData.status === SF_CONNECTION_STATUS.DRAFT ? SF_CONNECTION_STATUS.ENABLED : stepsData.status,
          };

          callSalesforceApi(`${API_SALESFORCE_CONNECTION}/${stepsData.id}`, 'PUT', newValues).then((response) => {
               if (response && response.status === 200) {
                    dispatch(setFlexibleModal({ show: false, component: '' }));
                    dispatch(setRuleHaveEditting({ show: false, type: '', idConnection: 0 }));
                    dispatch(fetchSalesforceRulesRequest(accountId, false));
                    setIsLoadingWithOut(false);
                    toast.success('Add Connection Salesforce Successfully!');
               } else {
                    setIsLoadingWithOut(false);
                    toast.error('Add Connection Salesforce Fail!');
               }
          });
     };

     const checkStepPassed = () => {
          let passed = true;
          const { status, maxDailyAPICall } = stepsData;

          if (status === SF_CONNECTION_STATUS.DRAFT || !maxDailyAPICall) {
               passed = false;
          }

          return passed;
     };

     return (
          <CCard className={activeStep === 4 ? 'show' : 'hide'}>
               <CCardHeader>
                    {activeStep !== 4 ? (
                         <div className="rule-step d-inline-flex justify-content-between w-100">
                              <h5 className="mb-0 inactive">Step 4: Objects to Import</h5>
                              {checkStepPassed() && (
                                   <div className="d-flex">
                                        <div className="d-inline-flex align-items-center">
                                             <CButton className="btn-edit" onClick={() => setActiveStep(4)}>
                                                  Edit
                                             </CButton>
                                        </div>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5 className="mb-0">Step 4: Objects to Import</h5>
                    )}
               </CCardHeader>
               {activeStep === 4 && (
                    <CCardBody>
                         <Formik initialValues={{ checkboxes: initialValues }} onSubmit={onSubmit} validateOnBlur={false}>
                              {({ handleSubmit, values, handleChange, setFieldValue }) => {
                                   if (idEdit) {
                                        if (
                                             values.checkboxes
                                                  .filter((i) => i.checked)
                                                  .map((i) => i.name)
                                                  .sort()
                                                  .join(',') ===
                                             initStepsData.objects
                                                  .filter((i) => !listObjectAutoImport.includes(i))
                                                  .sort()
                                                  .join(',')
                                        ) {
                                             dispatch(setRuleHaveEditting({ show: false, type: COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS }));
                                        } else {
                                             dispatch(setRuleHaveEditting({ show: true, type: COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS }));
                                        }
                                   }

                                   return (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CRow style={{ margin: 0 }}>
                                                  <p>
                                                       Select the objects you'd like to import from this Salesforce connection. You can always return
                                                       here to include additional objects in the future. We've pre-selected default objects that we
                                                       require, and you can search for Custom Objects you'd like to include.
                                                  </p>
                                             </CRow>
                                             <div style={{ marginLeft: '50px' }}>
                                                  {values &&
                                                       values.checkboxes.map((item, index) => {
                                                            return (
                                                                 <CRow key={index} className="mb-2">
                                                                      <CCol lg="3" style={{ display: 'flex', alignItems: 'center' }}>
                                                                           <CFormGroup variant="custom-checkbox" inline>
                                                                                <CInputCheckbox
                                                                                     custom
                                                                                     id={`checkboxes[${index}].checked`}
                                                                                     name={`checkboxes[${index}].checked`}
                                                                                     //  disabled={values.enableServerSide}
                                                                                     disabled={item.disabled}
                                                                                     checked={item.checked}
                                                                                     onChange={handleChange}
                                                                                />
                                                                                <CLabel
                                                                                     variant="custom-checkbox"
                                                                                     htmlFor={`checkboxes[${index}].checked`}
                                                                                     style={{
                                                                                          paddingLeft: '6px',
                                                                                          marginBottom: '0',
                                                                                          lineHeight: '17px',
                                                                                     }}
                                                                                >
                                                                                     <span style={{ color: 'black', fontSize: '16px' }}>
                                                                                          {item.name}
                                                                                     </span>
                                                                                </CLabel>
                                                                           </CFormGroup>
                                                                      </CCol>
                                                                      <CCol lg="9">
                                                                           {item.type === 'custom' && (
                                                                                <CButton
                                                                                     style={{
                                                                                          marginLeft: '30px',
                                                                                          display: 'flex',
                                                                                          alignItems: 'center',
                                                                                          gap: '10px',
                                                                                          padding: '0',
                                                                                     }}
                                                                                     onClick={() =>
                                                                                          handleRemoveCustomObject(index, setFieldValue, values)
                                                                                     }
                                                                                >
                                                                                     <CIcon icon="iconDeleteCircle" style={{ margin: '0' }} />{' '}
                                                                                     <span style={{ textTransform: 'none', lineHeight: '1' }}>
                                                                                          remove custom object
                                                                                     </span>
                                                                                </CButton>
                                                                           )}
                                                                      </CCol>
                                                                 </CRow>
                                                            );
                                                       })}
                                                  <CRow className="mb-4 mt-4">
                                                       <CCol lg="3">
                                                            <Select
                                                                 classNamePrefix="react-select"
                                                                 placeholder={'Search for custom objects by name'}
                                                                 options={optionsCustomObject}
                                                                 value={null}
                                                                 onChange={(option) => handleChangeOption(option, setFieldValue, values)}
                                                                 maxMenuHeight={200}
                                                            />
                                                       </CCol>
                                                  </CRow>
                                                  {/* <CRow>
                                                       <CCol>
                                                            <CButton className="btn-add-row mb-3">
                                                                 <CIcon icon="iconAddField" className="icon-add" />
                                                                 <CLabel className="add-row">Add custom object</CLabel>
                                                            </CButton>
                                                       </CCol>
                                                  </CRow> */}
                                                  <div className="d-flex align-items-center">
                                                       <CButton
                                                            type="submit"
                                                            className="px-4 mr-4 btn-next-step"
                                                            color="primary"
                                                            disabled={isLoadingWidthOut || stepsData.status === SF_CONNECTION_STATUS.PAUSED}
                                                       >
                                                            SAVE & SYNC
                                                       </CButton>
                                                       <CButton
                                                            className="mx-4"
                                                            color="link"
                                                            style={{ fontSize: '13px', maxWidth: '291px' }}
                                                            onClick={() => handleSaveWithoutHistorical(values)}
                                                            disabled={isLoadingWidthOut || stepsData.status === SF_CONNECTION_STATUS.PAUSED}
                                                       >
                                                            {isLoadingWidthOut ? (
                                                                 <span className="dots-waiting">Save without running historical sync</span>
                                                            ) : (
                                                                 'Save without running historical sync'
                                                            )}
                                                       </CButton>
                                                  </div>
                                             </div>
                                        </CForm>
                                   );
                              }}
                         </Formik>
                    </CCardBody>
               )}
               {/* <ConfirmSaveChange
                    show={saveModal}
                    onClose={toggleSaveModal}
                    isLoading={saveLoading}
                    onAccept={handleSave}
                    title={id ? 'Are You Sure You Want to Save This Change?' : 'Are you sure you want to save?'}
               >
                    <p>
                         {id
                              ? 'You are about to update this Salesforce Connection. This will not effect any other Salesforce Connection settings.'
                              : 'You are about to save this new Salesforce Connection. After doing this, publish your changes to see your Salesforce Connection working in your data layer.'}
                    </p>
               </ConfirmSaveChange> */}
          </CCard>
     );
};

export default ObjectsToImportSalesforce;
