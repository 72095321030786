import CIcon from '@coreui/icons-react';
import { CButton, CCard, CSpinner } from '@coreui/react';
import React, { useCallback, useRef, useState } from 'react';
import FormRequiringTableRow from './FormRequiringTableRow';
import { callTokenApi } from '../../../../../../apiCaller';
import { toastError, useOutsideHandling } from '../../../../../../utils';
import {
     API_CLIENT_UPDATE_FORM_CATEGORIES,
     API_CLIENT_UPDATE_INHERIT_PARENT,
     API_CLIENT_UPDATE_PUSH_EVENTS,
     COMPONENT_NAME,
} from '../../../../../../constants';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
     setFormIgnored,
     handleFetchCountFormsRequiring,
     handleFetchCountFormsIgnored,
     handleFetchCountFormsInventory,
     setFormInventory,
     setShowIgnoredForm,
     setFormInherit,
     setFormRedirect,
     handleApplyChildForms,
     handleApplyRedirectForms,
     handleUpdateChildFormsIgnored,
     setAllFormsInventory,
     setAllFormsIgnore,
     setFormRequiringAction,
     setAllFormsRequiring,
     setFormIgnoreEdit,
     setSubscriberState,
     setFilterFormCategories,
     setFilterEditFormCategories,
} from '../../../../../../actions/subscriber';
import cloneDeep from 'lodash/cloneDeep';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { setFlexibleModal } from '../../../../../../actions/common';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { ClickInfoIconTooltip } from '../../../../../general/custom-tooltips';
import MultiForms from './MultiForms';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../../migration/CInput';

const FormIgnoredLayout = ({ className = '', tableClassName = '', onLoadMore, formsIgnored }) => {
     const dispatch = useDispatch();
     // const formIgnored = useSelector((state) => state.subscriber.formIgnored);
     const allFormsIgnored = useSelector((state) => state.subscriber.allFormsIgnored);
     const formIgnoreEdit = useSelector((state) => state.subscriber.formIgnoreEdit);
     const ignoredShowPaging = useSelector((state) => state.subscriber.ignoredShowPaging);
     const countFormIgnored = useSelector((state) => state.subscriber.countFormIgnored);
     const ignoredCountLoading = useSelector((state) => state.subscriber.ignoredCountLoading);
     const showIgnoredForm = useSelector((state) => state.subscriber.showIgnoredForm);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const applyChild = useSelector((state) => state.subscriber.applyChild);
     const applyRedirect = useSelector((state) => state.subscriber.applyRedirect);
     const isLoading = useSelector((state) => state.subscriber.ignoredLoading);
     const isLoadingSettings = useSelector((state) => state.subscriber.requiringSettingsLoading);
     const ignoredFormsLoading = useSelector((state) => state.subscriber.ignoredFormsLoading);
     const ignoredNumberPerpage = useSelector((state) => state.subscriber.ignoredNumberPerpage);
     const ignoredDisableNextPage = useSelector((state) => state.subscriber.ignoredDisableNextPage);
     const formCategoriesRules = useSelector((state) => state.subscriber.formCategoriesRules);
     const [submitLoading, setSubmitLoading] = useState(false);
     const [updateModal, setUpdateModal] = useState(false); // false: not show
     const [errors, setErrors] = useState([]);
     const [tooltipSettings, setTooltipSettings] = useState(false);
     const statusRefSettings = useRef(null);
     const itemsPerPage = 12;

     const rulesDefault = formCategoriesRules ? formCategoriesRules.filter((rule) => rule.name === 'Categorize Forms') : [];

     let formsEdit = formsIgnored && formsIgnored.every((el) => el.issue !== 'isDrafted'); // Enable save changes button if has at least 1 draft form

     if (formsEdit) {
          formsEdit =
               formIgnoreEdit.length > 0
                    ? formIgnoreEdit.every((item) => {
                           if (item.issue === 'isDrafted') {
                                return false;
                           }

                           // Show save change ignored when reloading page without saving
                           if (item.categoryId && item.name && item.proposeName && item.proposeCategory) {
                                return false;
                           }

                           const initForm = allFormsIgnored && allFormsIgnored.find((form) => form.id === item.id);

                           if (initForm)
                                return (
                                     item.categoryId === initForm.categoryId &&
                                     item.inheritParent === initForm.inheritParent &&
                                     item.isRedirect === initForm.isRedirect &&
                                     item.name === initForm.name
                                );
                           return false;
                      })
                    : true;
     }

     const treeFamilyOfForms = formsIgnored
          ? formsIgnored.map((form) => {
                 const formExisted = formIgnoreEdit.find((item) => item.id === form.id);

                 if (formExisted) {
                      return { ...form, ...formExisted };
                 }
                 return form;
            })
          : [];

     const checkValidate = () => {
          let newErrors = [];
          const newForms = formIgnoreEdit && formIgnoreEdit.length > 0 ? cloneDeep(formIgnoreEdit) : cloneDeep(formsIgnored);

          for (let index = 0; index < newForms.length; index++) {
               if (newForms[index].categoryId && !newForms[index].name) {
                    newErrors = [...newErrors, { id: newForms[index].id, invalidName: true }];
               } else if (newForms[index].name && !newForms[index].categoryId) {
                    newErrors = [...newErrors, { id: newForms[index].id, invalidCategory: true }];
               } else {
                    newErrors = [...newErrors];
               }
          }

          if (newErrors.length > 0) {
               setErrors(newErrors);
               toast.error('Some fields are in error!');
          } else {
               setUpdateModal(true);
          }
     };

     const onSubmit = () => {
          setSubmitLoading(true);

          for (let i = 0; i < formIgnoreEdit.length; i++) {
               if (formIgnoreEdit[i].name && formIgnoreEdit[i].categoryId) {
                    formIgnoreEdit[i].issue = 'isCategorized';
               }

               if (formIgnoreEdit[i].issue !== 'isIgnored' && !formIgnoreEdit[i].name && !formIgnoreEdit[i].categoryId) {
                    formIgnoreEdit[i].issue = 'isAttention';
               }

               if (formIgnoreEdit[i].identifiedDate && formIgnoreEdit[i].name && formIgnoreEdit[i].categoryId) {
                    formIgnoreEdit[i].identifiedDate = null;
               }
          }

          //Update form categories
          if (formIgnoreEdit && formIgnoreEdit.length > 0) {
               formIgnoreEdit.map((item) => {
                    if (item.name) {
                         item.name = item.name.trim();
                    }
                    return item;
               });

               const newDataRequest = {
                    accountId: activeAccount.id,
                    data: formIgnoreEdit,
                    type: 'ignored',
               };
               callTokenApi(API_CLIENT_UPDATE_FORM_CATEGORIES, 'PUT', newDataRequest)
                    .then((response) => {
                         if (response.status === 200) {
                              const { countChildIgnored } = response.data;
                              // let newFormIgnored = [];
                              let newAllFormIgnored = [];

                              formIgnoreEdit.forEach((item) => {
                                   const existed =
                                        countChildIgnored && countChildIgnored.find((form) => form.id === item.id || form.id === item.parentId);
                                   if (existed) {
                                        item.count = existed.count || 0;
                                   }
                              });

                              // newFormIgnored = formIgnored.map((form) => {
                              //      for (let i = 0; i < formIgnoreEdit.length; i++) {
                              //           if (form.id === formIgnoreEdit[i].id) {
                              //                form.isRedirect = formIgnoreEdit[i].isRedirect;
                              //                form.name = formIgnoreEdit[i].name;
                              //                form.categoryId = formIgnoreEdit[i].categoryId;
                              //           }
                              //      }
                              //      return form;
                              // });

                              // newFormIgnored = newFormIgnored.filter(
                              //      (item) =>
                              //           !formIgnoreEdit.some(
                              //                (f) =>
                              //                     (f.id === item.id && f.hasChild && f.name && f.categoryId && f.count === 0) ||
                              //                     (f.id === item.id && !f.hasChild && !f.parentId && f.name && f.categoryId) ||
                              //                     (f.id === item.parentId && !f.hasChild && f.parentId && f.name && f.categoryId && f.count === 0),
                              //           ),
                              // );

                              newAllFormIgnored = allFormsIgnored.map((form) => {
                                   for (let i = 0; i < formIgnoreEdit.length; i++) {
                                        if (form.id === formIgnoreEdit[i].id) {
                                             form.name = formIgnoreEdit[i].name;
                                             form.categoryId = formIgnoreEdit[i].categoryId;
                                             form.isRedirect = formIgnoreEdit[i].isRedirect;
                                             form.issue = formIgnoreEdit[i].issue;
                                        }
                                   }
                                   return form;
                              });
                              newAllFormIgnored = newAllFormIgnored.filter(
                                   (item) =>
                                        !formIgnoreEdit.some(
                                             (f) =>
                                                  (f.id === item.id && f.hasChild && f.name && f.categoryId && f.count === 0) ||
                                                  (f.id === item.id && !f.hasChild && !f.parentId && f.name && f.categoryId) ||
                                                  (f.id === item.parentId && !f.hasChild && f.parentId && f.name && f.categoryId && f.count === 0)
                                        )
                              );

                              if (ignoredNumberPerpage === 0) {
                                   dispatch(setFormIgnored(newAllFormIgnored.slice(0, itemsPerPage)));
                              }
                              dispatch(setAllFormsIgnore(newAllFormIgnored));

                              // if (className === 'viewAllRequiringAction') {
                              //      if (newAllFormIgnored.length === 0) {
                              //           let newNumberPage = 0;

                              //           if (ignoredNumberPerpage > 0) {
                              //                newNumberPage = ignoredNumberPerpage - 1;
                              //           }
                              //           dispatch(handleFetchFormsIgnored(20, true, newNumberPage));
                              //      }
                              // }

                              dispatch(handleUpdateChildFormsIgnored());
                              // reset form requiring
                              dispatch(setFormRequiringAction(null));
                              dispatch(setAllFormsRequiring(null));
                              // reset form inventory
                              dispatch(setFormInventory(null));
                              dispatch(setAllFormsInventory(null));

                              dispatch(setFormIgnoreEdit(null));

                              dispatch(handleFetchCountFormsRequiring());
                              dispatch(handleFetchCountFormsIgnored(null, activeAccount));
                              dispatch(handleFetchCountFormsInventory(null, activeAccount));
                              //reset filters
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(null));

                              if (rulesDefault && rulesDefault.length === 0) {
                                   dispatch(setSubscriberState({ formCategoriesRules: null }));
                              }
                              // toast.success('Form updated!');
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         setUpdateModal(false);
                         setSubmitLoading(false);
                    });
          }

          //Update isPushEvents
          if (applyRedirect !== activeAccount.isPushEvents) {
               const dataPushEvents = {
                    accountId: activeAccount.id,
                    isPushEvents: applyRedirect,
               };

               callTokenApi(API_CLIENT_UPDATE_PUSH_EVENTS, 'PUT', dataPushEvents)
                    .then((response) => {
                         if (response.status === 200) {
                              const newActiveAccount = { ...activeAccount, isPushEvents: applyRedirect };
                              dispatch(setSubscriberState({ activeAccount: newActiveAccount }));
                         } else {
                              toastError(response);
                              setUpdateModal(false);
                              setSubmitLoading(false);
                         }
                    })
                    .finally(() => {
                         if (formIgnoreEdit && formIgnoreEdit.length === 0) {
                              setUpdateModal(false);
                              setSubmitLoading(false);
                         }
                    });
          }
     };

     const onClickShowAll = () => {
          setErrors([]);

          dispatch(
               setFlexibleModal({
                    show: true,
                    component: COMPONENT_NAME.FORM_REQUIRING_ACTION,
               })
          );
     };

     const clickOutsideTimestamps = useCallback(() => {
          setTooltipSettings(false);
     }, []);

     useOutsideHandling(statusRefSettings, clickOutsideTimestamps);

     const handleShowIgnoredForm = () => {
          dispatch(setShowIgnoredForm(!showIgnoredForm));
     };

     const handleApplyChild = () => {
          dispatch(setSubscriberState({ requiringSettingsLoading: true }));
          dispatch(setFormInherit(!applyChild));

          //Update isInheritParent
          if (!applyChild !== activeAccount.isInheritParent) {
               const dataInheritParent = {
                    accountId: activeAccount.id,
                    isInheritParent: !applyChild,
               };

               callTokenApi(API_CLIENT_UPDATE_INHERIT_PARENT, 'PUT', dataInheritParent)
                    .then((response) => {
                         if (response.status === 200) {
                              const newActiveAccount = { ...activeAccount, isInheritParent: !applyChild };
                              dispatch(setSubscriberState({ activeAccount: newActiveAccount }));

                              dispatch(handleApplyChildForms(!applyChild, 'ignored', setErrors));
                              // reset form inventory
                              dispatch(setFormInventory(null));
                              dispatch(setAllFormsInventory(null));
                              // fetch count
                              dispatch(handleFetchCountFormsRequiring());
                              dispatch(handleFetchCountFormsIgnored(null, activeAccount));
                              dispatch(handleFetchCountFormsInventory(null, activeAccount));
                              //reset filters
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(null));
                         } else {
                              toastError(response);
                              dispatch(setSubscriberState({ requiringSettingsLoading: false }));
                         }
                    })
                    .finally(() => {
                         dispatch(setSubscriberState({ requiringSettingsLoading: false }));
                    });
          }
     };

     const handleApplyRedirect = () => {
          dispatch(setSubscriberState({ requiringSettingsLoading: true }));
          dispatch(setFormRedirect(!applyRedirect));

          //Update isPushEvents
          if (!applyRedirect !== activeAccount.isPushEvents) {
               const dataPushEvents = {
                    accountId: activeAccount.id,
                    isPushEvents: !applyRedirect,
               };

               callTokenApi(API_CLIENT_UPDATE_PUSH_EVENTS, 'PUT', dataPushEvents)
                    .then((response) => {
                         if (response.status === 200) {
                              const newActiveAccount = { ...activeAccount, isPushEvents: !applyRedirect };
                              dispatch(setSubscriberState({ activeAccount: newActiveAccount }));

                              dispatch(handleApplyRedirectForms(!applyRedirect, 'ignored'));
                              // reset form inventory
                              dispatch(setFormInventory(null));
                              dispatch(setAllFormsInventory(null));
                              //reset filters
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(null));
                         } else {
                              toastError(response);
                              dispatch(setSubscriberState({ requiringSettingsLoading: false }));
                         }
                    })
                    .finally(() => {
                         dispatch(setSubscriberState({ requiringSettingsLoading: false }));
                    });
          }
     };

     // const handleClickBubbleSettings = () => {
     //      setTimeout(() => {
     //           if (!tooltipSettings) {
     //                setTooltipSettings(true);
     //           }
     //      }, 100);
     // };

     const tabOverlay = className === 'requiring-action' && treeFamilyOfForms.length >= itemsPerPage && countFormIgnored > itemsPerPage;

     return (
          <>
               <div className="title-form-action">
                    <p>You need to take action on these forms to make sure they are tracking correctly.</p>
                    <div className="form-action-settings">
                         <div
                              className="checkbox-header checkbox-ignored-form"
                              style={{ paddingRight: '30px', marginTop: '3px', marginBottom: '4px' }}
                         >
                              <CFormGroup variant="custom-checkbox" inline>
                                   <CInputCheckbox
                                        custom
                                        id={'ignoredForm'}
                                        name={'ignoredForm'}
                                        checked={!!showIgnoredForm}
                                        disabled={isLoadingSettings}
                                        onChange={handleShowIgnoredForm}
                                   />
                                   <CLabel variant="custom-checkbox" htmlFor={'ignoredForm'}>
                                        Show only ignored forms
                                   </CLabel>
                              </CFormGroup>
                         </div>

                         <div className="checkbox-header checkbox-settings">
                              {/* <CIcon icon='cil-settings' cursor='pointer' width={30} onClick={handleClickBubbleSettings} /> */}
                              {tooltipSettings && (
                                   <CCard className="block-tooltip reporting-values-tooltip" ref={statusRefSettings}>
                                        <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                        <div className="block-tooltip-text">
                                             <div className="checkbox-header checkbox-category">
                                                  <CFormGroup variant="custom-checkbox" inline>
                                                       <CInputCheckbox
                                                            custom
                                                            id={'requiring-action'}
                                                            name={'requiring-action'}
                                                            checked={!!applyChild}
                                                            disabled={isLoadingSettings}
                                                            onChange={() => handleApplyChild()}
                                                       />
                                                       <CLabel variant="custom-checkbox" htmlFor={'requiring-action'}>
                                                            Inherit from parent.
                                                       </CLabel>
                                                       <div className="tooltip-wrapper">
                                                            <ClickInfoIconTooltip>
                                                                 Changes you make in the interface are accumulated and must be published to go live on
                                                                 your website.
                                                            </ClickInfoIconTooltip>
                                                       </div>
                                                  </CFormGroup>
                                             </div>
                                             <div className="checkbox-header checkbox-redirect">
                                                  <CFormGroup variant="custom-checkbox" inline>
                                                       <CInputCheckbox
                                                            custom
                                                            id={'applyRedirect'}
                                                            name={'applyRedirect'}
                                                            checked={!!applyRedirect}
                                                            disabled={isLoadingSettings}
                                                            onChange={() => handleApplyRedirect()}
                                                       />
                                                       <CLabel variant="custom-checkbox" htmlFor={'applyRedirect'}>
                                                            Push events after redirect.
                                                       </CLabel>
                                                  </CFormGroup>
                                             </div>
                                        </div>
                                   </CCard>
                              )}
                         </div>
                    </div>
               </div>

               <div
                    className={`table-layout table-requiring-action ${tableClassName} ${+countFormIgnored === 0 || isLoading ? 'no-form-found' : ''}`}
               >
                    <div className="css-table">
                         {className === 'viewAllRequiringAction' && ignoredFormsLoading && (
                              <div className="table-forms-loading-overlay">
                                   <CenterSpinner />
                              </div>
                         )}
                         <div className="css-table-header">
                              <div className="listener-name">Listener Name</div>
                              <div className="form-id">Form ID</div>
                              <div className="form-location">Form Location</div>
                              <div>Status</div>
                              <div></div>
                              <div></div>
                              {/* <div></div> */}
                         </div>
                         <div
                              className={`css-table-body ${tabOverlay && !isLoading ? 'table-overlay' : ''} ${
                                   isLoadingSettings ? 'table-loading' : ''
                              }`}
                         >
                              {!isLoading ? (
                                   <>
                                        {treeFamilyOfForms.length > 0 ? (
                                             <>
                                                  {isLoadingSettings && (
                                                       <div className="loading-settings">
                                                            <CSpinner color="primary" />
                                                       </div>
                                                  )}

                                                  {treeFamilyOfForms.map((item, index) => {
                                                       const hasChild = item.hasChild;

                                                       return (
                                                            <React.Fragment key={`ignored-${item.id}`}>
                                                                 {hasChild ? (
                                                                      <MultiForms
                                                                           className={className}
                                                                           index={index}
                                                                           formsData={item}
                                                                           applyChild={applyChild}
                                                                           errors={errors}
                                                                           setErrors={setErrors}
                                                                           length={treeFamilyOfForms.length}
                                                                      />
                                                                 ) : (
                                                                      <FormRequiringTableRow
                                                                           className={className}
                                                                           data={item}
                                                                           redirect={true}
                                                                           applyChild={applyChild}
                                                                           index={index}
                                                                           requiring={true}
                                                                           errors={errors}
                                                                           setErrors={setErrors}
                                                                           length={treeFamilyOfForms.length}
                                                                      />
                                                                 )}
                                                            </React.Fragment>
                                                       );
                                                  })}
                                             </>
                                        ) : (
                                             <div className="table-body-row">
                                                  <p>No form found!</p>
                                             </div>
                                        )}
                                   </>
                              ) : (
                                   <CenterSpinner />
                              )}
                         </div>
                    </div>

                    {!isLoading && (
                         <>
                              {className === 'viewAllRequiringAction' && treeFamilyOfForms.length > 0 && ignoredShowPaging && (
                                   <div className="text-center forms-paging">
                                        <CButton
                                             className="btn-paging-forms btn-prev"
                                             disabled={isLoadingSettings || isLoading || ignoredFormsLoading || ignoredNumberPerpage < 1}
                                             onClick={() => onLoadMore(ignoredNumberPerpage - 1)}
                                        >
                                             {'< Prev'}
                                        </CButton>
                                        <CButton
                                             className="btn-paging-forms btn-next"
                                             disabled={isLoadingSettings || isLoading || ignoredFormsLoading || ignoredDisableNextPage}
                                             onClick={() => onLoadMore(ignoredNumberPerpage + 1)}
                                        >
                                             {'Next >'}
                                        </CButton>
                                   </div>
                              )}

                              {tabOverlay && (
                                   <CButton className="btn-show-all" onClick={onClickShowAll}>
                                        See all {ignoredCountLoading ? '...' : countFormIgnored} forms
                                        <CIcon className="icon-arrow-bar" icon="iconChevronRight" width={6} height={7} />
                                   </CButton>
                              )}
                              {treeFamilyOfForms.length > 0 && (
                                   <div className="save-changes-button-wrapper">
                                        <CButton color="primary" disabled={isLoadingSettings || submitLoading || formsEdit} onClick={checkValidate}>
                                             {submitLoading ? <span className="dots-waiting">Waiting</span> : 'Save Changes'}
                                        </CButton>
                                   </div>
                              )}
                         </>
                    )}
               </div>

               <ConfirmSaveChange
                    show={updateModal}
                    onClose={() => setUpdateModal(false)}
                    isLoading={submitLoading}
                    onAccept={() => onSubmit()}
                    title="Are you sure you want to save?"
               ></ConfirmSaveChange>
          </>
     );
};

export default FormIgnoredLayout;
