import React, { useEffect, useMemo, useState } from 'react';
import SelectTakeAction from './SelectTakeAction';
// import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { ClickInfoIconTooltip } from '../../../../../general/custom-tooltips';
import CopyToClipboard from 'react-copy-to-clipboard';
// import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import { getFromValueNameKey, toastError } from '../../../../../../utils';
import {
     ACCORDION_TYPES,
     API_CLIENT_IGNORED_FORM_CATEGORIES,
     API_CLIENT_SAVE_DRAFT_FORM_CATEGORIES,
     CLIENT_LISTENER_ENABLE,
     CLIENT_LISTENER_ENABLE_ALL_FEATURES,
     SPECIAL_FEATURE_LIST,
} from '../../../../../../constants';
import {
     handleFetchFormCategories,
     setConversionRules,
     setCustomFormTargetRules,
     setNewLayout,
     setShowBlockAccountPopup,
     setSubscriberState,
     setTriggerRules,
     setFormInventory,
     setFormIgnored,
     setFormRequiringAction,
     setFormInventoryEdit,
     handleFetchCountFormsRequiring,
     handleFetchCountFormsIgnored,
     handleFetchCountFormsInventory,
     setAllFormsInventory,
     setAllFormsIgnore,
     setAllFormsRequiring,
     setFormIgnoreEdit,
     handleSetAccordion,
     handleIgnoredFormChildInventory,
     handleUpdateRevealPeople,
     handleUpdateIsRedirectInventory,
     handleUpdateIsRedirectIgnored,
     setFormRequiringEdit,
     setFormsDraft,
     setFilterFormCategories,
     setFilterEditFormCategories,
     handleUpdateCustomCategories,
     handleFetchChildFormListSuggest,
     handleFetchChildFormRequiring
} from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import { getEnabledListeners, getListenerRoutes } from '../../../../../../helpers/cms/subscriber';
import TargetRulesModal from './target-rules/TargetRulesModal';
import TooltipTag from '../../../../../general/TooltipTag';
// import { toast } from 'react-toastify';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { useHistory } from 'react-router-dom';
import ModalCreateCategories from '../form-categories-wizard/ModalCreateCategories';
import { CInput, CInputCheckbox } from '../../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';
import CSwitch from '../../../../../migration/CSwitch';
import Waiting from '../../../../../general/Loadings/Waiting';

const FormInventoryTableRow = ({
     errors,
     setErrors,
     index,
     data,
     redirect,
     className,
     hasChild,
     toggleDetails,
     showAccordion,
     // parentValue,
     // parentName,
     length,
}) => {
     const history = useHistory();
     const dispatch = useDispatch();
     const inventoryNumberPerpage = useSelector((state) => state.subscriber.inventoryNumberPerpage);
     const formInventoryEdit = useSelector((state) => state.subscriber.formInventoryEdit);
     const formIgnoreEdit = useSelector((state) => state.subscriber.formIgnoreEdit);
     const listFormIgnored = useSelector((state) => state.subscriber.formIgnored);
     const allFormsIgnored = useSelector((state) => state.subscriber.allFormsIgnored);
     const formInventory = useSelector((state) => state.subscriber.formInventory);
     const allFormsInventory = useSelector((state) => state.subscriber.allFormsInventory);
     const showIgnoredInventoryForm = useSelector((state) => state.subscriber.showIgnoredInventoryForm);
     const listCustomCategories = useSelector((state) => state.subscriber.customCategories);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const newLayout = useSelector((state) => state.subscriber.newLayout);
     const accountRoutes = useSelector((state) => state.subscriber.routes);
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);
     // const listChildInventory = useSelector((state) => state.subscriber[data.id]);
     // const listChildIgnored = useSelector((state) => state.subscriber[`ignored${data.id}`]);
     const initialPersonalData = useSelector((state) => state.subscriber.initialPersonalData);
     const filterFormCategories = useSelector((state) => state.subscriber.filterFormCategories);
     const flexibleModal = useSelector((state) => state.theme.flexibleModal);
     const { showNewLayout } = newLayout;
     const CUSTOM_FORM_CODE = 'customForms';
     const { numberOfListeners, numberOfPremium } = activeAccount;
     const pathName = history.location.pathname;

     let specificFeatures = activeFeatures.filter((feature) => {
          let strToCompare = feature.code.toLowerCase();

          return SPECIAL_FEATURE_LIST.some((specialItem) => strToCompare.includes(specialItem));
     });

     const listenerFormsEnabled = useSelector((state) => state.subscriber.listenerFormsEnabled);
     const customFormListenerEnabled = listenerFormsEnabled.find((listener) => listener.code === CUSTOM_FORM_CODE);

     const [firstCopy, setFirstCopy] = useState(false);
     const [copyElement, setCopyElement] = useState(null);
     const [showIgnoreModal, setShowIgnoreModal] = useState(false);
     const [submitLoading, setSubmitLoading] = useState(false);
     const [popupEnableListener, setPopupEnableListener] = useState(false);
     const [showTargetFormModal, setShowTargetFormModal] = useState(false);
     const [showUpdateRulesModal, setShowUpdateRulesModal] = useState(false);
     const [showPopupCreateCategories, setShowPopupCreateCategories] = useState(false);
     const [isLoading, setLoading] = useState(false);
     const [rowMenuIsOpen, setRowMenuIsOpen] = useState(null);
     const itemsPerPage = 12;
     const [saveDraftLoading, setSaveDraftLoading] = useState(false);
     const formParentIgnore = showIgnoredInventoryForm && allFormsIgnored.some((form) => form.id === data.parentId && form.issue === 'isIgnored');

     const options = useMemo(() => {
          const newListCategories =
               listCustomCategories && listCustomCategories.length > 0
                    ? [{ id: null, name: 'Select a category', value: null }, ...listCustomCategories]
                    : [];
          const listOptions = newListCategories ? newListCategories.map((category) => ({ value: category.id, label: category.name })) : [];
        
          //add fake data vào list option
          if (data.proposeCategory && data.proposeName) {
               const newCategory = {
                    value: 'fake-' + data.proposeCategory,
                    label: data.proposeCategory,
               };
          
               const categoryExists = listOptions.find(
                    (category) => category.label === data.proposeCategory,
               );

               const checkFakeCategory = data.categoryId && data.categoryId.includes('fake');
               if (!categoryExists && checkFakeCategory && data.disable) {
                    // listOptions.splice(listOptions.length - 1,0,newCategory);
                    listOptions.push(newCategory);
               }

                  //update list option If duplicate categories were created before, it will update that category again
                  if (categoryExists && checkFakeCategory) {
                    data.categoryId = categoryExists.value;
               }
          }
     
          listOptions.push({
               value: 'createNewCategories',
               label: (
                    <CButton className="option-create-categories" color="link" onClick={() => setShowPopupCreateCategories(true)}>
                         <i className="fal fa-plus"></i>
                         Create new category
                    </CButton>
               ),
          });
          return listOptions;
     }, [listCustomCategories, data, data.disable]); // eslint-disable-line

     const errorGroup = errors && errors.find((item) => item.id === data.id);

     const findFormParent = (formData, parentId) => {
          const result = formData.find((item) => {
               return item.id === parentId;
          });
          return result;
     };

     const handleChangeInheritParent = (event) => {
          const inheritParent = event.target.value === 'on' ? false : true;
          const newForms = showIgnoredInventoryForm ? cloneDeep(allFormsIgnored) : cloneDeep(allFormsInventory);
          const listParentEdit = showIgnoredInventoryForm ? cloneDeep(formIgnoreEdit) : cloneDeep(formInventoryEdit);
          const formParent = findFormParent(newForms, data.parentId);
          const formParentEdit = listParentEdit && listParentEdit.find((item) => item.id === formParent.id);
          const newData = { ...data };

          //update suggested name and category
          let updateName = '';
          let updateCategoryId = null;
          if (newData.proposeName !== null){
               updateName = newData.proposeName;
          }

          const categoryExists = options.find((category) => category.label === newData.proposeCategory);

          if (newData.proposeCategory !== null){

               if (categoryExists) {
                    updateCategoryId = categoryExists.value;
               } else {
                    updateCategoryId = 'fake-' + newData.proposeCategory;
               }
          }

          if (!newData.disable && (newData.issue !== 'isDrafted' || newData.issue === '')){
               newData.disable = true;
          }

          if (showIgnoredInventoryForm){
               updateName = '';
               updateCategoryId = null;
          }

          let updateParentName = '';
          let updateParentCategoryId = null;

          if (!formParent.disable) {
               updateParentCategoryId = formParent.categoryId;
               updateParentName = formParent.name
          } 

          newData.inheritParent = inheritParent;

          if (formParentEdit) {
               newData.name = !inheritParent ? updateName : updateParentName;
               newData.categoryId = !inheritParent ? updateCategoryId : updateParentCategoryId;
               newData.isRedirect = !inheritParent ? false : formParentEdit.isRedirect;
               newData.revealPeople = formParentEdit.revealPeople;
               newData.isUnknown = formParentEdit.isUnknown
          } else {

               if (newData.exitSuggest && !inheritParent) {
                    updateName = '';
                    updateCategoryId = null;
                    newData.disable = false;
               }

               newData.name = !inheritParent ? updateName : updateParentName;
               newData.categoryId = !inheritParent ? updateCategoryId : updateParentCategoryId;
               newData.isRedirect = !inheritParent ? false : formParent.isRedirect;
               newData.revealPeople = formParent.revealPeople;
               //update suggestInherit
               newData.suggestInherit = !inheritParent;
               newData.isUnknown = formParent.isUnknown
          }

          if (showIgnoredInventoryForm) {
               dispatch(setFormIgnoreEdit(newData));
          } else {
               dispatch(setFormInventoryEdit(newData));
          }
          setErrors && setErrors(errors.filter((item) => item.id !== data.id));
     };

     const handleOnChangePushEvents = (value) => {
          const isRedirect = value === 'on' ? false : true;

          const dataRequest = {
               accountId: activeAccount.id,
               data: {
                    id: data.id,
                    hasChild: data.hasChild,
                    isRedirect: isRedirect,
               },
          };

          const parentId = data.parentId;
          const nextDispatch = {
               setLoading,
          };

          if (!showIgnoredInventoryForm) {
               dispatch(handleUpdateIsRedirectInventory(dataRequest, parentId, nextDispatch));
               dispatch(setFormIgnored(null));
          } else {
               dispatch(handleUpdateIsRedirectIgnored(dataRequest, parentId, nextDispatch));
               dispatch(setFormInventory(null));
               dispatch(setAllFormsInventory(null));
          }
          dispatch(setFormRequiringAction(null));
          dispatch(setAllFormsRequiring(null));
     };

     const handleOnChangeRevealPeople = (value) => {
          const revealPeople = value === 'on' ? false : true;

          const dataRequest = {
               accountId: activeAccount.id,
               data: {
                    id: data.id,
                    hasChild: data.hasChild,
                    revealPeople: revealPeople,
               },
          };
          const parentId = data.parentId;
          const nextDispatch = {
               setLoading,
          };
          dispatch(handleUpdateRevealPeople(dataRequest, parentId, nextDispatch));
     };

     const handleChangeFormCategory = (option) => {
          const newData = { ...data };

          if (newData.suggestInherit){
               newData.suggestInherit = false;
          }

          if (showIgnoredInventoryForm || className === 'inventory' || className === 'viewAllInventory') {
               newData.categoryId = option;
          } else {
               newData.categoryId = option;
               newData.issue = 'isDrafted';
          }

          if (showIgnoredInventoryForm) {
               if (newData.hasChild) {
                    formIgnoreEdit.forEach((form) => {
                         if (form.parentId && form.parentId === newData.id && form.inheritParent) {
                              form.categoryId = newData.categoryId;
                              if (form.suggestInherit){
                                   form.suggestInherit = false;
                                   console.log("onchange hasChild vào đây 11")
                              }    
                              dispatch(setFormIgnoreEdit(form));
                         }
                    });
               }
               dispatch(setFormIgnoreEdit(newData));
          } else {
               if (newData.hasChild) {
                    formInventoryEdit.forEach((form) => {
                         if (form.parentId && form.parentId === newData.id && form.inheritParent) {
                              form.categoryId = newData.categoryId;

                              if (form.suggestInherit){
                                   form.suggestInherit = false;
                              }
                              dispatch(setFormInventoryEdit(form));
                         }
                    });
               }
               dispatch(setFormInventoryEdit(newData));
          }

          if ((option && data.name) || (!option && !data.name)) {
               setErrors && setErrors(errors.filter((item) => item.id !== data.id));
          }
     };

     const handleChangeFormName = (e) => {
          const name = e.target.value.trim() === '' ? null : e.target.value;
          const newData = { ...data };

          if (showIgnoredInventoryForm || className === 'inventory' || className === 'viewAllInventory') {
               newData.name = name;
          } else {
               newData.name = name;
               newData.issue = 'isDrafted';
          }

          if (newData.suggestInherit){
               newData.suggestInherit = false;
          }   

          if (showIgnoredInventoryForm) {
               if (newData.hasChild) {
                    formIgnoreEdit.forEach((form) => {
                         if (form.parentId && form.parentId === newData.id && form.inheritParent) {
                              form.name = newData.name;
          
                              if (form.suggestInherit){
                                   form.suggestInherit = false;
                              }

                              dispatch(setFormIgnoreEdit(form));
                         }
                    });
               }
               dispatch(setFormIgnoreEdit(newData));
          } else {
               if (newData.hasChild) {
                    formInventoryEdit.forEach((form) => {
                         if (form.parentId && form.parentId === newData.id && form.inheritParent) {
                              form.name = newData.name;

                              if (form.suggestInherit){
                                   form.suggestInherit = false;
                              }
                              dispatch(setFormInventoryEdit(form));
                         }
                    });
               }
               dispatch(setFormInventoryEdit(newData));
          }

          if ((name && data.categoryId) || (!name && !data.categoryId)) {
               setErrors && setErrors(errors.filter((item) => item.id !== data.id));
          }
     };

     const onAcceptSuggest = async (data) => {
          setSaveDraftLoading(true);
          data.disable = !data.disable; //false;

          const newFormData = { ...data, issue: 'isDrafted', saveAccept: true, saveAcceptInventory: true };
          // if (newFormData.name === null) {
          //      newFormData.name = newFormData.proposeName;
          // }
          const newDataRequest = {
               accountId: activeAccount.id,
               data: [newFormData],
          };

          const functionSaveAccept = async (newDataRequest, newFormData) => {
               await callTokenApi(API_CLIENT_SAVE_DRAFT_FORM_CATEGORIES, 'PUT', newDataRequest)
                    .then((response) => {
                         if (response.status === 200) {
                              const dataResponse = response.data.forms[0];
                              dataResponse.disable = false;
                              dataResponse.suggestInherit = false;
                              
                              dispatch(setFormsDraft(dataResponse));
                              dispatch(setFormInventoryEdit(dataResponse));
                              dispatch(setFormRequiringAction(null));
                              dispatch(setAllFormsRequiring(null));
                              dispatch(setFormRequiringEdit(null));

                              // dispatch(handleFetchCountFormsInventory(null, activeAccount));
                              //reset filters
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(null));

                              if (allFormsIgnored && allFormsIgnored.length > 0) {
                                   const draftFormIgnored = allFormsIgnored.find((form) => form.id === newFormData.id);
                                   // reset form ignored
                                   if (draftFormIgnored) {
                                        dispatch(setFormIgnored(null));
                                        dispatch(setAllFormsIgnore(null));
                                   }
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         // setShowTooltip(false);
                         setSaveDraftLoading(false);
                    });
          };

          //tạo custom categories và name
          const checkOptionCategories = listCustomCategories.some((item) => item.name === data.proposeCategory && item.accountId === data.accountId);

          if (!checkOptionCategories) {
               let code = (Math.floor(Math.random() * 5555) + 1111).toString().substring(0, 4);
               const values = {
                    id: `categories${code}`,
                    name: data.proposeCategory,
                    flg_add: true,
                    isChecked: true,
                    accountId: activeAccount.id,
               };

               if (values.name) {
                    let customCategories = [values];

                    customCategories.map((item) => {
                         item.name = item.name.trim();
                         return item;
                    });

                    const nextDispatch = {
                         setFormRequiringEdit,
                         setFormInventoryEdit,
                         setFormIgnoreEdit,
                         functionSaveAccept: () => functionSaveAccept(newDataRequest, newFormData),
                    };
                    dispatch(handleUpdateCustomCategories(customCategories, nextDispatch));
               }
          } else {
               functionSaveAccept(newDataRequest, newFormData);
          }
     };

     const onReject = (data) =>{
          const newData = { ...data };
     
          if (newData.parentId){ //form has children
               const parentId = newData.parentId;
               dispatch(handleFetchChildFormListSuggest(parentId, newData ));
          } else {
               const updateListParentInventory = allFormsInventory.map((parent) => {
                    if (parent.id === newData.id){
                         parent.categoryId = null;
                         parent.name = null;
                         parent.disable = !parent.disable; //false
                    }
                    return parent;
               })
               dispatch(setAllFormsInventory(updateListParentInventory));
          }
     }

     const copyFirstScript = (element) => {
          setFirstCopy(true);
          setCopyElement(element);
     };

     useEffect(() => {
          if (firstCopy) {
               setTimeout(() => {
                    setFirstCopy(false);
                    setCopyElement(null);
               }, 2000);
          }
     }, [firstCopy]);

     const defaultValue = options.find((item) => item.value === data.categoryId);

     useEffect(() => {
          if (defaultValue && defaultValue.value === 'createNewCategories') {
               setShowPopupCreateCategories(true);
          }
     }, [defaultValue]);

     useEffect(() => {
          setShowPopupCreateCategories(false);
     }, [flexibleModal]);

     const listenerName = data.isUnknown ? (data.parentId ? '' : 'Unknown') : data.parentId ? '' : data.listenerName;

     const onClosePopup = () => {
          setPopupEnableListener(false);
     };

     const handleOpenListenerForms = () => {
          setPopupEnableListener(true);
     };

     const handleIgnoreForms = () => {
          setSubmitLoading(true);

          let formsCategoriesId = null;

          if (data.parentId) {
               formsCategoriesId = data.parentId;
          } else {
               formsCategoriesId = data.id;
          }

          const newForms = showIgnoredInventoryForm ? cloneDeep(listFormIgnored) : cloneDeep(formInventory);

          for (let i = 0; i < newForms.length; i++) {
               if (newForms[i].id === formsCategoriesId || newForms[i].parentId === formsCategoriesId) {
                    if (
                         (!newForms[i].hasChild && !newForms[i].categoryId && !newForms[i].name) ||
                         newForms[i].issue === 'isDrafted' ||
                         newForms[i].hasChild
                    ) {
                         newForms[i].issue = 'isIgnored';
                         newForms[i].inheritParent = false;
                         newForms[i].isRedirect = false;
                         newForms[i].categoryId = null;
                         newForms[i].name = null;
                    }
               }
          }

          const newAllForms = cloneDeep(allFormsInventory);
          const formUpdate = [];

          for (let i = 0; i < newAllForms.length; i++) {
               if (newAllForms[i].id === formsCategoriesId || newAllForms[i].parentId === formsCategoriesId) {
                    if (
                         (!newAllForms[i].hasChild && !newAllForms[i].categoryId && !newAllForms[i].name) ||
                         newAllForms[i].issue === 'isDrafted' ||
                         newAllForms[i].hasChild
                    ) {
                         newAllForms[i].issue = 'isIgnored';
                         newAllForms[i].inheritParent = false;
                         newAllForms[i].isRedirect = false;
                         newAllForms[i].categoryId = null;
                         newAllForms[i].name = null;

                         formUpdate.push(newAllForms[i]);
                    }
               }
          }

          const newDataRequest = {
               accountId: activeAccount.id,
               data: {
                    id: data.id,
                    parentId: data.parentId,
                    hasChild: data.hasChild,
               },
          };

          callTokenApi(API_CLIENT_IGNORED_FORM_CATEGORIES, 'PUT', newDataRequest)
               .then((response) => {
                    if (response.status === 200) {
                         const { numberFormChildInventory } = response.data;
                         if (data.hasChild) {
                              if (!showIgnoredInventoryForm) {
                                   // const newListForms = newForms.filter((item) => item.id !== formsCategoriesId);
                                   const newListAllForms = newAllForms.filter((item) => item.id !== formsCategoriesId);
                                   if (inventoryNumberPerpage === 0) {
                                        dispatch(setFormInventory(newListAllForms.slice(0, itemsPerPage)));
                                   }
                                   dispatch(setAllFormsInventory(newListAllForms));
                              }
                         } else {
                              dispatch(
                                   handleIgnoredFormChildInventory(formsCategoriesId, data.id, data.parentId ? true : false, numberFormChildInventory)
                              );
                              if(data.parentId) {
                                   const id = data.parentId
                                   const itemsPerPage = 12
                                   dispatch(handleFetchChildFormRequiring(id, itemsPerPage));
                              }
                         }
                         

                         // reset form requiring
                         dispatch(setFormRequiringAction(null));
                         dispatch(setAllFormsRequiring(null));
                         // reset form ingored
                         dispatch(setFormIgnored(null));
                         dispatch(setAllFormsIgnore(null));
                         // fetch count
                         dispatch(handleFetchCountFormsRequiring());
                         dispatch(handleFetchCountFormsIgnored());
                         dispatch(handleFetchCountFormsInventory(null, activeAccount, filterFormCategories));
                         // toast.success('Form Ignored!');
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setShowIgnoreModal(false);
                    setSubmitLoading(false);
               });
     };

     const disableListener = listenerFormsEnabled.every((listener) => listener.id !== data.listenerId);

     const activeAllFeatures = async ({ newActiveAccount, newAccountListeners, listenerId }) => {
          if (showNewLayout) {
               const data = {
                    accountId: activeAccount.id,
                    listenerId,
               };
               await callTokenApi(CLIENT_LISTENER_ENABLE_ALL_FEATURES, 'POST', data).then((response) => {
                    if (response.status === 200) {
                         const accountFeatureActive = response.data.accountFeatureActive;
                         if (accountFeatureActive) {
                              const enabledListeners = getEnabledListeners(newAccountListeners);
                              dispatch(
                                   setSubscriberState({
                                        activeAccount: newActiveAccount,
                                        accountListeners: newAccountListeners,
                                        activeListeners: enabledListeners,
                                        activeFeatures: accountFeatureActive,
                                   }),
                              );
                         }
                    } else {
                         toastError(response);
                    }
               });
          }
     };

     const handleShowEnableListener = (listenerId) => {
          setPopupEnableListener(true);
          let usedListeners = numberOfListeners;
          let usedPremium = numberOfPremium;

          const tempListener = accountListeners.find((listener) => listener.id === listenerId);
          let urlToCall = '';
          urlToCall = CLIENT_LISTENER_ENABLE;
          usedListeners = usedListeners + 1;
          usedPremium = tempListener.premium ? usedPremium + 1 : usedPremium;

          const data = {
               accountId: activeAccount.id,
               listenerId,
          };

          setSubmitLoading(true);

          callTokenApi(urlToCall, 'POST', data)
               .then(async (response) => {
                    if (response.status === 200) {
                         let newListener = {};
                         let listenerIndex = 0;

                         accountListeners.some((listener, index) => {
                              if (tempListener.id === listener.id) {
                                   listenerIndex = index;
                                   newListener = listener;
                                   newListener.configStatus = !newListener.configStatus;

                                   return true;
                              }

                              return false;
                         });

                         let newAccountListeners = [...accountListeners];
                         newAccountListeners[listenerIndex] = newListener;
                         let newActiveAccount = { ...activeAccount };
                         newActiveAccount.numberOfListeners = usedListeners;
                         let newSpecificFeatures = null;

                         newActiveAccount.numberOfPremium = usedPremium;

                         // Reset custom form target rules when enabling custom form listener
                         if (tempListener.code === 'customForms') {
                              dispatch(setCustomFormTargetRules(null));
                         }

                         await activeAllFeatures({ newActiveAccount, newAccountListeners, listenerId });
                         dispatch(setNewLayout({ activeSubNavItem: tempListener.type, showNavChild: true })); // Show Active Listeners dropdown when enable listener

                         // Wizard Form Categories
                         const newListenerFormsEnabled = [...listenerFormsEnabled];
                         newListenerFormsEnabled.push(newListener);

                         const enabledListeners = getEnabledListeners(newAccountListeners);
                         const listenerRoutes = getListenerRoutes(enabledListeners, newSpecificFeatures || specificFeatures, activeAccount.secondId);
                         const newAccountRoutes = [...accountRoutes].filter(
                              (route) => !listenerRoutes.some((listenerRoute) => listenerRoute.path === route.path),
                         );
                         const newRoutes = [...newAccountRoutes, ...listenerRoutes];

                         dispatch(
                              setSubscriberState({
                                   activeAccount: newActiveAccount,
                                   routes: newRoutes,
                                   accountListeners: newAccountListeners,
                                   activeListeners: enabledListeners,
                                   eventOptions: {
                                        listenerId: '',
                                        events: [],
                                   },
                              }),
                         );

                         if (newListener.configStatus === true) {
                              // High light listener nav in sidebar when turn on
                              dispatch(
                                   setSubscriberState({
                                        activeListener: newListener,
                                        elementJustActivated: {
                                             id: newListener.id,
                                             type: newListener.type,
                                        },
                                   }),
                              );
                              setTimeout(() => {
                                   dispatch(
                                        setSubscriberState({
                                             elementJustActivated: null,
                                        }),
                                   );
                              }, 500);
                         }

                         dispatch(setTriggerRules([]));
                         dispatch(setConversionRules([]));
                         dispatch(handleFetchFormCategories());

                         // toast.success(`Listener turning on!`);
                         onClosePopup();
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => {
                    setSubmitLoading(false);
               });
     };

     const handleTargetForm = () => {
          setShowTargetFormModal(true);
     };

     const handleClickLinkNoOptions = () => {
          dispatch(handleSetAccordion({ accordion: ACCORDION_TYPES.FORM_CATEGORIES, pathName }));
     };

     // const checkNewChildForm = (child) => {
     //      const findFormParentCategorized =
     //           allFormsInventory &&
     //           allFormsInventory.find((form) => {
     //                return form.id === child.parentId && form.hasChild && form.issue !== 'isDrafted' && form.issue !== 'isIgnored' && form.categoryId;
     //           });

     //      if (findFormParentCategorized && child.issue !== 'isDrafted' && child.issue !== 'isIgnored' && child.inheritParent && !child.categoryId) {
     //           return true;
     //      }

     //      return false;
     // };

     useEffect(() => {
          const checkbox = document.querySelectorAll('.inherit-category .custom-checkbox .custom-control-input');
          checkbox.forEach((item) => {
               if (isLoading) {
                    item.setAttribute('disabled', true);
                    item.classList.add('inherit-disable');
               } else {
                    item.removeAttribute('disabled');
                    item.classList.remove('inherit-disable');
               }
          });
     }, [isLoading]);

     const handleCancelCreateCategory = () => {
          setShowPopupCreateCategories(false);
          if (!showIgnoredInventoryForm) {
               const dataEdit = formInventoryEdit.find((item) => item.categoryId === 'createNewCategories');
               const oldForm = allFormsInventory.find((form) => form.id === dataEdit.id);
               if (typeof oldForm !== 'undefined') {
                    dispatch(setFormInventoryEdit({ ...dataEdit, categoryId: oldForm.categoryId }));
               } else {
                    dispatch(setFormInventoryEdit({ ...dataEdit, categoryId: null }));
               }
          } else {
               const dataEdit = formIgnoreEdit.find((item) => item.categoryId === 'createNewCategories');
               const oldForm = allFormsIgnored.find((form) => form.id === dataEdit.id);
               if (typeof oldForm !== 'undefined') {
                    dispatch(setFormIgnoreEdit({ ...dataEdit, categoryId: oldForm.categoryId }));
               } else {
                    dispatch(setFormIgnoreEdit({ ...dataEdit, categoryId: null }));
               }
          }
     };

     return (
          <>
               <div className={`css-table-row ${(index + 1) % 2 === 0 ? 'even' : 'odd'} ${index >= +length - 3 ? 'table-last-row' : ''} ${rowMenuIsOpen === index ? 'menu-is-open' : ''}`}>
                    {/* Listener Name */}
                    <div className="listener-name table-body-row">
                         {hasChild && !data.hiddenButton && (
                              <CButton
                                   color="light"
                                   size="sm"
                                   onClick={() => toggleDetails(data.id)}
                                   className={`accordion-btn ${showAccordion ? ' show' : ''}`}
                              ></CButton>
                         )}
                         {hasChild ? (
                              <span className="listener-name-parent" onClick={() => toggleDetails(data.id)}>
                                   {listenerName}
                              </span>
                         ) : (
                              <span>{listenerName}</span>
                         )}
                    </div>

                    {/* Form ID */}
                    <div className="form-id table-body-row">
                         {/* <span>{data.formValue}</span> */}
                         {!hasChild ? (
                              <div className="tooltip-wrapper">
                                   <ClickInfoIconTooltip showIcon={false} text={data.formValue}>
                                        <div className="tooltip-copy">
                                             <div>Unique Identifiers</div>
                                        </div>
                                        <div className="tooltip-copy">
                                             <div className="tooltip-label">
                                                  {listenerName.includes('ActiveCampaign') ? 'class' : getFromValueNameKey(data)}: {data.formValue}{' '}
                                             </div>
                                             <CopyToClipboard text={data.formValue} onCopy={() => copyFirstScript('id')}>
                                                  <div className="icon-copy">{firstCopy && copyElement === 'id' ? 'Copied' : 'Copy'}</div>
                                             </CopyToClipboard>{' '}
                                        </div>
                                        <div className="tooltip-copy">
                                             <div className="tooltip-label">
                                                  Slug:{' '}
                                                  <a href={data.formLocation} target="_blank" rel="noopener noreferrer">{`${data.formLocation}`}</a>
                                             </div>
                                             <a className="tooltip-slug" href={data.formLocation} target="_blank" rel="noopener noreferrer">
                                                  <img
                                                       src="/assets/icons/icon-arrow-up-right.svg"
                                                       alt="icon-arrow-up-right"
                                                       className="icon-arrow-up-right"
                                                  />
                                             </a>
                                             {/* <CopyToClipboard text={data.formLocation} onCopy={() => copyFirstScript('slug')}>
                                        <div className='icon-copy'>{firstCopy && copyElement === 'slug' ? 'Copied' : 'Copy'}</div>
                                    </CopyToClipboard>{' '} */}
                                        </div>
                                   </ClickInfoIconTooltip>
                              </div>
                         ) : (
                              <div className="tooltip-wrapper">
                                   <span style={{ cursor: 'auto' }}>{data.formValue}</span>
                              </div>
                         )}
                    </div>

                    {/* Form Location */}
                    <div
                         className={`form-location table-body-row ${
                              !showIgnoredInventoryForm && initialPersonalData && initialPersonalData.status ? 'form-location-people' : ''
                         }`}
                    >
                         {!hasChild && (
                              <div className="tooltip-wrapper">
                                   <ClickInfoIconTooltip>
                                        <div className="tooltip-copy">
                                             <div className="tooltip-label">
                                                  Slug:{' '}
                                                  <a href={data.formLocation} target="_blank" rel="noopener noreferrer">{`${data.formLocation}`}</a>
                                             </div>
                                             <CopyToClipboard text={data.formLocation} onCopy={() => copyFirstScript('slug')}>
                                                  <div className="icon-copy">{firstCopy && copyElement === 'slug' ? 'Copied' : 'Copy'}</div>
                                             </CopyToClipboard>
                                        </div>
                                   </ClickInfoIconTooltip>
                              </div>
                         )}
                    </div>

                    {/* Category */}
                    {data.isUnknown ? (
                         <div className="take-action table-body-row">
                              {!customFormListenerEnabled ? (
                                   <CButton className="custom-forms-listener" color="link" onClick={handleOpenListenerForms}>
                                        Enable Custom Forms Listener
                                   </CButton>
                              ) : (
                                   <CButton className="custom-forms-listener" color="link" onClick={handleTargetForm}>
                                        Use Custom Forms Listener
                                   </CButton>
                              )}
                         </div>
                    ) : (
                         <div className={`take-action table-body-row ${data.disable && data.proposeCategory && !data.inheritParent ? 'disable' :''}`}>
                              {disableListener ? (
                                   <CButton className="custom-forms-listener" color="link" onClick={handleOpenListenerForms}>
                                        Enable {data.listenerName} Listener
                                   </CButton>
                              ) : (
                                   <>
                                        <SelectTakeAction
                                             className={`${
                                                  data.inheritParent && options.some((category) => category.value !== 'createNewCategories')
                                                       ? 'inherit-parent'
                                                       : ''
                                             } ${errorGroup && errorGroup.invalidCategory ? 'is-invalid' : ''}`}
                                             data={data}
                                             options={options}
                                             handleChangeTakeAction={handleChangeFormCategory}
                                             // disabled={data.inheritParent && parentValue && parentName}
                                             disabled={data.disable && data.proposeCategory && !showIgnoredInventoryForm  }
                                             defaultValue={defaultValue || null}
                                             handleClickLinkNoOptions={handleClickLinkNoOptions}
                                             onMenuOpen={() => {
                                                  if (options && options.some((option) => option.value !== 'createNewCategories')) {
                                                       setRowMenuIsOpen(index);
                                                  }
                                             }}
                                             onMenuClose={() => setRowMenuIsOpen(null)}
                                        />

                                        {/* {data.inheritParent && parentValue && parentName && (
                                    <div className='block-tooltip'>
                                        <CIcon icon='arrow-preview' className='arrow-preview'></CIcon>
                                        <div className='block-tooltip-text'>
                                            <p>"Inherit from parent" is enabled.</p>
                                        </div>
                                    </div>
                                )} */}
                                        <CInvalidFeedback>Form Category cannot be empty!</CInvalidFeedback>
                                   </>
                              )}
                         </div>
                    )}

                    {/* Form Name */}
                    {data.isUnknown ? (
                         <div className="form-name table-body-row">
                              {!showIgnoredInventoryForm && (
                                   <CButton
                                        className="custom-forms-listener"
                                        color="link"
                                        onClick={() => {
                                             setShowIgnoreModal(true);
                                        }}
                                   >
                                        Ignore Form
                                   </CButton>
                              )}
                         </div>
                    ) : (
                         <div className={`form-name table-body-row ${data.inheritParent ? 'inherit-parent' : ''}`}>
                              {!disableListener ? (
                                   <>
                                        <CInput
                                             id={`formName-${className}-${data.id}`}
                                             name={`formName-${className}-${data.id}`}
                                             type="text"
                                             disabled={data.inheritParent || (data.disable && data.proposeName && !showIgnoredInventoryForm  )}
                                             value={data.name || ''}
                                             onChange={(e) => handleChangeFormName(e)}
                                             invalid={errorGroup && errorGroup.invalidName}
                                             autoComplete="off"
                                             data-lpignore="true"
                                             placeholder="Enter a form name"
                                        />
                                        <CInvalidFeedback>Form Name cannot be empty!</CInvalidFeedback>
                                   </>
                              ) : (
                                   !showIgnoredInventoryForm &&
                                   !data.inheritParent && (
                                        <CButton
                                             className="custom-forms-listener"
                                             color="link"
                                             onClick={() => {
                                                  setShowIgnoreModal(true);
                                             }}
                                        >
                                             Ignore Form
                                        </CButton>
                                   )
                              )}
                         </div>
                    )}

                    {/* Change Draft */}
                    <div className={`change-drafted table-body-row`}>
                         {data.issue === 'isDrafted' && <TooltipTag name={'Draft'} description={'Changes drafted'} type={'info'} />}
                         {/* {data.parentId && checkNewChildForm(data) && <TooltipTag name={'New'} description={'New child form'} type={'info'} />} */}
                    </div>
                    <div className='btn-suggest'>
                         {!disableListener && !showIgnoredInventoryForm && data.proposeCategory && data.proposeName && data.disable && (data.issue !== 'isDrafted' && data.issue !== 'isCategorized') && (!data.parentId || (data.parentId && !data.inheritParent)) && !data.isUnknown && (
                              <div className="save-changes-button-wrapper">
                                   {/* <CButton color="primary" onClick={() => onAcceptSuggest(data)} > <Waiting isLoading={saveDraftLoading}>Accept</Waiting></CButton> */}
                                   <CButton className="mr-3" color="primary"onClick={() => onAcceptSuggest(data)} >
                                        <Waiting isLoading={saveDraftLoading}>Accept</Waiting>
                                   </CButton>
                                   <CButton color="primary" onClick={() => onReject(data)}>Reject</CButton>
                              </div>
                         )}
                    </div> 

                    {/* Inherit Category */}

                    <div className={`inherit-category-inventory table-body-row`}>
                         {data.parentId && !hasChild && !data.isUnknown && (
                              <>
                                   <CFormGroup variant="custom-checkbox" inline>
                                        <CInputCheckbox
                                             custom
                                             id={`${className}-${data.id}`}
                                             name={`${className}-${data.id}`}
                                             value={data.inheritParent ? 'on' : 'off'}
                                             checked={!!data.inheritParent}
                                             // disabled={!parentValue || !parentName ? true : false}
                                             onChange={(e) => handleChangeInheritParent(e)}
                                             disabled={(!formParentIgnore && data.issue === 'isIgnored') || isLoading}
                                             // disabled={applyChild && data.parentId && !hasChild}
                                        />
                                        <CLabel variant="custom-checkbox" htmlFor={`${className}-${data.id}`}>
                                             Inherit from parent
                                        </CLabel>
                                   </CFormGroup>
                                   {/* {(!parentValue || !parentName) && (
                                <div className='block-tooltip'>
                                    <CIcon icon='arrow-preview' className='arrow-preview'></CIcon>
                                    <div className='block-tooltip-text'>
                                        <p>Categorize and enter a name for the parent form first.</p>
                                    </div>
                                </div>
                            )} */}
                              </>
                         )}
                    </div>

                    {/* Reveal People */}
                    {!showIgnoredInventoryForm && initialPersonalData && initialPersonalData.status && (
                         <div className="reveal-people  table-body-row">
                              {!data.isUnknown && !disableListener && (
                                   <CSwitch
                                        className="mr-3 d-inline-block align-middle"
                                        color={data.revealPeople ? 'success' : 'light'}
                                        checked={!!data.revealPeople}
                                        disabled={data.inheritParent || isLoading}
                                        value={data.revealPeople ? 'on' : 'off'}
                                        size="sm"
                                        tabIndex="0"
                                        shape="pill"
                                        onChange={(e) => handleOnChangeRevealPeople(e.target.value)}
                                   />
                              )}
                         </div>
                    )}

                    {/* Redirects */}
                    {redirect && (
                         <div className="redirects-status table-body-row">
                              {!data.isUnknown && !disableListener && (
                                   <CSwitch
                                        className="mr-3 d-inline-block align-middle"
                                        color={data.isRedirect ? 'success' : 'light'}
                                        checked={!!data.isRedirect}
                                        disabled={data.inheritParent || isLoading}
                                        value={data.isRedirect ? 'on' : 'off'}
                                        size="sm"
                                        tabIndex="0"
                                        shape="pill"
                                        onChange={(e) => handleOnChangePushEvents(e.target.value)}
                                   />
                              )}
                         </div>
                    )}
               </div>
               {popupEnableListener && (
                    <ConfirmSaveChange
                         show={popupEnableListener}
                         onAccept={() => handleShowEnableListener(data.listenerId)}
                         onClose={onClosePopup}
                         isIcon={false}
                         btnTextSave="Continue"
                         btnTextCancel="Back"
                         title={`Enable ${data.isUnknown ? 'Custom HTML Forms' : listenerName} Listener`}
                         isLoading={submitLoading}
                    >
                         {data.isUnknown ? (
                              <p>
                                   You are about to enable the Custom Forms Listener. This will allow you to track forms that are not targeted by
                                   other Listeners. You will be required to publish your ListenLayer account before the Listener is live.
                              </p>
                         ) : (
                              <p>
                                   You are about to enable the {listenerName} Listener. This will allow you to name and categorize your forms from{' '}
                                   {listenerName}. You will be required to publish your ListenLayer account before the Listener is live.
                              </p>
                         )}
                    </ConfirmSaveChange>
               )}
               {showIgnoreModal && (
                    <ConfirmSaveChange
                         show={showIgnoreModal}
                         onAccept={handleIgnoreForms}
                         onClose={() => {
                              setShowIgnoreModal(false);
                         }}
                         isLoading={submitLoading}
                         btnTextSave="Continue"
                         btnTextCancel="Back"
                         title="Ignore This Form"
                         isIcon={false}
                    >
                         <p>
                              The form will not be categorized, named, or tracked. It will be hidden from this table but you can still manage and
                              change its settings in the future by selecting the "<strong>Show only ignored forms</strong>" option.
                         </p>
                         {/* <p>
                        You are choosing to ignore a {data.hasChild ? 'parent' : 'child'} form. This will apply to the{' '}
                        {data.hasChild ? 'child' : 'parent'} form and all other forms.
                    </p> */}
                    </ConfirmSaveChange>
               )}

               {showTargetFormModal && (
                    <ConfirmSaveChange
                         show={showTargetFormModal}
                         onAccept={() => {
                              setShowUpdateRulesModal(true);
                              setShowTargetFormModal(false);
                         }}
                         onClose={() => {
                              setShowTargetFormModal(false);
                         }}
                         isLoading={submitLoading}
                         btnTextSave="Continue"
                         btnTextCancel="Back"
                         title="Update Targeting Rules"
                         isIcon={false}
                         dataForm={data.formValue}
                    >
                         {data.formValue ? (
                              <p>
                                   We'll add a rule to your targeting rules to include this form. After you complete this action, you'll be able to
                                   categorize and name this form.
                              </p>
                         ) : (
                              <p>You must add a form ID to the code of this form to track it.</p>
                         )}
                    </ConfirmSaveChange>
               )}

               {showUpdateRulesModal && (
                    <TargetRulesModal
                         show={showUpdateRulesModal}
                         onBack={() => {
                              setShowUpdateRulesModal(false);
                         }}
                         data={data}
                    />
               )}

               {showPopupCreateCategories && (
                    <ModalCreateCategories
                         show={showPopupCreateCategories}
                         onCancel={handleCancelCreateCategory}
                         setFormInventoryEdit={setFormInventoryEdit}
                         setFormIgnoreEdit={setFormIgnoreEdit}
                    />
               )}
          </>
     );
};

export default FormInventoryTableRow;
