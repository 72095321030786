import {
    CButton, CCard,
    CCardBody, CCardHeader, CCol, CRow
} from '@coreui/react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { setRuleHaveEditting, setViewedRule } from '../../../../../../actions/common';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_RULE_UPDATE, NATIVE_BROWSER_CONDITIONS, NO_OPTION_MESSAGE_TYPE, TYPE_SHOW_UNSAVE_CHANGE, VARIABLE_OPERATORS } from '../../../../../../constants';
import { useEventAndVariableOptionsV2 } from '../../../../../../helpers/customHooks';
import { deepCopyArray, toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { NoOptionsMessage } from '../../../../../general/rules';
import SelectInsertVariable from '../../SelectInsertVariable';
import { RuleSettingContext } from '../EditLookupSetting';
import SelectField from './SelectField';
import CLabel from '../../../../../migration/CLabel';

const validationSchema = () => {
    const isArrayEmpty = (array) => {
        return (!array || array.length === 0);
    }

    return (
        Yup.object().shape({
            events: Yup.array()
                .when(['variables', 'eventsNative', 'selectors'], {
                    is: (variables, eventsNative, selectors) =>
                        isArrayEmpty(variables) && isArrayEmpty(eventsNative) && isArrayEmpty(selectors),
                    then: Yup.array()
                        .min(1, "Please choose at least one option of these fields!")
                        .required("Please choose at least one option of these fields!")
                })
                .nullable(true),

            eventsNative: Yup.array()
                .when(['events', 'variables', 'selectors'], {
                    is: (events, variables, selectors) =>
                        isArrayEmpty(events) && isArrayEmpty(variables) && isArrayEmpty(selectors),
                    then: Yup.array()
                        .min(1, "Please choose at least one option of these fields!")
                        .required("Please choose at least one option of these fields!")
                })
                .nullable(true),

            variables: Yup.array()
                .when(['events', 'eventsNative', 'selectors'], {
                    is: (events, eventsNative, selectors) =>
                        isArrayEmpty(events) && isArrayEmpty(eventsNative) && isArrayEmpty(selectors),
                    then: Yup.array()
                        .min(1, "Please choose at least one option of these fields!")
                        .required("Please choose at least one option of these fields!")
                })
                .nullable(true),

            selectors: Yup.array()
                .when(['events', 'eventsNative', 'variables'], {
                    is: (events, eventsNative, variables) =>
                        isArrayEmpty(events) && isArrayEmpty(eventsNative) && isArrayEmpty(variables),
                    then: Yup.array()
                        .min(1, "Please choose at least one option of these fields!")
                        .required("Please choose at least one option of these fields!")
                })
                .nullable(true),
        },
            [
                ['events', 'eventsNative'], ['events', 'variables'], ['events', 'selectors'],
                ['eventsNative', 'variables'], ['eventsNative', 'selectors'],
                ['variables', 'selectors']
            ]
        )
    )
}

const EditStep3 = ({ stepsData, setStepsData }) => {
    const dispatch = useDispatch();
    const { activeStep, setActiveStep, activeListener } = useContext(RuleSettingContext);
    const [updateModal, setUpdateModal] = useState(false);
    const { conditions } = stepsData;
    const [newConditions, setNewConditions] = useState([]);
    const [stepSaveLoading, setStepSaveLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const {
        fetchLoading,
        isSelectorFeatureOpen,
        variables,
        events,
        eventsNative,
        nativeBrowserStorage,
        reactSelectSelectorValueOptions
    } = useEventAndVariableOptionsV2({ isFetchData: activeStep === 3, activeListener });
    const eventTypes = ["Event", "MyEventNative", "NativeBrowserStorage"];
    const initialGroup = {
        type: !activeListener.isNative ? 'Event' : 'MyEventNative',
        id: '',
        key: '',
        operator: '',
        value: '',
        isRegex: false
    };

    const handleHasChange = () => {
        dispatch(setRuleHaveEditting({ showLv2: true, typeLv2: TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP }));
    };

    const getEventOptions = () => {
        var eventData = [];
        if (events && events.length > 0) {
            eventData = events.map(item => {
                return {
                    label: item.friendlyName || item.code,
                    value: item.code,
                    friendlyName: item.friendlyName,
                    id: item.id
                }
            });
        }

        return eventData;
    }

    const getEventNativeOptions = () => {
        var eventData = [];
        if (eventsNative && eventsNative.length > 0) {
            eventData = eventsNative.map(item => {
                return {
                    label: item,
                    value: item,
                    friendlyName: item,
                    id: item
                }
            });
        }

        return eventData;
    }

    const getNativeBrowserStorageOptions = () => {
        var eventData = [];

        function capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        }

        if (nativeBrowserStorage && nativeBrowserStorage.length > 0) {
            eventData = nativeBrowserStorage.map(item => {
                return {
                    label: `${capitalizeFirstLetter(item.type)}: ${item.name}, Format: ${capitalizeFirstLetter(item.format)}`,
                    value: JSON.stringify(item),
                    friendlyName: JSON.stringify(item),
                    id: item.nativeId
                }
            });
        }

        return eventData;
    }

    const getVariableOption = (item) => {
        return {
            label: item.friendlyName || item.fullName,
            value: item.fullName,
            friendlyName: item.friendlyName,
            id: item.id
        }
    }

    const getVariableOptions = (variables) => {
        let variableData = [];
        if (variables && variables.length > 0) {
            variables.forEach(variable => {
                if (variable.status && variable.childs) {
                    variableData.push(...getVariableOptions(variable.childs));
                }
                else {
                    variableData.push(getVariableOption(variable));
                }
            })
        }
        return variableData;
    }

    const getConjunctions = (type, id, key) => {
        let operator = eventTypes.includes(type) ? "eq" : VARIABLE_OPERATORS[0].VALUE;

        if (type === eventTypes[2] && key.includes(`"format":"date function"`)) {
            operator = NATIVE_BROWSER_CONDITIONS.DATE_FUNCTION[0].VALUE; // Value of the first option
        }

        let newGroup = {
            conjunction: 'and',
            'g0': {
                ...initialGroup,
                operator,
                id, type, key
            }
        }
        return newGroup;
    }

    const getInitialConditions = () => {
        let eventList = [], variableList = [];
        let selectorList = [];
        let eventOptions = getEventOptions();
        let variableOptions = getVariableOptions(variables);
        let initialValues = {
            events: [],
            eventsNative: [],
            variables: [],
            selectors: []
        };
        if (conditions && conditions.length > 0) {
            conditions[0].forEach(item => {
                if (eventTypes.includes(item.g0.type)) {
                    eventList.push(item.g0.id);
                } else if (item.g0.type === 'Selector') {
                    selectorList.push(item.g0.id);
                } else {
                    variableList.push(item.g0.id);
                }
            })

            const getLastChosenOptions = (arr, list, key) => {
                arr.forEach(item => {
                    let index = list.indexOf(item.id);
                    if (index !== -1) {
                        initialValues[key][index] = { ...item }
                    }
                });
            }
            getLastChosenOptions(eventOptions, eventList, 'events');
            if (activeListener.isNative) {
                if (activeListener.code === 'nativeBrowserStorage') {
                    let nativeBrowserStorageOptions = getNativeBrowserStorageOptions();
                    getLastChosenOptions(nativeBrowserStorageOptions, eventList, 'eventsNative');
                } else {
                    let eventNativeOptions = getEventNativeOptions();
                    getLastChosenOptions(eventNativeOptions, eventList, 'eventsNative');
                }
            }
            getLastChosenOptions(variableOptions, variableList, 'variables');
            getLastChosenOptions(reactSelectSelectorValueOptions, selectorList, 'selectors');
        }

        return initialValues;
    }

    const initialValues = getInitialConditions();

    const onSubmit = (values) => {
        let { events, eventsNative, variables, selectors } = values;
        let conditions = [];
        let condition = [];

        if (events) {
            events.forEach(event => {
                condition.push(getConjunctions('Event', event.id, event.value));
            })
        }
        if (eventsNative) {
            if (activeListener.code === 'nativeBrowserStorage') {
                eventsNative.forEach(event => {
                    condition.push(getConjunctions('NativeBrowserStorage', event.id, event.value));
                })
            } else {
                eventsNative.forEach(event => {
                    condition.push(getConjunctions('MyEventNative', event.id, event.value));
                })
            }
        }
        if (variables) {
            variables.forEach(variable => {
                condition.push(getConjunctions('Variable', variable.id, variable.value));
            })
        }
        if (selectors) {
            selectors.forEach(selector => {
                selector = JSON.parse(selector.value);
                condition.push(getConjunctions('Selector', selector.id, selector.key));
            })
        }

        conditions.push(condition);
        setNewConditions(conditions);

        if (compareTwoConditions(stepsData.conditions[0], conditions[0])) {
            // setSaveLoading(false);
            setActiveStep(4);
        } else {
            setUpdateModal(true);
            setStepSaveLoading(true);
        }
    }

    const handleAcceptPopup = () => {
        let conditions = [...newConditions];
        let newStepsDataConditions = [...stepsData.conditions];
        let newList = [], newStepsData = {};
        newList = newStepsDataConditions.map((stepCondition) => {
            let newCons = [];
            newCons = conditions[0].map(condition => {
                let findIndex = checkInArray(condition.g0.id, stepCondition);
                if (findIndex !== -1) {
                    condition = stepCondition[findIndex];
                }
                return condition;
            });
            return deepCopyArray(newCons);
        });

        newList.forEach(condition => {
            condition.forEach(item => {
                if (item.conjunction === "") {
                    item.conjunction = "and"
                }
            });
            condition[condition.length - 1].conjunction = '';
        });

        newStepsData = { ...stepsData, conditions: newList };

        callTokenApi(API_CLIENT_RULE_UPDATE, 'POST', newStepsData)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Successfully update rule');
                    dispatch(setViewedRule(null, true));
                    dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                } else {
                    toastError(response);
                }
            })
            .finally(() => {
                setSaveLoading(false);
                setStepSaveLoading(false);
                setStepsData(newStepsData);
                setUpdateModal(false);
                setActiveStep(4);
            })
    }

    const compareTwoConditions = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let index in arr1) {
            if (arr1[index].g0.id !== arr2[index].g0.id)
                return false;
        }
        return true;
    }

    const checkInArray = (value, array) => {
        let index = -1;
        array.some((item, itemIndex) => {
            if (item.g0.id.indexOf(value) !== -1) {
                index = itemIndex;
                return true;
            }
            return false;
        });
        return index;
    }

    return (
        <>
            <CCard className={`cvr-step-3 ${activeStep !== 3 ? 'difference-step' : 'cvr-step-card'}`}>
                <CCardHeader>
                    {activeStep !== 3 ? (
                        <div className="rule-step d-inline-flex justify-content-between align-items-center w-100">
                            <h5 className="mb-0 inactive">Step 3: Select Condition Fields</h5>
                            {
                                (stepsData.conditions.length > 0) &&
                                <CButton className="btn-edit" onClick={() => setActiveStep(3)}>
                                    Edit
                                </CButton>
                            }
                        </div>
                    ) : (
                        <h5>Step 3: Select Condition Fields</h5>
                    )}
                </CCardHeader>
                {
                    activeStep === 3 && (
                        <>
                            <CCardBody>
                                <CRow>
                                    <CCol md="9">
                                        {
                                            fetchLoading ? (
                                                <CenterSpinner />
                                            ) : (
                                                <Formik
                                                    initialValues={initialValues}
                                                    validationSchema={validationSchema}
                                                    onSubmit={onSubmit}
                                                    validateOnChange={false}
                                                    validateOnBlur={false}
                                                    enableReinitialize
                                                >
                                                    {
                                                        ({
                                                            values,
                                                            errors,
                                                            touched,
                                                            setFieldValue,
                                                            setFieldTouched,
                                                            handleSubmit,
                                                            setErrors,
                                                        }) => (
                                                            <Form onSubmit={handleSubmit} className="rule-step">
                                                                <p>
                                                                    Select the data layer events or variables you would
                                                                    like to use as conditions to define other data layer values.
                                                                </p>
                                                                <div className="events-multiselect mb-3">
                                                                    <SelectField
                                                                        id="events"
                                                                        name="events"
                                                                        label={"Events"}
                                                                        tooltip={true}
                                                                        placeholder="Select Events"
                                                                        options={getEventOptions()}
                                                                        value={values.events}
                                                                        // invalid={!!errors.events}
                                                                        isMulti={true}
                                                                        onChange={setFieldValue}
                                                                        hasChange={handleHasChange}
                                                                        onBlur={setFieldTouched}
                                                                        touched={touched.events}
                                                                        error={errors.events}
                                                                        isClearable={true}
                                                                        backspaceRemovesValue={true}
                                                                        disabled={stepSaveLoading}
                                                                        // noOptionsMessage={() => <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.EVENT} listener={activeListener} options={getEventOptions()} />}
                                                                        setErrors={setErrors}
                                                                    />
                                                                </div>
                                                                {
                                                                    (activeListener.isNative && activeListener.code === 'nativeDataLayers') && (
                                                                        <div className="events-multiselect mb-3">
                                                                            <SelectField
                                                                                id="eventsNative"
                                                                                name="eventsNative"
                                                                                label="Native Event"
                                                                                tooltip={true}
                                                                                placeholder="Select Native Event"
                                                                                options={getEventNativeOptions()}
                                                                                value={values.eventsNative}
                                                                                // invalid={!!errors.events}
                                                                                isMulti={true}
                                                                                isNative={true}
                                                                                onChange={setFieldValue}
                                                                                hasChange={handleHasChange}
                                                                                onBlur={setFieldTouched}
                                                                                touched={touched.events}
                                                                                error={errors.events}
                                                                                isClearable={true}
                                                                                backspaceRemovesValue={true}
                                                                                disabled={stepSaveLoading}
                                                                                noOptionsMessage={() => <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.NATIVE_EVENT} options={getEventNativeOptions()} />}
                                                                                setErrors={setErrors}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    (activeListener.isNative && activeListener.code === 'nativeBrowserStorage') && (
                                                                        <div className="events-multiselect mb-3">
                                                                            <SelectField
                                                                                id="eventsNative"
                                                                                name="eventsNative"
                                                                                label="Native Browser Storage"
                                                                                tooltip={true}
                                                                                placeholder="Select Native Browser Storage"
                                                                                options={getNativeBrowserStorageOptions()}
                                                                                value={values.eventsNative}
                                                                                // invalid={!!errors.events}
                                                                                isMulti={true}
                                                                                isNative={true}
                                                                                onChange={setFieldValue}
                                                                                hasChange={handleHasChange}
                                                                                onBlur={setFieldTouched}
                                                                                touched={touched.events}
                                                                                error={errors.events}
                                                                                isClearable={true}
                                                                                backspaceRemovesValue={true}
                                                                                disabled={stepSaveLoading}
                                                                                noOptionsMessage={() => <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.NATIVE_BROWSER_STORAGE} options={getNativeBrowserStorageOptions()} />}
                                                                                setErrors={setErrors}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                <div className="variables-multiselect mb-3">
                                                                    <CLabel>Variables</CLabel>
                                                                    <SelectInsertVariable
                                                                        name="variables"
                                                                        placeholder="Select Variables"
                                                                        options={getVariableOptions(variables)}
                                                                        value={values.variables}
                                                                        onChange={setFieldValue}
                                                                        hasChange={handleHasChange}
                                                                        isMulti={true}
                                                                        selectInputType={false}
                                                                        error={errors.variables}
                                                                    />
                                                                </div>
                                                                {
                                                                    isSelectorFeatureOpen && (
                                                                        <div className="events-multiselect mb-3">
                                                                            <SelectField
                                                                                id="selectors"
                                                                                name="selectors"
                                                                                label="Selectors"
                                                                                tooltip={true}
                                                                                placeholder="Select Selectors"
                                                                                options={reactSelectSelectorValueOptions}
                                                                                value={values.selectors}
                                                                                // invalid={!!errors.events}
                                                                                isMulti={true}
                                                                                isNative={true}
                                                                                onChange={setFieldValue}
                                                                                hasChange={handleHasChange}
                                                                                onBlur={setFieldTouched}
                                                                                touched={touched.events}
                                                                                error={errors.events}
                                                                                isClearable={true}
                                                                                backspaceRemovesValue={true}
                                                                                disabled={stepSaveLoading}
                                                                                noOptionsMessage={() => <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.NATIVE_EVENT} options={getEventNativeOptions()} />}
                                                                                setErrors={setErrors}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                <div className="text-left">
                                                                    <CButton
                                                                        className="px-4"
                                                                        color="primary"
                                                                        type="submit"
                                                                        disabled={stepSaveLoading}
                                                                    >
                                                                        {`SAVE AND NEXT`}
                                                                    </CButton>
                                                                </div>
                                                            </Form>
                                                        )
                                                    }
                                                </Formik>
                                            )
                                        }
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </>
                    )
                }
            </CCard>
            <ConfirmSaveChange
                show={updateModal}
                onClose={() => {
                    setUpdateModal(false);
                    setStepSaveLoading(false);
                }}
                onAccept={handleAcceptPopup}
                title="Warning! This Will Change Your Rule"
                isLoading={saveLoading}
            >
                You are changing Conditions. This will make some changes to this rule.
                You will need to review your lookup table settings and adjust them as needed.
            </ConfirmSaveChange>
        </>
    )
}

EditStep3.propTypes = {
    stepsData: PropTypes.object,
    setStepsData: PropTypes.func
}
export default EditStep3
