import {
     TOGGLE_THEME,
     SET_ASIDE_SHOW,
     SET_SIDEBAR_SHOW,
     SET_USER,
     SET_OVERLAY,
     SET_USER_ROLE,
     SET_FLEXIBLE_MODAL,
     SET_VIEWED_RULES,
     SET_UNSAVED_CHANGES_RULE,
     SET_RULE_HAVE_EDITTING,
     NEED_FETCH_RULE,
     SET_FLEXIBLE_MULTI_MODAL,
} from './types';

export const toggleTheme = () => {
     return {
          type: TOGGLE_THEME
     }
}

export const setAsideShow = (asideShow) => {
     return {
          type: SET_ASIDE_SHOW,
          payload: asideShow
     }
}

export const setSidebarShow = (sidebarShow) => {
     return {
          type: SET_SIDEBAR_SHOW,
          payload: sidebarShow
     }
}

export const setOverlay = (overlay) => {
     return {
          type: SET_OVERLAY,
          payload: overlay
     }
}

export const setUser = (user) => {
     return {
          type: SET_USER,
          payload: user
     }
}

export const setUserRole = (userRole) => {
     return {
          type: SET_USER_ROLE,
          payload: userRole
     }
}

export const setFlexibleModal = (flexibleModal) => {
     return {
          type: SET_FLEXIBLE_MODAL,
          payload: flexibleModal
     }
}

export const setFlexibleMultiModal = (flexibleMultiModal) => {
     return {
          type: SET_FLEXIBLE_MULTI_MODAL,
          payload: flexibleMultiModal
     }
}

export const setViewedRule = (rule, editting) => {
     return {
          type: SET_VIEWED_RULES,
          payload: rule,
          editting: editting
     }
};

export const setUnsavedChanges = (bool) => {
     return {
          type: SET_UNSAVED_CHANGES_RULE,
          payload: bool
     }
};

export const setRuleHaveEditting = (edittingRule) => {
     return {
          type: SET_RULE_HAVE_EDITTING,
          payload: edittingRule
     }
};

export const setFetchRule = () => {
     return {
          type: NEED_FETCH_RULE
     }
};
