import React, { useCallback, useRef, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { useOutsideHandling } from '../../../../../utils';
import PropTypes from 'prop-types';

const SegmentTooltip = ({ buttonLink, iconView, onClickEdit, icon }) => {
     const [showPopup, setShowPopup] = useState(false);

     const handleClickBubble = () => {
          setTimeout(() => {
               if (!showPopup) {
                    setShowPopup(true);
               }
          }, 100);
     };

     const clickOutside = useCallback(() => {
          setShowPopup(false);
     }, []);

     const wrapperRef = useRef(null);
     useOutsideHandling(wrapperRef, clickOutside);

     return (
          <div className="menu-icon-container">
               <div className="menu-icon" onClick={handleClickBubble}>
                    <CIcon icon={icon} style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
               </div>
               {showPopup && (
                    <div className="popup">
                         <div className="popup-item" ref={wrapperRef}>
                              <div className="d-flex align-items-cent" onClick={onClickEdit}>
                                   <div className="icon-view mr-2">
                                        <CIcon icon={iconView} style={{ cursor: 'pointer', width: '16px', height: '15px' }}></CIcon>
                                   </div>
                                   <span className="option" onClick={onClickEdit}>
                                        {buttonLink}
                                   </span>
                              </div>
                         </div>
                    </div>
               )}
          </div>
     );
};

SegmentTooltip.propTypes = {
     icon: PropTypes.string,
     buttonLink: PropTypes.string,
     iconView: PropTypes.string,
     onClickEdit: PropTypes.func,
};
export default SegmentTooltip;
