import { CCard, CCardBody } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { SwitchPopup } from '../../../../general/popup';
import { callTokenApi } from '../../../../../apiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { API_CLIENT_EXTERNAL_DATA, API_CLIENT_EXTERNAL_UPDATE_DATA } from '../../../../../constants';
import { toastError } from '../../../../../utils';
import { setAccountExternalDataSources } from '../../../../../actions/subscriber';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import CSwitch from '../../../../migration/CSwitch';

const ManageExternalItem = () => {
     const [isLoading, setIsLoading] = useState(false);
     const [tempItem, setTempItem] = useState();
     const [turningOnPopup, setTurningOnPopup] = useState(false);
     const [turningOffPopup, setTurningOffPopup] = useState(false);
     const [status, setStatus] = useState();
     const dispatch = useDispatch();

     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { id: activeAccountId } = activeAccount;

     const sources = useSelector((state) => (state.subscriber.accountExternalData ? state.subscriber.accountExternalData.sources : null));

     useEffect(() => {
          if (!sources && activeAccountId) {
               setIsLoading(true);
               callTokenApi(`${API_CLIENT_EXTERNAL_DATA}/${activeAccountId}`, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(setAccountExternalDataSources(response.data.externalData));
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => setIsLoading(false));
          }
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const acceptSwitching = () => {
          setIsLoading(true);
          let data = {
               accountId: activeAccountId,
               externalId: tempItem.id,
               status: status === 'on' ? false : true,
          };
          callTokenApi(`${API_CLIENT_EXTERNAL_UPDATE_DATA}`, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         const indexSourceUpdate = sources.findIndex((source) => source.id === tempItem.id);
                         const newSources = [...sources];
                         newSources[indexSourceUpdate].accountExternalData = response.data.externalAccountData;
                         dispatch(setAccountExternalDataSources(newSources));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setTurningOffPopup(false);
                    setTurningOnPopup(false);
                    setIsLoading(false);
               });
     };

     const toggleItem = (item, status) => {
          setTempItem(item);
          setStatus(status);

          if (status === 'on') {
               setTurningOffPopup(true);
          } else {
               setTurningOnPopup(true);
          }
     };

     return (
          <div className="library-wrapper">
               <div className="listener-items row-items">
                    {!sources ? (
                         <CenterSpinner />
                    ) : (
                         sources.map((source) => {
                              if (source.code === 'fromFile') {
                                   source.isComingSoon = true;
                              }
                              const { name, description, isComingSoon, icon, accountExternalData } = source;
                              const configStatus = accountExternalData && accountExternalData.status;
                              return (
                                   <div className="listener-item" key={source.name}>
                                        <CCard>
                                             <CCardBody>
                                                  <div className="card-title d-flex justify-content-between w-100">
                                                       <div className="d-flex">
                                                            <img src={icon} alt="Icon" className="icon-20 mr-2" />
                                                            <h4>{name}</h4>
                                                       </div>
                                                  </div>

                                                  <div className="card-text h-100">{description && <p className="f-14">{description}</p>}</div>
                                                  <div className={`card-icon d-flex justify-content-end`}>
                                                       <div className="card-header-actions">
                                                            {isComingSoon ? (
                                                                 <span>Coming soon</span>
                                                            ) : (
                                                                 <CSwitch
                                                                      className="float-right mb-0"
                                                                      color={configStatus ? 'success' : 'light'}
                                                                      checked={!!configStatus} // !!false = false, !!undefined = false, !!true = true
                                                                      value={configStatus ? 'on' : 'off'}
                                                                      size="lg"
                                                                      tabIndex="0"
                                                                      shape="pill"
                                                                      onChange={(e) => toggleItem(source, e.target.value)}
                                                                      disabled={isLoading}
                                                                 />
                                                            )}
                                                       </div>
                                                  </div>
                                             </CCardBody>
                                        </CCard>
                                   </div>
                              );
                         })
                    )}
                    <SwitchPopup
                         show={turningOnPopup}
                         isLoading={isLoading}
                         onAccept={() => acceptSwitching(tempItem.id)}
                         onCancel={() => setTurningOnPopup(false)}
                    >
                         <h2>Enable External Data</h2>
                         <div className="confirm-save-content">
                              You're about to enable this external data. You will still need to publish your changes before this goes live.
                         </div>
                    </SwitchPopup>
                    <SwitchPopup
                         show={turningOffPopup}
                         onAccept={() => acceptSwitching(tempItem.id)}
                         onCancel={() => setTurningOffPopup(false)}
                         isLoading={isLoading}
                    >
                         <h2>Disable External Data</h2>
                         <div className="confirm-save-content">
                              Once a external data is disabled, all settings and rules for it will be lost and cannot be restored. Are you sure you
                              want to make this change?
                         </div>
                    </SwitchPopup>
               </div>
          </div>
     );
};

export default ManageExternalItem;
