import React, { useContext, useState } from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { toast } from 'react-toastify';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { SCHEDULE_DEMO } from '../../../../../constants';

const ScheduleDemo = () => {
     const { setCurrentStep, account } = useContext(CreateAccountContext);
     const [isLoading, setIsLoading] = useState(true)

     useCalendlyEventListener({
          onEventTypeViewed: () => setIsLoading(false),
          onEventScheduled: (e) => {
               if (e.data.event) {
                    localStorage.setItem(SCHEDULE_DEMO, true);
                    setCurrentStep(STEPS.scheduleDemoThankYou);
                    toast.success("Schedule a Demo Successful");
               }
          },
     });

     const onBackBtnClicked = () => {
          if (account.completedSteps && account.completedSteps.length > 3) {
               setCurrentStep(STEPS.accountSetup);
          }
          else {
               setCurrentStep(STEPS.seeHowItWorks, CHANGE_STEP_TYPE.subStepNext);
          }
     }

     return (
          <CreateAccountBody onBackBtnClicked={onBackBtnClicked} hideStepProgress={true}>
               <div>
                    <CCard className='account-see-how-it-work'>
                         <CCardBody>
                              <h3>Schedule a Demo</h3>
                              <p className='des'>Grab a time below for an initial 15-minute discovery call. Then we'll <br /> schedule a custom demo  specifically for you.</p>
                              {isLoading && <CenterSpinner />}
                              <InlineWidget url='https://calendly.com/nduy12310/schedule' styles={{
                                   height: '800px'
                              }} />
                         </CCardBody>
                    </CCard>
               </div>
          </CreateAccountBody>
     );
};

export default ScheduleDemo;
