import React, { useContext, useState } from 'react';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CRow } from '@coreui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SalesforceDetailDetailContext } from '../Popup/SalesforceConnectAccount';
import { callSalesforceApi } from '../../../../../../../../apiCaller';
import { API_SALESFORCE_CONNECTION, COMPONENT_NAME, SF_CONNECTION_STATUS } from '../../../../../../../../constants';
// import { toastError } from '../../../../../../../../utils';
import { toast } from 'react-toastify';
import { fetchSalesforceRulesRequest, setSalesforceAccounts } from '../../../../../../../../actions/subscriber';
import { useDispatch, useSelector } from 'react-redux';
import { setRuleHaveEditting } from '../../../../../../../../actions/common';
import { CInput, CTextarea } from '../../../../../../../migration/CInput';
import CLabel from '../../../../../../../migration/CLabel';
import CFormGroup from '../../../../../../../migration/CFormGroup';
import { CInvalidFeedback } from '../../../../../../../migration/CInvalidFeedback';

const validationSchema = Yup.object().shape({
     name: Yup.string().trim().required('Name is required').max(200, 'Name cannot be more than 200 characters'),
     // .notOneOf(connectionNames, 'Existed name, please choose another name'),
});

const NameAndDescriptionSalesforce = ({ idEdit }) => {
     const dispatch = useDispatch();
     const { accountId } = useSelector((state) => state.subscriber.activeAccount);
     const { activeStep, setActiveStep, stepsData, setStepsData, initStepsData, setInitStepsData } = useContext(SalesforceDetailDetailContext);
     const listAccountConnected = useSelector((state) =>
          state.subscriber.accountExternalData ? state.subscriber.accountExternalData.salesforceAccounts : []
     );

     const [isLoading, setIsLoading] = useState(false);
     const initialValues = {
          name: initStepsData.name || '',
          description: initStepsData.description || '',
     };

     const onSubmit = (value) => {
          setIsLoading(true);

          // name, description no change.
          if (value.name === stepsData.name && value.description === stepsData.description) {
               setActiveStep(3);
               setIsLoading(false);
               return;
          }
          callSalesforceApi(`${API_SALESFORCE_CONNECTION}/${stepsData.id}`, 'PUT', value)
               .then((response) => {
                    if (response && response.status === 200) {
                         setStepsData({ ...stepsData, ...value });
                         setInitStepsData({ ...stepsData, ...value });

                         const newListAccount = listAccountConnected.map((item) =>
                              item.id === stepsData.id ? { ...item, ...value } : item
                         );
                         dispatch(setSalesforceAccounts(newListAccount));
                         dispatch(fetchSalesforceRulesRequest(accountId, false));
                         toast.success('Update Connection Success.');
                         setActiveStep(3);
                    } else {
                         toast.error('Update Connect Salesforce fail!');
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };

     const checkStepPassed = () => {
          let passed = true;
          const { name, status } = stepsData;

          if (!name || status === SF_CONNECTION_STATUS.DRAFT) {
               passed = activeStep > 1 || !!initStepsData.name
          }

          return passed;
     };

     return (
          <CCard className={activeStep === 2 ? 'show' : 'hide'}>
               <CCardHeader>
                    {activeStep !== 2 ? (
                         <div className="rule-step d-inline-flex justify-content-between w-100">
                              <h5 className="mb-0 inactive">Step 2: Name & Description</h5>
                              {checkStepPassed() && (
                                   <div className="d-flex">
                                        <div className="pr-3">
                                             <span>Name: {initialValues.name}</span>
                                        </div>
                                        <div className="d-inline-flex align-items-center">
                                             <CButton className="btn-edit" onClick={() => setActiveStep(2)}>
                                                  Edit
                                             </CButton>
                                        </div>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5 className="mb-0">Step 2: Name & Description</h5>
                    )}
               </CCardHeader>
               {activeStep === 2 && (
                    <CCardBody>
                         <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnBlur={false}>
                              {({ values, errors, handleChange, handleSubmit }) => {
                                   if (idEdit) {
                                        if (values.name === initStepsData.name && values.description === initStepsData.description) {
                                             dispatch(setRuleHaveEditting({ show: false, type: COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS }));
                                        } else {
                                             dispatch(setRuleHaveEditting({ show: true, type: COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS }));
                                        }
                                   }

                                   return (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CRow>
                                                  <CCol lg="6">
                                                       <CFormGroup>
                                                            <CLabel htmlFor="name">Name this Connection so you can identify it easily.</CLabel>
                                                            <CInput
                                                                 id="name"
                                                                 placeholder="Connection name"
                                                                 invalid={!!errors.name}
                                                                 // disabled={saveLoading}
                                                                 value={values.name}
                                                                 onChange={handleChange}
                                                                 data-lpignore="true"
                                                            />
                                                            <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                       </CFormGroup>
                                                  </CCol>
                                                  <CCol lg="12">
                                                       <CFormGroup>
                                                            <CLabel htmlFor="description">Describe this connection</CLabel>
                                                            <CTextarea
                                                                 id="description"
                                                                 placeholder="Enter a description for your own reference..."
                                                                 // disabled={saveLoading}
                                                                 value={values.description}
                                                                 onChange={handleChange}
                                                            />
                                                       </CFormGroup>
                                                  </CCol>
                                             </CRow>
                                             <CButton type="submit" className="px-4 btn-next-step" color="primary" disabled={isLoading}>
                                                  {isLoading ? <span className="dots-waiting">Waiting</span> : idEdit ? 'Save And Next' : 'NEXT STEP'}
                                             </CButton>
                                        </CForm>
                                   );
                              }}
                         </Formik>
                    </CCardBody>
               )}
          </CCard>
     );
};

export default NameAndDescriptionSalesforce;
