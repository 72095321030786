import { callTokenApi } from '../apiCaller';
import {
     API_CLIENT_COUNT_FORM_INVENTORY,
     API_CLIENT_FETCH_CHILD_FORM_IGNORED,
     API_CLIENT_FETCH_CHILD_FORM_INVENTORY,
     API_CLIENT_FETCH_FORM_INVENTORY_LISTENER,
     API_CLIENT_FORM_IGNORED,
     API_CLIENT_UPDATE_IS_REDIRECT,
     API_CLIENT_UPDATE_REVEAL_PEOPLE,
     API_VERSION_WAITING,
     CLIENT_LISTENER,
     LISTENER_TYPE,
} from '../constants';
import { toastError } from '../utils';
import {
     ADMIN_INCREMENT_NUMBER_HISTORIES,
     ADMIN_SET_PUSHER_CHANNEL_NAME,
     ADMIN_SET_PUBLISHED_ACCOUNTS,
     ADMIN_SET_PUBLISHING_ACCOUNTS,
     ADMIN_SET_ACCOUNT,
     SET_SUBSCRIBER_STATE,
     SET_FORM_INVENTORY,
     FORM_CATEGORY_SET_ALL_FORMS_INVENTORY,
     SET_COUNT_FORM_INVENTORY,
     FORM_CATEGORY_SET_ALL_FORMS_IGNORE,
     SET_FORM_IGNORED,
     SET_INITIAL_PERSONAL_DATA,
     SET_FORM_REQUIRING_ACTION,
     FORM_CATEGORY_SET_FORM_EDIT,
     FORM_REQUIRING_SET_ALL_FORMS,
     FORM_IGNORE_SET_FORM_EDIT,
     SET_LISTENER_FORMS_ENABLED,
     SET_LOADINGS,
     SET_ACTIVE_LISTENERS,
     SET_SHOW_IGNORED_INVENTORY_FORM,
     FORM_CATEGORY_SET_LIST_FORM_CHILDS,
     FORM_CATEGORY_SET_FILTERS,
     SET_VERSION_PUBLISH,
     SET_VERSIONS,
     SET_NUMBER_HISTORIES,
     SET_SHOW_PUBLISH_POPUP,
} from './types';

export const adminSetIncrementHistories = () => {
     return {
          type: ADMIN_INCREMENT_NUMBER_HISTORIES,
     };
};

export const setPusherChannelName = (channelName) => {
     return {
          type: ADMIN_SET_PUSHER_CHANNEL_NAME,
          payload: channelName,
     };
};

export const setPublishedAccounts = (accounts) => {
     return {
          type: ADMIN_SET_PUBLISHED_ACCOUNTS,
          payload: accounts,
     };
};

export const setPublishingAccounts = (accounts) => {
     return {
          type: ADMIN_SET_PUBLISHING_ACCOUNTS,
          payload: accounts,
     };
};

export const setAccountAdmin = (account) => {
     return {
          type: ADMIN_SET_ACCOUNT,
          payload: account,
     };
};

export const setSubscriberState = (state) => {
     return {
          type: SET_SUBSCRIBER_STATE,
          payload: state,
     };
};

export const setFormInventory = (formInventory) => {
     return {
          type: SET_FORM_INVENTORY,
          payload: formInventory,
     };
};

export const setAllFormsInventory = (forms) => {
     return {
          type: FORM_CATEGORY_SET_ALL_FORMS_INVENTORY,
          payload: forms,
     };
};

export const setAllFormsRequiring = (forms) => {
     return {
          type: FORM_REQUIRING_SET_ALL_FORMS,
          payload: forms,
     };
};

export const setFormIgnoreEdit = (forms) => {
     return {
          type: FORM_IGNORE_SET_FORM_EDIT,
          payload: forms,
     };
};

export const setCountFormInventory = (countFormInventory) => {
     return {
          type: SET_COUNT_FORM_INVENTORY,
          payload: countFormInventory,
     };
};

export const setAllFormsIgnore = (forms) => {
     return {
          type: FORM_CATEGORY_SET_ALL_FORMS_IGNORE,
          payload: forms,
     };
};

export const setFormIgnored = (formIgnored) => {
     return {
          type: SET_FORM_IGNORED,
          payload: formIgnored,
     };
};

export const setInitialPersonalData = (personalData) => {
     return {
          type: SET_INITIAL_PERSONAL_DATA,
          payload: personalData,
     };
};

export const setFormRequiringAction = (forms) => {
     return {
          type: SET_FORM_REQUIRING_ACTION,
          payload: forms,
     };
};

export const setFormInventoryEdit = (forms) => {
     return {
          type: FORM_CATEGORY_SET_FORM_EDIT,
          payload: forms,
     };
};

export const setListenerFormsEnabled = (listeners) => {
     return {
          type: SET_LISTENER_FORMS_ENABLED,
          payload: listeners,
     };
};

export const setLoadings = (loadings) => {
     return {
          type: SET_LOADINGS,
          payload: loadings,
     };
};

export const setActiveListeners = (listeners) => {
     return {
          type: SET_ACTIVE_LISTENERS,
          payload: listeners,
     };
};

export const setShowIgnoredInventoryForm = (showIgnoredInventoryForm) => {
     return {
          type: SET_SHOW_IGNORED_INVENTORY_FORM,
          payload: showIgnoredInventoryForm,
     };
};

export const setFilterFormCategories = (filters) => {
     return {
          type: FORM_CATEGORY_SET_FILTERS,
          payload: filters,
     };
};

export const setListFormsChild = (forms) => {
     return {
          type: FORM_CATEGORY_SET_LIST_FORM_CHILDS,
          payload: forms,
     };
};

export const setVersionPublish = (options) => {
     return {
          type: SET_VERSION_PUBLISH,
          payload: options,
     };
};

export const setVersions = (options) => {
     return {
          type: SET_VERSIONS,
          payload: options,
     };
};

export const setShowPublishPopup = (show) => {
     return {
          type: SET_SHOW_PUBLISH_POPUP,
          payload: show,
     };
};

export const setNumberOfHistories = (options) => {
     return {
          type: SET_NUMBER_HISTORIES,
          payload: options,
     };
};

export const handleSetListenerFormsEnabled = () => (dispatch, getState) => {
     const activeListeners = getState().admin.activeListeners;
     const listenerFormEnabled = activeListeners.filter((listener) => listener.configStatus && listener.type === LISTENER_TYPE.FORMS);
     dispatch(setListenerFormsEnabled(listenerFormEnabled));
};

export const handleFetchCountFormsInventory =
     (nextDispatch, activeAccount, filters = []) =>
     (dispatch, getState) => {
          let newActiveAccount = activeAccount;
          let filterQuery = '';

          if (!activeAccount) {
               newActiveAccount = getState().admin.accountAdmin;
          }
          const { id: accountId } = newActiveAccount;

          if (nextDispatch && nextDispatch.setIsLoading) {
               nextDispatch.setIsLoading(true);
          }
          dispatch(setSubscriberState({ inventoryCountLoading: true }));

          filters.forEach((filter) => {
               if (filter.type) {
                    const filterValue = filter.value.trim() ? filter.value : '';
                    filterQuery = `${filterQuery}&filter.${filter.type}.${filter.operator}=${filterValue}`;
               }
          });

          if (accountId && accountId !== '') {
               callTokenApi(`${API_CLIENT_COUNT_FORM_INVENTORY.replace(':accountId', accountId)}?${filterQuery}`, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              const countFormInventory = response.data.countForms;
                              dispatch(setCountFormInventory(countFormInventory));
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         if (nextDispatch && nextDispatch.setIsLoading) {
                              nextDispatch.setIsLoading(false);
                         }
                         dispatch(setSubscriberState({ inventoryCountLoading: false }));
                    });
          }
     };

export const handleFetchFormsIgnored =
     (limit, next, numberPage = 0) =>
     (dispatch, getState) => {
          const activeAccount = getState().admin.accountAdmin;

          const newStateSet = {};
          let firstLoad,
               numberOffset = 0;

          if (next) {
               firstLoad = { ignoredFormsLoading: true };
               numberOffset = numberPage * 20;
          } else {
               firstLoad = { ignoredLoading: true };
          }

          dispatch(setSubscriberState(firstLoad));

          callTokenApi(`${API_CLIENT_FORM_IGNORED.replace(':accountId', activeAccount.id)}?limit=${limit}&offset=${numberOffset}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const listForms = response.data.formAccount;

                         // next = click from view all
                         if (next) {
                              if (listForms && listForms.length > 0) {
                                   const newAllForms = [...listForms].splice(0, limit);
                                   dispatch(setAllFormsIgnore(newAllForms));
                              } else {
                                   newStateSet.ignoredDisableNextPage = true;

                                   if (numberPage === 1) {
                                        dispatch(setSubscriberState({ ignoredShowPaging: false }));
                                   }
                              }
                         } else {
                              // first load
                              const newForms = [...listForms].splice(0, 12);
                              const newAllForms = [...listForms].splice(0, limit);

                              dispatch(setFormIgnored(newForms));
                              dispatch(setAllFormsIgnore(newAllForms));

                              if (listForms.length <= limit) {
                                   dispatch(setSubscriberState({ ignoredShowPaging: false }));
                              } else {
                                   dispatch(setSubscriberState({ ignoredShowPaging: true }));
                              }
                         }

                         if (listForms && listForms.length > 0) {
                              newStateSet.ignoredNumberPerpage = numberPage || 0;
                         }

                         if (listForms.length <= limit) {
                              newStateSet.ignoredDisableNextPage = true;
                         } else {
                              newStateSet.ignoredDisableNextPage = false;
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    if (next) {
                         newStateSet.ignoredFormsLoading = false;
                    } else {
                         newStateSet.ignoredLoading = false;
                    }

                    dispatch(setSubscriberState(newStateSet));
               });
     };

export const handleFetchFormsInventory =
     (limit, next, numberPage = 0, filters = []) =>
     (dispatch, getState) => {
          const activeAccount = getState().admin.accountAdmin;
          const newStateSet = {};
          let firstLoad,
               numberOffset = 0;
          let filterQuery = '';

          if (next) {
               firstLoad = { inventoryFormsLoading: true };
               numberOffset = numberPage * 20;
          } else {
               firstLoad = { inventoryLoading: true };
          }

          dispatch(setSubscriberState(firstLoad));

          filters.forEach((filter) => {
               if (filter.type) {
                    const filterValue = filter.value.trim() ? filter.value : '';
                    filterQuery = `${filterQuery}&filter.${filter.type}.${filter.operator}=${filterValue}`;
               }
          });

          callTokenApi(
               `${API_CLIENT_FETCH_FORM_INVENTORY_LISTENER.replace(
                    ':accountId',
                    activeAccount.id
               )}?limit=${limit}&offset=${numberOffset}${filterQuery}`,
               'GET'
          )
               .then((response) => {
                    if (response.status === 200) {
                         const listFormInventory = response.data.formAccount;

                         // next = click from view all
                         if (next) {
                              if (listFormInventory && listFormInventory.length > 0) {
                                   const newAllForms = [...listFormInventory].splice(0, limit);
                                   dispatch(setAllFormsInventory(newAllForms));
                              } else {
                                   newStateSet.inventoryDisableNextPage = true;

                                   if (numberPage === 1) {
                                        dispatch(setSubscriberState({ inventoryShowPaging: false }));
                                   }
                              }
                         } else {
                              // first load
                              const newForms = [...listFormInventory].splice(0, 12);
                              const newAllForms = [...listFormInventory].splice(0, limit);

                              dispatch(setFormInventory(newForms));
                              dispatch(setAllFormsInventory(newAllForms));

                              if (listFormInventory.length <= limit) {
                                   dispatch(setSubscriberState({ inventoryShowPaging: false }));
                              } else {
                                   dispatch(setSubscriberState({ inventoryShowPaging: true }));
                              }
                         }

                         if (listFormInventory && listFormInventory.length > 0) {
                              newStateSet.inventoryNumberPerpage = numberPage || 0;
                         }

                         if (listFormInventory.length <= limit) {
                              newStateSet.inventoryDisableNextPage = true;
                         } else {
                              newStateSet.inventoryDisableNextPage = false;
                         }
                    } else {
                         // toastError(response);
                    }
               })
               .finally(() => {
                    if (next) {
                         newStateSet.inventoryFormsLoading = false;
                    } else {
                         newStateSet.inventoryLoading = false;
                    }

                    dispatch(setSubscriberState(newStateSet));
               });
     };

export const handleUpdateRevealPeople = (values, parentId, nextDispatch) => (dispatch, getState) => {
     const { data } = values;
     const formInventory = getState().admin.formInventory;
     const allFormsInventory = getState().admin.allFormsInventory;
     const formInventoryEdit = getState().admin.formInventoryEdit || [];
     const listFormChild = getState().admin[data.id] || [];
     const listFormChildParent = getState().admin[parentId] || [];

     if (nextDispatch.setLoading) {
          nextDispatch.setLoading(true);
     }

     if (data.hasChild) {
          listFormChild.map((item) => {
               if (item.inheritParent) {
                    item.revealPeople = data.revealPeople;
               }
               return item;
          });
     } else {
          if (parentId) {
               listFormChildParent.map((item) => {
                    if (item.id === data.id) {
                         item.revealPeople = data.revealPeople;
                    }
                    return item;
               });
          }
     }

     const newFormInventory = formInventory.map((item) => {
          if (item.id === data.id) {
               item.revealPeople = data.revealPeople;
          }
          return item;
     });

     const newAllFormInventory = allFormsInventory.map((item) => {
          if (item.id === data.id) {
               item.revealPeople = data.revealPeople;
          }
          return item;
     });

     callTokenApi(API_CLIENT_UPDATE_REVEAL_PEOPLE, 'PUT', values)
          .then((response) => {
               if (response.status === 200) {
                    dispatch(
                         setSubscriberState({
                              [data.id]: listFormChild,
                         })
                    );
                    dispatch(
                         setSubscriberState({
                              [parentId]: listFormChildParent,
                         })
                    );
                    dispatch(setFormInventory(newFormInventory));
                    dispatch(setAllFormsInventory(newAllFormInventory));

                    formInventoryEdit.forEach((form) => {
                         if (form.id === data.id || (form.inheritParent && form.parentId === data.id)) {
                              form.revealPeople = data.revealPeople;
                              dispatch(setFormInventoryEdit(form));
                         }
                    });
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               nextDispatch.setLoading(false);
          });
};

export const handleUpdateIsRedirectInventory = (values, parentId, nextDispatch) => (dispatch, getState) => {
     const { data } = values;
     const formInventory = getState().admin.formInventory;
     const allFormsInventory = getState().admin.allFormsInventory;
     const formInventoryEdit = getState().admin.formInventoryEdit || [];
     const listFormChild = getState().admin[data.id] || [];
     const listFormChildParent = getState().admin[parentId] || [];
     let newFormInventory = [];
     let newAllFormInventory = [];

     if (nextDispatch.setLoading) {
          nextDispatch.setLoading(true);
     }

     if (data.hasChild) {
          listFormChild.map((item) => {
               if (item.inheritParent) {
                    item.isRedirect = data.isRedirect;
               }
               return item;
          });
     } else {
          if (parentId) {
               listFormChildParent.map((item) => {
                    if (item.id === data.id) {
                         item.isRedirect = data.isRedirect;
                    }
                    return item;
               });
          }
     }

     newFormInventory = formInventory.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     newAllFormInventory = allFormsInventory.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     callTokenApi(API_CLIENT_UPDATE_IS_REDIRECT, 'PUT', values)
          .then((response) => {
               if (response.status === 200) {
                    dispatch(
                         setSubscriberState({
                              [data.id]: listFormChild,
                         })
                    );
                    dispatch(
                         setSubscriberState({
                              [parentId]: listFormChildParent,
                         })
                    );
                    dispatch(setFormInventory(newFormInventory));
                    dispatch(setAllFormsInventory(newAllFormInventory));

                    formInventoryEdit.forEach((form) => {
                         if (form.id === data.id || (form.inheritParent && form.parentId === data.id)) {
                              form.isRedirect = data.isRedirect;
                              dispatch(setFormInventoryEdit(form));
                         }
                    });
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               nextDispatch.setLoading(false);
          });
};

export const handleUpdateIsRedirectIgnored = (values, parentId, nextDispatch) => (dispatch, getState) => {
     const { data } = values;
     const listFormIgnored = getState().admin.formIgnored;
     const allFormsIgnored = getState().admin.allFormsIgnored;
     const formIgnoreEdit = getState().admin.formIgnoreEdit || [];
     const listChildIgnored = getState().admin[`ignored${data.id}`] || [];
     const listFormChildIgnoredParent = getState().admin[`ignored${parentId}`] || [];
     let newFormIgnored = [];
     let newAllFormIgnored = [];

     if (nextDispatch.setLoading) {
          nextDispatch.setLoading(true);
     }

     if (data.hasChild) {
          listChildIgnored.map((item) => {
               if (item.inheritParent) {
                    item.isRedirect = data.isRedirect;
               }
               return item;
          });
     } else {
          if (parentId) {
               listFormChildIgnoredParent.map((item) => {
                    if (item.id === data.id) {
                         item.isRedirect = data.isRedirect;
                    }
                    return item;
               });
          }
     }

     newFormIgnored = listFormIgnored.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     newAllFormIgnored = allFormsIgnored.map((item) => {
          if (item.id === data.id) {
               item.isRedirect = data.isRedirect;
          }
          return item;
     });

     callTokenApi(API_CLIENT_UPDATE_IS_REDIRECT, 'PUT', values)
          .then((response) => {
               if (response.status === 200) {
                    dispatch(
                         setSubscriberState({
                              [`ignored${data.id}`]: listChildIgnored,
                         })
                    );
                    dispatch(
                         setSubscriberState({
                              [`ignored${parentId}`]: listFormChildIgnoredParent,
                         })
                    );
                    dispatch(setFormIgnored(newFormIgnored));
                    dispatch(setAllFormsIgnore(newAllFormIgnored));

                    formIgnoreEdit.forEach((form) => {
                         if (form.id === data.id || (form.inheritParent && form.parentId === data.id)) {
                              form.isRedirect = data.isRedirect;
                              dispatch(setFormIgnoreEdit(form));
                         }
                    });
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               nextDispatch.setLoading(false);
          });
};

export const fetchListeners = (activeAccountId) => {
     return (dispatch) => {
          dispatch(setLoadings({ listeners: true, destinations: true }));

          if (activeAccountId) {
               callTokenApi(`${CLIENT_LISTENER}${activeAccountId}`, 'GET', null).then((response) => {
                    if (response.status === 200) {
                         const { accountListeners } = response.data;
                         let enabledListeners = accountListeners.filter((listener) => listener.configStatus);

                         if (enabledListeners.length > 0) {
                              dispatch(setActiveListeners(enabledListeners));
                         } else {
                              dispatch(setActiveListeners([]));
                              dispatch(setLoadings({ listeners: false, destinations: false }));
                         }
                    } else {
                         toastError(response);
                         dispatch(setLoadings({ listeners: false, destinations: false }));
                    }
               });
          }
     };
};

export const handleFetchChildForm =
     (id, nextDispatch, limit, paging, filters = []) =>
     (dispatch, getState) => {
          let listFormsChild = [];
          let filterQuery = '';
          const showIgnoredInventoryForm = getState().admin.showIgnoredInventoryForm;
          const numberOffsetChild = paging ? getState().admin[`numberOffset${id}`] : 0;

          if (nextDispatch && nextDispatch.setLoading) {
               nextDispatch.setLoading(true);
          }

          if (nextDispatch && nextDispatch.setisLoadingLoadMore) {
               nextDispatch.setisLoadingLoadMore(true);
          }

          if (!showIgnoredInventoryForm) {
               listFormsChild = paging ? getState().admin[id] : [];
          } else {
               listFormsChild = paging ? getState().admin[`ignored${id}`] : [];
          }

          const url = showIgnoredInventoryForm ? API_CLIENT_FETCH_CHILD_FORM_IGNORED : API_CLIENT_FETCH_CHILD_FORM_INVENTORY;

          filters.forEach((filter) => {
               if (filter.type) {
                    const filterValue = filter.value.trim() ? filter.value : '';
                    filterQuery = `${filterQuery}&filter.${filter.type}.${filter.operator}=${filterValue}`;
               }
          });

          callTokenApi(`${url.replace(':parentId', id)}?limit=${limit}&offset=${listFormsChild.length}${filterQuery}`, 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const listFormResponse = response.data.formAccount;
                         const newListForms = limit ? [...listFormsChild, ...listFormResponse] : listFormResponse;
                         const newNumber = numberOffsetChild + listFormResponse.length;
                         if (!showIgnoredInventoryForm) {
                              dispatch(
                                   setListFormsChild({
                                        [id]: newListForms,
                                        [`numberOffset${id}`]: newNumber,
                                   })
                              );
                         } else {
                              dispatch(
                                   setListFormsChild({
                                        [`ignored${id}`]: newListForms,
                                        [`numberOffset${id}`]: newNumber,
                                   })
                              );
                         }

                         if (nextDispatch && nextDispatch.setShowLoadMoreBtn && (newNumber < numberOffsetChild + limit || newNumber < limit)) {
                              nextDispatch.setShowLoadMoreBtn(false);
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    if (nextDispatch && nextDispatch.setLoading) {
                         nextDispatch.setLoading(false);
                    }
                    if (nextDispatch && nextDispatch.setFetched) {
                         nextDispatch.setFetched(true);
                    }
                    if (nextDispatch && nextDispatch.setisLoadingLoadMore) {
                         nextDispatch.setisLoadingLoadMore(false);
                    }
               });
     };

export const fetchVersion = (accountId, flag) => {
     return (dispatch) => {
          const data = {
               accountId: accountId,
               status: 'waiting',
          };
          callTokenApi(`${API_VERSION_WAITING}`, 'POST', data).then((response) => {
               if (response.status === 200) {
                    let isPublish = parseInt(response.data.version.numberHistories) > 0 ? 'enable' : 'disable';
                    dispatch(setVersions(response.data.versions));
                    dispatch(setVersionPublish({ version: response.data.version, isPublish }));
                    if (flag) {
                         if (isPublish === 'enable') {
                              dispatch(setNumberOfHistories(parseInt(response.data.version.numberHistories)));
                         } else {
                              dispatch(setNumberOfHistories(0));
                         }
                    }
               } else {
                    // toastError(response);
               }
          });
     };
};
