import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CButton, CContainer, CModal } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Link } from 'react-router-dom';

import { setGTMWizard } from '../../../../../actions/subscriber';

import { GTM_WIZARD_STEPS } from '../../../../../constants';
import GTMConnection from './GTMConnection';
import GTMSelectAccount from './GTMSelectAccount';
import GTMSelectContainer from './GTMSelectContainer';
import GTMCreateData from './GTMCreateData';
import GTMRemoveData from './GTMRemoveData';
import GTMPublishData from './GTMPublishData';
import GTMVerifyChanges from './GTMVerifyChanges';
import GTMMakeChanges from './GTMMakeChanges';
import GTMExists from './GTMExists';
import GTMSuccess from './GTMSuccess';
import GTMUnknownFailure from './GTMUnknownFailure';
import GTMNoPublishingRights from './GTMNoPublishingRights';
import GTMPauseTag from './GTMPauseTag';

export const GTMWizardContext = React.createContext({});
export const CHANGE_STEP_TYPE = {
     NEXT: 'Next',
     PREV: 'Prev'
};

export const GTMWizardBody = ({ onBackBtnClicked, backBtnDisabled, shouldHideBackBtn, children, mainContentClassName = '' }) => {
     return (
          <CContainer className="main-container">
               <div className="d-flex align-items-center m-0 main-header">
                    <CButton
                         className={`btn-back m-0 ${onBackBtnClicked && !shouldHideBackBtn ? '' : 'invisible'}`}
                         onClick={onBackBtnClicked && !shouldHideBackBtn ? onBackBtnClicked : null}
                         disabled={backBtnDisabled}
                    >
                         Back
                    </CButton>
                    <div className="hero-logo">
                         <CIcon icon="logo-dark" height={23} />
                    </div>
               </div>
               <div className={`main-content ${mainContentClassName}`}>
                    {children}
               </div>
               <div className="footer-create-account">
                    <div className="logo-footer">
                         <CIcon icon="logo-dark" height={23} />
                    </div>
                    <div className="nav-link-footer">
                         <ul>
                              <li>
                                   <Link to="/">Support</Link>
                              </li>
                              <li>
                                   <Link to="">Terms of Use</Link>
                              </li>
                              <li>
                                   <Link to="/">Privacy & Data Policy</Link>
                              </li>
                         </ul>
                    </div>
               </div>
          </CContainer>
     );
}

const initialStepsData = {
     didCheckBeforeCreating: false,
     verifyChanges: []
};

const GTMWizard = () => {
     const dispatch = useDispatch();
     const [stepsData, setStepsDataState] = useState(initialStepsData);
     const [prevSteps, setPrevSteps] = useState([]);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const gtmWizard = useSelector((state) => state.subscriber.gtmWizard);
     const showPopup = gtmWizard.show;
     const [currentStep, setCurrentStepState] = useState(() => {
          let initialStep = gtmWizard.initialStep || GTM_WIZARD_STEPS.gtmConnection;

          if (gtmWizard.initialStep === GTM_WIZARD_STEPS.gtmCreateData && (!activeAccount.gtmInfo || !activeAccount.gtmInfo.currentContainer)) {
               initialStep = GTM_WIZARD_STEPS.gtmConnection;
          }

          return initialStep;
     });

     const setStepsData = (values) => {
          setStepsDataState(prevStepsData => ({ ...prevStepsData, ...values }));
     };

     const setCurrentStep = (step, type) => {
          let newCurrentStep;

          switch (type) {
               // Used for going to next step and when pressing back button from that next step, you go back to screen that navigate to this next step
               case CHANGE_STEP_TYPE.NEXT:
                    setPrevSteps(steps => [...steps, currentStep]);
                    setCurrentStepState(step);
                    break;
               // Used for going to previous step of CHANGE_STEP_TYPE.NEXT case
               case CHANGE_STEP_TYPE.PREV:
                    let newPrevSteps = [...prevSteps];
                    newCurrentStep = newPrevSteps.pop();

                    if (newCurrentStep) {
                         setPrevSteps(newPrevSteps);
                         setCurrentStepState(newCurrentStep);
                    } else {
                         dispatch(setGTMWizard({ show: false }));
                    }
                    break;
               default:
                    setCurrentStepState(step);
          }
     };

     const handleWhenHideAndShowPopup = () => {
          if (showPopup) {
               setTimeout(() => document.body.classList.add('no-scroll'), 0);
          } else {
               document.body.classList.remove('no-scroll');
          }
     };

     useEffect(handleWhenHideAndShowPopup, [showPopup]);

     const handleBackBrowserBtn = () => {
          if (showPopup) {
               const browserBackBtnClick = () => {
                    closeModal();
               }

               window.addEventListener('popstate', browserBackBtnClick, false); // Close create account popup on clicking back button of browser

               return () => window.removeEventListener('popstate', browserBackBtnClick);
          }
     }

     useEffect(handleBackBrowserBtn, [showPopup, activeAccount.id, stepsData.shouldFetchListeners]); // eslint-disable-line react-hooks/exhaustive-deps

     const closeModal = () => {
          dispatch(setGTMWizard({ show: false }));
     };

     const getCurrentComponent = useCallback(() => {
          switch (currentStep) {
               case GTM_WIZARD_STEPS.gtmConnection:
                    return <GTMConnection />;
               case GTM_WIZARD_STEPS.gtmSelectAccount:
                    return <GTMSelectAccount />;
               case GTM_WIZARD_STEPS.gtmSelectContainer:
                    return <GTMSelectContainer />;
               case GTM_WIZARD_STEPS.gtmCreateData:
                    return <GTMCreateData />;
               case GTM_WIZARD_STEPS.gtmRemoveData:
                    return <GTMRemoveData />;
               case GTM_WIZARD_STEPS.gtmPauseTag:
                    return <GTMPauseTag />;
               case GTM_WIZARD_STEPS.gtmPublishData:
                    return <GTMPublishData />;
               case GTM_WIZARD_STEPS.gtmVerifyChanges:
                    return <GTMVerifyChanges />;
               case GTM_WIZARD_STEPS.gtmExists:
                    return <GTMExists />;
               case GTM_WIZARD_STEPS.gtmMakeChanges:
                    return <GTMMakeChanges />;
               case GTM_WIZARD_STEPS.gtmSuccess:
                    return <GTMSuccess />;
               case GTM_WIZARD_STEPS.gtmUnknownFailure:
                    return <GTMUnknownFailure />;
               case GTM_WIZARD_STEPS.gtmNoPublishingRights:
                    return <GTMNoPublishingRights />;
               default:
                    return <></>;
          }
     }, [currentStep]);

     const contextValue = {
          stepsData, setStepsData,
          currentStep, setCurrentStep
     };

     return (
          <div className={`create-account-popup gtm-wizard`}>
               <GTMWizardContext.Provider value={contextValue}>
                    <CModal fullscreen id="gtm-wizard-content" visible={showPopup} onClose={closeModal} backdrop="static" portal={false}>
                         <div className="modal-body">
                              {getCurrentComponent()}
                         </div>
                    </CModal>
               </GTMWizardContext.Provider>
          </div>
     );
};

export default GTMWizard;
