import React, { useRef, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useOutsideHandling } from '../../../utils';
import {
    CCard,
    CCardBody,
    CLink,
} from '@coreui/react';
import CIcon from "@coreui/icons-react";
import { SUBSCRIBER_PATH, NO_OPTION_MESSAGE_TYPE, ACCORDION_TYPES } from '../../../constants';
import { setFlexibleModal, setRuleHaveEditting } from '../../../actions/common';
import { useDispatch, useSelector } from 'react-redux';
import { handleSetAccordion } from '../../../actions/subscriber';

const NoOptionsMessage = ({ type, options, listener = null, handleClickLinkAction = null, usedVariableOptions }) => {
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const activeAccount = useSelector((state) => state.subscriber.activeAccount);
    const listenerPath = `${listener ? `/${activeAccount.secondId}/${listener.alias}` : ''}`;

    const [detailMessage, setDetailMessage] = useState(false);

    const clickOutside = useCallback(() => {
        setDetailMessage(false);
    }, []);

    useOutsideHandling(wrapperRef, clickOutside);

    const handleClickBubble = () => {
        setTimeout(() => {
            setDetailMessage(!detailMessage)
        }, 100);
    }

    const handleOverBubble = () => {
        setTimeout(() => {
            if (!detailMessage) {
                setDetailMessage(true)
            }
        }, 100);
    }

    const handleClickLink = (accordion, pathName) => {
        dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
        dispatch(setFlexibleModal({
            show: false,
            showLv2: false,
            ruleId: '',
            ruleIdLv2: '',
            component: '',
            componentLv2: ''
        }));
        if (handleClickLinkAction) {
            handleClickLinkAction();
        }
        if (accordion && pathName) {
            dispatch(handleSetAccordion({ accordion, pathName }));
        }
    };

    const getChildren = () => {
        switch (type) {
            case NO_OPTION_MESSAGE_TYPE.EVENT:
                return (
                    <>
                        You have to turn on at least one status before events will appear here. You can do this on <CLink as={Link} to={listenerPath} onClick={() => handleClickLink(ACCORDION_TYPES.EVENTS, listenerPath)}>Data Layer Event</CLink> screen for this Listener.
                    </>
                )
            case NO_OPTION_MESSAGE_TYPE.AUTOMATIC_VARIABLES:
                return (
                    <>
                        We’re still waiting for data layer activity from this Listener. Make sure the <CLink as={Link} to={SUBSCRIBER_PATH.WEBSITE.replace(':secondId', activeAccount.secondId)} onClick={handleClickLink}>Website Script</CLink> is on your website, enable <CLink as={Link} to={listenerPath} onClick={() => handleClickLink(ACCORDION_TYPES.EVENTS, listenerPath)}>Activity Features</CLink>, and publish your changes. As data layer activity occurs on your website, available data layer variables will appear here.
                    </>
                )
            case NO_OPTION_MESSAGE_TYPE.CUSTOM_VARIABLE:
                return (
                    <>
                        You must define <CLink as={Link} to={SUBSCRIBER_PATH.CUSTOM_DATA_LAYER_VARIABLES.replace(':secondId', activeAccount.secondId)} onClick={() => handleClickLink(ACCORDION_TYPES.CUSTOM_VARIABLES, SUBSCRIBER_PATH.CUSTOM_DATA_LAYER_VARIABLES.replace(':secondId', activeAccount.secondId))}>custom data layer variables</CLink> before they will appear here.
                    </>
                )
            case NO_OPTION_MESSAGE_TYPE.NATIVE_EVENT:
                return (
                    <>
                        No options to select, please define a{" "}
                        <CLink as={Link} to={SUBSCRIBER_PATH.NATIVE_DATA_LAYER.replace(':secondId', activeAccount.secondId)} onClick={() => handleClickLink(ACCORDION_TYPES.NATIVE_DATA_LAYER, SUBSCRIBER_PATH.NATIVE_DATA_LAYER.replace(':secondId', activeAccount.secondId))}>Native Event.</CLink>
                    </>
                )
            case NO_OPTION_MESSAGE_TYPE.NATIVE_BROWSER_STORAGE:
                return (
                    <>
                        No options to select, please define a{" "}
                        <CLink as={Link} to={`/${activeAccount.secondId}/listeners/native-browser-storage`} onClick={() => handleClickLink(ACCORDION_TYPES.NATIVE_BROWSER_STORAGE, `/${activeAccount.secondId}/listeners/native-browser-storage`)}>Native Browser Storage.</CLink>
                    </>
                )
            case NO_OPTION_MESSAGE_TYPE.CUSTOM_BROWSER_VARIABLE:
                return (
                    <>
                        You must define <CLink as={Link} to={SUBSCRIBER_PATH.CUSTOM_BROWSER_STORAGE_VARIABLES.replace(':secondId', activeAccount.secondId)} onClick={() => handleClickLink(ACCORDION_TYPES.BROWSER_STORAGE_VARIABLES, SUBSCRIBER_PATH.CUSTOM_BROWSER_STORAGE_VARIABLES.replace(':secondId', activeAccount.secondId))}>browser storage variables</CLink> before they will appear here.
                    </>
                )
            case NO_OPTION_MESSAGE_TYPE.TRIGGERS:
                return (
                    <>
                        Rules user Triggers and conversion triggers as the condition to define value.You need to create one or more <CLink as={Link} to={SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS.replace(':secondId', activeAccount.secondId)} onClick={() => handleClickLink(ACCORDION_TYPES.TRIGGERS, SUBSCRIBER_PATH.TRIGGER_AND_CONVERSIONS.replace(':secondId', activeAccount.secondId))}>Triggers</CLink>/ <CLink as={Link} to={SUBSCRIBER_PATH.GOALS_WEB_CONVERSIONS.replace(':secondId', activeAccount.secondId)} onClick={() => handleClickLink(ACCORDION_TYPES.CONVERSIONS, SUBSCRIBER_PATH.TRIGGER_AND_CONVERSIONS.replace(':secondId', activeAccount.secondId))}>conversion triggers</CLink>  before you can write conditions
                    </>
                )
            case NO_OPTION_MESSAGE_TYPE.VARIABLES_TRIGGERS:
                return (
                    <>
                        We’re still waiting for data layer activity from this Listener. Make sure the <CLink as={Link} to={SUBSCRIBER_PATH.WEBSITE.replace(':secondId', activeAccount.secondId)} onClick={handleClickLink}>Website Script</CLink> is on your website, enable <CLink as={Link} to={listenerPath} onClick={() => handleClickLink(ACCORDION_TYPES.EVENTS, listenerPath)}>Activity Features</CLink>, and publish your changes. As data layer activity occurs on your website, available data layer variables will appear here.
                        {/* We’re still waiting for data layer activity from this Listener. As data layer activity occurs on your website, available data layer variables will appear here. */}
                    </>
                )
            case NO_OPTION_MESSAGE_TYPE.FORM_CATEGORIES:
                return (
                    <>
                        Define categories to assign them to your forms. <CLink onClick={handleClickLink}>Click here</CLink> to create your first one.
                    </>
                )
            default:
                return (
                    <>
                        Our system needs to capture some activity from your website in order to
                        identify the available variables for your rules.
                        Make sure the <CLink as={Link} to={SUBSCRIBER_PATH.WEBSITE.replace(':secondId', activeAccount.secondId)} onClick={handleClickLink}>Website Script</CLink> is
                        on your website and you have published a version of ListenLayer.
                        Need more help? <CLink as={Link} to={SUBSCRIBER_PATH.WEBSITE.replace(':secondId', activeAccount.secondId)} onClick={handleClickLink}>Learn more</CLink>.
                    </>
                )
        }
    }

    return (
        <span className='no-options-message'>
            {`No options available. `}
            {(typeof options === 'undefined' ||
                (options && options.length === 0) ||
                typeof usedVariableOptions === 'undefined' ||
                (usedVariableOptions && usedVariableOptions.length) === options.length) && (
                    <span ref={wrapperRef}>
                        <CIcon icon='icon-info' className='icon-info' onClick={handleClickBubble} onMouseEnter={handleOverBubble} height={18}></CIcon>
                    </span>
                )}
            {detailMessage && (
                <CCard className='code-preview no-options-info'>
                    <CIcon icon='arrow-preview' className='arrow-preview'></CIcon>
                    <CCardBody className='text-wrapper'>
                        <span>{getChildren()}</span>
                    </CCardBody>
                </CCard>
            )}
        </span>
    );
}

export default NoOptionsMessage;
