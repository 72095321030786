import {
     CRAWL_FROM_ACTION,
     FORM_CATEGORY_SET_ALL_FORMS_IGNORE,
     FORM_CATEGORY_SET_ALL_FORMS_INVENTORY,
     FORM_CATEGORY_SET_FORM_EDIT,
     FORM_CATEGORY_SET_LIST_FORM_CHILDS,
     FORM_CATEGORY_SET_NUMBER_OFFSET,
     FORM_CATEGORY_UPDATE_FORM_CHILD,
     FORM_IGNORE_SET_FORM_EDIT,
     FORM_REQUIRING_SET_ALL_FORMS,
     FORM_REQUIRING_SET_FORM_EDIT,
     LOAD_VERSION_PUBLISHING,
     PUSHER_ADD_NEW_TICKET_COMMENT,
     PUSHER_ADD_RULE,
     PUSHER_CHANGE_ACCOUNT_EVENTS,
     PUSHER_CHANGE_ACCOUNT_NAME,
     PUSHER_CHANGE_ACCOUNT_TIMESTAMPS,
     PUSHER_CHANGE_ACCOUNT_VARIABLES,
     PUSHER_CHANGE_CHILD_FEATURE_STATUS,
     PUSHER_CHANGE_EVENT_CHART,
     PUSHER_CHANGE_FEATURE_STATUS,
     PUSHER_CHANGE_LISTENER_STATUS,
     PUSHER_CHANGE_NUMBER_SESSIONS_CHART,
     PUSHER_CHANGE_NUMBER_VISITORS_CHART,
     PUSHER_CHANGE_VISITORS_AND_SESSIONS_PER_MONTH,
     PUSHER_CONSENT_RULE_CHANGED,
     PUSHER_CREATE_INVOICES,
     PUSHER_CREATE_NEW_USER_MANAGER,
     PUSHER_INCREASE_NUMBER_OF_EVENTS,
     PUSHER_INCREASE_NUMBER_OF_FAKE_VISITORS_BY_1,
     PUSHER_INCREASE_NUMBER_OF_HISTORIES,
     PUSHER_INVITE_MANAGER_USER,
     PUSHER_INVOICE_FINALIZATION,
     PUSHER_NATIVE_BROWSER_STORAGE_CHANGE,
     PUSHER_REMOVE_MANAGER_USER,
     PUSHER_REMOVE_RULE,
     PUSHER_REORDER_RULES,
     PUSHER_RESET_CMT_NUMBER_OF_CLIENT,
     PUSHER_RESET_FEATURE_SUPPORT,
     PUSHER_RESET_NUMBER_OF_EVENTS,
     PUSHER_SAVE_CARD_TO_CHANGE,
     PUSHER_SAVE_CREDIT_CARD,
     PUSHER_SCHEDULE_UPGRADE,
     PUSHER_SET_ACCOUNT_COMPLETED_STEPS,
     PUSHER_SET_RELOAD_OFFICE_HOUR_CALENDAR,
     PUSHER_SET_RELOAD_PARTNER,
     PUSHER_UPDATE_ACCOUNT,
     PUSHER_UPDATE_ACCOUNT_CONSOLE_STATUS,
     PUSHER_UPDATE_AVAILABLE_HOURS,
     PUSHER_UPDATE_CUSTOM_FORM_RULES,
     PUSHER_UPDATE_EVENT_TABLE,
     PUSHER_UPDATE_FEATURE_SUPPORT_CHILD,
     PUSHER_UPDATE_FEATURE_SUPPORT_UPDATE_VIDEO_PROGRESSES,
     PUSHER_UPDATE_INVOICE,
     PUSHER_UPDATE_INVOICE_INFORMATION,
     PUSHER_UPDATE_LISTENER_FOR_ROUTES,
     PUSHER_UPDATE_LIST_DATALAYER_VARIABLES,
     PUSHER_UPDATE_LIST_EVENTS,
     PUSHER_UPDATE_NOTIFICATION,
     PUSHER_UPDATE_PERSONAL_DATA,
     PUSHER_UPDATE_PRECISE_LOCATION_CONFIG,
     PUSHER_UPDATE_RULE,
     PUSHER_UPDATE_RULE_CONDITIONS,
     PUSHER_UPDATE_RULE_STATUS,
     PUSHER_UPDATE_SELECTOR_VALUES,
     PUSHER_UPDATE_SELECTOR_VARIABLES,
     PUSHER_UPDATE_USER_MANAGER_INFO,
     PUSHER_UPDATE_VARIABLE_VALUES,
     PUSHER_UPGRADE_OR_DOWNGRADE_ACCOUNT,
     RELOAD_CONVERSION_RULES,
     RELOAD_DATA_ACTIONS,
     RELOAD_DESTINATION_CONVERSIONS,
     RELOAD_DESTINATION_ECOMMERCE,
     RELOAD_LOOKUP_TABLE,
     RELOAD_TRIGGER_RULES,
     RELOAD_USER_SOURCE,
     RESET_LIMITTAION_TYPE,
     RESET_PREVIEW,
     RESET_SUBSCRIBER_REDUCER,
     REVERT_PUBLISH_DATA,
     SAVE_TEMP_PUBLISH_DATA,
     SET_ACCORDION,
     SET_ACCOUNTS,
     SET_ACCOUNT_DESTINATION,
     SET_ACCOUNT_LISTENERS,
     SET_ACCOUNT_PASTDUE,
     SET_ACCOUNT_PREVIEW,
     SET_ACCOUNT_REDEEMED_COUPONS,
     SET_ACROSS_DOMAINS,
     SET_ACTIVE_ACCOUNT,
     SET_ACTIVE_FEATURES,
     SET_ACTIVE_LISTENER,
     SET_ACTIVE_LISTENERS,
     SET_ALL_DATA_ACTION_RULES,
     SET_ALL_EVENTS,
     SET_APPLY_CHILD,
     SET_APPLY_REDIRECT,
     SET_AUTOMATIC_VARIABLE_RELOAD,
     SET_AUTOMATIC_VARIABLE_WARNING,
     SET_AVAILABLE_HOURS,
     SET_BROWSER_RULES,
     SET_BROWSER_VARIABLE_OPTIONS,
     SET_BROWSE_LISTENER_FORM_CATEGORY,
     SET_BUY_OFFICE_HOURS,
     SET_CHART_OPTIONS,
     SET_CHECKED_ITEMS_BYPAGE,
     SET_CLICK_IDS,
     SET_CLICK_RULES,
     SET_COMPLETE_SETUP_GUIDE,
     SET_CONFIRM_ADD_SCRIPT,
     SET_CONSENT_LISTENER_ACCOUNT,
     SET_CONSENT_RULES_ACCOUNT,
     SET_CONVERSION_RULES,
     SET_COUNT_FORM_IGNORED,
     SET_COUNT_FORM_INVENTORY,
     SET_COUNT_FORM_REQUIRING,
     SET_CREATED_ACCOUNT,
     SET_CURRENT_PAGE,
     SET_CUSTOM_CATEGORY,
     SET_CUSTOM_DESTINATION_MODAL,
     SET_CUSTOM_FORM_TARGET_RULES,
     SET_CUSTOM_REPORT,
     SET_CUSTOM_VARIABLE_OPTIONS,
     SET_CUSTOM_VARIABLE_RULES,
     SET_DATA_ACTIONS_RULES,
     SET_DATA_CHILD_SETUP_GUIDE_COMPLETED,
     SET_DATA_SETUP_GUIDE_COMPLETED,
     SET_DATA_WEATHER_TEMPERATURE,
     SET_DATE_REPORTS,
     SET_DATE_RANGE_RECORD,
     SET_DISABLE,
     SET_DOMAINS,
     SET_DOMAINS_PREVIEW,
     SET_DOMAIN_PREVIEW,
     SET_DORMANT_ACCOUNT,
     SET_EDITING_RULE_RELOAD,
     SET_ENGAGEMENT_RULES,
     SET_ESTIMATED_TOTAL_EVENTS,
     SET_EVENT_CHART,
     SET_EVENT_NATIVE_OPTIONS,
     SET_EVENT_OPTIONS,
     SET_EVENT_TABLE,
     SET_EVENT_TABLE_OF_LISTENERS,
     SET_FEATURES,
     SET_FEATURE_CONFIGURE_DESTINATION,
     SET_FEATURE_CUSTOM_OPTINAL,
     SET_FEATURE_FORM_CONVERSION,
     SET_FEATURE_INSTALL_GOALS,
     SET_FEATURE_LEARN_DATA,
     SET_FEATURE_LEARN_SEDING_DATA,
     SET_FILTER_SHOW_ENABLED_FEATURES,
     SET_FORM_CATEGORY,
     SET_FORM_IGNORED,
     SET_FORM_INVENTORY,
     SET_FORM_REQUIRING_ACTION,
     SET_GLOBAL_REPORTING,
     SET_GTM_ACCOUNTS,
     SET_GTM_CHANGELOG,
     SET_GTM_CONTAINERS,
     SET_GTM_TAGS_IN_LL,
     SET_GTM_TRIGGERS_IN_LL,
     SET_GTM_VARIABLES_IN_LL,
     SET_GTM_WIZARD,
     SET_INCREMENT_NUMBER_EVENT,
     SET_INITIAL_CLICK_RULES,
     SET_INITIAL_CONVERSION_RULES,
     SET_INITIAL_CUSTOM_VARIABLE_RULES,
     SET_INITIAL_PERSONAL_DATA,
     SET_INITIAL_TRIGGER_RULES,
     SET_INITIAL_USER_SOURCE_RULES,
     SET_INVOICES,
     SET_IS_CUSTOMER_SUPPORT_LOGIN_AS,
     SET_IS_FROM_GET_STARTED,
     SET_LESSON_VIDEO_REF,
     SET_LIMITATION_TYPE,
     SET_LISTENERS_IN_TICKET,
     SET_LISTENER_EVENTS,
     SET_LISTENER_EVENTS_NATIVE,
     SET_LISTENER_FORMS_ENABLED,
     SET_LISTENER_PREVIEW,
     SET_LISTENER_SELECTORS,
     SET_LISTENER_VARIABLES,
     SET_LIST_BROWSER_VARIABLES,
     SET_LIST_CUSTOM_VARIABLES,
     SET_LIST_FEATURE_CUSTOM_FORMS,
     SET_LOADINGS,
     SET_LOOKUP_RULE_ACTIVE_STEP,
     SET_MANAGERS,
     SET_MANAGE_GOALS_TYPES,
     SET_MASTERCLASS_CERTIFICATION,
     SET_MASTERCLASS_COURSE,
     SET_MAX_PACKAGE,
     SET_MONITORING_NOTIFICATIONS,
     SET_MONITORING_RULES,
     SET_MY_CREATED_TICKETS,
     SET_NATIVE_BROWSER_ACCOUNT_FEATURES,
     SET_NATIVE_BROWSER_STORAGE,
     SET_NATIVE_BROWSER_STORAGE_OPTIONS,
     SET_NEW_CUSTOM_VARIABLE,
     SET_NEW_LAYOUT,
     SET_NEXT_PACKAGE_INDEX,
     SET_NUMBER_HISTORIES,
     SET_NUMBER_OF_COMMENT_TICKETS,
     SET_OFFICE_HOUR,
     SET_OFFICE_HOUR_CALENDAR,
     SET_OTHER_TICKETS,
     SET_OWNER,
     SET_PACKAGES,
     SET_PARENT_SETUP_GUIDE_COMPLETED,
     SET_POPULATE_RULES,
     SET_POPUP_CONFIGURATION_REQUIRED,
     SET_POPUP_CONFIRM_ADD_SCRIPT,
     SET_POPUP_CREATE_BROWSER_VARIABLE,
     SET_POPUP_CREATE_VARIABLE,
     SET_POPUP_DOMAIN_PREVIEW,
     SET_PREVIEW_BROWSER_RULE,
     SET_PREVIEW_CUSTOM_RULE,
     SET_PREVIEW_OPTIONS,
     SET_PUBLISHED_VERSION,
     SET_REDIRECT_RULES,
     SET_ROUTES,
     SET_SCROLL_SIDEBAR,
     SET_SEARCH_KEY,
     SET_SELECTOR_OPTIONS,
     SET_SELECTOR_VALUES,
     SET_SELECTOR_VARIABLE_RELOAD,
     SET_SETUP_GUIDE_ROADMAP,
     SET_SETUP_GUIDE_ROADMAP_DECREASE,
     SET_SETUP_GUIDE_ROADMAP_INCREASE,
     SET_SHOW_ACCOUNT_DORMANT_POPUP,
     SET_SHOW_ARCHIVE_NOTIFICATIONS,
     SET_SHOW_BLOCK_ACCOUNT_POPUP,
     SET_SHOW_BLOCK_SETUP_GUIDE_POPUP,
     SET_SHOW_CATEGORY_FORM,
     SET_SHOW_COMPARE_PLANS_POPUP,
     SET_SHOW_CREATE_ACCOUNT_POPUP,
     SET_SHOW_EVENT_REPORTING_WARNING,
     SET_SHOW_GET_HELP_LABEL,
     SET_SHOW_HAMBURGER_ICON,
     SET_SHOW_IFRAME_CUSTOM_FORMS,
     SET_SHOW_IGNORED_FORM,
     SET_SHOW_IGNORED_INVENTORY_FORM,
     SET_SHOW_MASTERCLASS_ASIDE,
     SET_SHOW_PUBLISH_POPUP,
     SET_SHOW_ROADMAP,
     SET_SHOW_ROADMAP_MINIMIZE,
     SET_SHOW_STEP_CUSTOM_FORM,
     SET_SHOW_STEP_FORM_CATEGORY,
     SET_SHOW_TOUR_COMPETED_WIZARD,
     SET_SHOW_TOUR_FROM_ROADMAP,
     SET_SHOW_UPGRADE_ACCOUNT_POPUP,
     SET_SHOW_UPGRADE_ACCOUNT_SCHEDULE_POPUP,
     SET_SHOW_VIDEO,
     SET_SIGN_UP_STEP,
     SET_SKIP_WIZARD_FORM_CATEGORY,
     SET_STEPS_CUSTOM_FORMS,
     SET_STEPS_WIZARD_CATEGORIES,
     SET_STEP_NOT_SHOW_ANIMATION_ROADMAP,
     SET_SUBSCRIBER_STATE,
     SET_SUBSCRIBER_TICKET,
     SET_SWITCH_TYPE_WIZARD,
     SET_TERM_PUBLISH,
     SET_TICKET_INIT,
     SET_TOAST_IS_OPEN,
     SET_TOGGLE_PREVIEW_POPUP,
     SET_TOUR,
     SET_TRIGGERS_NAME_RULES,
     SET_TRIGGER_EVENT_ECOMMERCE,
     SET_TRIGGER_RULES,
     SET_TURNOFF_PREVIEW_POPUP,
     SET_UNSAVED_LOOKUP_TABLE_MODAL,
     SET_USED_ITEMS,
     SET_USER_SOURCE_RULES,
     SET_USER_TERMS,
     SET_VARIABLE_OPTIONS,
     SET_VARIABLE_VALUE_CHART,
     SET_VERSIONS,
     SET_VERSION_PUBLISH,
     SET_VIDEO_ENGAGEMENTS,
     SET_VIDEO_PROGRESSES,
     SET_VISITOR_SESSIONS_UNIQUE,
     SET_WIDTH_COLUMN_REPORTS,
     TOGGLE_ASIDE_LIMITATION_HIT,
     SET_REDUX_ACTIVE_DROPDOWN,
     SET_LOAD_FORM_CHILD_REQUIRING,
     FEW_FORMS_SET_FORM_EDIT,
     SET_OPEN_FROM_CONFIG_CONVERSION,
     SET_SHOW_POPUP_CONFIG_CONVERSION,
     SET_STEPS_CONVERSION,
     FORM_CATEGORY_SET_FILTERS,
     FORM_CATEGORY_SET_FILTERS_EDIT,
     SET_ELEMENT_CLICK_TARGET_RULES,
     PUSHER_UPDATE_ELEMENT_CLICK_RULES,
     SET_FORM_DRAFT,
     SET_LIST_CHANNELS,
     SET_CUSTOM_USER_SOURCES,
     SET_LIST_USER_SOURCES,
     SET_SKIP_FORM_LISTENER,
     SET_MONITORING_FORM_CATEGORY,
     SET_ACCOUNT_EXTERNAL_DATA_SOURCES,
     SET_MAX_STEP_FORM,
     SET_MAX_STEP_RECORD_PROFILE,
     SET_GOOGLE_ADS_ACCOUNTS,
     SET_CUSTOMER_IDS_RESPONSE,
     SET_NATIVE_ECOMMERCE,
     SET_LISTENER_EVENTS_LOADING,
     SET_SALESFORCE_ACCOUNTS,
     SET_SHOW_HISTORICAL_POPUP,
     SET_DATA_HISTORICAL_SALESFORCE,
     PUSHER_UPDATE_ACCOUNT_USER,
     SET_REDUX_RELOAD_TABLE_REPORT,
     SET_SALESFORCE_RULES,
     SET_RELOAD_ACCOUNT_SALESFORCE,
     SET_LIST_SEGMENT,
     SET_LIST_SEGMENT_REPORT,
     SET_LIST_SEGMENT_APPLY_RELOAD,
     SET_LIST_SEGMENT_WAITING_QUEUE,
     PUSHER_UPDATE_SEGMENT,
     SET_CONVERSIONS_NAME_RULES,
     SET_BLOCK_TRIGGER_RULES,
     SET_SAVED_REPORT,
     SET_LOADING_FORM_IGNORE,
} from '../actions/types';
import { STEPS_CUSTOM_FORM, STEPS_WIZARD_CATEGORIES } from '../constants';
import { subscriberPusher } from '../helpers/cms/subscriber';
import { guidGenerator } from '../utils';

const INITIAL_GTM_WIZARD = {
     show: false,
     initialStep: '',
     type: '', // variable, trigger, data actions
     data: null,
     linkToRedirect: null,
     key: guidGenerator(),
};

const INITIAL_CUSTOM_DESTINATION_MODAL = {
     show: false,
     initialStep: '',
     data: null,
     key: guidGenerator(),
};

const INITIAL_SWITCH_TYPE_WIZARD = {
     show: false,
     initialStep: '',
     usingType: 'gtm',
     key: guidGenerator(),
};

// State to reset when changing account
const STATE_TO_RESET = {
     disable: [],
     owner: null,
     managers: [],
     eventChart: {
          accountId: '',
          chartData: {
               charts: [],
               labels: [],
               sessions: [],
               visitors: [],
               visitorsStart: [],
               sessionsStart: [],
          },
          filter: '',
          keysConcept: [],
          keysFilterMetrics: [],
          listenerId: '',
          selected: '',
     },
     chartOptions: {},
     eventTable: {
          listenerId: '',
          countData: {}, // Data of "Event Count" column on event table
          eventStatisticsOnUI: [], // Data of "Event Name" and "Variable Name" column on event table. UI: User Interface
          featureCustomVariables: {}, // Custom variable ids which are displayed for each feature id
          variableValueConfig: {}, // Enable/Disable saving values for variables
          variableReportingValues: {}, // Save reporting values of variables
          // variableValueCount: {},
          startDay: '',
          endDay: '',
          visitorsUnique: [],
          sessionsUnique: [],
     },
     eventTableOfListeners: {
          countData: {}, // Data of "Event Count" column on event table
          eventStatisticsOnUI: [], // Data of "Event Name" and "Variable Name" column on event table. UI: User Interface
          featureCustomVariables: {}, // Custom variable ids which are displayed for each feature id
          automaticVariables: {},
          selectorVariables: {},
          allTypeEventFeatures: [],
          variableValueConfig: {}, // Enable/Disable saving values for variables
          variableReportingValues: {}, // Save reporting values of variables
          startDay: '',
          endDay: '',
          visitorsUnique: [],
          sessionsUnique: [],
     },
     variableValueChart: {
          chartData: {
               charts: [],
               labels: [],
          },
          filter: '',
          keysConcept: [],
          selected: '',
     },
     domains: {
          initialDomains: [],
          editedDomains: [], // Edited Domains from the Initial
          addedDomains: [], // New Domains from the Initial
          listDisabled: [], // Status of fields
          numOfDomains: 0,
     },
     customVariables: {
          initialVariables: [],
          editedVariables: [], // Edited variables from the Initial
          addedVariables: [], // New variables from the Initial
          listDisabled: [], // Status of fields
          numOfVariables: 0,
     }, // Used in Custom Data Layer Variables
     features: [],
     listenerEventsLoading: false,
     listenerEvents: {
          listenerId: '',
          initialEvents: [],
          editedEvents: [],
          eventsStatus: [],
          featureSupports: [],
     }, // Used in Event Settings
     listenerEventsNative: {
          listenerId: '',
          initialEventsNative: [],
          initialEventsNativeChange: [],
          addedEvents: [],
          editedEvents: [],
          eventsStatus: [],
          featureSupports: [],
     },
     nativeBrowserStorage: {
          listenerId: '',
          initialEvents: [],
          addedEvents: [],
          editedEvents: [],
          eventsStatus: [],
          featureSupports: [],
          eventBrowserStorage: [],
     },
     videoProgresses: {
          listenerId: '',
          initialThresholds: [],
          addedThresholds: [],
          editedThresholds: [],
          fieldStatus: [],
     }, //Used in Event Settings of listeners for video
     acrossDomains: {
          listenerId: '',
          initialDomains: [],
          addedDomains: [],
          editedDomains: [],
          fieldStatus: [],
     },
     clickIds: {
          listenerId: '',
          initialClickIds: [],
          addedClickIds: [],
          editedClickIds: [],
          removedClickIds: [],
          idStatus: [],
     },
     videoEngagements: [],
     listenerVariables: {
          listenerId: '',
          initialVariables: [],
          editedVariables: [],
          nonLevelList: [],
     }, // Used in Variable Settings
     listenerSelectors: {
          listenerId: '',
          initialSelectors: [],
          editedSelectors: [],
     }, // Used in Variable Settings
     customVariableRules: null,
     initialCustomVariableRules: null, //For reordering Custom Values.
     formCategoriesRules: null,
     initialFormCategoriesRules: null, //For reordering Custom Values.
     userSourceRules: [],
     initialUserSourceRules: [],
     redirectRules: {
          status: false,
          to: '',
     }, //For redirecting to rules page
     eventOptions: {
          listenerId: '',
          events: [],
     }, // Used in Custom Variable Rules
     customReport: {
          reports: [],
          scopes: [],
          dimensions: [],
          metrics: [],
          checkList: null,
     }, // Used in Custom Variable Rules
     savedReport: [],
     eventNativeOptions: {
          listenerId: '',
          eventsNative: [],
     },
     nativeBrowserStorageOptions: {
          listenerId: '',
          nativeBrowserStorage: [],
     },
     variableOptions: {
          listenerId: '',
          variables: [],
     }, // Used in Custom Variable Rules
     selectorOptions: {
          listenerId: '',
          variables: [],
     }, // Used in Custom Variable Rules
     customVariableOptions: [], // Used in Custom Variable Rules
     browserVariableOptions: [], // Used for creating or editing a Browser Storage Rules
     previewOptions: {
          eventOptions: {
               listenerId: '',
               events: [],
          }, // Used in Preview Custom Variable Rules
          eventNativeOptions: {
               listenerId: '',
               eventsNative: [],
          },
          nativeBrowserStorageOptions: {
               listenerId: '',
               nativeBrowserStorage: [],
          },
          variableOptions: {
               listenerId: '',
               variables: [],
          }, // Used in Preview Custom Variable Rules
          selectorOptions: {
               listenerId: '',
               variables: [],
          }, // Used in Preview Custom Variable Rules
          customVariableOptions: [], // Used in Custom Variable Rules
          browserVariableOptions: [], // Used for creating or editing a Browser Storage Rules
     },
     limitationType: '', // Type of limitation subscribers've hit when they go to a specific page
     showHamburgerIcon: false, // Hamburger icon on the top right
     events: {
          listenerId: '',
          listEvents: [],
     }, // Used in Event Reporting
     activeFeatures: [], // Number of active features of active listeners
     customBrowserVariables: {
          initialVariables: [],
          editedVariables: [], // Edited browser variables from the Initial
          addedVariables: [], // New browser variables from the Initial
          listDisabled: [], // Status of fields
          numOfVariables: 0,
     }, // Used in Custom Data Layer browser variables
     browserRules: null, // Used for Browser Storage Rules
     initialBrowserRules: null, //For reordering Browser Storage Rules
     populateRules: [], // Used for Populate Rules
     initialPopulateRules: [], //For reordering Browser Storage Rules
     monitoringRules: null, // Used for Event Monitoring Rules
     listRuleConditions: [],
     toastIsOpen: false,
     filterShowEnabledFeatures: {}, // { 'featureId' : true/false },
     automaticVariableWarning: { listenerId: '', show: false }, // Used for showing automatic variable warning of rules
     selectorValues: {
          listenerId: '',
          initialSelectorValues: [],
          addedSelectorValues: [],
          editedSelectorValues: [],
          selectorValuesStatus: [],
     },
     unsavedLookupTableModal: {
          unsaved: false,
          show: false,
          onAccept: null,
     },
     tempPublishData: {},
     myCreatedTickets: [],
     otherTickets: [], // Other tickets the current user can see beside user's created tickets
     ticket: {}, // The ticket is being viewed
     listenerInTickets: {},
     numberOfCommentsTickets: 0,
     ticketInit: {
          ticketStep: null,
          ticketType: null,
          hideListenerSelect: false,
     },
     accountRedeemedCoupons: [],
     userWeatherTemperature: null,
     customFormTargetRules: null,
     clickRules: [],
     initialClickRules: [],
     monitoringNotifications: null,
     isFromGetStarted: false,
     consentRules: [],
     initialConsentRules: [],
     checkedItemsByPage: [],
     searchKey: '',
     currentPage: 1,
     triggerRules: [],
     initialTriggerRules: [],
     reloadTriggerRules: false,
     reloadUserSource: false,
     conversionRules: [],
     initialConversionRules: [],
     reloadConversionRules: false,
     reloadDestinationConversions: false,
     reloadDestinationEcommerce: false,
     reloadLookupTable: false,
     dataActionRules: [],
     dataActions: {
          rules: [],
          initialRules: [],
          destinationId: '',
          loading: true,
          reload: false,
     },
     triggersNameOptions: {
          ruleId: '',
          triggersName: [],
     },
     gtmWizard: { ...INITIAL_GTM_WIZARD },
     gtmVariablesInLL: null,
     gtmTriggersInLL: null,
     gtmTagsInLL: null,
     gtmChangelog: null,
     previewBrowserRule: {
          showPopupConfirm: false,
          showPopup: false,
          selectedListener: false,
          storageType: '',
          storageData: null,
          showPopupBrowserVariable: false,
     },
     scheduleUpgrade: false,
     previewCustomRule: {
          showPopupConfirm: false,
          showPopup: false,
          selectedListener: false,
          eventCode: '',
          ruleType: 'Simple Rule',
          customValueData: null,
          showPopupCustomVariable: false,
     },
     initialPersonalData: null,
     customDestinationModal: { ...INITIAL_CUSTOM_DESTINATION_MODAL },
     switchTypeWizard: { ...INITIAL_SWITCH_TYPE_WIZARD },
     isShowStepFormCategory: false,
     isShowStepCustomForm: false,
     manageGoalsTypes: {
          ecommerce: false,
     },
     setShowRoadmapMininize: false,
     isShowCustomForm: false,
     isshowCategoriesForm: false,
     scrollbars: {
          scrollDistance: 0,
          isHandleScroll: false,
          showScrollDown: false,
          showScrollTop: false,
     },
     skipWizardFormCategory: false,
     activeListenerWizard: false,
     isRoadmap: false,
     showTourFromRoadMap: false,
     stepNotShowAnimationRoadmap: true,
     showTourCompletedWizard: {
          wizardFormCategory: false,
          wizardCustomForm: false,
     },
     fewFormsEdit: [],
     formsRequiring: null,
     allFormsRequiring: null,
     formRequiringEdit: [],
     requiringNumberPerpage: 0,
     requiringShowPaging: true,
     requiringCountLoading: false,
     requiringSettingsLoading: false,
     requiringLoading: false,
     requiringFormsLoading: false,
     requiringDisableNextPage: false,
     formIgnored: null,
     allFormsIgnored: null,
     formIgnoreEdit: [],
     ignoredNumberPerpage: 0,
     ignoredShowPaging: true,
     ignoredCountLoading: false,
     ignoredLoading: false,
     ignoredPagingLoading: false,
     ignoredFormsLoading: false,
     ignoredDisableNextPage: false,
     formInventory: null,
     allFormsInventory: null,
     formInventoryEdit: [],
     inventoryNumberPerpage: 0,
     inventoryShowPaging: true,
     inventoryCountLoading: false,
     inventoryLoading: false,
     inventoryFormsLoading: false,
     inventoryDisableNextPage: false,
     countFormRequiring: 0,
     countFormIgnored: 0,
     countFormInventory: 0,
     openFromConfigConversion: false,
     showPopupConversion: '',
     currentStepConversion: '',
     customCategories: null,
     skipFormListener: '',
     filterFormCategories: [],
     filterEditFormCategories: [{ type: '', operator: 'ct', value: '' }],
     elementClickTargetRules: null,
     formDraft: [],
     listChannels: null,
     customUserSources: null,
     listUserSources: null,
     monitoringFormCategory: {},
     nativeECommerce: false,
     dateRangeReports: {},
     reloadReportData: {
          tableName: '',
          tableNameCompare: '',
          reportName: '',
     },
     conversionsNameOptions: {
          ruleId: '',
          conversionsName: [],
     },
     listSegment: [],
     listSegmentReport: [],
     listSegmentWaitingQueue: false,
     listSegmentApplyReload: false,
     salesforceRuleLoading: false,
     salesforceRulePagingLoading: false,
     salesforceRuleTotal: 0,
     salesforceRuleActivePage: 1,
     accountPastDue: {
          status: false,
          title: 'Failed Payment',
          message: '',
          buttonText: 'Fix Now',
          function: 'goToAccountSetting',
          showUpdatePaymentPopup: false,
     },
     accountExternalData: {
          salesforceAccounts: [],
          salesforceRules: [],
          googleAdsAccounts: {},
     },
};

const INITIAL_STATE = {
     preview: {
          show: false,
          domain: '',
          domains: [],
          initDomains: [],
          isDisable: true,
          running: false,
          listenerId: '',
          accountId: '',
          noticeType: '',
          accountWarning: false,
          iframeLoading: true,
          browserStorageKey: {
               cookie: [],
               session: [],
               localStorage: [],
          },
          iframePageLoading: false,
          activeListener: null,
     },
     showIframe: false,
     domain: false,
     showPopupDomain: false,
     showPopupVariable: false,
     listFeatureCustomForms: [
          {
               name: 'customFormSubmitted',
               fired: false,
          },
          {
               name: 'customFormLoaded',
               fired: false,
          },
          {
               name: 'customFormValidationFailed',
               fired: false,
          },
     ],
     newCustomVariable: {
          variable: '',
          typeVariable: '',
          show: false,
     },
     showPopupBrowserVariable: false,
     showConfigurationRequired: {
          show: false,
          listener: '',
     },
     showConfirmAddScript: {
          show: false,
          listener: '',
     },
     confirmAddScript: {
          confirm: false,
          optionListenerConfirm: [],
     },
     accounts: [], // Used for dropdown accounts on the header
     activeAccount: {
          id: '',
     },
     routes: [],
     accountListeners: [], // Active account's listeners
     accountDestinations: [], // Active account's listeners
     accountDestination: null,
     activeListeners: [],
     activeDestinations: [],
     activeDestination: null,
     activeListener: null,
     packages: [],
     nextPackageIndex: 0, // Index of next package
     maxPackage: false, // This account reaches to the highest package price
     versionPublish: {
          version: '',
          isPublish: 'disable',
     },
     numberOfHistories: 0,
     lookupRuleActiveStep: null,
     loadings: {
          accounts: true,
          listeners: true,
          account: false,
          customVariableRules: true,
          browserRules: true,
          monitoringRules: true,
          populateRules: true,
          consentRules: true,
          wholePageOverlay: true,
          triggerRules: true,
          conversionRules: true,
          destinations: true,
          gtmToken: false,
     },
     versions: [],
     showCreateAccountPopup: false,
     showUpgradeAccountPopup: false,
     showUpgradeAccountSchedulePopup: false,
     showComparePlansPopup: false,
     showBlockAccountPopup: false,
     showBlockSetupGuidePopup: false,
     accountPastDue: {
          status: false,
          title: 'Failed Payment',
          message: '',
          buttonText: 'Fix Now',
          function: 'goToAccountSetting',
          showUpdatePaymentPopup: false,
     },
     invoices: [],
     automaticVariableReload: false,
     selectorVariableReload: false,
     nativeBrowserAccountFeature: [],
     globalReporting: {
          listenerNeedLoaded: '',
     },
     editingRuleReload: false,
     asideLimitationHit: false, // Change this variable to make the aside show limitation hit component
     showEventReportingWarning: false, // Show warning about event when a user is in reporting page of a listener,
     estimatedTotalEvents: 0,
     userTerms: [],
     termPublishes: [],
     termAndConditionPopup: false,
     isCustomerSupportLoginAs: false,
     showMasterclassAside: false,
     buyOfficeHours: false,
     masterclassCourse: {},
     masterclassCertification: null,
     officeHour: {},
     availableHours: [], // Available office hours
     officeHourCalendar: {
          timeZone: '',
          freeHoursOfDates: [],
     },
     reloadAvailableHours: false,
     lessonVideoRef: null,
     reloadPartner: false,
     reloadOfficeHourCalendar: false,
     reloadCustomFormRules: false,
     reloadElementClickRules: false,
     monitoringNotifications: null,
     showArchiveNotifications: false,
     showPublishPopup: false,
     showGetHelpLabel: false,
     gtmContainers: [],
     checkedItemsByPage: [],
     searchKey: '',
     consentListener: null,
     newLayout: {
          showNewLayout: true,
          activeMainNavItem: '',
          minimizeNav: false,
          showNavChild: false,
          activeSubNavItem: '',
          forceShowNavChild: false,
     },
     accordion: '',
     showPublishConfirmPopup: false,
     showPublishPreviewPopup: false,
     tour: {
          stepTour: 0,
          showTour: false,
     },
     createdAccount: null,
     signUpStep: {
          currentStep: null,
          previousStep: null,
     },
     gtmAccounts: [],
     listManagerBillingRight: [],
     triggerEventEcommerce: [],
     engagementRules: [],
     showAccountDormantPopup: false,
     dormantAccount: null,
     stepFormCategories: STEPS_WIZARD_CATEGORIES.defineYourFormCategories,
     listenerFormsEnabled: [],
     stepCustomForms: STEPS_CUSTOM_FORM.configureCustomForms,
     dateRangeReports: {},
     dateRangeRecord: {},
     showIgnoredForm: false,
     showIgnoredInventoryForm: false,
     widthColumnReports: {},
     completeStepSetupGuide: [],
     setupGuideDataCompleted: null,
     set_setup_guide_roadmap: -1,
     setShowVideo: {
          installAndLearn: false,
          learnGoals: false,
          PreviewVisualize: false,
          enrichData: false,
          customWebData: false,
          learnConsent: false,
          GA4: false,
          FBAds: false,
          GGAds: false,
          LinkedinAds: false,
          MicrosoftAds: false,
     },
     reduxActiveDropdown: '',
     formChildId: null,
     maxStepForm: null,
     maxStepRecordForm: null,
     accountExternalData: {
          salesforceAccounts: [],
          salesforceRules: [],
          googleAdsAccounts: {},
     },
     showHistoricalPopup: false,
     dataHistoricalSalesforce: {},
     listBlockTriggers: [],
     reloadAccountSalesforce: false,
     ...STATE_TO_RESET,
     loadingFormIgnore: false,
};

const subscriber = (state = INITIAL_STATE, action) => {
     switch (action.type) {
          case SET_STEP_NOT_SHOW_ANIMATION_ROADMAP:
               return { ...state, stepNotShowAnimationRoadmap: action.payload };
          case SET_SUBSCRIBER_STATE:
               return { ...state, ...action.payload };
          case SET_ACCOUNTS:
               return { ...state, accounts: action.payload };
          case SET_ACTIVE_ACCOUNT:
               return { ...state, activeAccount: action.payload };
          case SET_USED_ITEMS:
               const { itemName, quantity } = action.payload;
               let newActiveAccount = { ...state.activeAccount };
               newActiveAccount[itemName] = quantity;
               return { ...state, activeAccount: { ...newActiveAccount } };
          case SET_ROUTES:
               return { ...state, routes: action.payload };
          case SET_ACCOUNT_LISTENERS:
               return { ...state, accountListeners: action.payload };
          case SET_ACTIVE_LISTENERS:
               return { ...state, activeListeners: action.payload };
          case SET_ACTIVE_LISTENER:
               return { ...state, activeListener: action.payload };
          case SET_ACCOUNT_DESTINATION:
               return { ...state, accountDestination: action.payload };
          case SET_DOMAINS:
               return { ...state, domains: action.payload };
          case SET_DISABLE:
               return { ...state, disable: action.payload };
          case SET_OWNER:
               return { ...state, owner: action.payload };
          case SET_MANAGERS:
               return { ...state, managers: action.payload };
          case SET_PACKAGES:
               return { ...state, packages: action.payload };
          case SET_MAX_PACKAGE:
               return { ...state, maxPackage: action.payload };
          case SET_NEXT_PACKAGE_INDEX:
               return { ...state, nextPackageIndex: action.payload };
          case SET_LIST_CUSTOM_VARIABLES:
               return { ...state, customVariables: action.payload };
          case SET_LIST_BROWSER_VARIABLES:
               return { ...state, customBrowserVariables: action.payload };
          case SET_FEATURES:
               return { ...state, features: action.payload };
          case SET_CUSTOM_VARIABLE_RULES:
               return { ...state, customVariableRules: action.payload };
          case SET_USER_SOURCE_RULES:
               return { ...state, userSourceRules: action.payload };
          case SET_INITIAL_USER_SOURCE_RULES:
               return { ...state, initialUserSourceRules: action.payload };
          case SET_TRIGGER_RULES:
               return { ...state, triggerRules: action.payload };
          case SET_TRIGGERS_NAME_RULES:
               return { ...state, triggersNameOptions: action.payload };
          case SET_INITIAL_TRIGGER_RULES:
               return { ...state, initialTriggerRules: action.payload };
          case SET_CONVERSION_RULES:
               return { ...state, conversionRules: action.payload };
          case SET_INITIAL_CONVERSION_RULES:
               return { ...state, initialConversionRules: action.payload };
          case SET_DATA_ACTIONS_RULES:
               return { ...state, dataActions: { ...state.dataActions, ...action.payload } };
          case SET_ALL_DATA_ACTION_RULES:
               return { ...state, dataActionRules: action.payload };
          case SET_INITIAL_CUSTOM_VARIABLE_RULES:
               return { ...state, initialCustomVariableRules: action.payload };
          case SET_REDIRECT_RULES:
               return { ...state, redirectRules: action.payload };
          case SET_LISTENER_EVENTS:
               return { ...state, listenerEvents: action.payload };
          case SET_LISTENER_EVENTS_LOADING:
               return { ...state, listenerEventsLoading: action.payload };
          case SET_LISTENER_EVENTS_NATIVE:
               return { ...state, listenerEventsNative: action.payload };
          case SET_NATIVE_BROWSER_STORAGE:
               return { ...state, nativeBrowserStorage: action.payload };
          case SET_LISTENER_VARIABLES:
               return { ...state, listenerVariables: action.payload };
          case SET_LISTENER_SELECTORS:
               return { ...state, listenerSelectors: action.payload };
          case SET_VIDEO_PROGRESSES:
               return { ...state, videoProgresses: action.payload };
          case SET_VIDEO_ENGAGEMENTS:
               return { ...state, videoEngagements: action.payload };
          case SET_EVENT_OPTIONS:
               return { ...state, eventOptions: action.payload };
          case SET_CUSTOM_REPORT:
               return { ...state, customReport: { ...state.customReport, ...action.payload } };
          case SET_SAVED_REPORT:
               let updatedReports = [...state.savedReport];

               if (Array.isArray(action.payload)) {
                    updatedReports = action.payload;
               } else {
                    const { id, deleted } = action.payload;

                    if (deleted) {
                         updatedReports = updatedReports.filter((_report) => _report.id !== id);
                    } else {
                         const reportIndex = updatedReports.findIndex((_report) => _report.id === id);

                         if (reportIndex > -1) {
                              updatedReports[reportIndex] = action.payload;
                         } else {
                              updatedReports.unshift(action.payload);
                         }
                    }
               }

               return { ...state, savedReport: updatedReports };
          case SET_NATIVE_ECOMMERCE:
               return { ...state, nativeECommerce: action.payload };
          case SET_ACROSS_DOMAINS:
               return { ...state, acrossDomains: action.payload };
          case SET_CLICK_IDS:
               return { ...state, clickIds: { ...state.clickIds, ...action.payload } };
          case SET_EVENT_NATIVE_OPTIONS:
               return { ...state, eventNativeOptions: action.payload };
          case SET_NATIVE_BROWSER_STORAGE_OPTIONS:
               return { ...state, nativeBrowserStorageOptions: action.payload };
          case SET_EVENT_CHART:
               return { ...state, eventChart: action.payload };
          case SET_CHART_OPTIONS:
               return { ...state, chartOptions: action.payload };
          case SET_ALL_EVENTS:
               return { ...state, events: action.payload };
          case SET_VARIABLE_OPTIONS:
               return { ...state, variableOptions: action.payload };
          case SET_SELECTOR_OPTIONS:
               return { ...state, selectorOptions: action.payload };
          case SET_CUSTOM_VARIABLE_OPTIONS:
               return { ...state, customVariableOptions: action.payload };
          case SET_BROWSER_VARIABLE_OPTIONS:
               return { ...state, browserVariableOptions: action.payload };
          case SET_VERSION_PUBLISH:
               // if (state.versionPublish.isPublish !== 'publishing') {

               // }
               return { ...state, versionPublish: action.payload };
          case SET_VERSIONS:
               return { ...state, versions: action.payload };
          case SET_INCREMENT_NUMBER_EVENT:
               return { ...state, activeAccount: { ...state.activeAccount } };
          case SET_NUMBER_HISTORIES:
               return { ...state, numberOfHistories: action.payload };
          case LOAD_VERSION_PUBLISHING:
               let newVersions = [...state.versions];
               newVersions[0] = action.payload;
               return { ...state, versions: newVersions };
          case SET_PUBLISHED_VERSION:
               let versions = [...state.versions];
               for (var j = 0; j < versions.length; j++) {
                    if (versions[j].id === action.payload.id) {
                         versions[j] = action.payload;
                         break;
                    }
               }
               let isPublish = state.versionPublish.version ? 'enable' : 'disable';
               return { ...state, versions: versions, versionPublish: { ...state.versionPublish, isPublish } };
          case SET_LOOKUP_RULE_ACTIVE_STEP:
               return { ...state, lookupRuleActiveStep: action.payload };
          case SET_LIMITATION_TYPE:
               return { ...state, limitationType: action.payload };
          case RESET_LIMITTAION_TYPE:
               return { ...state, limitationType: '' };
          case SET_SHOW_HAMBURGER_ICON:
               return { ...state, showHamburgerIcon: action.payload };
          case SET_SHOW_CREATE_ACCOUNT_POPUP:
               return { ...state, showCreateAccountPopup: action.payload };
          case SET_SHOW_ACCOUNT_DORMANT_POPUP:
               return { ...state, showAccountDormantPopup: action.payload };
          case SET_SHOW_UPGRADE_ACCOUNT_POPUP:
               return { ...state, showUpgradeAccountPopup: action.payload };
          case SET_SHOW_BLOCK_SETUP_GUIDE_POPUP:
               return { ...state, showBlockSetupGuidePopup: action.payload };
          case SET_SHOW_UPGRADE_ACCOUNT_SCHEDULE_POPUP:
               return { ...state, showUpgradeAccountSchedulePopup: action.payload };
          case SET_SHOW_COMPARE_PLANS_POPUP:
               return { ...state, showComparePlansPopup: action.payload };
          case SET_SHOW_BLOCK_ACCOUNT_POPUP:
               return { ...state, showBlockAccountPopup: action.payload };
          case SET_ACCOUNT_PASTDUE:
               return { ...state, accountPastDue: { ...state.accountPastDue, ...action.payload } };
          case SET_LOADINGS:
               return {
                    ...state,
                    loadings: { ...state.loadings, ...action.payload },
               };
          case SET_TOAST_IS_OPEN:
               return { ...state, toastIsOpen: action.payload };
          case SET_FILTER_SHOW_ENABLED_FEATURES:
               return { ...state, filterShowEnabledFeatures: action.payload };
          case SET_INVOICES:
               return { ...state, invoices: action.payload };
          case SET_AUTOMATIC_VARIABLE_RELOAD:
               return { ...state, automaticVariableReload: action.payload };
          case SET_SELECTOR_VARIABLE_RELOAD:
               return { ...state, selectorVariableReload: action.payload };
          case SET_GLOBAL_REPORTING:
               return { ...state, globalReporting: action.payload };
          case SET_ACTIVE_FEATURES:
               return { ...state, activeFeatures: action.payload };
          case SET_EDITING_RULE_RELOAD:
               return { ...state, editingRuleReload: action.payload };
          case SET_BROWSER_RULES:
               return { ...state, browserRules: action.payload };
          case SET_MONITORING_RULES:
               return { ...state, monitoringRules: action.payload };
          case SET_POPULATE_RULES:
               return { ...state, populateRules: action.payload };
          case TOGGLE_ASIDE_LIMITATION_HIT:
               return { ...state, asideLimitationHit: !state.asideLimitationHit };
          case SET_SHOW_EVENT_REPORTING_WARNING:
               return { ...state, showEventReportingWarning: action.payload };
          case SET_EVENT_TABLE:
               return { ...state, eventTable: { ...state.eventTable, ...action.payload } };
          case SET_EVENT_TABLE_OF_LISTENERS:
               return { ...state, eventTableOfListeners: { ...state.eventTableOfListeners, ...action.payload } };
          case SET_VARIABLE_VALUE_CHART:
               return { ...state, variableValueChart: action.payload };
          case SET_AUTOMATIC_VARIABLE_WARNING:
               return { ...state, automaticVariableWarning: action.payload };
          case SET_ESTIMATED_TOTAL_EVENTS:
               return { ...state, estimatedTotalEvents: action.payload };
          case SET_SELECTOR_VALUES:
               return { ...state, selectorValues: action.payload };
          case SET_UNSAVED_LOOKUP_TABLE_MODAL:
               return { ...state, unsavedLookupTableModal: { ...state.unsavedLookupTableModal, ...action.payload } };
          case SAVE_TEMP_PUBLISH_DATA:
               return {
                    ...state,
                    tempPublishData: {
                         numberOfHistories: state.numberOfHistories,
                         versions: state.versions,
                         versionPublish: state.versionPublish,
                    },
               };
          case REVERT_PUBLISH_DATA:
               // Only revert publish data for the user who published this account
               if (state.tempPublishData.numberOfHistories !== state.numberOfHistories) {
                    return { ...state, ...state.tempPublishData };
               }

               return { ...state };
          case SET_USER_TERMS:
               return { ...state, userTerms: action.payload };
          case SET_TERM_PUBLISH:
               return { ...state, termPublishes: action.payload };
          case SET_NATIVE_BROWSER_ACCOUNT_FEATURES:
               return { ...state, nativeBrowserAccountFeature: action.payload };
          case SET_IS_CUSTOMER_SUPPORT_LOGIN_AS:
               return { ...state, isCustomerSupportLoginAs: action.payload };
          case SET_MY_CREATED_TICKETS:
               return { ...state, myCreatedTickets: action.payload };
          case SET_OTHER_TICKETS:
               return { ...state, otherTickets: action.payload };
          case SET_SUBSCRIBER_TICKET:
               return { ...state, ticket: action.payload };
          case RESET_SUBSCRIBER_REDUCER:
               return { ...state, ...STATE_TO_RESET };
          case PUSHER_INCREASE_NUMBER_OF_HISTORIES:
               return { ...state, numberOfHistories: state.numberOfHistories + 1 };
          case SET_LISTENERS_IN_TICKET:
               return { ...state, listenerInTickets: action.payload };
          case SET_NUMBER_OF_COMMENT_TICKETS:
               return { ...state, numberOfCommentsTickets: action.payload };
          case SET_TICKET_INIT:
               return { ...state, ticketInit: { ...state.ticketInit, ...action.payload } };
          case SET_SHOW_MASTERCLASS_ASIDE:
               return { ...state, showMasterclassAside: action.payload };
          case SET_BUY_OFFICE_HOURS:
               return { ...state, buyOfficeHours: action.payload };
          case SET_MASTERCLASS_COURSE:
               return { ...state, masterclassCourse: action.payload };
          case SET_MASTERCLASS_CERTIFICATION:
               return { ...state, masterclassCertification: action.payload };
          case SET_OFFICE_HOUR:
               return { ...state, officeHour: action.payload };
          case SET_AVAILABLE_HOURS:
               return { ...state, availableHours: action.payload };
          case SET_ACCOUNT_REDEEMED_COUPONS:
               return { ...state, accountRedeemedCoupons: action.payload };
          case SET_OFFICE_HOUR_CALENDAR:
               return { ...state, officeHourCalendar: action.payload };
          case SET_LESSON_VIDEO_REF:
               return { ...state, lessonVideoRef: action.payload };
          case SET_DATA_WEATHER_TEMPERATURE:
               return { ...state, userWeatherTemperature: action.payload };
          case SET_CUSTOM_FORM_TARGET_RULES:
               return { ...state, customFormTargetRules: action.payload };
          case SET_ELEMENT_CLICK_TARGET_RULES:
               return { ...state, elementClickTargetRules: action.payload };
          case SET_CLICK_RULES:
               return { ...state, clickRules: action.payload };
          case SET_INITIAL_CLICK_RULES:
               return { ...state, initialClickRules: action.payload };
          case SET_MONITORING_NOTIFICATIONS:
               return { ...state, monitoringNotifications: action.payload };
          case SET_SHOW_ARCHIVE_NOTIFICATIONS:
               return { ...state, showArchiveNotifications: action.payload };
          case SET_VISITOR_SESSIONS_UNIQUE:
               return { ...state, visitorsSessionsUnique: action.payload };
          case SET_SHOW_PUBLISH_POPUP:
               return { ...state, showPublishPopup: action.payload };
          case SET_SHOW_GET_HELP_LABEL:
               return { ...state, showGetHelpLabel: action.payload };
          case SET_GTM_CONTAINERS:
               return { ...state, gtmContainers: action.payload };
          case SET_IS_FROM_GET_STARTED:
               return { ...state, isFromGetStarted: action.payload };
          case SET_CONSENT_LISTENER_ACCOUNT:
               return { ...state, activeAccount: { ...state.activeAccount, ...action.payload } };
          case SET_CONSENT_RULES_ACCOUNT:
               return { ...state, consentRules: action.payload };
          case SET_SEARCH_KEY:
               return { ...state, searchKey: action.payload };
          case SET_DOMAINS_PREVIEW:
               return {
                    ...state,
                    preview: {
                         ...state.preview,
                         initDomains: action.payload,
                         domains: action.payload.map((domain) => ({
                              label: domain.name,
                              value: domain.name,
                              scriptInstalled: domain.scriptInstalled,
                              isIframeBlocked: domain.isIframeBlocked,
                              isCSPBlocked: domain.isCSPBlocked,
                         })),
                    },
               };
          case SET_DOMAIN_PREVIEW:
               return { ...state, preview: { ...state.preview, domain: action.payload, isDisable: false } };
          case SET_TOGGLE_PREVIEW_POPUP:
               if (action.payload) {
                    return { ...state, preview: { ...state.preview, show: action.payload, running: true } };
               }

               return { ...state, preview: { ...state.preview, show: action.payload } };
          case SET_TURNOFF_PREVIEW_POPUP:
               return {
                    ...state,
                    preview: {
                         ...state.preview,
                         show: false,
                         running: false,
                         listenerId: '',
                         accountId: '',
                    },
               };
          case SET_POPUP_DOMAIN_PREVIEW:
               return { ...state, showPopupDomain: action.payload };
          case SET_POPUP_CREATE_VARIABLE:
               return { ...state, showPopupVariable: action.payload };
          case SET_NEW_CUSTOM_VARIABLE:
               return { ...state, newCustomVariable: action.payload };
          case SET_POPUP_CREATE_BROWSER_VARIABLE:
               return { ...state, showPopupBrowserVariable: action.payload };
          case SET_POPUP_CONFIGURATION_REQUIRED:
               return { ...state, showConfigurationRequired: action.payload };
          case SET_POPUP_CONFIRM_ADD_SCRIPT:
               return { ...state, showConfirmAddScript: action.payload };
          case SET_CONFIRM_ADD_SCRIPT:
               return { ...state, confirmAddScript: action.payload };
          case SET_LISTENER_PREVIEW:
               return { ...state, preview: { ...state.preview, listenerId: action.payload } };
          case SET_ACCOUNT_PREVIEW:
               return { ...state, preview: { ...state.preview, ...action.payload } };
          case SET_NEW_LAYOUT:
               return { ...state, newLayout: { ...state.newLayout, ...action.payload } };
          case SET_PREVIEW_OPTIONS:
               return { ...state, previewOptions: { ...state.previewOptions, ...action.payload } };
          case SET_GTM_WIZARD:
               if (action.payload.show) {
                    return { ...state, gtmWizard: { ...INITIAL_GTM_WIZARD, key: guidGenerator(), ...action.payload } };
               }
               return { ...state, gtmWizard: { ...state.gtmWizard, ...action.payload } };
          case SET_SWITCH_TYPE_WIZARD:
               if (action.payload.show) {
                    return {
                         ...state,
                         switchTypeWizard: {
                              ...INITIAL_SWITCH_TYPE_WIZARD,
                              usingType: state.switchTypeWizard.usingType,
                              key: guidGenerator(),
                              ...action.payload,
                         },
                    };
               }
               return { ...state, switchTypeWizard: { ...state.switchTypeWizard, ...action.payload } };
          case RESET_PREVIEW:
               return { ...state, preview: INITIAL_STATE.preview };
          case SET_GTM_VARIABLES_IN_LL:
               return { ...state, gtmVariablesInLL: action.payload };
          case SET_GTM_TRIGGERS_IN_LL:
               return { ...state, gtmTriggersInLL: action.payload };
          case SET_GTM_TAGS_IN_LL:
               return { ...state, gtmTagsInLL: action.payload };
          case SET_GTM_CHANGELOG:
               return { ...state, gtmChangelog: action.payload };
          case SET_TOUR:
               return { ...state, tour: { ...state.tour, ...action.payload } };
          case SET_CREATED_ACCOUNT:
               return { ...state, createdAccount: action.payload };
          case SET_SIGN_UP_STEP:
               return { ...state, signUpStep: { ...state.signUpStep, ...action.payload } };
          case SET_GTM_ACCOUNTS:
               return { ...state, gtmAccounts: action.payload };
          case SET_INITIAL_PERSONAL_DATA:
               return { ...state, initialPersonalData: action.payload };
          case SET_DORMANT_ACCOUNT:
               return { ...state, dormantAccount: action.payload };
          case RELOAD_DATA_ACTIONS:
               return { ...state, dataActions: { ...state.dataActions, reload: !state.dataActions.reload } };
          case SET_PREVIEW_BROWSER_RULE:
               return { ...state, previewBrowserRule: { ...state.previewBrowserRule, ...action.payload } };
          case SET_PREVIEW_CUSTOM_RULE:
               return { ...state, previewCustomRule: { ...state.previewCustomRule, ...action.payload } };
          case SET_ENGAGEMENT_RULES:
               return { ...state, engagementRules: action.payload };
          case RELOAD_TRIGGER_RULES:
               return { ...state, reloadTriggerRules: !state.reloadTriggerRules };
          case RELOAD_USER_SOURCE:
               return { ...state, reloadUserSource: !state.reloadUserSource };
          case RELOAD_CONVERSION_RULES:
               return { ...state, reloadConversionRules: !state.reloadConversionRules };
          case RELOAD_DESTINATION_CONVERSIONS:
               return { ...state, reloadDestinationConversions: !state.reloadDestinationConversions };
          case RELOAD_DESTINATION_ECOMMERCE:
               return { ...state, reloadDestinationEcommerce: !state.reloadDestinationEcommerce };
          case RELOAD_LOOKUP_TABLE:
               return { ...state, reloadLookupTable: !state.reloadLookupTable };
          case SET_CUSTOM_DESTINATION_MODAL:
               if (action.payload.show) {
                    return { ...state, customDestinationModal: { ...INITIAL_CUSTOM_DESTINATION_MODAL, key: guidGenerator(), ...action.payload } };
               }
               return { ...state, customDestinationModal: { ...state.customDestinationModal, ...action.payload } };
          case PUSHER_INVITE_MANAGER_USER:
               return { ...state, ...subscriberPusher.inviteManagerUser(state, action.payload) };
          case CRAWL_FROM_ACTION:
               return { ...state, ...subscriberPusher.crawlForm(state, action.payload) };
          case PUSHER_REMOVE_MANAGER_USER:
               return { ...state, ...subscriberPusher.removeManagerUser(state, action.payload) };
          case PUSHER_CREATE_NEW_USER_MANAGER:
               return { ...state, ...subscriberPusher.createNewUserManager(state, action.payload) };
          case PUSHER_CHANGE_LISTENER_STATUS:
               return { ...state, ...subscriberPusher.changeListenerStatus(state, action.payload) };
          case PUSHER_CHANGE_FEATURE_STATUS:
               return { ...state, ...subscriberPusher.changeFeatureStatus(state, action.payload) };
          case PUSHER_CHANGE_CHILD_FEATURE_STATUS:
               return { ...state, ...subscriberPusher.changeChildFeatureStatus(state, action.payload) };
          case PUSHER_ADD_RULE:
               return { ...state, ...subscriberPusher.addRule(state, action.payload) };
          case PUSHER_CONSENT_RULE_CHANGED:
               return { ...state, ...subscriberPusher.consentRulesCreated(state, action.payload) };
          case PUSHER_UPDATE_RULE:
               return { ...state, editingRuleReload: !state.editingRuleReload };
          case PUSHER_UPDATE_RULE_STATUS:
               return { ...state, ...subscriberPusher.updateRuleStatus(state, action.payload) };
          case PUSHER_REMOVE_RULE:
               return { ...state, ...subscriberPusher.removeRule(state, action.payload) };
          case PUSHER_REORDER_RULES:
               return { ...state, ...subscriberPusher.reorderRule(state, action.payload) };
          case PUSHER_CHANGE_ACCOUNT_NAME:
               return { ...state, ...subscriberPusher.changeAccountName(state.accounts, action.payload) };
          case PUSHER_CHANGE_ACCOUNT_TIMESTAMPS:
               return { ...state, ...subscriberPusher.changeAccountTimestamps(state.accounts, action.payload) };
          case PUSHER_UPGRADE_OR_DOWNGRADE_ACCOUNT:
               return { ...state, ...subscriberPusher.upgradeOrDowngradeAccount(state.accounts, action.payload) };
          case PUSHER_UPDATE_INVOICE_INFORMATION:
               return { ...state, activeAccount: { ...state.activeAccount, ...action.payload } };
          case PUSHER_SAVE_CREDIT_CARD:
               return { ...state, activeAccount: { ...state.activeAccount, ...action.payload } };
          case PUSHER_SAVE_CARD_TO_CHANGE:
               return { ...state, ...subscriberPusher.saveCardToChange(state.accounts, action.payload) };
          case PUSHER_INVOICE_FINALIZATION:
               return { ...state, ...subscriberPusher.invoiceFinalization() };
          case PUSHER_CHANGE_ACCOUNT_EVENTS:
               return { ...state, ...subscriberPusher.changeAccountEvents(state, action.payload) };
          case PUSHER_CHANGE_ACCOUNT_VARIABLES:
               return { ...state, ...subscriberPusher.changeAccountVariables(state, action.payload) };
          case PUSHER_CREATE_INVOICES:
               return { ...state, ...subscriberPusher.createInvoices(state, action.payload) };
          case PUSHER_UPDATE_INVOICE:
               return { ...state, ...subscriberPusher.updateInvoice(state, action.payload) };
          case PUSHER_UPDATE_ACCOUNT:
               return { ...state, ...subscriberPusher.updateAccount(state, action.payload) };
          case PUSHER_UPDATE_FEATURE_SUPPORT_CHILD:
               return { ...state, ...subscriberPusher.updateFeatureSupportChild(state, action.payload) };
          case PUSHER_UPDATE_FEATURE_SUPPORT_UPDATE_VIDEO_PROGRESSES:
               return { ...state, ...subscriberPusher.updateFeatureSupportVideoProgresses(state, action.payload) };
          case PUSHER_RESET_FEATURE_SUPPORT:
               return { ...state, ...subscriberPusher.resetFeatureSupport(state, action.payload) };
          case PUSHER_INCREASE_NUMBER_OF_EVENTS:
               return { ...state, ...subscriberPusher.increaseNumberOfEvents(state, action.payload) };
          case PUSHER_CHANGE_EVENT_CHART:
               return { ...state, ...subscriberPusher.changeEventChart(state, action.payload) };
          case PUSHER_CHANGE_NUMBER_VISITORS_CHART:
               return { ...state, ...subscriberPusher.changeNumberOfVisitorChart(state, action.payload) };
          case PUSHER_CHANGE_NUMBER_SESSIONS_CHART:
               return { ...state, ...subscriberPusher.changeNumberOfSessionChart(state, action.payload) };
          case PUSHER_RESET_NUMBER_OF_EVENTS:
               return { ...state, activeAccount: subscriberPusher.resetNumberOfEvents(state.activeAccount) };
          case PUSHER_UPDATE_RULE_CONDITIONS:
               return { ...state, ...subscriberPusher.setListRuleConditions(state, action.payload) };
          case PUSHER_UPDATE_LIST_EVENTS:
               return { ...state, ...subscriberPusher.updateListEvents(state, action.payload) };
          case PUSHER_UPDATE_LIST_DATALAYER_VARIABLES:
               return { ...state, ...subscriberPusher.updateListDatalayerVariables(state, action.payload) };
          case PUSHER_UPDATE_USER_MANAGER_INFO:
               return { ...state, ...subscriberPusher.updateUserManagerInfo(state, action.payload) };
          case PUSHER_UPDATE_EVENT_TABLE:
               return { ...state, ...subscriberPusher.updateEventTable(state, action.payload) };
          case PUSHER_UPDATE_VARIABLE_VALUES:
               return { ...state, ...subscriberPusher.updateVariableValues(state, action.payload) };
          case PUSHER_UPDATE_SELECTOR_VARIABLES:
               return { ...state, ...subscriberPusher.updateSelectorVariables(state, action.payload) };
          case PUSHER_UPDATE_SELECTOR_VALUES:
               return { ...state, ...subscriberPusher.updateSelectorValues(state, action.payload) };
          case PUSHER_NATIVE_BROWSER_STORAGE_CHANGE:
               return { ...state, ...subscriberPusher.nativeBrowserStorageChange(state, action.payload) };
          case PUSHER_UPDATE_ACCOUNT_CONSOLE_STATUS:
               return { ...state, ...subscriberPusher.updateAccountConsoleStatus(state, action.payload) };
          case PUSHER_ADD_NEW_TICKET_COMMENT:
               return { ...state, ...subscriberPusher.addNewTicketComment(state, action.payload) };
          case PUSHER_RESET_CMT_NUMBER_OF_CLIENT:
               return { ...state, ...subscriberPusher.resetCmtNumberOfClient(state, action.payload) };
          case PUSHER_UPDATE_AVAILABLE_HOURS:
               return { ...state, reloadAvailableHours: !state.reloadAvailableHours };
          case PUSHER_SET_RELOAD_PARTNER:
               return { ...state, reloadPartner: !state.reloadPartner };
          case PUSHER_SET_RELOAD_OFFICE_HOUR_CALENDAR:
               return { ...state, ...subscriberPusher.reloadOfficeHourCalendar(state, action.payload) };
          // case PUSHER_UPDATE_SESSION_LENGTH:
          //      return { ...state, ...subscriberPusher.updateSessionLength(state, action.payload) };
          case PUSHER_UPDATE_PRECISE_LOCATION_CONFIG:
               return { ...state, ...subscriberPusher.updatePreciseLocationConfigV2(state, action.payload) };
          case PUSHER_UPDATE_LISTENER_FOR_ROUTES:
               return { ...state, ...subscriberPusher.updateListenerForRoutes(state, action.payload) };
          case PUSHER_UPDATE_PERSONAL_DATA:
               return { ...state, ...subscriberPusher.updatePersonalData(state, action.payload) };
          case PUSHER_UPDATE_CUSTOM_FORM_RULES:
               return { ...state, reloadCustomFormRules: !state.reloadCustomFormRules };
          case PUSHER_UPDATE_ELEMENT_CLICK_RULES:
               return { ...state, reloadElementClickRules: !state.reloadElementClickRules };
          case PUSHER_UPDATE_NOTIFICATION:
               return { ...state, ...subscriberPusher.updateNotification(state, action.payload) };
          case SET_CHECKED_ITEMS_BYPAGE:
               return { ...state, checkedItemsByPage: action.payload };
          case SET_CURRENT_PAGE:
               return { ...state, currentPage: action.payload };
          case PUSHER_SCHEDULE_UPGRADE:
               return { ...state, activeAccount: { ...state.activeAccount, isScheduleUpgrade: action.payload } };
          case PUSHER_CHANGE_VISITORS_AND_SESSIONS_PER_MONTH:
               return { ...state, ...subscriberPusher.changeVisitorsAndSessionsPerMonth(state, action.payload) };
          case PUSHER_INCREASE_NUMBER_OF_FAKE_VISITORS_BY_1:
               return { ...state, ...subscriberPusher.increaseNumberOfFakeVisitorsBy1(state) };
          case PUSHER_SET_ACCOUNT_COMPLETED_STEPS:
               return { ...state, activeAccount: { ...state.activeAccount, isComplete: true, completedSteps: action.payload } };
          case PUSHER_UPDATE_SEGMENT:
               return { ...state, ...subscriberPusher.updateSegment(state, action.payload) };     
          case SET_ACCORDION:
               return { ...state, accordion: action.payload };
          case SET_TRIGGER_EVENT_ECOMMERCE:
               return { ...state, triggerEventEcommerce: action.payload };
          case SET_SHOW_IGNORED_FORM:
               return { ...state, showIgnoredForm: action.payload };
          case SET_SHOW_IGNORED_INVENTORY_FORM:
               return { ...state, showIgnoredInventoryForm: action.payload };
          case SET_APPLY_CHILD:
               return { ...state, applyChild: action.payload };
          case SET_APPLY_REDIRECT:
               return { ...state, applyRedirect: action.payload };
          case SET_FORM_CATEGORY:
               return { ...state, formCategory: action.payload };
          case SET_FORM_REQUIRING_ACTION:
               return { ...state, formsRequiring: action.payload };
          case FORM_REQUIRING_SET_ALL_FORMS:
               return { ...state, allFormsRequiring: action.payload };
          case SET_COUNT_FORM_REQUIRING:
               return { ...state, countFormRequiring: action.payload };
          case SET_FORM_IGNORED:
               return { ...state, formIgnored: action.payload };
          case SET_COUNT_FORM_IGNORED:
               return { ...state, countFormIgnored: action.payload };
          case SET_FORM_INVENTORY:
               return { ...state, formInventory: action.payload };
          case FORM_CATEGORY_SET_NUMBER_OFFSET:
               return { ...state, numberOffsetFormInventory: action.payload };
          case SET_COUNT_FORM_INVENTORY:
               return { ...state, countFormInventory: action.payload };
          case SET_CUSTOM_CATEGORY:
               return { ...state, customCategories: action.payload };
          case SET_STEPS_WIZARD_CATEGORIES:
               return { ...state, stepFormCategories: action.payload };
          case SET_LISTENER_FORMS_ENABLED:
               return { ...state, listenerFormsEnabled: action.payload };
          case SET_WIDTH_COLUMN_REPORTS:
               return { ...state, widthColumnReports: action.payload };
          case SET_STEPS_CUSTOM_FORMS:
               return { ...state, stepCustomForms: action.payload };
          case SET_DATE_REPORTS:
               return { ...state, dateRangeReports: action.payload };
          case SET_DATE_RANGE_RECORD:
               return { ...state, dateRangeRecord: action.payload };
          case SET_SHOW_IFRAME_CUSTOM_FORMS:
               return { ...state, showIframe: action.payload };
          case SET_LIST_FEATURE_CUSTOM_FORMS:
               return { ...state, listFeatureCustomForms: action.payload };
          case SET_COMPLETE_SETUP_GUIDE:
               return { ...state, completeStepSetupGuide: action.payload };
          case SET_FEATURE_INSTALL_GOALS:
               return { ...state, installAndLearn: { ...state.installAndLearn, ...action.payload } };
          case SET_FEATURE_FORM_CONVERSION:
               return { ...state, forCategorization: { ...state.forCategorization, ...action.payload } };
          case SET_FEATURE_CUSTOM_OPTINAL:
               return { ...state, customImplementation: { ...state.customImplementation, ...action.payload } };
          case SET_FEATURE_LEARN_DATA:
               return { ...state, dataEnrichment: { ...state.dataEnrichment, ...action.payload } };
          case SET_FEATURE_LEARN_SEDING_DATA:
               return { ...state, sendingData: { ...state.sendingData, ...action.payload } };
          case SET_FEATURE_CONFIGURE_DESTINATION:
               return { ...state, destination: { ...state.destination, ...action.payload } };
          case SET_SETUP_GUIDE_ROADMAP:
               return { ...state, set_setup_guide_roadmap: action.payload };
          case SET_SETUP_GUIDE_ROADMAP_INCREASE:
               return { ...state, set_setup_guide_roadmap: state.set_setup_guide_roadmap + action.payload };
          case SET_SETUP_GUIDE_ROADMAP_DECREASE:
               return { ...state, set_setup_guide_roadmap: state.set_setup_guide_roadmap - action.payload };
          case SET_SHOW_VIDEO:
               return { ...state, setShowVideo: { ...state.setShowVideo, ...action.payload } };
          case SET_SHOW_STEP_FORM_CATEGORY:
               return { ...state, isShowStepFormCategory: action.payload };
          case SET_SHOW_STEP_CUSTOM_FORM:
               return { ...state, isShowStepCustomForm: action.payload };
          case SET_SCROLL_SIDEBAR:
               return { ...state, scrollbars: action.payload };
          case SET_MANAGE_GOALS_TYPES:
               return { ...state, manageGoalsTypes: action.payload };
          case SET_SHOW_ROADMAP_MINIMIZE:
               return { ...state, setShowRoadmapMininize: action.payload };
          case SET_SHOW_CATEGORY_FORM:
               return { ...state, isshowCategoriesForm: action.payload };
          case SET_DATA_SETUP_GUIDE_COMPLETED:
               if (!state.setupGuideDataCompleted || !action.payload) return { ...state, setupGuideDataCompleted: action.payload };
               return { ...state, setupGuideDataCompleted: { ...state.setupGuideDataCompleted, ...action.payload } };
          case SET_PARENT_SETUP_GUIDE_COMPLETED:
               return {
                    ...state,
                    setupGuideDataCompleted: {
                         ...state.setupGuideDataCompleted,
                         completedSteps: {
                              ...state.setupGuideDataCompleted['completedSteps'],
                              ...action.payload,
                         },
                    },
               };
          case SET_DATA_CHILD_SETUP_GUIDE_COMPLETED:
               const { key, data } = action.payload;

               const newSetupGuideDataCompleted =
                    state.setupGuideDataCompleted && state.setupGuideDataCompleted[key] ? { ...state.setupGuideDataCompleted[key] } : {};

               return {
                    ...state,
                    setupGuideDataCompleted: {
                         ...state.setupGuideDataCompleted,
                         [key]: {
                              ...newSetupGuideDataCompleted,
                              ...data,
                         },
                    },
               };
          case SET_SKIP_WIZARD_FORM_CATEGORY:
               return { ...state, skipWizardFormCategory: action.payload };
          case SET_BROWSE_LISTENER_FORM_CATEGORY:
               return { ...state, activeListenerWizard: action.payload };
          case SET_SHOW_ROADMAP:
               return { ...state, isRoadmap: action.payload };
          case SET_SHOW_TOUR_FROM_ROADMAP:
               return { ...state, showTourFromRoadMap: action.payload };
          case SET_SHOW_TOUR_COMPETED_WIZARD: {
               return { ...state, showTourCompletedWizard: { ...state.showTourCompletedWizard, ...action.payload } };
          }
          case FORM_CATEGORY_SET_LIST_FORM_CHILDS:
               if (!action.payload) return state;
               return { ...state, ...action.payload };
          case FORM_CATEGORY_UPDATE_FORM_CHILD:
               return { ...state, [action.payload.parentId]: action.payload };
          case FEW_FORMS_SET_FORM_EDIT:
               if (!action.payload) return { ...state, fewFormsEdit: [] };
               let fewFormsEdit = [...state.fewFormsEdit];

               if (fewFormsEdit.length > 0) {
                    const findIndex = fewFormsEdit.findIndex((form) => form.id === action.payload.id);

                    if (findIndex > -1) {
                         fewFormsEdit[findIndex] = action.payload;
                    } else {
                         fewFormsEdit.push(action.payload);
                    }
               } else {
                    fewFormsEdit = [action.payload];
               }

               return { ...state, fewFormsEdit: fewFormsEdit };
          case FORM_CATEGORY_SET_FORM_EDIT:
               if (!action.payload) return { ...state, formInventoryEdit: [] };
               let formsEdit = [...state.formInventoryEdit];

               if (formsEdit.length > 0) {
                    const findIndex = formsEdit.findIndex((form) => form.id === action.payload.id);

                    if (findIndex > -1) {
                         formsEdit[findIndex] = action.payload;
                    } else {
                         formsEdit.push(action.payload);
                    }
               } else {
                    formsEdit = [action.payload];
               }

               return { ...state, formInventoryEdit: formsEdit };
          case FORM_REQUIRING_SET_FORM_EDIT:
               if (!action.payload) return { ...state, formRequiringEdit: [] };
               let formsRequiringEdit = [...state.formRequiringEdit];

               if (formsRequiringEdit.length > 0) {
                    const findIndex = formsRequiringEdit.findIndex((form) => form.id === action.payload.id);

                    if (findIndex > -1) {
                         formsRequiringEdit[findIndex] = action.payload;
                    } else {
                         formsRequiringEdit.push(action.payload);
                    }
               } else {
                    formsRequiringEdit = [action.payload];
               }

               return { ...state, formRequiringEdit: formsRequiringEdit };
          case FORM_IGNORE_SET_FORM_EDIT:
               if (!action.payload) return { ...state, formIgnoreEdit: [] };
               let formsIgnoreEdit = [...state.formIgnoreEdit];

               if (formsIgnoreEdit.length > 0) {
                    const findIndex = formsIgnoreEdit.findIndex((form) => form.id === action.payload.id);

                    if (findIndex > -1) {
                         formsIgnoreEdit[findIndex] = action.payload;
                    } else {
                         formsIgnoreEdit.push(action.payload);
                    }
               } else {
                    formsIgnoreEdit = [action.payload];
               }
               return { ...state, formIgnoreEdit: formsIgnoreEdit };
          case SET_FORM_DRAFT:
               if (!action.payload) return { ...state, formDraft: [] };
               let formsDraft = [...state.formDraft];

               if (formsDraft.length > 0) {
                    const findIndex = formsDraft.findIndex((form) => form.id === action.payload.id);

                    if (findIndex > -1) {
                         formsDraft[findIndex] = action.payload;
                    } else {
                         formsDraft.push(action.payload);
                    }
               } else {
                    formsDraft = [action.payload];
               }

               return { ...state, formDraft: formsDraft };

          case FORM_CATEGORY_SET_ALL_FORMS_INVENTORY:
               return { ...state, allFormsInventory: action.payload };
          case FORM_CATEGORY_SET_ALL_FORMS_IGNORE:
               return { ...state, allFormsIgnored: action.payload };
          case FORM_CATEGORY_SET_FILTERS:
               return { ...state, filterFormCategories: action.payload };
          case FORM_CATEGORY_SET_FILTERS_EDIT:
               return { ...state, filterEditFormCategories: action.payload };

          case SET_REDUX_ACTIVE_DROPDOWN:
               return { ...state, reduxActiveDropdown: action.payload };
          case SET_LOAD_FORM_CHILD_REQUIRING:
               return { ...state, formChildId: action.payload };
          case SET_OPEN_FROM_CONFIG_CONVERSION:
               return { ...state, openFromConfigConversion: action.payload };
          case SET_SHOW_POPUP_CONFIG_CONVERSION:
               return { ...state, showPopupConversion: action.payload };
          case SET_STEPS_CONVERSION:
               return { ...state, currentStepConversion: action.payload };
          case SET_SKIP_FORM_LISTENER:
               return { ...state, skipFormListener: action.payload };
          case SET_ACCOUNT_EXTERNAL_DATA_SOURCES:
               return { ...state, accountExternalData: { ...state.accountExternalData, sources: action.payload } };
          case SET_GOOGLE_ADS_ACCOUNTS:
               return { ...state, accountExternalData: { ...state.accountExternalData, googleAdsAccounts: action.payload } };
          case SET_SALESFORCE_ACCOUNTS:
               return { ...state, accountExternalData: { ...state.accountExternalData, salesforceAccounts: action.payload } };
          case SET_SALESFORCE_RULES:
               return { ...state, accountExternalData: { ...state.accountExternalData, salesforceRules: action.payload } };
          case SET_CUSTOMER_IDS_RESPONSE:
               return { ...state, accountExternalData: { ...state.accountExternalData, customerIdsResponse: action.payload } };
          case SET_MONITORING_FORM_CATEGORY:
               return { ...state, monitoringFormCategory: action.payload };
          case SET_LIST_CHANNELS:
               return { ...state, listChannels: action.payload };
          case SET_CUSTOM_USER_SOURCES:
               return { ...state, customUserSources: action.payload };
          case SET_LIST_USER_SOURCES:
               return { ...state, listUserSources: action.payload };
          case SET_MAX_STEP_FORM:
               return { ...state, maxStepForm: action.payload };
          case SET_MAX_STEP_RECORD_PROFILE:
               return { ...state, maxStepRecordForm: action.payload };
          case SET_SHOW_HISTORICAL_POPUP:
               return { ...state, showHistoricalPopup: action.payload };
          case SET_DATA_HISTORICAL_SALESFORCE:
               return { ...state, dataHistoricalSalesforce: action.payload };
          case SET_RELOAD_ACCOUNT_SALESFORCE:
               return { ...state, reloadAccountSalesforce: !state.reloadAccountSalesforce };
          case PUSHER_UPDATE_ACCOUNT_USER:
               return { ...state, ...subscriberPusher.updateAccountUser(state, action.payload) };
          case SET_REDUX_RELOAD_TABLE_REPORT:
               return { ...state, reloadReportData: action.payload };
          case SET_LIST_SEGMENT:
               return { ...state, listSegment: action.payload };
          case SET_LIST_SEGMENT_REPORT:
               return { ...state, listSegmentReport: action.payload };
          case SET_LIST_SEGMENT_APPLY_RELOAD:
               return { ...state, listSegmentApplyReload: action.payload };
          case SET_LIST_SEGMENT_WAITING_QUEUE:
               return { ...state, listSegmentWaitingQueue: action.payload };
          case SET_CONVERSIONS_NAME_RULES:
               return { ...state, conversionsNameOptions: action.payload };
          case SET_BLOCK_TRIGGER_RULES:
               return { ...state, listBlockTriggers: action.payload };
          case SET_LOADING_FORM_IGNORE:
               return { ...state, loadingFormIgnore: action.payload };
          default:
               return state;
     }
};

export default subscriber;
