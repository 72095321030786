import React, { useEffect, useState, useReducer, useCallback, useRef } from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
// import difference from 'lodash/difference';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import {
    setCustomVariableOptions,
    setCustomVariables,
    setEventTableOfListeners,
    setGlobalReporting,
    setShowBlockAccountPopup,
} from '../../../../../actions/subscriber';
import { callTokenApi, makeRequest } from '../../../../../apiCaller';
import {
    API_CLIENT_CUSTOM_VARIABLE,
    API_CLIENT_DATALAYER_VARIABLE,
    API_CLIENT_REPORTING_CUSTOM_VARIABLE,
    DEFAULT_DATE_FORMAT,
    // API_CLIENT_REPORTING_ACCOUNT_EVENT_TABLE,
    API_CLIENT_SELECTOR_VARIABLE,
    API_CLIENT_REPORTING_SELECTOR_VARIABLE,
    LISTENER_CODE,
    API_REPORTING_ACCOUNT_EVENT_TABLE_LAMBDA_URL
} from '../../../../../constants';
// import { usePrevious } from '../../../../../helpers/customHooks';
import { toastError } from '../../../../../utils';
import EventTableLayout from './EventTableLayout';
// import useFetchVariableValues from './useFetchVariableValues';
import TableLoading from '../../../../general/Loadings/TableLoading';

export const EventTableContext = React.createContext({});

const initialState = {
    rowLoadingIds: [] // Loading for a part of table
};

const reducer = (state, action) => {
    let newRowLoadingIds;

    switch (action.type) {
        case 'setRowLoadingIds':
            newRowLoadingIds = [...state.rowLoadingIds];
            newRowLoadingIds.push(action.payload);

            return { rowLoadingIds: newRowLoadingIds };
        case 'remove1RowLoadingId':
            newRowLoadingIds = [...state.rowLoadingIds];
            let indexFound = newRowLoadingIds.indexOf(action.payload);

            if (indexFound > -1) {
                newRowLoadingIds.splice(indexFound, 1);
            }

            return { ...state, rowLoadingIds: newRowLoadingIds };
        default:
            throw new Error();
    }
}

// const listEventNative = ['nativeDataLayer', 'nativeLocalStorage', 'nativeSessionStorage', 'nativeCookie'];

const EventTable = ({ filter, fromDay, endDay, keysConcept, setVariableValueToShow, selected }) => {
    const dispatch = useDispatch();
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    const eventTableOfListeners = useSelector(state => state.subscriber.eventTableOfListeners);
    const customVariables = useSelector(state => state.subscriber.customVariables);
    const { listenerNeedReloaded } = useSelector(state => state.subscriber.globalReporting);
    const activeListeners = useSelector((state) => state.subscriber.activeListeners);
    const [firstFetched, setFirstFetched] = useState(false);
    const [canFetchVariables, setCanFetchVariables] = useState(false); // Fetch automatic and custom variables when first time clicking open "Automatic Variables" or "Custom Variables"
    const [canFetchAllCountData, setCanFetchAllCountData] = useState(false); // Fetch data when first time clicking open automatic or custom variables
    const [isLoading, setIsLoading] = useState(false); // Loading for a whole tableLoading
    const [eventStatistics, setEventStatistics] = useState([]); // Event Statistics to be displayed
    const [details, setDetails] = useState([]);
    const [isSwitchChanging, setIsSwitchChanging] = useState(false); // Is a reporting value switch changing
    const [customVariablesOfRules, setCustomVariablesOfRules] = useState({}); // Custom variable IDs are being used for rules for many listeners
    const [firstLevelListenerIds, setFirstLevelListenerIds] = useState([]); // Listener IDs of first level opened features
    const [secondLevelListenerIds, setSecondLevelListenerIds] = useState([]); // Listener IDs of second level opened features
    const [fetchVariable, setFetchVariable] = useState({ childVariables: [], featureId: '', variableType: '', level: -1, variableToOpen: {}, repeatedVariable: undefined });
    // const prevFetch = usePrevious({ canFetchVariables, canFetchAllCountData, secondLevelListenerIds });
    const activeFeatures = useSelector(state => state.subscriber.activeFeatures);
    const typeEventActiveFeatures = activeFeatures.filter(activeFeature => activeFeature.featureType === 'event' || activeFeature.type === 'event');
    const [myState, myDispatch] = useReducer(reducer, initialState);
    const { rowLoadingIds } = myState;
    let newFromDay = filter === 'custom' ? fromDay : dayjs(fromDay).subtract(14, 'day').format(DEFAULT_DATE_FORMAT);
    let accountIdParam = `accountId=${activeAccount.id}`;
    let date00 = `date00=${newFromDay}`;
    let date01 = `date01=${endDay}`;
    let allVariableCount = useRef({});
    const remove1RowLoading = useCallback(() => {
        // details[details.length - 1]: ID of variable from which it loaded data
        // For example: toggle a variable => details = ['1'] => call API => toggle another variable => details = ['1', '2'] => API with '1' is done
        // => details for the first toggle is still = ['1'], not ['1', '2'], so we just need to remove the last element from details
        myDispatch({ type: 'remove1RowLoadingId', payload: details[details.length - 1] });
    }, [details]);

    // const { fetchVariableValueListenerIds, setFetchVariableValueListenerIds } = useFetchVariableValues({ newFromDay, endDay, remove1RowLoading });

    const getEventStatisticsOnUI = ({
        typeEventActiveFeatures,
        allTypeEventFeatures,
        countData,
        automaticVariables,
        customVariables,
        selectorVariables,
        featureCustomVariables,
        variableValueConfig,
        variableReportingValues
    }) => {
        let newEventStatisticsOnUI = [];

        let eventIds = []; // Ids of events which have count > 0

        Object.entries(countData).forEach(([key, value]) => {
            if (key !== 'total' && value && value.number > 0) {
                eventIds.push(key);
            }
        })

        // displayedFeatures include features which are being turned on or their count > 0 and their types are 'event'
        let displayedFeatures = [...typeEventActiveFeatures];

        eventIds.forEach(eventId => {
            let foundFromTypeEventFeatures = allTypeEventFeatures.find(feature => feature.id === eventId);
            let foundFromEnabledFeatures = typeEventActiveFeatures.find(feature => feature.id === eventId);

            if (foundFromTypeEventFeatures && !foundFromEnabledFeatures) {
                displayedFeatures.push(foundFromTypeEventFeatures);
            }
        })

        displayedFeatures = orderBy(displayedFeatures, 'code', 'asc');

        newEventStatisticsOnUI = displayedFeatures.map(feature => {
            let customVariableChildren = [];
            let selectorVariableChildren = [];
            let newAutomaticVariables;

            // Start to set variable values and configs to automatic variables
            if (automaticVariables && Array.isArray(automaticVariables[feature.listenerId])) {
                // If not clone deep, set newAutomaticVariables = [...automaticVariables],
                // then change newAutomaticVariables, automaticVariables is also changed, which makes it work incorrectly
                newAutomaticVariables = cloneDeep(automaticVariables[feature.listenerId]);

                const doRecursion = (arr) => {
                    arr.forEach(automaticVariable => {
                        automaticVariable.type = 'automatic';

                        if (Array.isArray(automaticVariable.childs)) {
                            doRecursion(automaticVariable.childs);
                        } else {
                            if (Array.isArray(variableValueConfig[feature.id])) {
                                let foundConfig = variableValueConfig[feature.id].find(config => config.automaticVariableId === automaticVariable.id);

                                if (foundConfig) {
                                    automaticVariable.saveValuesEnabled = foundConfig.status;
                                }
                            }

                            if (variableReportingValues[feature.id]) {
                                if (variableReportingValues[feature.id][automaticVariable.id]) {
                                    automaticVariable.variableValues = variableReportingValues[feature.id][automaticVariable.id];
                                }
                            }
                        }
                    })
                }

                doRecursion(newAutomaticVariables);
            }
            // End

            // Convert featureCustomVariables into an array of custom variables with more information
            if (featureCustomVariables && Array.isArray(featureCustomVariables[feature.id]) && featureCustomVariables[feature.id].length > 0) {
                featureCustomVariables[feature.id].forEach(customVariableId => {
                    let foundIndex = findIndex(customVariables, { id: customVariableId });

                    if (foundIndex > -1) {
                        let saveValuesEnabled = false;
                        let variableValues = [];

                        if (Array.isArray(variableValueConfig[feature.id])) {
                            let foundConfig = variableValueConfig[feature.id].find(config => config.customVariableId === customVariableId);

                            if (foundConfig) {
                                saveValuesEnabled = foundConfig.status;
                            }
                        }

                        if (variableReportingValues[feature.id]) {
                            if (variableReportingValues[feature.id][customVariableId]) {
                                variableValues = variableReportingValues[feature.id][customVariableId];
                            }
                        }

                        customVariableChildren.push({ ...customVariables[foundIndex], foundIndex, variableValues, type: 'custom', saveValuesEnabled });
                    }
                })

                customVariableChildren = orderBy(customVariableChildren, 'foundIndex', 'asc');
            }

            let childArr = [
                {
                    id: `${feature.id}-automatic-variables`,
                    listenerId: feature.listenerId,
                    variableName: 'Automatic Variables',
                    childs: newAutomaticVariables || [],
                    type: 'automatic'
                },
                {
                    id: `${feature.id}-custom-variables`,
                    listenerId: feature.listenerId,
                    variableName: 'Custom Variables',
                    childs: customVariableChildren,
                    type: 'custom'
                }
            ]

            if (feature.code.toLowerCase().includes('form')) { // Only forms have selector variables
                // Convert selectorVariables into an array of selector variables with more information
                if (selectorVariables && Array.isArray(selectorVariables[feature.listenerId])) {
                    selectorVariableChildren = selectorVariables[feature.listenerId].map(selectorVariable => {
                        let variableValues = [], saveValuesEnabled = false;

                        if (Array.isArray(variableValueConfig[feature.id])) {
                            let foundConfig = variableValueConfig[feature.id].find(config => config.selectorVariableId === selectorVariable.id);

                            if (foundConfig) {
                                saveValuesEnabled = foundConfig.status;
                            }
                        }

                        if (variableReportingValues[feature.id]) {
                            if (variableReportingValues[feature.id][selectorVariable.id]) {
                                variableValues = variableReportingValues[feature.id][selectorVariable.id];
                            }
                        }

                        return { ...selectorVariable, type: 'selector', saveValuesEnabled, variableValues };
                    })
                }
                let checkFormHaveSelectorValue = false;
                const findFormHaveSelectorValue = activeListeners.find((item) => item.id === feature.listenerId)
                if (findFormHaveSelectorValue) {
                    checkFormHaveSelectorValue = [LISTENER_CODE.CUSTOM_FORMS, LISTENER_CODE.PARDOT_FORM, LISTENER_CODE.GRAVITY_FORMS].includes(findFormHaveSelectorValue.code)
                }
                if (checkFormHaveSelectorValue && selectorVariableChildren.length > 0) {
                    childArr.push({
                        id: `${feature.id}-selector-variables`,
                        listenerId: feature.listenerId,
                        variableName: 'Selector Variables',
                        childs: selectorVariableChildren,
                        type: 'selector'
                    })
                }
            }

            return {
                id: feature.id,
                listenerId: feature.listenerId,
                eventName: feature.code,
                childs: childArr
            }
        });

        newEventStatisticsOnUI.push({
            id: 'total',
            eventName: 'Total'
        })

        return newEventStatisticsOnUI;
    }

    const fetchFirstAsync = async () => {
        setIsLoading(true);

        // let apiURL = `${API_CLIENT_REPORTING_ACCOUNT_EVENT_TABLE}`;
        let apiURL = API_REPORTING_ACCOUNT_EVENT_TABLE_LAMBDA_URL;
        apiURL += `?${accountIdParam}&${date00}&${date01}&getFeatureCount=true&${eventTableOfListeners.allTypeEventFeatures.length === 0 ? '&getFeatures=true' : ''}`;

        await makeRequest(apiURL, 'POST', null).then(response => {
            if (response.status === 200) {
                let { eventTableCountData, allTypeEventFeatures } = response.data;

                if (!allTypeEventFeatures) {
                    allTypeEventFeatures = eventTableOfListeners.allTypeEventFeatures;
                }


                let newEventStatisticsOnUI = getEventStatisticsOnUI({ typeEventActiveFeatures, allTypeEventFeatures, countData: eventTableCountData });
                const newEventTableOfListeners = {
                    countData: eventTableCountData,
                    eventStatisticsOnUI: newEventStatisticsOnUI,
                    allTypeEventFeatures,
                    startDay: newFromDay,
                    endDay
                };
                dispatch(setEventTableOfListeners(newEventTableOfListeners));
            } else {
                toastError(response);
            }
        })

        setFirstFetched(true);
        setIsLoading(false);
    }

    // Get features, count of those features of all listeners and event statistics
    // to be displayed on UI
    const fetchFirst = () => {
        fetchFirstAsync();

        return () => {
            dispatch(setEventTableOfListeners({
                featureCustomVariables: {}, // Custom variable ids which are displayed for each feature id
                automaticVariables: {},
            }));
        };
    }

    useEffect(fetchFirst, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFetchCount = ({ eventTableCountData, allVariableCount, customVariableCount, selectorVariableCount, variableReportingValues, totalSessions, totalVisitors }) => {
        let newCountData = eventTableCountData ? { ...eventTableCountData } : { ...eventTableOfListeners.countData };
        let splitCustomVariableCount = {};

        if (Number.isFinite(totalSessions)) {
            newCountData['total']['numberOfSessions'] = totalSessions;
        }

        if (Number.isFinite(totalVisitors)) {
            newCountData['total']['numberOfVisitors'] = totalVisitors;
        }

        // Start to handle newCountData
        // Update automatic, custom and selector variable counts for some features of a listener
        if (typeof allVariableCount === 'object' && allVariableCount) {
            Object.entries(allVariableCount).forEach(([key, countArr]) => { // key is feature ID
                if (Array.isArray(countArr)) {
                    splitCustomVariableCount[key] = [];

                    countArr.forEach(countEl => {
                        newCountData[key][countEl.automaticVariableId || countEl.customVariableId || countEl.selectorVariableId] = {
                            number: parseInt(countEl.number),
                            automaticVariableId: countEl.automaticVariableId,
                            customVariableId: countEl.customVariableId,
                            selectorVariableId: countEl.selectorVariableId
                        };

                        if (countEl.customVariableId) {
                            splitCustomVariableCount[key].push(countEl);
                        }
                    })
                }
            })
        }

        // Update custom variable counts for some features of a listener
        if (typeof customVariableCount === 'object' && customVariableCount) {
            Object.entries(customVariableCount).forEach(([key, countArr]) => { // key is feature ID
                if (Array.isArray(countArr)) {
                    countArr.forEach(countEl => {
                        newCountData[key][countEl.automaticVariableId || countEl.customVariableId || countEl.selectorVariableId] = {
                            number: parseInt(countEl.number),
                            automaticVariableId: countEl.automaticVariableId,
                            customVariableId: countEl.customVariableId,
                            selectorVariableId: countEl.selectorVariableId
                        };
                    })
                }
            })
        }

        // Update selector variable counts for some features of a listener
        if (typeof selectorVariableCount === 'object' && selectorVariableCount) {
            Object.entries(selectorVariableCount).forEach(([key, countArr]) => { // key is feature ID
                if (Array.isArray(countArr)) {
                    countArr.forEach(countEl => {
                        newCountData[key][countEl.automaticVariableId || countEl.customVariableId || countEl.selectorVariableId] = {
                            number: parseInt(countEl.number),
                            automaticVariableId: countEl.automaticVariableId,
                            customVariableId: countEl.customVariableId,
                            selectorVariableId: countEl.selectorVariableId
                        };
                    })
                }
            })
        }
        // Finish to handle newCountData

        // Start to handle newFeatureCustomVariables
        // An object of feature IDs, each feature ID contain an array of custom variables whose count > 0
        let joinCustomVariable = { ...customVariableCount, ...splitCustomVariableCount };
        let newFeatureCustomVariables = {};

        // Update featureCustomVariables
        let featuresToUpdate = eventTableOfListeners.allTypeEventFeatures.filter(typeEventFeature => firstLevelListenerIds.includes(typeEventFeature.listenerId));

        featuresToUpdate.forEach((feature) => {
            newFeatureCustomVariables[feature.id] = [];

            if (Array.isArray(joinCustomVariable[feature.id]) && joinCustomVariable[feature.id].length > 0) {
                newFeatureCustomVariables[feature.id] = joinCustomVariable[feature.id].map(el => el.customVariableId);
            }

            customVariablesOfRules[feature.listenerId].forEach((customVariableId) => {
                if (newFeatureCustomVariables[feature.id].indexOf(customVariableId) === -1) {
                    newFeatureCustomVariables[feature.id].push(customVariableId);
                }
            })
        })
        // Finish to handle newFeatureCustomVariables

        let newEventTableOfListeners = {
            countData: newCountData,
            featureCustomVariables: newFeatureCustomVariables,
            startDay: newFromDay,
            endDay,
        };

        // Start to handle newVariableReportingValues
        if (variableReportingValues) {
            let newVariableReportingValues = groupBy(variableReportingValues, 'featureId'); // groupBy always returns an object

            Object.entries(newVariableReportingValues).forEach(([key, value]) => {
                let customAndAutomaticVariableValue = {};

                value.forEach(el => {
                    let id = el.automaticVariableId || el.customVariableId || el.selectorVariableId;

                    if (customAndAutomaticVariableValue[id]) {
                        if (customAndAutomaticVariableValue[id].length < 50) {
                            customAndAutomaticVariableValue[id].push({ ...el, number: Number(el.number) });
                        }
                    } else {
                        customAndAutomaticVariableValue[id] = [{ ...el, number: Number(el.number) }];
                    }
                })

                newVariableReportingValues[key] = { ...customAndAutomaticVariableValue };
            })

            newEventTableOfListeners.variableReportingValues = { ...newVariableReportingValues };
        }
        // Finish to handle newVariableReportingValues

        dispatch(setEventTableOfListeners(newEventTableOfListeners));
    }

    // Fetch count of automatic and custom variables
    const fetchCount = () => {
        if (firstFetched && fetchVariable.level > 0) {
            // let pageYOffset = window.pageYOffset;
            let apiURL = API_REPORTING_ACCOUNT_EVENT_TABLE_LAMBDA_URL;
            apiURL += `?${accountIdParam}&${date00}&${date01}`;

            // let apiURLTotal = `${API_CLIENT_REPORTING_ACCOUNT_TOTAL_VISITOR_SESSION}`;
            // apiURLTotal += `?${accountIdParam}&${date00}&${date01}&selected=${selected}&${keysConceptParam}&filter=${filter}`;

            // Fetch feature, custom variable count and variable values when it's not 'canFetchAllCountData' changed and 'secondLevelListenerIds' changed
            // if (prevFetch.canFetchAllCountData === canFetchAllCountData && secondLevelListenerIds.length === prevFetch.secondLevelListenerIds.length) {
            // if (fetchVariable.level === 0) {
            // setIsLoading(true);

            // apiURL += '&getFeatureCount=true';
            // let customVariableListenerIds = difference(firstLevelListenerIds, secondLevelListenerIds).join();

            // if (customVariableListenerIds) {
            //     // Because custom and selector variable count process the same way, fetch custom variable count -> we also fetch selector variable count
            //     apiURL += `&getCustomVariableCount=true&customVariableListenerIds=${customVariableListenerIds}`;
            //     apiURL += `&getSelectorVariableCount=true&selectorVariableListenerIds=${customVariableListenerIds}`;
            // }

            // if (fetchVariableValueListenerIds.length > 0) {
            //     apiURL += `&variableValueListenerIds=${fetchVariableValueListenerIds}`;
            // }
            // } else {
            apiURL += `&getVariableCount=true&featureId=${fetchVariable.featureId}&variableType=${fetchVariable.variableType}`
            // }

            // if (canFetchAllCountData) {
            //     if (secondLevelListenerIds.length === prevFetch.secondLevelListenerIds.length) {
            //         apiURL += `&listenerIds=${secondLevelListenerIds.join()}&getAllVariableCount=true`;
            //     } else {
            //         apiURL += `&listenerId=${secondLevelListenerIds[secondLevelListenerIds.length - 1]}&getAllVariableCount=true`;
            //     }
            // }

            const body = {
                variableList: fetchVariable.childVariables,
                variableToOpen: fetchVariable.variableToOpen,
                repeatedVariable: fetchVariable.repeatedVariable
            };

            makeRequest(apiURL, 'POST', body)
                .then(async (response) => {
                    if (response.status === 200) {
                        const { eventTableCountData, customVariableCount, selectorVariableCount, variableReportingValues, variableCount } = response.data;

                        if (Array.isArray(variableCount)) {
                            if (allVariableCount.current[fetchVariable.featureId]) {
                                allVariableCount.current[fetchVariable.featureId].push(...variableCount);
                            } else {
                                allVariableCount.current[fetchVariable.featureId] = variableCount;
                            }
                        }

                        // let sessions, visitors;

                        // if (fetchVariable.level === 1) {
                        //     const totalRes = await callTokenApi(apiURLTotal, 'GET', null);

                        //     if (totalRes.status === 200) {
                        //         const { data } = totalRes.data;
                        //         sessions = data.sessions;
                        //         visitors = data.visitors;
                        //     }
                        // }

                        handleFetchCount({
                            eventTableCountData,
                            allVariableCount: allVariableCount.current,
                            customVariableCount,
                            selectorVariableCount,
                            variableReportingValues,
                            // totalSessions: sessions ? sessions.length : null,
                            // totalVisitors: visitors ? visitors.length : null
                        });
                    } else {
                        toastError(response);
                    }
                })
                .finally(() => {
                    setIsLoading(false);

                    // if (prevFetch.canFetchAllCountData !== canFetchAllCountData || secondLevelListenerIds.length !== prevFetch.secondLevelListenerIds.length) {
                    if (fetchVariable.level > 0) {
                        remove1RowLoading();
                        // window.scrollTo(0, pageYOffset); // Scroll to the position before loading when it's 'canFetchAllCountData' changed or 'secondLevelListenerIds' changed
                    }
                })
        }
    }

    useEffect(fetchCount, [filter, fromDay, endDay, canFetchAllCountData, secondLevelListenerIds, keysConcept, fetchVariable]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchAllVariables = async () => {
        // let pageYOffset = window.pageYOffset;
        let theLastListenerId = firstLevelListenerIds[firstLevelListenerIds.length - 1];
        let automaticVariableExisted = !!eventTableOfListeners.automaticVariables[theLastListenerId];
        let selectorVariableExisted = !!eventTableOfListeners.selectorVariables[theLastListenerId];
        let customVariableExisted = true;
        const initialCustomVariables = customVariables.initialVariables;
        let newEventTableOfListeners = { ...eventTableOfListeners };

        if (initialCustomVariables.length === 0 || (initialCustomVariables.length === 1 && !initialCustomVariables[0].name)) {
            customVariableExisted = false;
        }

        if (!automaticVariableExisted) {
            // If automatic variables have not been fetched, fetch them
            let automaticVariableRes = await callTokenApi(`${API_CLIENT_DATALAYER_VARIABLE}${activeAccount.id}/${theLastListenerId}`, 'GET', null);

            if (automaticVariableRes.status === 200) {
                let data = automaticVariableRes.data.datalayerVariables;

                newEventTableOfListeners.automaticVariables[theLastListenerId] = data;
            } else {
                if (automaticVariableRes.data.accountBlocked) {
                    dispatch(setShowBlockAccountPopup(true));
                } else {
                    toastError(automaticVariableRes);
                }
            }
        }

        if (!customVariableExisted) {
            // If custom variables have not been fetched, fetch them
            let customVariableRes = await callTokenApi(`${API_CLIENT_CUSTOM_VARIABLE}${activeAccount.id}?group=rule`, 'GET', null);

            if (customVariableRes.status === 200) {
                const { count, variables } = customVariableRes.data;
                let initialVariables = [], listDisabled = [];

                if (count >= 1) {
                    variables.forEach(item => {
                        initialVariables.push({ id: item.id, name: item.name });
                        listDisabled.push(true);
                    });
                    dispatch(setCustomVariables({
                        initialVariables,
                        addedVariables: [],
                        editedVariables: [],
                        listDisabled: [...listDisabled],
                        numOfVariables: count
                    }));
                    dispatch(setCustomVariableOptions(variables));
                } else {
                    dispatch(setCustomVariables({
                        initialVariables: [{ id: 'variable1', name: "" }],
                        addedVariables: [{ id: 'variable1', name: "" }],
                        editedVariables: [],
                        listDisabled: [false],
                        numOfVariables: 1
                    }));
                }
            } else {
                toastError(customVariableRes);
            }
        }

        // Handle fetch selector data
        if (!selectorVariableExisted) {
            let promises = [];
            promises.push(callTokenApi(`${API_CLIENT_SELECTOR_VARIABLE}${activeAccount.id}/${theLastListenerId}`, 'GET', null));
            promises.push(callTokenApi(`${API_CLIENT_REPORTING_SELECTOR_VARIABLE}?${accountIdParam}&listenerId=${theLastListenerId}&${date00}&${date01}`, 'GET', null));

            const [selectorVariableRes, featureSelectorVariableRes] = await Promise.all(promises);

            if (selectorVariableRes.status === 200) {
                let data = selectorVariableRes.data.selectorVariables;

                newEventTableOfListeners.selectorVariables[theLastListenerId] = data.filter(el => !el.fullName.startsWith('formSelectorValues'));
            } else {
                toastError(selectorVariableRes);
            }

            if (featureSelectorVariableRes.status === 200) {
                const { selectorVariableCount } = featureSelectorVariableRes.data;

                if (Array.isArray(selectorVariableCount)) {
                    selectorVariableCount.forEach(el => {
                        newEventTableOfListeners.countData[el.featureId][el.selectorVariableId] = {
                            number: parseInt(el.number),
                            automaticVariableId: null,
                            customVariableId: null,
                            selectorVariableId: el.selectorVariableId
                        };;
                    })
                }
            } else {
                toastError(featureSelectorVariableRes);
            }
        }

        if (!automaticVariableExisted) {
            // Get custom variables which are being used for custom rules and count > 0
            let apiURL = `${API_CLIENT_REPORTING_CUSTOM_VARIABLE}`;
            let listenerIdParam = `listenerId=${theLastListenerId}`;
            apiURL += `?${accountIdParam}&${listenerIdParam}&${date00}&${date01}`;

            let featureCustomVariableRes = await callTokenApi(apiURL, 'GET', null);

            if (featureCustomVariableRes.status === 200) {
                const { customVariableCount, customVariablesOfRules: resCustomVariablesOfRules } = featureCustomVariableRes.data;
                let groupByFeatureId = groupBy(customVariableCount, 'featureId');
                let newFeatureCustomVariables = { ...newEventTableOfListeners.featureCustomVariables };

                eventTableOfListeners.allTypeEventFeatures.filter(typeEventFeature => typeEventFeature.listenerId === theLastListenerId).forEach((feature) => {
                    newFeatureCustomVariables[feature.id] = [];

                    if (groupByFeatureId && groupByFeatureId[feature.id]) {
                        groupByFeatureId[feature.id].forEach(customVariable => {
                            // Update featureCustomVariables
                            newFeatureCustomVariables[feature.id].push(customVariable.customVariableId);

                            // Update count data
                            newEventTableOfListeners.countData[feature.id][customVariable.customVariableId] = {
                                number: parseInt(customVariable.number),
                                automaticVariableId: null,
                                customVariableId: customVariable.customVariableId,
                                selectorVariableId: null
                            };
                        })
                    }

                    resCustomVariablesOfRules.forEach((customVariableId) => {
                        if (newFeatureCustomVariables[feature.id].indexOf(customVariableId) === -1) {
                            newFeatureCustomVariables[feature.id].push(customVariableId);
                        }
                    })
                })

                newEventTableOfListeners.featureCustomVariables = newFeatureCustomVariables;
                setCustomVariablesOfRules({ ...customVariablesOfRules, [theLastListenerId]: resCustomVariablesOfRules });
            } else {
                toastError(featureCustomVariableRes);
            }
        }
        // End getting custom variables displayed on table

        if (!automaticVariableExisted || !selectorVariableExisted) {
            dispatch(setEventTableOfListeners(newEventTableOfListeners));
        }

        remove1RowLoading();

        // Fetch count data for theLastListenerId, need it to check if show plus icon or not
        if (secondLevelListenerIds.indexOf(theLastListenerId) === -1) {
            setSecondLevelListenerIds([...secondLevelListenerIds, theLastListenerId]);
        }
        setCanFetchAllCountData(true);

        // window.scrollTo(0, pageYOffset);
    }

    const fetchVariables = () => {
        if (canFetchVariables) {
            fetchAllVariables();
        }
    }

    useEffect(fetchVariables, [canFetchVariables, firstLevelListenerIds]); // eslint-disable-line react-hooks/exhaustive-deps

    const reloadAutomaticVariables = () => {
        if (canFetchVariables && listenerNeedReloaded) {
            setIsLoading(true);

            const finallyFunc = () => {
                setIsLoading(false);
                dispatch(setGlobalReporting({ listenerNeedReloaded: '' }));
            }
            callTokenApi(`${API_CLIENT_DATALAYER_VARIABLE}${activeAccount.id}/${listenerNeedReloaded}`, 'GET', null)
                .then(response => {
                    if (response.status === 200) {
                        let data = response.data.datalayerVariables;
                        let newEventTableOfListeners = cloneDeep(eventTableOfListeners);
                        // let newEventTableOfListeners = {...eventTableOfListeners}
                        newEventTableOfListeners.automaticVariables[listenerNeedReloaded] = data;
                        dispatch(setEventTableOfListeners(newEventTableOfListeners));
                    } else {
                        if (response.data.accountBlocked) {
                            dispatch(setShowBlockAccountPopup(true));
                        } else {
                            toastError(response);
                        }
                    }
                })
                .finally(() => finallyFunc());
        }
    }
    useEffect(reloadAutomaticVariables, [listenerNeedReloaded]); // eslint-disable-line react-hooks/exhaustive-deps

    // Set new features to be displayed on event table
    const handleKeysConceptChange = () => {
        if (keysConcept.find(concept => concept.value === 'all')) {
            setEventStatistics([...eventTableOfListeners.eventStatisticsOnUI]);
        } else {
            let newEventStatistics = [];

            keysConcept.forEach(keyConcept => {
                if (keyConcept.value !== 'all') {
                    newEventStatistics.push(eventTableOfListeners.eventStatisticsOnUI.find(eventStatistic => eventStatistic.id === keyConcept.value))
                }
            })

            setEventStatistics(newEventStatistics);
        }
    }

    useEffect(handleKeysConceptChange, [keysConcept, eventTableOfListeners.eventStatisticsOnUI]);

    const onEventStatisticsOnUIChanged = () => {
        if (firstFetched) {
            let newEventStatisticsOnUI = getEventStatisticsOnUI({
                typeEventActiveFeatures: typeEventActiveFeatures,
                allTypeEventFeatures: eventTableOfListeners.allTypeEventFeatures,
                countData: eventTableOfListeners.countData,
                automaticVariables: eventTableOfListeners.automaticVariables,
                selectorVariables: eventTableOfListeners.selectorVariables,
                customVariables: customVariables.initialVariables,
                featureCustomVariables: eventTableOfListeners.featureCustomVariables,
                variableValueConfig: eventTableOfListeners.variableValueConfig,
                variableReportingValues: eventTableOfListeners.variableReportingValues
            });

            dispatch(setEventTableOfListeners({ eventStatisticsOnUI: newEventStatisticsOnUI }));
        }
    }

    useEffect(
        onEventStatisticsOnUIChanged,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            activeFeatures,
            eventTableOfListeners.allTypeEventFeatures,
            eventTableOfListeners.countData,
            eventTableOfListeners.automaticVariables,
            eventTableOfListeners.selectorVariables,
            customVariables.initialVariables,
            eventTableOfListeners.featureCustomVariables,
            eventTableOfListeners.variableValueConfig,
            eventTableOfListeners.variableReportingValues
        ]
    )

    const contextValue = {
        details,
        setDetails,
        isSwitchChanging,
        setIsSwitchChanging,
        eventStatistics,
        setCanFetchVariables,
        setCanFetchAllCountData,
        firstLevelListenerIds,
        setFirstLevelListenerIds,
        secondLevelListenerIds,
        setSecondLevelListenerIds,
        // fetchVariableValueListenerIds,
        // setFetchVariableValueListenerIds,
        setVariableValueToShow,
        rowLoadingIds,
        addRowLoadingId: (id) => myDispatch({ type: 'setRowLoadingIds', payload: id }),
        allVariableCount: allVariableCount.current,
        setFetchVariable
    }

    return (
        <CCard>
            <CCardBody className="reporting-table-event table-event">
                {
                    isLoading ? (
                        <TableLoading />
                    ) : (
                        <EventTableContext.Provider value={contextValue}>
                            <EventTableLayout />
                        </EventTableContext.Provider>
                    )
                }
            </CCardBody>
        </CCard>

    )
}

EventTable.propTypes = {
    filter: PropTypes.string,
    fromDay: PropTypes.string,
    endDay: PropTypes.string,
    keysConcept: PropTypes.array
}

export default EventTable
