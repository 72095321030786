import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { CButton, CLink } from '@coreui/react';
import { Context } from './Dropdown';
import { tagPropType } from '../../../utils';
import { setUnsavedLookupTableModal } from '../../../actions/subscriber';

//component - CoreUI / CDropdownToggle

const DropdownToggle = (props) => {
     const dispatch = useDispatch();
     const { className, innerRef, onClick, caret = true, split, tag, ...attributes } = props;
     const isLookupTableUnsaved = useSelector((state) => state.subscriber.unsavedLookupTableModal.unsaved);
     const { reference, setReference, isOpen, setIsOpen, inNav, setSplit } = useContext(Context);

     innerRef && innerRef(reference);

     useEffect(() => {
          setSplit(split);
     });

     const click = (e) => {
          if (props.disabled) {
               return;
          }
          if (isLookupTableUnsaved) {
               dispatch(
                    setUnsavedLookupTableModal({
                         show: true,
                         onAccept: () => {
                              onClick && onClick(e);
                              setIsOpen(!isOpen);
                         },
                    })
               );
          } else {
               onClick && onClick(e);
               setIsOpen(!isOpen);
          }
     };

     const Tag = tag || (inNav ? CLink : CButton);

     const classes = classNames(className, {
          'dropdown-toggle': caret && !split,
          'nav-link': inNav,
     });

     const togglerAttrs = {
          onClick: click,
          'aria-expanded': isOpen ? 'true' : 'false',
          'aria-haspopup': 'true',
          'aria-label': 'Dropdown toggle',
          'ref': setReference,
     };

     if (split) {
          return (
               <React.Fragment>
                    <CButton {...attributes}>{props.children}</CButton>
                    <CButton className='dropdown-toggle dropdown-toggle-split' {...togglerAttrs} {...attributes}>
                         {''}
                    </CButton>
               </React.Fragment>
          );
     } else {
          return <Tag className={classes} {...togglerAttrs} {...attributes} />;
     }
};

DropdownToggle.propTypes = {
     tag: tagPropType,
     children: PropTypes.node,
     className: PropTypes.string,
     //
     innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
     caret: PropTypes.bool,
     onClick: PropTypes.func,
     split: PropTypes.bool,
     disabled: PropTypes.bool,
};

export default DropdownToggle;
