import React from 'react';
import { CModal, CModalBody, CButton } from '@coreui/react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';

const SwitchPopup = ({ show, isLoading, onAccept, onCancel, children }) => {
    return (
        <div className="confirm-save-popup">
            <CModal visible={show} onClose={onCancel} color="primary" alignment="center" portal={false}>
                <CModalBody className="text-center confirm-save">
                    <div className="confirm-save-icon">
                        <CIcon icon="icon-save" height={46} width={46} />
                    </div>
                    {children}
                    <CButton
                        className="btn-primary"
                        onClick={onAccept}
                        disabled={isLoading}
                    >
                        {isLoading ? (<>Waiting<span className="dots-waiting"></span></>) : "Save Now"}
                    </CButton>
                    <CButton onClick={onCancel} className="btn-light">
                        Cancel Changes
                    </CButton>
                </CModalBody>
            </CModal>
        </div>
    );
};

SwitchPopup.propTypes = {
     show: PropTypes.bool,
     isLoading: PropTypes.bool,
     onAccept: PropTypes.func,
     onCancel: PropTypes.func,
};

export default SwitchPopup;
