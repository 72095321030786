import React, { useContext, useEffect, useState } from 'react';
import { CButton, CCard, CCardBody, CTooltip } from '@coreui/react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import CIcon from '@coreui/icons-react';

import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { toastError } from '../../../../../../utils';
import { useActiveDestination } from '../../../../../../helpers/customHooks';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_GADS_GET_CONVERSIONS } from '../../../../../../constants';
import Waiting from '../../../../../general/Loadings/Waiting';
import { SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import CFormGroup from '../../../../../migration/CFormGroup';
import CLabel from '../../../../../migration/CLabel';
import { CInputCheckbox } from '../../../../../migration/CInput';

const GadsSelectConversion = () => {
     const { setCurrentStep, setStepsData, handleUpdateSwitchType } = useContext(SwitchTypeWizardContext);
     const googleAdsConnection = useSelector((state) => state.subscriber.accountExternalData.googleAdsAccounts.googleAdsConnection);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const activeDestination = useActiveDestination();
     const { generalSettings } = activeDestination;
     const currentConnection = (googleAdsConnection && googleAdsConnection.find((item) => item.id === generalSettings.customer_id) )|| {}
     const customer_id = (currentConnection.customer_id && currentConnection.customer_id.toString()) || ''
     const [conversions, setConversions] = useState([])
     const [selectConversion, setSelectConversions] = useState({})
     const [isLoading, setIsLoading] = useState()
     const handleFirstLoad = () => {
          setIsLoading(true)
          callTokenApi(API_CLIENT_GADS_GET_CONVERSIONS, 'POST', {accountId: activeAccount.id, customer_id}).then((response) => {
               if (response.status === 200) {
                    setConversions(response.data.conversions);
               } else {
                    toastError(response);
               }
          })
          .finally(() => {
               setIsLoading(false);
          });
     };

     useEffect(handleFirstLoad, []); //eslint-disable-line

     const handleSelectConversion = (conversion, value) => {
          setSelectConversions(prev => {return {...prev, [conversion.existId]: value}})
     };

     const goToNextStep = () => {
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSuccess);
          handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.getStarted }); // Reset step
     }

     const onSubmit = () => {
          const conversionSendTo = Object.entries(selectConversion).filter(([_, value]) => !!value).map(([value, _]) => value.toString());
          if(conversionSendTo.length === 0) {
               goToNextStep()
          } else {
               setStepsData({conversionSendTo})
               setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gadsMakeChange)
          }
     }

     return (
          <SwitchTypeWizardBody>
               <CCard className={classNames('account-container', { 'no-container': conversions.length === 0 })}>
                    <CCardBody>
                         {isLoading ? (
                              <CenterSpinner />
                         ) : (
                              <CFormGroup>
                                   <h3>Select Your Tags</h3>
                                   {conversions.length === 0 ? (
                                        <>
                                             <p className="des">You have no tag, please create one on your GoogleAds.</p>
                                             <CButton className="btn-save" onClick={() => goToNextStep()}>
                                                  <Waiting>NEXT</Waiting>
                                             </CButton>
                                        </>
                                   ) : (
                                        <>
                                             <p className="des">
                                                  Choose a tag you want to activate. If a tag cannot be activated, it means it hasn't been created in the app yet. Please go into the app and create a tag with the same name to use.
                                             </p>
                                             {/* {displayFilter && (
                                                  <>
                                                       <CInput className='filter' placeholder='Type to filter list...' value={filterText} onChange={onFilterChange} />
                                                       <div className={classNames('list-container', { 'd-none': filterGTMContainers.length > 3 })}>
                                                            <ContainerList
                                                                 sortedGTMContainers={sortedGTMContainers}
                                                                 selectedGTMContainer={selectedGTMContainer}
                                                                 handleSelectContainer={handleSelectContainer}
                                                                 displayNoOptions
                                                                 optionDisabled={nextStepLoading}
                                                            />
                                                       </div>
                                                  </>
                                             )} */}
                                             <div className="list-conversion-gads">
                                                  {conversions.map((conversion) => {
                                                       let clickAdded = {};

                                                       let tooltipAttrs = {
                                                            placement: 'bottom',
                                                            advancedOptions: {
                                                                 onShow(instance) {
                                                                      instance.popper.className = 'custom-tooltip';
                                                                 },
                                                                 onTrigger(e) {
                                                                      if (!clickAdded[e.id]) {
                                                                           e.reference.addEventListener('click', function (e) {
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                           });
                                                                           clickAdded[e.id] = true;
                                                                      }
                                                                 },
                                                                 hideOnClick: false,
                                                            },
                                                       };

                                                       return (
                                                            <CLabel className={`conversion`} key={conversion.existId}>
                                                                 <div className="d-flex align-items-center label">
                                                                      <div className="topic-label" htmlFor={`_${conversion.existId}`}>
                                                                           {conversion.conversion_name}
                                                                      </div>
                                                                      {!conversion.existId && (
                                                                           <CTooltip
                                                                                content="Create this tag in ListenLayer app to use it"
                                                                                {...tooltipAttrs}
                                                                           >
                                                                                <CIcon name="iconInfo" width={12} height={12} />
                                                                           </CTooltip>
                                                                      )}
                                                                 </div>
                                                                 {conversion.existId && (
                                                                      <CInputCheckbox
                                                                           id={`_${conversion.existId}`}
                                                                           checked={!!selectConversion[conversion.existId]}
                                                                           onChange={(e) => handleSelectConversion(conversion, e.target.checked)}
                                                                           disabled={!conversion.existId}
                                                                      />
                                                                 )}
                                                            </CLabel>
                                                       );
                                                  })}
                                             </div>
                                             <CButton className="btn-save" onClick={() => onSubmit()}>
                                                  <Waiting>NEXT</Waiting>
                                             </CButton>
                                        </>
                                   )}
                                   <span className="foot-note"></span>
                              </CFormGroup>
                         )}
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default GadsSelectConversion;
