import React, { useEffect } from 'react';
import { CButton } from '@coreui/react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { HoverInfoIconTooltip } from '../../custom-tooltips';
import { STEPS_WIZARD_CATEGORIES } from '../../../../constants';
import { setSkipWizardFormCategory } from '../../../../actions/subscriber';
import CImg from '../../../migration/CImg';

const BoxFeature = ({ feature, isActiveConversion, isSuccess }) => {
     const history = useHistory();
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const roadMapWizard = JSON.parse(localStorage.getItem(`roadMapWizard${activeAccount.id}`));
     const skipWizardFormCategory = useSelector((state) => state.subscriber.skipWizardFormCategory);
     const showBlockSetupGuidePopup = useSelector((state) => state.subscriber.showBlockSetupGuidePopup);
     const stepRoadmap = useSelector((state) => state.subscriber.set_setup_guide_roadmap);
     const conversionRules = useSelector((state) => state.subscriber.conversionRules);
     const isConversionRule = conversionRules.length;
     const showFinish =
          activeAccount.currentStepFormCategories &&
          activeAccount.currentStepFormCategories !== STEPS_WIZARD_CATEGORIES.yourDone &&
          activeAccount.currentStepFormCategories !== STEPS_WIZARD_CATEGORIES.manageFormCategories &&
          activeAccount.currentStepFormCategories !== STEPS_WIZARD_CATEGORIES.defineYourFormCategories;
     activeAccount.skipWizardFormCategory = skipWizardFormCategory;
     const { isWaiting = false, timeVideo, title, btnText, onClick, contenWait, status, contentTooltip, btnWaiting } = feature;

     const handleComplete = () => {
          onClick(dispatch, activeAccount, history, status);
     };

     window.onbeforeunload = () =>
          localStorage.setItem(
               `roadMapWizard${activeAccount.id}`,
               JSON.stringify({ stepPause: stepRoadmap, type: roadMapWizard ? roadMapWizard.type : '' })
          );

     useEffect(() => {
          if (!skipWizardFormCategory && showFinish) {
               dispatch(setSkipWizardFormCategory(true));
          }
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (!showBlockSetupGuidePopup) {
               const stepData = { stepPause: stepRoadmap, type: roadMapWizard ? roadMapWizard.type : '' };
               localStorage.setItem(`roadMapWizard${activeAccount.id}`, JSON.stringify(stepData));
          }
     }, [showBlockSetupGuidePopup, stepRoadmap]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleWatchAgain = () => {
          if (title !== 'Install ListenLayer') {
               onClick(dispatch, activeAccount, history, isConversionRule);
          }
     };

     return (
          <div
               className={`feature-block d-flex justify-content-between align-items-center ${status || isSuccess ? 'success' : ''} ${
                    isWaiting && !isActiveConversion ? 'wait' : ''
               }`}
               onClick={btnText === 'Start' && status ? handleWatchAgain : () => {}}
          >
               <div className="feature-title d-flex align-items-center justify-content-start">
                    {(status || isSuccess) && <CImg className="feature-title-icon" src="/assets/images/btn-success.png" width={23} height={23} />}
                    <p>{title}</p>
                    {contentTooltip && !status && (
                         <div className="tooltip-wrapper">
                              <div className="tooltip-wrapper">
                                   <HoverInfoIconTooltip>
                                        <p>{contentTooltip}</p>
                                   </HoverInfoIconTooltip>
                              </div>
                         </div>
                    )}
               </div>
               <div className={`d-flex justify-content-between align-items-center ${isWaiting ? 'wait' : ''}`}>
                    {status || isSuccess ? (
                         <>
                              {(btnText === 'Watch' || btnText === 'Waiting') && (
                                   <CButton
                                        className="feature-btn-success d-flex justify-content-center align-items-center"
                                        onClick={handleWatchAgain}
                                   >
                                        <span>Watch Again</span>
                                        {/* <CImg className='feature-icon-success' src='/assets/images/btn-watch-again.png' width={23} height={23} /> */}
                                        <CIcon className='ml-2 roadmap' icon='icon-play-circle' width={23} height={23} />
                                   </CButton>
                              )}
                         </>
                    ) : (
                         <>
                              {((isWaiting && isActiveConversion) || !isWaiting) && (
                                   <>
                                        {btnText === 'Watch' && isWaiting && isActiveConversion && (
                                             <CImg className="feature-icon" src="/assets/images/circle-play-solid.png" width={18} height={18} />
                                        )}
                                        <span className="feature-time">
                                             {(btnText === 'Watch' || (isWaiting && isActiveConversion)) && timeVideo}
                                        </span>
                                        <CButton
                                             className={`feature-btn d-flex justify-content-center align-items-center`}
                                             onClick={() => handleComplete()}
                                        >
                                             {btnText === 'Watch' && (
                                                  <CImg
                                                       className={`feature-btn-icon ${isWaiting ? 'wait' : ''}`}
                                                       src="/assets/images/play-circle-icon.png"
                                                       width={12}
                                                       height={12}
                                                  />
                                             )}
                                             {btnWaiting === 'Waiting' && btnText}
                                             {!isWaiting && btnText}
                                        </CButton>
                                   </>
                              )}
                              {isWaiting && !isActiveConversion && (
                                   <div className="d-inline-flex">
                                        <div className="tooltip-wrapper">
                                             <CButton
                                                  name="icon-info"
                                                  className={`feature-btn d-flex justify-content-center align-items-center hover-show-tooltip ${
                                                       isWaiting ? 'wait' : ''
                                                  }`}
                                             >
                                                  {btnText}
                                             </CButton>
                                             {isWaiting && (
                                                  <div className="block-tooltip">
                                                       <CImg
                                                            src="/assets/images/tooltip-bg.png"
                                                            name="arrow-preview"
                                                            className="arrow-preview"
                                                            height={25}
                                                            width={250}
                                                       />
                                                       <div className="feature-tooltip-text">
                                                            <p>{contenWait}</p>
                                                       </div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              )}
                         </>
                    )}
               </div>
          </div>
     );
};

export default BoxFeature;
