import { LOCAL_SUPPORT_PUSHER_REDIRECT } from '../../constants';
import { guidGenerator } from '../../utils';

export const supportPusher = {
     setNewComment: (state, data) => {
          const pathName = `/tickets/${data.comment.ticketId}`;

          if (window.location.pathname === pathName) {
               const { ticket } = state;
               const { comment } = data;
               let newTicket = { ...ticket };

               newTicket.comments.push(comment);

               return { ticket: newTicket };
          } else {
               const { comment, ticket } = data;

               const { tickets } = state;
               let newTickets = [...tickets];
               let newTicket = {};

               const flag = newTickets.some((ticket, index) => {
                    if (ticket.id === comment.ticketId) {
                         newTicket = ticket;
                         if (typeof newTicket.comments === 'undefined' && !newTicket.comments) {
                              newTicket.comments = [];
                         }
                         newTicket.comments.push(comment);
                         newTickets.splice(index, 1);
                         return true;
                    }
                    return false;
               });

               if (flag) {
                    newTicket.numCmtByClient += 1;
                    newTicket.status = '0';
               } else {
                    newTicket = ticket;
                    newTicket.status = '0';
                    newTicket.numCmtByClient = ticket.numCmtByClient + 1;
               }
               newTickets.unshift(newTicket);

               return { tickets: newTickets };
          }
     },
     resetNumberCommnet: (state, data) => {
          const { tickets } = state;

          let newTickets = [...tickets];

          newTickets.some((ticket) => {
               if (ticket.id === data.ticketId) {
                    ticket.numCmtByClient = 0;
                    return true;
               }
               return false;
          });

          return { tickets: newTickets };
     },
     updateStatusTicket: (state, data) => {
          const { tickets } = state;

          let newTickets = [...tickets];

          newTickets.some((ticket) => {
               if (ticket.id === data.id) {
                    ticket.status = data.status;
                    return true;
               }
               return false;
          });

          const pathName = `/tickets/${data.id}`;

          if (window.location.pathname === pathName) {
               return { tickets: newTickets, ticket: { ...state.ticket, status: data.status } };
          }

          return { tickets: newTickets };
     },
};

export const getSupportRedirectChannelId = () => {
     let channelId = localStorage.getItem(LOCAL_SUPPORT_PUSHER_REDIRECT);

     if (!channelId) {
          channelId = guidGenerator();
          localStorage.setItem(LOCAL_SUPPORT_PUSHER_REDIRECT, channelId);
     }

     return channelId;
};

export function formatNumber(num) {
     const hasDecimal = num % 1 !== 0;

     if (!Number.isFinite(num) || !hasDecimal) {
          return num
     }else {
          const decimalPart = num.toString().split('.')[1];
          const numDecimals = decimalPart.length;

          if (numDecimals <= 2) {
               return num;
          } else {
               return num.toFixed(2);
          }
     }
}
