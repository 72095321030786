import React, { useEffect, useState } from 'react';
import { Message } from './Message.js';
import { CButton, CRow, CCol } from '@coreui/react';
import { API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE } from '../../../../constants/apiRest.js';
import { callTokenApi } from '../../../../apiCaller.js';
import { useDispatch, useSelector } from 'react-redux';
import { setParentSetupGuideDataCompleted } from '../../../../actions/subscriber.js';
import BoxFeature from './BoxFeature.js';
import CImg from '../../../migration/CImg.js';
export const FormConversions = (props) => {
     const { nextTour, dataFeature } = props;
     const dispatch = useDispatch();
     const [featureSuccess, setFeatureSuccess] = useState(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [isActiveConversion, setIsActiveConversion] = useState(false);
     const stepRoadmap = useSelector((state) => state.subscriber.set_setup_guide_roadmap);
     const showRoadmap = useSelector((state) => state.subscriber.showBlockSetupGuidePopup);
     const stepPause = JSON.parse(localStorage.getItem(`roadMapWizard${activeAccount.id}`));
     const [render, setRender] = useState(0);

     const showAnimationRoadmap = localStorage.getItem('showAnimationRoadmap');
     const notShowStepAnimation = localStorage.getItem('forCategorization');

     useEffect(() => {
          setTimeout(() => {
               localStorage.setItem('forCategorization', true);
               localStorage.removeItem('showAnimationRoadmap');
          }, 2000);
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const numberStepCompleted = dataFeature.feature.filter((feature) => feature.status);
     useEffect(() => {
          if (numberStepCompleted.length === dataFeature.feature.length) {
               setFeatureSuccess(true);
          } else if (numberStepCompleted.length === 1) {
               setIsActiveConversion(true);
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dataFeature]);

     useEffect(() => {
          if (stepRoadmap === 3 && showRoadmap) {
               setRender((prev) => prev + 1);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [showRoadmap, stepRoadmap]);

     const onClickNextStep = () => {
          localStorage.setItem('forCategorization', false);
          localStorage.setItem('showAnimationRoadmap', true);
          nextTour();
          const data = {
               accountId: activeAccount.id,
               data: { forCategorization: true },
          };
          callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE, 'PUT', data).then((response) => {
               if (response.status === 200) {
                    dispatch(setParentSetupGuideDataCompleted({ forCategorization: true }));
               }
          });
     };

     // const completeStep = dataStepCompleted ? (dataStepCompleted.completedSteps ? dataStepCompleted.completedSteps.forCategorization : false) : false;

     const isShowAnimation = !!(!notShowStepAnimation && showAnimationRoadmap);

     const isMessageMoveForward = (stepPause && stepPause.stepPause === 3 && !featureSuccess) || (render >= 2 && !featureSuccess);

     return (
          <CRow className="guide-row">
               <CCol md="1">
                    <CImg
                         src="/assets/images/avtAdSetupGuide.png"
                         height="60"
                         width="60"
                         className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s' : ''}
                    />
               </CCol>
               <CCol md="11">
                    <div className={`tour-guide show setup-guide`}>
                         <div className="tour-guide-content content">
                              <Message
                                   text={'Cool! We have the basics out of the way.'}
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s animate__faster-2s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-4s' : ''}
                              />
                              <Message
                                   text={
                                        "Now that you understand Goals, and the Insights ListenLayer can generate, let's configure some settings specifically for you."
                                   }
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-5s animate__faster-3s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-9s' : ''}
                              />
                              <Message
                                   text={
                                        "It may feel overwhelming to build your goals now! Don't worry, these steps simply introduce you to this important part of the platform... you can adjust the settings at any time in the future!"
                                   }
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-10s animate__faster-4s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-15s' : ''}
                              />
                              {isMessageMoveForward && (
                                   <Message
                                        text={'Go ahead and complete these steps so we can move forward.'}
                                        hasLoading={isShowAnimation}
                                        animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-16s' : ''}
                                        animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-18s' : ''}
                                   />
                              )}
                              <div
                                   className={
                                        isShowAnimation
                                             ? `animate__animated animate__fadeIn ${
                                                    isMessageMoveForward ? 'animate__delay-18s' : 'animate__delay-16s'
                                               }`
                                             : ''
                                   }
                              >
                                   {dataFeature.feature.map((feature, index) => (
                                        <BoxFeature key={index} feature={feature} isActiveConversion={isActiveConversion} />
                                   ))}
                              </div>
                              {featureSuccess && (
                                   <>
                                        <Message
                                             text={"Nice work! Select NEXT when you're ready to move forward."}
                                             hasLoading={isShowAnimation}
                                             animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-19s' : ''}
                                             animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-21s' : ''}
                                        />
                                        <div
                                             className={`buttons justify-content-end ${
                                                  isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-22s' : ''
                                             }`}
                                        >
                                             <CButton className="next-button roadmap" onClick={() => onClickNextStep()}>
                                                  Next
                                             </CButton>
                                        </div>
                                   </>
                              )}
                         </div>
                    </div>
               </CCol>
          </CRow>
     );
};
