import React, { useEffect } from 'react';
import { Message } from './Message.js';
import { CButton, CRow, CCol } from '@coreui/react';
import { setShowRoadmapMinimize, setShowSetupGuidePopup } from '../../../../actions/subscriber.js';
import CImg from '../../../migration/CImg.js';
import { useDispatch, useSelector } from 'react-redux';

export const EndRoadmap = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const showAnimationRoadmap = localStorage.getItem('showAnimationRoadmap');
     const notShowStepAnimation = localStorage.getItem('endRoadmap');

     useEffect(() => {
          setTimeout(() => {
               localStorage.setItem('endRoadmap', true);
               localStorage.removeItem('showAnimationRoadmap');
          }, 2000);
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const onClose = () => {
          dispatch(setShowRoadmapMinimize(false));
          dispatch(setShowSetupGuidePopup(false));
          window.localStorage.removeItem('isRoadmap' + activeAccount.id)
     };

     const isShowAnimation = !!(!notShowStepAnimation && showAnimationRoadmap);

     return (
          <CRow className='guide-row'>
               <CCol md='1'>
                    <CImg
                         src='/assets/images/avtAdSetupGuide.png'
                         height='60'
                         width='60'
                         className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s' : ''}
                    />
               </CCol>
               <CCol md='11'>
                    <div className={`tour-guide show setup-guide`}>
                         <div className='tour-guide-content content'>
                              <Message
                                   text={
                                        "Congrats! You've completed the initial configuration of your account. You can always revisit this conversation to re-watch the videos."
                                   }
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s animate__faster-3s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-5s' : ''}
                              />
                              <Message
                                   text={'And remember, you can reach out to our support team with any questions you might have!'}
                                   hasLoading={isShowAnimation}
                                   animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-6s animate__faster-2s' : ''}
                                   animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-9s' : ''}
                              />
                              <div
                                   className={`buttons justify-content-end ${
                                        isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-10s' : ''
                                   }`}
                              >
                                   <CButton className={`next-button roadmap`} onClick={onClose}>
                                        Finish
                                   </CButton>
                              </div>
                         </div>
                    </div>
               </CCol>
          </CRow>
     );
};
