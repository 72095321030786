import React, { useState } from 'react';
import { CCard, CCardBody, CButton, CRow } from '@coreui/react';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { toast } from 'react-toastify';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import CIcon from '@coreui/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { setScheduleUpgrade, setShowUpgradeAccountPopup, setShowUpgradeAccountSchedulePopup } from '../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../apiCaller';
import { API_UPDATE_SCHEDULE_DEMO } from '../../../../../constants';

const TalkToSales = ({ toggleTalkToSales, setShowTalkToSales, url }) => {
     const dispatch = useDispatch()
     const [isLoading, setIsLoading] = useState(true)
     const isSchedule = useSelector(state => state.subscriber.activeAccount.isScheduleUpgrade);
     const accountId = useSelector(state => state.subscriber.activeAccount.id);
     const [schedule, setSchedule] = useState(true)
     const [showThank, setShowThank] = useState(false)
     const [firstSchedule, setFirstSchedule] = useState(isSchedule ? false : true)
     const showUpgradeAccountSchedulePopup = useSelector(state => state.subscriber.showUpgradeAccountSchedulePopup);
     useCalendlyEventListener({
          onEventTypeViewed: () => setIsLoading(false),
          onEventScheduled: (e) => {
               if (e.data.event) {
                    toast.success("Schedule a Talk to Sales Successful");
                    callTokenApi(API_UPDATE_SCHEDULE_DEMO, "PUT", { accountId, isScheduleUpgrade: true })
                    dispatch(setScheduleUpgrade(true))
                    setSchedule(true)
                    if (isSchedule) {
                         setFirstSchedule(false)
                    }
                    setShowThank(true)
               }
          },
     });

     const onCloseModal = () => {
          setShowTalkToSales(false)
          dispatch(setShowUpgradeAccountPopup(false));
          dispatch(setShowUpgradeAccountSchedulePopup(false))
     }

     const oncClickSchedule = () => {
          setSchedule(false)
     }

     const onClickBack = () => {
          if (!schedule) {
               setSchedule(true)
          }
          else if (showUpgradeAccountSchedulePopup || isSchedule) {
               onCloseModal()
          }
          else {
               toggleTalkToSales()
          }
     }

     return (
          <div className="account-header">
               <CButton className={`btn-back btn-back-choose`} onClick={() => onClickBack()}>
                    <i className="fal fa-angle-left"></i>
                    Back
               </CButton>
               <CIcon
                    icon="logo-dark"
                    height={34}
               />
               {isSchedule && schedule ? (
                    firstSchedule || showThank ? (
                         <CCard className='talk-to-sales thank-you'>
                              <CCardBody>
                                   <h3>Thank You!</h3>
                                   <p className='des'>
                                        Your meeting has been scheduled. Check your email, an invitation was sent with details and will be added to your calendar.
                                   </p>
                                   <CButton className="btn-continue" onClick={onCloseModal}>
                                        Continue
                                   </CButton>
                              </CCardBody>
                         </CCard>
                    ) :
                         (
                              <CCard className='talk-to-sales thank-you'>
                                   <CCardBody>
                                        <h3>You Previously Requested a Demo</h3>
                                        <p className='des'>
                                             It looks like you’ve submitted a demo request previously. If you’d like to schedule another one, click below.
                                        </p>
                                        <CRow style={{ justifyContent: 'center' }}>
                                             <CButton className="btn-continue btn-white" onClick={onCloseModal} style={{ margin: '0 10px', width: '230px' }}>
                                                  Go back
                                             </CButton>
                                             <CButton className="btn-continue" onClick={oncClickSchedule} style={{ margin: '0 10px', width: '230px' }}>
                                                  Submit Another Request
                                             </CButton>
                                        </CRow>
                                   </CCardBody>
                              </CCard>
                         )

               ) : (
                    <CCard className='talk-to-sales'>
                         <CCardBody>
                              <h3>Schedule A Demo</h3>
                              <p className='des'>Looking to upgrade to a Business Plan? Grab a time below for an initial 15-minute discovery call. Then we'll schedule a time to review your custom pricing options</p>
                              {isLoading && <CenterSpinner />}
                              <InlineWidget url='https://calendly.com/nduy12310/schedule' styles={{
                                   height: '800px'
                              }} />
                         </CCardBody>
                    </CCard>
               )}


               <div className="footer text-center">
                    <CIcon
                         icon="logo-dark"
                         height={34}
                    />
                    <div className="d-flex justify-content-center">
                         <div>
                              <a className="text-footer" href={`${url}support/`}>Support</a>
                         </div>
                         <div>
                              <a className="text-footer" href={`${url}terms-of-use/`}>Terms of Use</a>
                         </div>
                         <div>
                              <a className="text-footer" href={`${url}privacy-policy/`} >Privacy & Data Policy</a>
                         </div>
                    </div>
               </div>
          </div >
     );
};

export default TalkToSales;
