import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { RuleSettingContext } from '../EditLookupSetting';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { callTokenApi } from '../../../../../../apiCaller';
import { clearConversionDraft, deepCopyArray, toastError } from '../../../../../../utils';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { API_CLIENT_ACCOUNT_RULE, COMPONENT_NAME, CONSENT_TYPE_INIT, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../constants';
import CIcon from '@coreui/icons-react';
import { setInitialTriggerRules, setTriggerRules } from '../../../../../../actions/subscriber';
import { setFlexibleModal, setRuleHaveEditting, setViewedRule } from '../../../../../../actions/common';
import NewGroup from '../../../listener-settings-v2/custom-data-layer-variables/simple/NewGroup';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';
import CLabel from '../../../../../migration/CLabel';
import CSwitch from '../../../../../migration/CSwitch';
import { CSelect } from '../../../../../migration/CInput';

const initialGroup = {
     key: '',
     value: '',
     conjunction: '',
};

const EditStep4 = ({ stepsData, setStepsData }) => {
     const dispatch = useDispatch();
     const { activeStep, setActiveStep } = useContext(RuleSettingContext);
     const [saveLoading, setSaveLoading] = useState(false);
     const [saveModal, setSaveModal] = useState(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const rules = useSelector((state) => state.subscriber.triggerRules);
     const [groups, setGroups] = useState([]);
     const [errors, setErrors] = useState([]);
     const [saveSkip, setSaveSkip] = useState(false);
     const [consentBlock, setConsentBlock] = useState(false);
     const { showLv2 } = useSelector((state) => state.theme.flexibleModal);

     const { id: activeAccountId } = activeAccount;

     const toggleSaveModal = useCallback(() => {
          setSaveModal(!saveModal);
     }, [saveModal]);

     const closeSaveModal = useCallback(() => {
          setSaveModal(false);
     }, []);

     const setConditionGroups = () => {
          if (activeStep === 4) {
               const conditionGroups = cloneDeep(stepsData.consent);
               setGroups(conditionGroups);

               const _consentBlock = cloneDeep(stepsData.data.consentBlock);
               setConsentBlock(_consentBlock);
          }
     };

     useEffect(setConditionGroups, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

     const changeAValueOfGroup = ({ lvl1GroupIndex, lvl2GroupIndex, propName, propValue }) => {
          let newGroups = [...groups];

          newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`][propName] = propValue;

          let newErrors = [...errors];
          if (newErrors[lvl1GroupIndex]) {
               delete newErrors[lvl1GroupIndex].group;
               if (newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`]) {
                    delete newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`].row;
                    delete newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`][propName];
                    if (propName === 'type') {
                         delete newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`];
                    }
               }
          }

          setErrors(newErrors);
          setGroups(newGroups);
          handleHasChange();
     };

     const selectLvl1Conjunction = (lvl1GroupIndex, conjunction) => {
          let newGroups = [...groups];

          if (!newGroups[lvl1GroupIndex].conjunction) {
               newGroups.push({
                    conjunction: '',
                    g0: { ...initialGroup },
               });
          }

          newGroups[lvl1GroupIndex].conjunction = conjunction;
          setGroups(newGroups);
          handleHasChange();
     };

     const updateLvl1Conjunction = (lvl1GroupIndex, conjunction) => {
          let newGroups = [...groups];

          newGroups[lvl1GroupIndex - 1].conjunction = conjunction;
          setGroups(newGroups);
          handleHasChange();
     };

     const deleteLvl1Group = (lvl1GroupIndex) => {
          let newGroups = [...groups];

          // Remove the last group (last group's conjunction is empty), then set the previous group's conjunction to empty
          if (!newGroups[lvl1GroupIndex].conjunction) {
               newGroups[lvl1GroupIndex - 1].conjunction = '';
          } else if (newGroups[lvl1GroupIndex - 1]) {
               newGroups[lvl1GroupIndex - 1].conjunction = newGroups[lvl1GroupIndex].conjunction;
          }

          newGroups.splice(lvl1GroupIndex, 1);
          setGroups(newGroups);
          handleHasChange();
     };

     const deleteLvl2Group = (lvl1GroupIndex, lvl2GroupIndex) => {
          let newGroups = [...groups];
          let newGroup = { ...newGroups[lvl1GroupIndex] };
          let newGroupConjunction = newGroup.conjunction;
          delete newGroup.conjunction;
          const newGroupLength = Object.keys(newGroup).length;

          // Remove the last group (last group's conjunction is empty), then set the previous group's conjunction to empty
          if (!newGroup[`g${lvl2GroupIndex}`].conjunction && lvl2GroupIndex !== 0) {
               newGroup[`g${lvl2GroupIndex - 1}`].conjunction = '';
          }

          for (let i = lvl2GroupIndex; i < newGroupLength; i++) {
               newGroup[`g${i}`] = { ...newGroup[`g${i + 1}`] };
          }

          delete newGroup[`g${newGroupLength - 1}`];
          newGroup.conjunction = newGroupConjunction;
          newGroups[lvl1GroupIndex] = { ...newGroup };

          setGroups(newGroups);
          setErrors([]);
          handleHasChange();
     };

     const validate = useCallback(() => {
          // check require field
          let validate = true;
          let newErrors = [];

          for (let index = 0; index < groups.length; index++) {
               const lvl1Group = groups[index];
               newErrors[index] = {};
               let workingLvl1Group = { ...lvl1Group };
               delete workingLvl1Group.conjunction;

               for (var lvl2GroupKey in workingLvl1Group) {
                    const { key, value } = workingLvl1Group[lvl2GroupKey];
                    let lv2Errors = {};

                    // if operator = 1 of ['exav', 'nex', 'itp', 'itft'], value variable is empty, so don't need to check value
                    if (!key || (typeof value === 'string' && !value.trim())) {
                         validate = false;
                    }

                    if (!validate) {
                         if (!key) {
                              lv2Errors = { ...lv2Errors, key: 'This field is required.' };
                         }
                         if (!value) {
                              lv2Errors = { ...lv2Errors, value: 'This field is required.' };
                         }
                    }
                    newErrors[index][lvl2GroupKey] = lv2Errors;
               }
          }

          // check validate dupplicate groups
          let duplicateGroups = false;
          let groupsToValidate = groups.map((group) => {
               let newGroup = { ...group };
               delete newGroup.conjunction;

               return newGroup;
          });

          for (let i = 0; i < groupsToValidate.length - 1; i++) {
               for (let j = i + 1; j < groupsToValidate.length; j++) {
                    if (isEqual(groupsToValidate[i], groupsToValidate[j])) {
                         newErrors[j] = { group: 'There are duplicate group' };
                         duplicateGroups = true;
                         validate = false;
                         break;
                    }
               }

               if (duplicateGroups) {
                    break;
               }
          }

          if (!duplicateGroups) {
               for (let index = 0; index < groupsToValidate.length; index++) {
                    const lvl1Group = groupsToValidate[index];
                    const lvl1GroupLength = Object.keys(lvl1Group).length;

                    for (let i = 0; i < lvl1GroupLength; i++) {
                         let iGroup = { ...lvl1Group[`g${i}`] };
                         iGroup.conjunction = '';

                         for (let j = i + 1; j < lvl1GroupLength; j++) {
                              let jGroup = { ...lvl1Group[`g${j}`] };
                              jGroup.conjunction = '';

                              if (isEqual(iGroup, jGroup)) {
                                   if (!newErrors[index]) {
                                        newErrors[index] = {};
                                   }
                                   if (!newErrors[index][`g${j}`] || Object.keys(newErrors[index][`g${j}`]).length === 0) {
                                        newErrors[index][`g${j}`] = { row: "There are duplicate row's values in the same group" };
                                   }
                                   duplicateGroups = true;
                              }
                         }
                    }

                    if (duplicateGroups) {
                         validate = false;
                    }
               }
          } else {
               validate = false;
          }

          setErrors(newErrors);
          return validate;
     }, [groups]);

     const handleSave = (check = true) => {
          if (consentBlock !== stepsData.data.consentBlock) {
               if (check && consentBlock) {
                    if (validate()) {
                         toggleSaveModal();
                    }
               } else {
                    if (groups[0].g0.key !== '' || groups[0].g0.value !== '') {
                         setSaveSkip(true);
                    }
                    toggleSaveModal();
               }
          } else {
               if (check && consentBlock) {
                    if (validate()) {
                         if (compareTwoVariables(stepsData.consent, groups)) {
                              dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                              setTimeout(() => {
                                   if (!showLv2) {
                                        dispatch(
                                             setFlexibleModal({
                                                  show: true,
                                                  showLv2: true,
                                                  ruleId: stepsData.id,
                                                  ruleIdLv2: stepsData.id,
                                                  component: COMPONENT_NAME.TRIGGER_LOOKUP_SETTING,
                                                  componentLv2: COMPONENT_NAME.TRIGGER_LOOKUP_TABLE,
                                             })
                                        );
                                   } else {
                                        dispatch(
                                             setFlexibleModal({
                                                  show: true,
                                                  showLv2: false,
                                                  ruleId: stepsData.id,
                                                  ruleIdLv2: '',
                                                  component: COMPONENT_NAME.TRIGGER_LOOKUP_TABLE,
                                                  componentLv2: '',
                                             })
                                        );
                                   }
                              }, 0);
                         } else {
                              toggleSaveModal();
                         }
                    }
               } else {
                    if (groups[0].g0.key !== '' || groups[0].g0.value !== '') {
                         setSaveSkip(true);
                         toggleSaveModal();
                    } else {
                         dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                         setTimeout(() => {
                              if (!showLv2) {
                                   dispatch(
                                        setFlexibleModal({
                                             show: true,
                                             showLv2: true,
                                             ruleId: stepsData.id,
                                             ruleIdLv2: stepsData.id,
                                             component: COMPONENT_NAME.TRIGGER_LOOKUP_SETTING,
                                             componentLv2: COMPONENT_NAME.TRIGGER_LOOKUP_TABLE,
                                        })
                                   );
                              } else {
                                   dispatch(
                                        setFlexibleModal({
                                             show: true,
                                             showLv2: false,
                                             ruleId: stepsData.id,
                                             ruleIdLv2: '',
                                             component: COMPONENT_NAME.TRIGGER_LOOKUP_TABLE,
                                             componentLv2: '',
                                        })
                                   );
                              }
                         }, 0);
                    }
               }
          }
     };

     const compareTwoVariables = (arr1, arr2) => {
          if (arr1.length !== arr2.length) {
               return false;
          }

          if (JSON.stringify(arr1) !== JSON.stringify(arr2)) {
               return false;
          }

          return true;
     };

     const handleAcceptPopup = () => {
          setSaveLoading(true);

          const newData = {
               accountId: activeAccountId,
               ...stepsData,
               consent: groups,
               data: { ...stepsData.data, consentBlock },
          };

          if (saveSkip) {
               newData.data = {
                    ...stepsData.data,
                    consentBlock: false,
               };
               newData.consent = [
                    {
                         conjunction: '',
                         g0: {
                              key: '',
                              value: '',
                              conjunction: '',
                         },
                    },
               ];
          }

          callTokenApi(API_CLIENT_ACCOUNT_RULE, 'PUT', newData)
               .then((response) => {
                    setSaveLoading(false);

                    if (response.status === 200) {
                         const { rule } = response.data;

                         let newRules = [...rules].map((item) => {
                              if (item.id === rule.id) {
                                   return { ...item, ...rule };
                              }
                              return item;
                         });

                         setStepsData(newData);
                         dispatch(setTriggerRules(newRules));
                         dispatch(setInitialTriggerRules(deepCopyArray(newRules)));
                         dispatch(setViewedRule({ ...rule }, true));
                         toast.success('Successfully update rule');
                         dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                         setTimeout(() => {
                              if (!showLv2) {
                                   dispatch(
                                        setFlexibleModal({
                                             show: true,
                                             showLv2: true,
                                             ruleId: stepsData.id,
                                             ruleIdLv2: stepsData.id,
                                             component: COMPONENT_NAME.TRIGGER_LOOKUP_SETTING,
                                             componentLv2: COMPONENT_NAME.TRIGGER_LOOKUP_TABLE,
                                        })
                                   );
                              } else {
                                   dispatch(
                                        setFlexibleModal({
                                             show: true,
                                             showLv2: false,
                                             ruleId: stepsData.id,
                                             ruleIdLv2: '',
                                             component: COMPONENT_NAME.TRIGGER_LOOKUP_TABLE,
                                             componentLv2: '',
                                        })
                                   );
                              }
                         }, 0);

                         if (activeAccount.id) {
                              clearConversionDraft(activeAccount.id);
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    closeSaveModal();
               });
     };

     const toggleSwitchConsent = (val) => {
          setConsentBlock(val === 'on' ? false : true);
          setGroups([
               {
                    conjunction: '',
                    g0: {
                         key: '',
                         value: '',
                         conjunction: '',
                    },
               },
          ]);
          handleHasChange();
     };
     const addLvl2Conjunction = ({ lvl1GroupIndex, lvl2GroupIndex, conjunction }) => {
          let newGroups = [...groups];

          if (!newGroups[lvl1GroupIndex][`g0`].conjunction) {
               newGroups[lvl1GroupIndex][`g0`].conjunction = 'and';
          }
          if (!newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`].conjunction) {
               newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`].conjunction = conjunction;
          }
          newGroups[lvl1GroupIndex][`g${lvl2GroupIndex + 1}`] = { ...initialGroup };

          setGroups(newGroups);
          setErrors([]);
          handleHasChange();
     };
     const updateLvl2Conjunction = ({ lvl1GroupIndex, conjunction }) => {
          const newGroups = [...groups];

          let newGroupsUpdate = { ...newGroups[lvl1GroupIndex] };

          delete newGroupsUpdate.conjunction;

          Object.keys(newGroupsUpdate).forEach((key, index) => {
               if (index < Object.keys(newGroupsUpdate).length - 1) {
                    newGroupsUpdate[key].conjunction = conjunction;
               }
          });

          newGroups[lvl1GroupIndex] = { ...groups[lvl1GroupIndex], ...newGroupsUpdate };

          setGroups(newGroups);
          handleHasChange();
     };
     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ showLv2: true, typeLv2: TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP }));
     };
     return (
          <CCard className={`cvr-step-4 ${activeStep !== 4 ? 'difference-step' : 'cvr-step-card'}`}>
               <CCardHeader>
                    {activeStep !== 4 ? (
                         <div className="rule-step d-inline-flex justify-content-between w-100">
                              <h5 className="mb-0 inactive">Step 4: Consent</h5>
                              {(stepsData.customVariables.length > 0 || stepsData.conditions.length > 0) && (
                                   <div className="d-inline-flex align-items-center">
                                        <CButton className="btn-edit" onClick={() => setActiveStep(4)}>
                                             Edit
                                        </CButton>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5>Step 4: Consent</h5>
                    )}
               </CCardHeader>
               {activeStep === 4 ? (
                    <>
                         <CCardBody>
                              <p>
                                   Use ListenLayer to easily manage all of your consent policies by blocking triggers when certain rules are met for a
                                   User. Managing these at the trigger level in ListenLayer simplifies consent in your measurement.
                              </p>

                              <div className="d-flex align-items-center mb-3">
                                   <p className="m-0 p-0">
                                        <strong>Enable Consent blocking for this trigger.</strong>
                                   </p>
                                   <div className="switch-box">
                                        <CSwitch
                                             className="ml-4 d-inline-block align-middle"
                                             color={consentBlock ? 'success' : 'light'}
                                             checked={!!consentBlock}
                                             value={consentBlock ? 'on' : 'off'}
                                             size="sm"
                                             tabIndex="0"
                                             shape="pill"
                                             onChange={(e) => toggleSwitchConsent(e.target.value)}
                                        />
                                   </div>
                              </div>

                              {consentBlock && (
                                   <>
                                        <p>Block this Trigger if the following Consent rule is matched.</p>

                                        {groups.map((lvl1Group, lvl1GroupIndex) => {
                                             let workingLvl1Group = { ...lvl1Group };
                                             delete workingLvl1Group.conjunction;
                                             const lvl1GroupConjunction = lvl1GroupIndex > 0 ? groups[lvl1GroupIndex - 1].conjunction : '';
                                             const groupDupplicate = errors[lvl1GroupIndex] && errors[lvl1GroupIndex].group;

                                             return (
                                                  <React.Fragment key={lvl1GroupIndex}>
                                                       <CCard className="conditions-group conditions-validate">
                                                            <CCardHeader className="d-flex align-items-center justify-content-between">
                                                                 <div className="d-flex align-items-center ">
                                                                      <h6>{`Rule Group ${lvl1GroupIndex + 1}`}</h6>
                                                                      {lvl1GroupIndex > 0 && (
                                                                           <div className="d-flex align-items-center button-new-rule">
                                                                                <CButton
                                                                                     className={` btn-add-or-rule ${
                                                                                          lvl1GroupConjunction === 'and' ? 'active' : ''
                                                                                     }`}
                                                                                     color={lvl1GroupConjunction === 'and' ? 'info' : 'dark'}
                                                                                     onClick={() => updateLvl1Conjunction(lvl1GroupIndex, 'and')}
                                                                                >
                                                                                     AND
                                                                                </CButton>
                                                                                <CButton
                                                                                     className={` btn-add-or-rule ${
                                                                                          lvl1GroupConjunction === 'or' ? 'active' : ''
                                                                                     }`}
                                                                                     color={lvl1GroupConjunction === 'or' ? 'info' : 'dark'}
                                                                                     onClick={() => updateLvl1Conjunction(lvl1GroupIndex, 'or')}
                                                                                >
                                                                                     OR
                                                                                </CButton>
                                                                                match this group
                                                                           </div>
                                                                      )}
                                                                 </div>

                                                                 {groups.length > 1 && (
                                                                      <CButton
                                                                           className="remove-group"
                                                                           onClick={() => deleteLvl1Group(lvl1GroupIndex)}
                                                                      >
                                                                           Remove Group
                                                                      </CButton>
                                                                 )}
                                                            </CCardHeader>
                                                            <CCardBody>
                                                                 {Object.entries(workingLvl1Group).map(([key, lvl2Group], lvl2GroupIndex) => {
                                                                      const lvl2GroupConjunction = lvl1Group[`g0`].conjunction || '';
                                                                      // 'exists with any value' and 'does not exist' operator don't have value input

                                                                      const errorLvl2Group =
                                                                           errors[lvl1GroupIndex] && errors[lvl1GroupIndex][key]
                                                                                ? errors[lvl1GroupIndex][key]
                                                                                : {};
                                                                      const rowDupplicateValidate = errorLvl2Group.row;
                                                                      const isLastRow = key === `g${Object.entries(workingLvl1Group).length - 1}`;
                                                                      const isValidate = rowDupplicateValidate || (isLastRow && groupDupplicate);

                                                                      return (
                                                                           <React.Fragment key={key}>
                                                                                {lvl2GroupIndex === 0 && (
                                                                                     <div className="d-flex align-items-center mb-2">
                                                                                          <CLabel className="mb-0">Match</CLabel>
                                                                                          <div className="button-new-rule ml-1">
                                                                                               <CButton
                                                                                                    className={`btn-all-or-any ${
                                                                                                         lvl2GroupConjunction === 'and'
                                                                                                              ? 'active'
                                                                                                              : ''
                                                                                                    }`}
                                                                                                    onClick={() =>
                                                                                                         updateLvl2Conjunction({
                                                                                                              lvl1GroupIndex,
                                                                                                              conjunction: 'and',
                                                                                                         })
                                                                                                    }
                                                                                               >
                                                                                                    All
                                                                                               </CButton>
                                                                                               <CButton
                                                                                                    className={`btn-all-or-any ${
                                                                                                         lvl2GroupConjunction === 'or' ? 'active' : ''
                                                                                                    }`}
                                                                                                    onClick={() =>
                                                                                                         updateLvl2Conjunction({
                                                                                                              lvl1GroupIndex,
                                                                                                              conjunction: 'or',
                                                                                                         })
                                                                                                    }
                                                                                               >
                                                                                                    Any
                                                                                               </CButton>
                                                                                          </div>
                                                                                          <CLabel className="mb-0">conditions in this group.</CLabel>
                                                                                     </div>
                                                                                )}
                                                                                <div
                                                                                     className={`mb-3 conditions-match d-flex align-items-top${
                                                                                          !!isValidate ? ' is-invalid' : ''
                                                                                     }`}
                                                                                >
                                                                                     <div
                                                                                          className={`select-type ${
                                                                                               consentBlock ? 'consent-block' : ''
                                                                                          }`}
                                                                                     >
                                                                                          <CLabel>Select a Consent Type</CLabel>
                                                                                          <Select classNamePrefix='react-select'
                                                                                               options={CONSENT_TYPE_INIT}
                                                                                               value={CONSENT_TYPE_INIT.filter(
                                                                                                    (option) => option.value === lvl2Group.key
                                                                                               )}
                                                                                               onChange={(e) =>
                                                                                                    changeAValueOfGroup({
                                                                                                         lvl1GroupIndex,
                                                                                                         lvl2GroupIndex,
                                                                                                         propName: 'key',
                                                                                                         propValue: e.value,
                                                                                                    })
                                                                                               }
                                                                                               className={!!errorLvl2Group.key ? 'is-invalid' : ''}
                                                                                          />
                                                                                          <CInvalidFeedback>{errorLvl2Group.key}</CInvalidFeedback>
                                                                                     </div>

                                                                                     <div className="select-type">
                                                                                          <CLabel>Condition</CLabel>
                                                                                          <CSelect
                                                                                               value={lvl2Group.value}
                                                                                               onChange={(e) =>
                                                                                                    changeAValueOfGroup({
                                                                                                         lvl1GroupIndex,
                                                                                                         lvl2GroupIndex,
                                                                                                         propName: 'value',
                                                                                                         propValue: e.target.value,
                                                                                                    })
                                                                                               }
                                                                                               invalid={!!errorLvl2Group.value}
                                                                                          >
                                                                                               <option value="" disabled hidden>
                                                                                                    True or False?
                                                                                               </option>
                                                                                               <option value="true">True</option>
                                                                                               <option value="false">False</option>
                                                                                          </CSelect>
                                                                                          <CInvalidFeedback>{errorLvl2Group.value}</CInvalidFeedback>
                                                                                     </div>
                                                                                     <div className={`button-delete-rule`}>
                                                                                          {Object.keys(workingLvl1Group).length > 1 && (
                                                                                               <CIcon
                                                                                                    icon="iconDeleteField"
                                                                                                    className="icon-delete"
                                                                                                    onClick={() =>
                                                                                                         deleteLvl2Group(
                                                                                                              lvl1GroupIndex,
                                                                                                              lvl2GroupIndex
                                                                                                         )
                                                                                                    }
                                                                                               />
                                                                                          )}
                                                                                     </div>
                                                                                </div>
                                                                                <CInvalidFeedback>{rowDupplicateValidate}</CInvalidFeedback>
                                                                                {isLastRow && <CInvalidFeedback>{groupDupplicate}</CInvalidFeedback>}
                                                                                {lvl2GroupIndex === Object.entries(workingLvl1Group).length - 1 && (
                                                                                     <>
                                                                                          <CButton
                                                                                               className="btn-add-row mb-3"
                                                                                               onClick={() =>
                                                                                                    addLvl2Conjunction({
                                                                                                         lvl1GroupIndex,
                                                                                                         lvl2GroupIndex,
                                                                                                         conjunction: lvl2GroupConjunction,
                                                                                                    })
                                                                                               }
                                                                                          >
                                                                                               <CIcon icon="iconAddField" className="icon-add" />
                                                                                               <CLabel className="add-row">ADD ROW</CLabel>
                                                                                          </CButton>
                                                                                     </>
                                                                                )}
                                                                           </React.Fragment>
                                                                      );
                                                                 })}
                                                            </CCardBody>
                                                       </CCard>
                                                       {lvl1GroupIndex === groups.length - 1 && (
                                                            <NewGroup lvl1GroupIndex={lvl1GroupIndex} selectLvl1Conjunction={selectLvl1Conjunction} />
                                                       )}
                                                  </React.Fragment>
                                             );
                                        })}
                                   </>
                              )}

                              <div className="d-flex align-items-center">
                                   <CButton className="px-4" color="primary" onClick={() => handleSave(true)}>{`Save & Manage Values`}</CButton>
                                   {consentBlock && (
                                        <div className="btn-skip ml-4" onClick={() => handleSave(false)}>
                                             {'Skip this step >'}
                                        </div>
                                   )}
                              </div>
                         </CCardBody>
                    </>
               ) : (
                    ''
               )}
               <ConfirmSaveChange
                    show={saveModal}
                    onClose={() => {
                         setSaveSkip(false);
                         closeSaveModal();
                    }}
                    onAccept={handleAcceptPopup}
                    isLoading={saveLoading}
                    title="Warning! This Will Change Your Rule"
               >
                    {!saveSkip ? (
                         <p>
                              You are changing consent. This will make some changes to this rule. You will need to review your lookup table settings
                              and adjust them as needed.
                         </p>
                    ) : (
                         <p>You are save skip consent.</p>
                    )}
               </ConfirmSaveChange>
          </CCard>
     );
};

EditStep4.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};

export default EditStep4;
