import React from 'react';
import ManageExternalItem from './ManageExternalItem';

const ManageExternalData = () => {
     return (
          <div className="listeners">
               <div className="listener-title headline-wrapper external-data-manage">
                    <h1>Manage External Data Sources</h1>
                    <div className="description">
                         Connect your external data sources and import data objects that can be associated with your centralized data in ListenLayer.
                         This powers attribution and
                    </div>
               </div>
               <ManageExternalItem />
          </div>
     );
};

export default ManageExternalData;
