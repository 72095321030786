import React, { useState, useEffect } from 'react';
import {
    CRow,
    CCol,
    CButton,
    CForm,
} from '@coreui/react';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';
import { toastError } from '../../../../../utils';
import { toast } from 'react-toastify';
import { callTokenApi } from '../../../../../apiCaller';
import { Popup, UpdatePaymentPopup } from '../../../../general/popup';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    API_CLIENT_INVOICE_CHECKING,
    API_CLIENT_SAVE_CARD,
    CARD_TYPES,
} from '../../../../../constants';
import { fetchUserAccounts, handleCompleteOwnershipTransfer, setAccountPastdue, setShowBlockAccountPopup, setShowUpgradeAccountPopup } from '../../../../../actions/subscriber';
import CIcon from '@coreui/icons-react';
import { useHistory } from 'react-router-dom';
import { useMainInvoiceItems } from '../../../../../helpers/customHooks';
import { CInput } from '../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../migration/CInvalidFeedback';
import CLabel from '../../../../migration/CLabel';
import CFormGroup from '../../../../migration/CFormGroup';

const cardValidationSchema = (cardInputMask, cardCVCMask) => {
    let cardInputMaskTemp = cardInputMask.replace(/\s/gi, '');
    let cardNumberLength = cardInputMaskTemp.length;
    let cardCVCMaskTemp = cardCVCMask.replace(/\s/gi, '');
    let cardCVCLength = cardCVCMaskTemp.length;

    return Yup.object().shape({
        stripeCardName: Yup.string().trim()
            .required('Card Name is required')
        ,
        stripeCardNumber: Yup.string().trim()
            .required('Card Number is required')
            .test(
                'lenCardNumber',
                `Card Number must be exactly ${cardNumberLength} digits`,
                value => { return value && value.replace(/_/gi, '').replace(/\s/gi, '').length === cardNumberLength },
            )
        ,
        stripeCardExpiration: Yup.string().trim()
            .required('Expiration is required')
            .test(
                'validateExpiration',
                `Card Expiration has not been fully entered`,
                value => { return value && value.replace(/_/gi, '').replace(/\s/gi, '').length === 7 },
            )
        ,
        stripeCardCVC: Yup.string().trim()
            .required('CVC number is required')
            .test(
                'lenCardCVC',
                `Card CVC Number must be exactly ${cardCVCLength} digits`,
                value => { return value && value.replace(/_/gi, '').replace(/\s/gi, '').length === cardCVCLength },
            )
    })
}

const BlockAccount = () => {
    const [cardInputMask, setCardInputMask] = useState('9999 9999 9999 9999');
    const [cardCVCMask, setCardCVCMask] = useState('999');
    const [cardCVCPlaceholder, setCardCVCPlaceholder] = useState('000');
    const [cardType, setCardType] = useState('');
    const { showTour } = useSelector(state => state.subscriber.tour);
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    const accountPastDue = useSelector(state => state.subscriber.accountPastDue);
    const showBlockAccountPopup = useSelector(state => state.subscriber.showBlockAccountPopup);
    const dispatch = useDispatch();
    const history = useHistory();
    const { singularInvoiceType } = useMainInvoiceItems();

    const listUserPath = ['profile', 'email-notifications', 'payment-methods', 'support-tickets', 'become-a-partner', 'office-hours', 'certification', 'masterclass'];
    const showPopup = (pathName = history.location.pathname) => {
        const checkUserPath = listUserPath.some(pathExcluded => {
            if (pathName.includes(pathExcluded)) {
                return true;
            }
            return false;
        })

        if (!checkUserPath) {
            if (accountPastDue.status && accountPastDue.message) {
                dispatch(setShowBlockAccountPopup(true))
            } else {
                dispatch(setShowBlockAccountPopup(false))
            }
        }
    }

    useEffect(() => {
        const unlisten = history.listen(() => {
            const pathName = history.location.pathname;
            showPopup(pathName);
        });

        return () => unlisten();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        isOwner,
        stripeCardId,
        stripeCardName,
        stripeClientId,
        confirmPayment,
    } = activeAccount

    const initialValues = {
        name: activeAccount.name,
        stripeCardNumber: ''
    }

    const fetchData = () => {
        if (activeAccount.packageIsCustom) return;

        let pathName = history.location.pathname;
        if (/\/compare-plan/.test(pathName)) {
            dispatch(setShowUpgradeAccountPopup(true))
        } else {
            dispatch(setAccountPastdue({
                status: false,
                message: '',
                buttonText: 'Fix Now',
                function: 'goToAccountSetting'
            }))
            dispatch(setShowBlockAccountPopup(false))
            if (activeAccount.id) {
                callTokenApi(`${API_CLIENT_INVOICE_CHECKING}${activeAccount.id}`, 'GET', null)
                    .then(response => {
                        if (response) {
                            if (response.status === 200) {
                                const dataResponse = response.data;
                                if (dataResponse.invoiceDueTo === true && (!dataResponse.isUpdateCard || (dataResponse.isUpdateCard && dataResponse.freeAccountLimitEvent))) {
                                    dispatch(setAccountPastdue({
                                        status: true,
                                        title: 'Failed Payment',
                                        message: dataResponse.message
                                    }))

                                    if (dataResponse.accountBlocked) {
                                        dispatch(setAccountPastdue({ title: 'Paused Account' }))
                                    }
                                    if (dataResponse.freeAccountLimitEvent) {
                                        dispatch(setAccountPastdue({
                                            title: `Over ${singularInvoiceType} Limit`,
                                            buttonText: 'Upgrade Now',
                                            function: 'upgradeAccount'
                                        }))
                                    }

                                    showPopup()
                                } else if (dataResponse.cardExpiring) {
                                    dispatch(setAccountPastdue({
                                        title: 'Expiring Credit Card',
                                        status: true,
                                        message: dataResponse.message,
                                        function: 'showModalUpdatePayment'
                                    }))
                                    dispatch(setShowBlockAccountPopup(true))
                                }
                            } else {
                                toastError(response);
                            }
                        }
                    })
            }
        }
    }

    useEffect(fetchData, [stripeCardId, stripeCardName, stripeClientId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdatePayment = (values, { setSubmitting }) => {
        let cardNumber = values.stripeCardNumber.replace(/_/gi, '').replace(/ /gi, '');
        let stripeCardExpiration = values.stripeCardExpiration;
        let expiration = stripeCardExpiration.split('/');
        let expMonth = expiration[0];
        let expYear = expiration[1];

        setSubmitting(true);
        if (activeAccount) {
            let data = {
                accountId: activeAccount.id,
                name: values.stripeCardName,
                cardNumber,
                expMonth,
                expYear,
                cvc: values.stripeCardCVC,
            }
            callTokenApi(`${API_CLIENT_SAVE_CARD}`, 'POST', data)
                .then(response => {
                    if (response.status === 200) {
                        toggleModalUpdatePayment();
                        let finallyNext = () => toast.success("Payment has been updated successfully");

                        if (confirmPayment) {
                            dispatch(handleCompleteOwnershipTransfer({ accountId: activeAccount.id, userId: activeAccount.userId }), finallyNext);
                        } else {
                            dispatch(fetchUserAccounts(activeAccount.id, true, finallyNext))
                        }
                    } else {
                        setSubmitting(false);
                        toastError(response);
                    }
                })
        }
    }

    const toggleModalUpdatePayment = () => {
        dispatch(setAccountPastdue({ showUpdatePaymentPopup: !accountPastDue.showUpdatePaymentPopup }));
    }

    const closeModalUpdatePayment = () => {
        dispatch(setAccountPastdue({ showUpdatePaymentPopup: false }));
    }

    // const toggleModalPastDue = () => {
    //     dispatch(setShowBlockAccountPopup(!showBlockAccountPopup));
    // }

    const closeModalPastDue = () => {
        dispatch(setShowBlockAccountPopup(false));
    }

    const clickBlockPopup = () => {
        if (accountPastDue.function === 'upgradeAccount') {
            upgradeAccount();
        } else {
            updatePayment();
        }
    }

    const updatePayment = () => {
        closeModalPastDue();
        toggleModalUpdatePayment();
    }

    const upgradeAccount = () => {
        closeModalPastDue();
        dispatch(setShowUpgradeAccountPopup(true));
    }

    return (
        <>
            <Popup show={showBlockAccountPopup && accountPastDue.message !== '' && !showTour} onClose={closeModalPastDue} className="popup-account-past">
                <CIcon icon="cil-dollar" height={40} />
                <h4>{accountPastDue.title}</h4>
                <div className="description"><p>{accountPastDue.message}</p></div>
                {isOwner && <CButton shape="pill" className="btn btn-outline-danger" onClick={clickBlockPopup}>{accountPastDue.buttonText}</CButton>}
            </Popup>
            <UpdatePaymentPopup show={accountPastDue.showUpdatePaymentPopup} onClose={closeModalUpdatePayment}>
                <CIcon icon="cil-x" onClick={closeModalUpdatePayment} className="icon-close-popup"></CIcon>
                <div className="icon-payment"><CIcon icon="icon-payment" height={52} /></div>
                <div className="popup-update-payment-description">
                    <h2>Update Payment Information</h2>
                    <p>Update your payment information by adding a new credit card. If you are attempting to update the current card {"("}as a result of a new expiration date, etc.{")"} select the checkbox "update current card info"</p>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={() => cardValidationSchema(cardInputMask, cardCVCMask)}
                    onSubmit={handleUpdatePayment}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                >
                    {
                        ({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            setFieldError,
                            isSubmitting,
                        }) => {
                            const resetCardType = () => {
                                setCardType('');
                                setCardInputMask(CARD_TYPES.OTHER.INPUT_MASK);
                                setCardCVCMask(CARD_TYPES.OTHER.CVC_MASK);
                                setCardCVCPlaceholder(CARD_TYPES.OTHER.CVC_PLACEHOLDER);
                            }

                            const handleChangeCardNumber = (e) => {
                                const { value } = e.target;
                                let cardNumberValue = value.replace(/_/gi, '').replace(/ /gi, '');
                                if (cardNumberValue) {
                                    for (const type in CARD_TYPES) {
                                        const cardType = CARD_TYPES[type];
                                        if (typeof cardType.NAME !== 'undefined') {
                                            if (cardType.REGEX_PREFIX.test(cardNumberValue)) {
                                                setCardType(cardType.NAME);
                                                setCardInputMask(cardType.INPUT_MASK);
                                                setCardCVCMask(cardType.CVC_MASK);
                                                setCardCVCPlaceholder(cardType.CVC_PLACEHOLDER);
                                                break;
                                            }
                                        } else {
                                            resetCardType();
                                        }
                                    }
                                } else {
                                    resetCardType();
                                }
                                setFieldValue('stripeCardCVC', '')
                                setFieldValue('stripeCardNumber', value)
                                if (errors['stripeCardNumber']) {
                                    setFieldError('stripeCardNumber', undefined);
                                }
                            }

                            const updateCardOnPaste = (e) => {
                                let cardNumberValue = e.clipboardData.getData('Text');
                                cardNumberValue = cardNumberValue.replace(/\D/g, '');
                                let currentCardNumber = values.stripeCardNumber.replace(/\D/g, '');
                                if (currentCardNumber.length !== cardNumberValue.length) {
                                    e.preventDefault();
                                    for (const type in CARD_TYPES) {
                                        const cardType = CARD_TYPES[type];
                                        if (typeof cardType.NAME !== 'undefined') {
                                            if (cardType.REGEX_PREFIX.test(cardNumberValue)) {
                                                setCardType(cardType.NAME);
                                                setCardInputMask(cardType.INPUT_MASK);
                                                setCardCVCMask(cardType.CVC_MASK);
                                                setCardCVCPlaceholder(cardType.CVC_PLACEHOLDER);
                                                break;
                                            }
                                        }
                                    }
                                    values.stripeCardNumber = cardNumberValue;
                                }
                            }

                            return (
                                <CForm onSubmit={handleSubmit} noValidate>
                                    <CRow>
                                        <CCol md="12">
                                            <CFormGroup>
                                                <CLabel>Name on Card</CLabel>
                                                <CInput
                                                    name='stripeCardName'
                                                    invalid={!!errors.stripeCardName}
                                                    placeholder="Enter full name"
                                                    value={values.stripeCardName ? values.stripeCardName : ''}
                                                    disabled={isSubmitting}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        if (errors['stripeCardName']) {
                                                            setFieldError('stripeCardName', undefined);
                                                        }
                                                    }}
                                                />
                                                <CInvalidFeedback>{errors.stripeCardName}</CInvalidFeedback>
                                            </CFormGroup>
                                        </CCol>
                                        <CCol md="8" className="popup-input-card-number">
                                            <CFormGroup>
                                                <CLabel>Card Number</CLabel>
                                                <div className="input-card-number">
                                                    <div className="logo-card">
                                                        <img className={cardType === CARD_TYPES.MASTERCARD.NAME ? 'card-active' : ''} src="/assets/images/card-master-card.svg" alt="Master Card" />
                                                        <img className={cardType === CARD_TYPES.AMERICAN_EXPRESS.NAME ? 'card-active' : ''} src="/assets/images/card-amex.svg" alt="Amex Card" />
                                                        <img className={cardType === CARD_TYPES.VISA.NAME ? 'card-active' : ''} src="/assets/images/card-visa.svg" alt="Visa Card" />
                                                        <img className={cardType === CARD_TYPES.DISCOVER.NAME ? 'card-active' : ''} src="/assets/images/card-discover.svg" alt="Discover Card" />
                                                    </div>
                                                    <InputMask
                                                        className={`form-control${(!!errors.stripeCardNumber) ? ' is-invalid' : ''}`}
                                                        name='stripeCardNumber'
                                                        placeholder="0000 0000 0000 0000"
                                                        value={values.stripeCardNumber ? values.stripeCardNumber : ''}
                                                        disabled={isSubmitting}
                                                        onChange={handleChangeCardNumber}
                                                        onPaste={updateCardOnPaste}
                                                        mask={cardInputMask}
                                                        maskChar="_"
                                                    />
                                                    <CInvalidFeedback>{errors.stripeCardNumber}</CInvalidFeedback>
                                                </div>
                                            </CFormGroup>
                                        </CCol>
                                        <CCol md="2" className="popup-input-expiration">
                                            <CFormGroup>
                                                <CLabel>Expiration</CLabel>
                                                <InputMask
                                                    className={`form-control${(!!errors.stripeCardExpiration) ? ' is-invalid' : ''}`}
                                                    name='stripeCardExpiration'
                                                    placeholder="MM/YYYY"
                                                    value={values.stripeCardExpiration ? values.stripeCardExpiration : ''}
                                                    disabled={isSubmitting}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        if (errors['stripeCardExpiration']) {
                                                            setFieldError('stripeCardExpiration', undefined);
                                                        }
                                                    }}
                                                    mask="99/2099"
                                                    maskChar="_"
                                                />
                                                <CInvalidFeedback>{errors.stripeCardExpiration}</CInvalidFeedback>
                                            </CFormGroup>
                                        </CCol>
                                        <CCol md="2" className="popup-input-cvc">
                                            <CFormGroup>
                                                <CLabel>CVC</CLabel>
                                                <InputMask
                                                    className={`form-control${(!!errors.stripeCardCVC) ? ' is-invalid' : ''}`}
                                                    name='stripeCardCVC'
                                                    placeholder={cardCVCPlaceholder}
                                                    value={values.stripeCardCVC ? values.stripeCardCVC : ''}
                                                    disabled={isSubmitting}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        if (errors['stripeCardCVC']) {
                                                            setFieldError('stripeCardCVC', undefined);
                                                        }
                                                    }}
                                                    mask={cardCVCMask}
                                                    maskChar="_"
                                                />
                                                <CInvalidFeedback>{errors.stripeCardCVC}</CInvalidFeedback>
                                            </CFormGroup>
                                        </CCol>
                                        <CCol md="12" className="text-right">
                                            <CFormGroup>
                                                <CButton
                                                    className="btn-fake-link"
                                                    onClick={closeModalUpdatePayment}
                                                >
                                                    Cancel Change
                                                </CButton>
                                                <CButton
                                                    className="btn-primary"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? (<span className="dots-waiting">Waiting</span>) : 'Update Payment'}
                                                </CButton>
                                            </CFormGroup>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            )
                        }
                    }
                </Formik>
            </UpdatePaymentPopup>
        </>
    )
}

export default BlockAccount;
