import React, { useState, useEffect, useMemo } from 'react';
import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
// import SplitPane from 'react-split-pane';
import { useDispatch, useSelector } from 'react-redux';
import { guidGenerator, parseEventDataLv, toastError, tryParseJSON } from '../../utils/';
import {
     // API_CLIENT_PREVIEW,
     API_EVENT_PREVIEW,
     API_VERSION_PUBLISH,
     BROWSER_STORAGE_TYPE,
     LOCAL_BEGIN_TEST,
     LOCAL_PREVIEW,
     LOCAL_PREVIEW_DOMAIN,
     RULE_TYPE,
} from '../../constants';
import {
     resetAllPreview,
     setAccountPreview,
     setListFeatureCustomForms,
     setPreviewBrowserRule,
     setPreviewCustomRule,
     setSubscriberState,
     setTogglePreviewPopup,
     setVersionPublish,
} from '../../actions/subscriber';
import { useIsPreviewDomainCSPBlocked, useIsPreviewDomainIframeBlocked, usePrevious } from '../../helpers/customHooks';
import { callTokenApi, callApiPreviewKV } from '../../apiCaller';
import PopupPreview from './preview/PopupPreview';
import PopupBeginTest from './preview/PopupBeginTest';
import ThePreviewFeature from './preview/ThePreviewFeature';
import ThePreviewWebsite from './preview/ThePreviewWebsite';
import { getListenerColorIcon } from '../../helpers/cms/subscriber';
import CenterSpinner from '../../components/general/Loadings/CenterSpinner';
import PublishUpdatesModal from './preview/PublishUpdatesModal';
import PublishVersionModal from './header/PublishVersionModal';
import { setNumberOfHistories, setPublishingVersion } from '../../actions/pusher';
import CreatePreviewBrowserStorageRule from '../../components/cms/subscriber/listener-settings-v2/custom-browser-storage-variables/create-preview-rule/CreatePreviewRule';
import CreatePreviewCustomValueRule from '../../components/cms/subscriber/listener-settings-v2/custom-data-layer-variables/create-preview-rule/CreatePreviewRule';
import get from 'lodash/get';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
// import LLButtonIconPill from '../../ll-ui/atoms/Button/LLButtonIconPill';

const listEventsPostMessage = [
     'acuitySchedulingConfirmation',
     'customFormLoad',
     'customFormSubmitted',
     'customFormFail',
     'acuitySchedulingConfirmation',
];

export const PreviewContext = React.createContext({});

const useFetchSession = (blockIframe) => {
     const dispatch = useDispatch();
     const [isLoading, setIsLoading] = useState(true);
     const [fetchAgain, setFetchAgain] = useState(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { sessionId, domain, listenerId } = useSelector((state) => state.subscriber.preview);
     const prevFetchAgain = usePrevious(fetchAgain);

     const handleFetch = () => {
          if (!sessionId || (typeof prevFetchAgain !== 'undefined' && fetchAgain !== prevFetchAgain)) {
               setIsLoading(true);

               const sesssionValue = `${Math.floor(Math.random() * 10000)}${Date.now()}`;
               const dataSend = {
                    keyValue: sesssionValue,
                    data: {
                         preview: {
                              previewLLSession: sesssionValue,
                              previewLLAccount: activeAccount.id,
                              domain: domain,
                              previewLLListener: listenerId,
                              previewLLType: blockIframe ? '1' : 'iframe',
                         },
                    },
               };

               callApiPreviewKV(dataSend)
                    .then((res) => {
                         dispatch(setAccountPreview({ sessionId: sesssionValue }));
                    })
                    .finally(() => setIsLoading(false));
          } else {
               setIsLoading(false);
          }
     };

     useEffect(handleFetch, [fetchAgain]); // eslint-disable-line react-hooks/exhaustive-deps

     return { isLoading, setFetchAgain };
};

const useCheckRunningPreview = (closePreview) => {
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);

     const checkRunningPreview = () => {
          const theInterval = setInterval(() => {
               const previewLocal = tryParseJSON(localStorage.getItem(LOCAL_PREVIEW));

               if (!previewLocal || previewLocal.accountId !== activeAccountId) {
                    clearInterval(theInterval);
                    closePreview();
               }
          }, 4000);

          return () => clearInterval(theInterval);
     };

     useEffect(checkRunningPreview, []); // eslint-disable-line react-hooks/exhaustive-deps
};

const ThePreview = ({ show, onClose, previewRunning }) => {
     const dispatch = useDispatch();
     const previewUrl = useSelector((state) => state.subscriber.previewUrl);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const versionPublish = useSelector((state) => state.subscriber.versionPublish);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const listFeatureCustomForms = useSelector((state) => state.subscriber.listFeatureCustomForms);
     const showPublishConfirmPopup = useSelector((state) => state.subscriber.showPublishConfirmPopup);
     const showPublishPreviewPopup = useSelector((state) => state.subscriber.showPublishPreviewPopup);
     const { domain, listenerId, sessionId, browserStorageKey, domains } = useSelector((state) => state.subscriber.preview); //eslint-disable-line
     const { showPopupConfirm: showBrowserRulePopupConfirm, showPopup: showBrowserRulePopup } = useSelector(
          (state) => state.subscriber.previewBrowserRule
     );
     const { showPopupConfirm: showCustomRulePopupConfirm, showPopup: showCustomRulePopup } = useSelector(
          (state) => state.subscriber.previewCustomRule
     );

     const [keyIframe, setKeyIframe] = useState(false);
     const [openedWindow, setOpenedWindow] = useState(null);
     const [eventsPreview, setEventsPreview] = useState([]);
     const [destinationsPreview, setDestinationsPreview] = useState([]);
     const [renderingCode, setRenderingCode] = useState(false);
     const [previewListener, setPreviewListener] = useState(null);
     const [selectedDestination, setSelectedDestination] = useState(null);
     const [showPopupTest, setShowPopupTest] = useState(() => {
          const beginTest = localStorage.getItem(LOCAL_BEGIN_TEST);
          return !beginTest || beginTest === 'false';
     });
     const [showPopupEndTest, setShowPopupEndTest] = useState(false);
     const [showPopupResetTest, setShowPopupResetTest] = useState(false);
     const [isSplitPaneDragging, setIsSplitPaneDragging] = useState(false);
     const [storeBrowser, setStoreBrowser] = useState(false);
     const [customValues, setCustomValues] = useState(false);
     const [previewGroupAccordion, setPreviewGroupAccordion] = useState([]);
     const [previewGroupDestinationAccordion, setPreviewGroupDestinationAccordion] = useState([]);
     const [previewEventAccordion, setPreviewEventAccordion] = useState([]);
     const [previewDestinationAccordion, setPreviewDestinationAccordion] = useState([]);
     const [listenerChildShow, setListenerChildShow] = useState([]);
     const [destinationChildShow, setDestinationChildShow] = useState([]);

     const eventPreviewFilter = useMemo(() => {
          return previewListener && previewListener.length > 0
               ? eventsPreview.filter((item) => previewListener.some((option) => option.label === item.listener))
               : eventsPreview;
     }, [eventsPreview, previewListener]);

     const destinationPreviewFilter = useMemo(() => {
          return selectedDestination && selectedDestination.length > 0
               ? destinationsPreview.filter((item) => selectedDestination.some((option) => option.label === item.destinationCode))
               : destinationsPreview;
     }, [destinationsPreview, selectedDestination]);

     const isPreviewDomainIframeBlocked = useIsPreviewDomainIframeBlocked();
     const isPreviewDomainCSPBlocked = useIsPreviewDomainCSPBlocked();
     const { isLoading, setFetchAgain } = useFetchSession(isPreviewDomainIframeBlocked);
     const MyIFrame = document.getElementById('llPreviewTheme');
     const KEYNAME_VALUE = `llEventPreview${domain || localStorage.getItem(LOCAL_PREVIEW_DOMAIN)}${activeAccount.id}`;
     const KEYNAME_DESTINATION_VALUE = `llDestinationPreview${domain || localStorage.getItem(LOCAL_PREVIEW_DOMAIN)}${activeAccount.id}`;

     const handleShowAndHidePreview = () => {
          if (show) {
               setTimeout(() => document.body.classList.add('no-scroll'), 0);
          } else {
               document.body.classList.remove('no-scroll');
          }

          return () => document.body.classList.remove('no-scroll');
     };

     useEffect(handleShowAndHidePreview, [show]);

     useEffect(() => {
          const existedEventsPreview = localStorage.getItem(KEYNAME_VALUE);
          const existedDestinationsPreview = localStorage.getItem(KEYNAME_DESTINATION_VALUE);
          const newEventsPreview = existedEventsPreview ? [...JSON.parse(existedEventsPreview)] : [];
          const newDestinationsPreview = existedDestinationsPreview ? [...JSON.parse(existedDestinationsPreview)] : [];

          setEventsPreview(newEventsPreview);
          setDestinationsPreview(newDestinationsPreview);

          const newSetEvent = (e) => {
               if (
                    e.data.type === 'hsFormCallback' &&
                    (e.data.eventName === 'onFormReady' || e.data.eventName === 'onFormFailedValidation' || e.data.eventName === 'onFormSubmit')
               ) {
                    if (e.source !== 'undefined' && typeof e.source.postMessage !== 'undefined') {
                         e.source.parent.postMessage(e.data, '*');
                    }
               }

               if (e.data === 'readyForConsentListener') {
                    if (e.source !== 'undefined' && typeof e.source.postMessage !== 'undefined') {
                         setTimeout(() => {
                              e.source.parent.postMessage(e.data, '*');
                         }, 500);
                    }
               }

               if ((e.data.type && listEventsPostMessage.includes(e.data.type)) || e.data.eventName === 'pdOnFormReady') {
                    const MyIFrame = document.getElementById('llPreviewTheme');

                    if (typeof MyIFrame.contentWindow.postMessage !== 'undefined') {
                         MyIFrame.contentWindow.postMessage(e.data, '*');
                    }
               }
          };

          window.addEventListener('message', newSetEvent);
          return () => window.removeEventListener('message', newSetEvent);
     }, [keyIframe]); // eslint-disable-line react-hooks/exhaustive-deps

     const processEventPreview = (data) => {
          const dataParse = parseEventDataLv(data, '');

          setEventsPreview((eventsPreview) => {
               const newEventsPreview = [...eventsPreview];
               let fullCode = {};

               Object.entries(data).forEach(([key, value]) => {
                    if (value && typeof value === 'object' && Object.keys(value).length === 0) {
                         // Remove empty object value
                         return;
                    }

                    fullCode[key] = value;
               });

               // Make sure to insert the new event with descending timestamp order
               const eventTime = get(data, 'eventTimestamp.utcTime.time.iso8601');
               let indexToInsert = 0;

               if (eventTime) {
                    eventsPreview.some((el, i) => {
                         const elTimestamp = get(el, 'fullCode.eventTimestamp.utcTime.time.iso8601');
                         if (typeof elTimestamp === 'undefined' || eventTime > elTimestamp) {
                              indexToInsert = i;
                              return true;
                         }

                         return false;
                    });
               }

               const eventToInsert = {
                    event: data.event,
                    listener: data.ListenLayer ? data.ListenLayer.listenerName : '',
                    listenerId: data.ListenLayer ? data.ListenLayer.listenerID : '',
                    body: dataParse,
                    id: guidGenerator(),
                    fullCode,
                    currentLocation: data.currentLocation ? data.currentLocation : data.eventLocation.originPathName,
               };

               if (indexToInsert === 0) {
                    newEventsPreview.unshift(eventToInsert);
               } else {
                    newEventsPreview.splice(indexToInsert, 0, eventToInsert);
               }

               localStorage.setItem(KEYNAME_VALUE, JSON.stringify(newEventsPreview));

               return newEventsPreview;
          });
     };

     const processDestinationPreview = (datas) => {
          setDestinationsPreview((destinationsPreview) => {
               const newDestinationsPreview = [...destinationsPreview];

               if (datas && datas.length > 0) {
                    datas.forEach((item) => {
                         newDestinationsPreview.unshift(item);
                    });

                    localStorage.setItem(KEYNAME_DESTINATION_VALUE, JSON.stringify(newDestinationsPreview));
               }

               return newDestinationsPreview;
          });
     };

     const processUpdateDestinationPreview = (datas) => {
          setDestinationsPreview((destinationsPreview) => {
               const newDestinationsPreview = [...destinationsPreview];

               if (datas && datas.length > 0) {
                    datas.forEach((item) => {
                         newDestinationsPreview.some((destination) => {
                              if (item.id === destination.eventDestinationId) {
                                   destination.response = item.response;
                                   return true;
                              }
                              return false;
                         });
                    });

                    localStorage.setItem(KEYNAME_DESTINATION_VALUE, JSON.stringify(newDestinationsPreview));
               }

               return newDestinationsPreview;
          });
     };

     const handlePusher = () => {
          if (!sessionId) {
               return;
          }

          // Pusher ba607b8450050c872f07
          // process.env.REACT_APP_PUSHER_KEY
          const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
               cluster: process.env.REACT_APP_PUSHER_CLUSTER,
          });
          const channel = pusher.subscribe(`channel-${sessionId}`);

          channel.bind('eventPreviewPusher', function (data) {
               processEventPreview(data);
          });

          channel.bind('eventDestinationPusher', function (data) {
               processDestinationPreview(data);
          });

          channel.bind('updateEventDestinationPusher', function (data) {
               processUpdateDestinationPreview(data);
          });

          // call api get destination out size
          channel.bind('emitDestinationPreview', function (data) {
               if (data && data.id) {
                    callTokenApi(`${API_EVENT_PREVIEW}?id=${data.id}`, 'GET').then((response) => {
                         if (response.status === 200) {
                              const { event } = response.data;

                              processDestinationPreview(JSON.parse(event.body));
                         } else {
                              toastError(response);
                         }
                    });
               }
          });

          channel.bind('emitEventPreview', function (data) {
               if (data && data.id) {
                    callTokenApi(`${API_EVENT_PREVIEW}?id=${data.id}`, 'GET').then((response) => {
                         if (response.status === 200) {
                              const { event } = response.data;

                              processEventPreview(JSON.parse(event.body));
                         } else {
                              toastError(response);
                         }
                    });
               }
          });

          return () => {
               channel.unbind();
          };
     };

     useEffect(handlePusher, [sessionId]); // eslint-disable-line react-hooks/exhaustive-deps

     const listenPreviewInfoMessage = () => {
          // Handle listen to post messages
          const listenerMessageCB = function (e) {
               if (e.data === 'preview-info') {
                    const message = {
                         previewLLSession: sessionId,
                    };
                    e.source.postMessage(JSON.stringify(message), '*');
               }
          };

          window.addEventListener('message', listenerMessageCB);

          return () => window.removeEventListener('message', listenerMessageCB);
     };

     useEffect(listenPreviewInfoMessage, [sessionId, activeAccount.id]);

     useEffect(() => {
          if (listenerId) {
               const newPreviewListener = accountListeners.find((item) => item.id === listenerId);
               if (newPreviewListener) {
                    let iconSrc = getListenerColorIcon(newPreviewListener);
                    setPreviewListener([
                         {
                              value: newPreviewListener.id,
                              label: newPreviewListener.name,
                              iconSrc,
                              name: newPreviewListener.name,
                              type: newPreviewListener.type,
                         },
                    ]);
               }
          }
     }, [listenerId, accountListeners]); // eslint-disable-line react-hooks/exhaustive-deps

     const resetPreview = () => {
          if (MyIFrame && typeof MyIFrame.contentWindow.postMessage !== 'undefined') {
               MyIFrame.contentWindow.postMessage({ type: 'resetPreview', browserStorageKey }, '*');
          }
          if (isPreviewDomainIframeBlocked) {
               if (openedWindow) {
                    openedWindow.close();
               }

               setOpenedWindow(null);
          }

          localStorage.removeItem(KEYNAME_VALUE);
          localStorage.removeItem(KEYNAME_DESTINATION_VALUE);
          setEventsPreview([]);
          dispatch(setAccountPreview({ iframeLoading: true }));
          setTimeout(() => {
               setKeyIframe(!keyIframe);
          }, 500);
          setShowPopupResetTest(false);
          setFetchAgain((prevFetch) => !prevFetch);
          console.clear();
     };

     const closePreview = () => {
          // if (MyIFrame && typeof MyIFrame.contentWindow.postMessage !== 'undefined') {
          //      MyIFrame.contentWindow.postMessage({ type: 'resetPreview', browserStorageKey }, '*');
          // }

          setShowPopupEndTest(false);
          setTimeout(() => {
               setEventsPreview([]);
               setDestinationsPreview([]);
               dispatch(resetAllPreview(activeAccount.id, KEYNAME_DESTINATION_VALUE));
               if (openedWindow) {
                    openedWindow.close();
               }
               if (previewUrl) {
                    const openedWindow = window.open(previewUrl.replace('previewLL=iframe', 'previewLL=1'), 'LL Preview');
                    if (openedWindow) {
                         openedWindow.close();
                    }
               }
               onClose();
          }, 500);
          console.clear();
     };

     const handleTogglePreviewPopup = () => {
          dispatch(setTogglePreviewPopup(false));
     };

     const reloadIframe = () => {
          if (MyIFrame && MyIFrame.contentWindow && typeof MyIFrame.contentWindow.postMessage !== 'undefined') {
               MyIFrame.contentWindow.postMessage({ type: 'reloadPreview' }, '*');
               dispatch(setAccountPreview({ iframeLoading: true }));
               setTimeout(() => {
                    dispatch(setAccountPreview({ iframeLoading: false }));
               }, 5000);
          }
     };
     // eslint-disable-next-line no-unused-vars
     const onDragStarted = () => {
          setIsSplitPaneDragging(true);
     };

     // eslint-disable-next-line no-unused-vars
     const onDragFinished = () => {
          setIsSplitPaneDragging(false);
          setRenderingCode(true);
          setRenderingCode(false);
     };

     useCheckRunningPreview(closePreview);

     const propsOfThePreviewWebsite = {
          sessionId,
          setShowPopupEndTest,
          eventsPreview,
          keyIframe,
          resetIframe: () => setKeyIframe(!keyIframe),
          resetPreview,
          beginTest: !showPopupTest,
          openedWindow,
          setOpenedWindow,
          reloadIframe,
          previewListener,
          isSplitPaneDragging,
     };

     const getStoreInBrowserKeyByAction = (action) => {
          if (action) {
               switch (action) {
                    case BROWSER_STORAGE_TYPE.COOKIE:
                         return 'Cookie';
                    case BROWSER_STORAGE_TYPE.LOCAL_STORAGE:
                         return 'Local Storage Key';
                    case BROWSER_STORAGE_TYPE.SESSION_STORAGE:
                         return 'Session Storage Key';
                    default:
                         break;
               }
          }
          return 'Cookie/Local Storage Key/Session Storage Key';
     };

     const getListenerName = (listenerId) => {
          if (accountListeners) {
               const listener = accountListeners && listenerId && accountListeners.find((listener) => listener.id === listenerId);
               if (listener) {
                    return listener.name;
               }
          }
          return '';
     };

     const handleCreateBrowserStorage = () => {
          if (storeBrowser.listenerId) {
               dispatch(
                    setPreviewBrowserRule({
                         showPopupConfirm: false,
                         showPopup: true,
                         selectedListener: storeBrowser.listenerId,
                         storageType: storeBrowser.action,
                         storageData: storeBrowser.data,
                    })
               );
          }
     };

     // const handleToggleBrowserPopupConfirm = () => {
     //      dispatch(setPreviewBrowserRule({ showPopupConfirm: !showBrowserRulePopupConfirm }));
     // };

     const handleCloseBrowserPopupConfirm = () => {
          dispatch(setPreviewBrowserRule({ showPopupConfirm: false }));
     };

     const handleCreateCustomRule = () => {
          if (customValues.listenerId) {
               dispatch(
                    setPreviewCustomRule({
                         showPopupConfirm: false,
                         showPopup: true,
                         selectedListener: customValues.listenerId,
                         ruleType: RULE_TYPE.SIMPLE_RULE,
                         customValueData: customValues.data,
                         eventCode: customValues.eventCode,
                    })
               );
          }
     };

     // const handleToggleCustomRulePopupConfirm = () => {
     //      dispatch(setPreviewCustomRule({ showPopupConfirm: !showCustomRulePopupConfirm }));
     // };

     const handleCloseCustomRulePopupConfirm = () => {
          dispatch(setPreviewCustomRule({ showPopupConfirm: false }));
     };

     const showPopupCreateBrowserRule = () => {
          if (storeBrowser) {
               dispatch(setPreviewBrowserRule({ showPopupConfirm: true }));
          } else {
               dispatch(setPreviewBrowserRule({ showPopupConfirm: false }));
          }
     };
     useEffect(showPopupCreateBrowserRule, [storeBrowser]); // eslint-disable-line react-hooks/exhaustive-deps

     const showPopupCreateCustomRule = () => {
          if (customValues) {
               dispatch(setPreviewCustomRule({ showPopupConfirm: true }));
          } else {
               dispatch(setPreviewCustomRule({ showPopupConfirm: false }));
          }
     };
     useEffect(showPopupCreateCustomRule, [customValues]); // eslint-disable-line react-hooks/exhaustive-deps

     const previewTogglePublishUpdatesModal = () => {
          dispatch(
               setSubscriberState({
                    showPublishConfirmPopup: false,
                    showPublishPreviewPopup: true,
               })
          );
     };

     const previewCancelPublishUpdatesModal = () => {
          dispatch(setSubscriberState({ showPublishConfirmPopup: false }));
     };

     const onAcceptPublic = (values, { setSubmitting, setValues }) => {
          if (values.name.trim() === '') {
               toast.warn('Name not empty!');
          } else {
               if (activeAccount && versionPublish.version) {
                    values.versionId = versionPublish.version.id;
                    values.accountId = activeAccount.id;
                    callTokenApi(API_VERSION_PUBLISH, 'POST', values)
                         .then((response) => {
                              if (response.status === 200) {
                                   toast.success(response.data.message);
                                   dispatch(setNumberOfHistories(0));
                                   dispatch(setPublishingVersion(response.data.version));
                                   dispatch(
                                        setVersionPublish({
                                             version: '',
                                             isPublish: 'publishing',
                                        })
                                   );
                                   resetPreview();
                                   setTimeout(() => {
                                        reloadIframe();
                                   }, 4000);
                              } else {
                                   toast.error(response.data.message);
                              }
                         })
                         .finally(() => {
                              setValues({
                                   name: '',
                                   description: '',
                              });
                              setSubmitting(false);
                              togglePublishModal();
                         });
               } else {
                    setSubmitting(false);
                    togglePublishModal();
                    dispatch(setNumberOfHistories(0));
                    toast.error('Latest version has already published');
               }
          }
     };

     const togglePublishModal = () => {
          dispatch(setSubscriberState({ showPublishPreviewPopup: !showPublishPreviewPopup }));
     };

     const closePublishModal = () => {
          dispatch(setSubscriberState({ showPublishPreviewPopup: false }));
     };

     const handleReset = () => {
          const listFeatures = listFeatureCustomForms.map((data) => {
               const item = {
                    name: data.name,
                    fired: false,
               };
               return item;
          });
          dispatch(setListFeatureCustomForms(listFeatures));
          setShowPopupResetTest(true);
     };

     const openNewTab = () => {
          const newDomains = [...domains];

          newDomains.some((d) => {
               if (d.value === domain) {
                    d.isIframeBlocked = true;
                    return true;
               }
               return false;
          });

          dispatch(setAccountPreview({ domains: newDomains }));
     };

     return (
          <div className={`preview ${previewRunning && !show ? 'minimize' : ''}`}>
               <div className="modal overflow-auto fade show d-block">
                    <div className="preview_header d-flex align-items-center justify-content-between">
                         <div className="preview_header_logo">
                              <h5 className="d-flex align-items-center">
                                   <CIcon icon="favicon" />
                                   LIVE DATA LAYER TEST
                              </h5>
                         </div>
                         <div className="preview_header_action d-flex align-items-center">
                              <div className="preview_header_action_reset">
                                   <CButton onClick={handleReset} variant="outline" color="dark">
                                        <CIcon icon="iconReload" />
                                        Reset
                                   </CButton>
                              </div>
                              <div className="preview_header_action_minimize">
                                   <CButton onClick={handleTogglePreviewPopup} variant="outline" color="dark">
                                        <CIcon icon="iconShrink" />
                                        Minimize
                                   </CButton>
                              </div>
                              <div className="preview_header_action_close">
                                   <CButton className="rounded-circle" color="danger" onClick={() => setShowPopupEndTest(true)}>
                                        <CIcon icon="cil-x" />
                                   </CButton>
                              </div>
                         </div>
                    </div>
                    {isLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className="preview_main d-flex h-100">
                              {isPreviewDomainIframeBlocked ? (
                                   <ThePreviewFeature
                                        eventsPreview={eventPreviewFilter}
                                        destinationsPreview={destinationPreviewFilter}
                                        previewListener={previewListener}
                                        setPreviewListener={setPreviewListener}
                                        selectedDestination={selectedDestination}
                                        setSelectedDestination={setSelectedDestination}
                                        renderingCode={renderingCode}
                                        openedWindow={openedWindow}
                                        previewWebsiteComponent={<ThePreviewWebsite {...propsOfThePreviewWebsite} />}
                                        setStoreBrowser={setStoreBrowser}
                                        setCustomValues={setCustomValues}
                                        isPreviewDomainIframeBlocked={isPreviewDomainIframeBlocked}
                                        isPreviewDomainCSPBlocked={isPreviewDomainCSPBlocked}
                                        previewGroupAccordion={previewGroupAccordion}
                                        setPreviewGroupAccordion={setPreviewGroupAccordion}
                                        previewGroupDestinationAccordion={previewGroupDestinationAccordion}
                                        setPreviewGroupDestinationAccordion={setPreviewGroupDestinationAccordion}
                                        previewEventAccordion={previewEventAccordion}
                                        setPreviewEventAccordion={setPreviewEventAccordion}
                                        previewDestinationAccordion={previewDestinationAccordion}
                                        setPreviewDestinationAccordion={setPreviewDestinationAccordion}
                                        listenerChildShow={listenerChildShow}
                                        setListenerChildShow={setListenerChildShow}
                                        destinationChildShow={destinationChildShow}
                                        setDestinationChildShow={setDestinationChildShow}
                                   />
                              ) : (
                                   <div style={{ height: '100%', width: '100%' }}>
                                        <Allotment
                                             // defaultSizes={['50%', '50%']}
                                             // minSize={600}
                                             // maxSize={-600}
                                             onDragStarted={onDragStarted}
                                             onDragFinished={onDragFinished}
                                        >
                                             <Allotment.Pane minSize={600}>
                                                  <div>
                                                       <ThePreviewFeature
                                                            eventsPreview={eventPreviewFilter}
                                                            destinationsPreview={destinationPreviewFilter}
                                                            previewListener={previewListener}
                                                            setPreviewListener={setPreviewListener}
                                                            selectedDestination={selectedDestination}
                                                            setSelectedDestination={setSelectedDestination}
                                                            renderingCode={renderingCode}
                                                            setStoreBrowser={setStoreBrowser}
                                                            setCustomValues={setCustomValues}
                                                            previewGroupAccordion={previewGroupAccordion}
                                                            setPreviewGroupAccordion={setPreviewGroupAccordion}
                                                            previewGroupDestinationAccordion={previewGroupDestinationAccordion}
                                                            setPreviewGroupDestinationAccordion={setPreviewGroupDestinationAccordion}
                                                            previewEventAccordion={previewEventAccordion}
                                                            setPreviewEventAccordion={setPreviewEventAccordion}
                                                            previewDestinationAccordion={previewDestinationAccordion}
                                                            setPreviewDestinationAccordion={setPreviewDestinationAccordion}
                                                            listenerChildShow={listenerChildShow}
                                                            setListenerChildShow={setListenerChildShow}
                                                            destinationChildShow={destinationChildShow}
                                                            setDestinationChildShow={setDestinationChildShow}
                                                       />
                                                  </div>
                                             </Allotment.Pane>
                                             <Allotment.Pane minSize={600}>
                                                  <div className="preview_main_content preview_main_website">
                                                       <div className="preview_main_content_header preview_main_website_header d-flex align-items-center justify-content-center">
                                                            <h4>Your Live Website</h4>
                                                            <div className="preview_main_tooltip">
                                                                 <CIcon icon="icon-info" className="hover-show-tooltip" height={14}></CIcon>
                                                                 <div className="block-tooltip">
                                                                      <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                                                      <div className="block-tooltip-text">
                                                                           <p>
                                                                                Interact with your website to test Listeners generate data layer
                                                                                activity.
                                                                           </p>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <div className="open-new-tab">
                                                                 <div className=" preview_main_tooltip">
                                                                      <div
                                                                           className="d-flex align-items-center justify-content-end hover-show-tooltip"
                                                                           onClick={() => openNewTab()}
                                                                      >
                                                                           Open new tab
                                                                           <CIcon
                                                                                icon="cil-external-link"
                                                                                className="ml-2 arrow-preview"
                                                                                height={14}
                                                                           ></CIcon>
                                                                      </div>
                                                                      <div className="block-tooltip">
                                                                           <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                                                           <div className="block-tooltip-text">
                                                                                <p>Test your Website in a Separate Tab.</p>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div className="preview_main_content_body preview_main_website_body">
                                                            <ThePreviewWebsite {...propsOfThePreviewWebsite} />
                                                       </div>
                                                  </div>
                                             </Allotment.Pane>
                                        </Allotment>
                                   </div>
                              )}
                         </div>
                    )}
               </div>
               {show && <PopupBeginTest show={showPopupTest} onClose={() => setShowPopupTest(false)} closePreview={closePreview} />}
               <PopupPreview
                    show={showPopupEndTest}
                    onClose={() => setShowPopupEndTest(false)}
                    onAccept={closePreview}
                    acceptBtnText="Yes, end my test"
                    goBackBtnText="No, go back"
               >
                    <h3>Are You Sure?</h3>
                    <p className="description">This will end your active test session.</p>
               </PopupPreview>
               <PopupPreview
                    show={showPopupResetTest}
                    onClose={() => setShowPopupResetTest(false)}
                    onAccept={resetPreview}
                    acceptBtnText="Reset Test"
                    goBackBtnText="Cancel, go back"
               >
                    <h3>You're Session Will Reset</h3>
                    <p className="description">
                         You'll be treated as a brand new user with no history in your browser so you can test without any hostorical influence.
                    </p>
               </PopupPreview>
               {storeBrowser && (
                    <PopupPreview show={showBrowserRulePopupConfirm} onClose={handleCloseBrowserPopupConfirm} onAccept={handleCreateBrowserStorage}>
                         <h3>Create Browser Storage</h3>
                         <div className="description">
                              <p>
                                   You can write rules to store data from your data layer in the browser. This allows the data to persist across page
                                   loads and sessions
                              </p>
                              <p>
                                   You're chosen to store <strong>{storeBrowser.data.path}</strong> in a{' '}
                                   <strong>{getStoreInBrowserKeyByAction(storeBrowser.action)}</strong>. This will apply only to events from the{' '}
                                   <strong>{getListenerName(storeBrowser.listenerId)}</strong> listener
                              </p>
                              <p>
                                   We'll walk you through the rule setup. Your rule will be saved under{' '}
                                   <strong>{`Data > Custom Data > Browser Storage`}</strong> so you can edit and manage it easily.
                              </p>
                         </div>
                    </PopupPreview>
               )}
               {customValues && (
                    <PopupPreview show={showCustomRulePopupConfirm} onClose={handleCloseCustomRulePopupConfirm} onAccept={handleCreateCustomRule}>
                         <h3>Create Custom Data</h3>
                         <div className="description">
                              <p>
                                   You can write rules to push custom data into the data layer when rule conditions are matched. The values you push
                                   into the data layer can be constants, other variables, browser storage, and even formulas.
                              </p>
                              <p>
                                   You've chosen to write a custom data rule for the <strong>{getListenerName(customValues.listenerId)}</strong>{' '}
                                   Listener.
                              </p>
                              <p>
                                   We'll walk you through the rule setup. Your rule will be saved under{' '}
                                   <strong>{`Data > Custom Data > Data Layer`}</strong> so you can edit and manage it easily.
                              </p>
                         </div>
                    </PopupPreview>
               )}
               {showBrowserRulePopup && <CreatePreviewBrowserStorageRule />}
               {showCustomRulePopup && <CreatePreviewCustomValueRule />}
               <PublishUpdatesModal
                    show={showPublishConfirmPopup}
                    onAccept={previewTogglePublishUpdatesModal}
                    onCancel={previewCancelPublishUpdatesModal}
                    acceptBtnDisabled={!versionPublish.version}
               />
               <PublishVersionModal show={showPublishPreviewPopup} onAccept={onAcceptPublic} onCancel={closePublishModal} />
          </div>
     );
};

export default ThePreview;
