import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { CButton, CCard } from '@coreui/react';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import FormInventoryTableRow from './FormInventoryTableRow';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { API_CLIENT_UPDATE_FORM_CATEGORIES } from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import { toastError, useOutsideHandling } from '../../../../../../utils';
import {
     setFormRequiringAction,
     handleFetchCountFormsInventory,
     setFormIgnored,
     handleFetchCountFormsRequiring,
     handleFetchCountFormsIgnored,
     setSubscriberState,
     setShowIgnoredInventoryForm,
     setFormInherit,
     setFormRedirect,
     setFormIgnoreEdit,
     setAllFormsIgnore,
     handleApplyChildForms,
     handleApplyRedirectForms,
     handleUpdateChildFormsIgnored,
     setAllFormsInventory,
     setAllFormsRequiring,
     setFormInventory,
     setFilterFormCategories,
     setFilterEditFormCategories,
} from '../../../../../../actions/subscriber';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import MultiForms from './MultiFormsInventory';
import { ClickInfoIconTooltip } from '../../../../../general/custom-tooltips';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../../migration/CInput';

const useDraftForms = ({ formIgnored }) => {
     const dispatch = useDispatch();
     const formIgnoreEdit = useSelector((state) => state.subscriber.formIgnoreEdit);

     // Push draft forms to formIgnoreEdit to save changes
     useEffect(() => {
          if (formIgnored) {
               const newFormIgnoreEdit = [...formIgnoreEdit];

               formIgnored.forEach((item) => {
                    if (item.issue === 'isDrafted' && formIgnoreEdit.every((el) => el.id !== item.id)) {
                         newFormIgnoreEdit.push(item);
                    }
               });

               if (newFormIgnoreEdit.length !== formIgnoreEdit.length) {
                    dispatch(setSubscriberState({ formIgnoreEdit: newFormIgnoreEdit }));
               }
          }
     }, [formIgnored]); // eslint-disable-line
};

const FormInventoryIgnoredLayout = ({ className = '', tableClassName = '', formIgnored, onLoadMore, onClickShowAll }) => {
     const dispatch = useDispatch();
     const ignoredCountLoading = useSelector((state) => state.subscriber.ignoredCountLoading);
     const inventoryCountLoading = useSelector((state) => state.subscriber.inventoryCountLoading);
     const formIgnoreEdit = useSelector((state) => state.subscriber.formIgnoreEdit);
     const countFormInventory = useSelector((state) => state.subscriber.countFormInventory);
     const countFormIgnored = useSelector((state) => state.subscriber.countFormIgnored);
     const allFormsIgnored = useSelector((state) => state.subscriber.allFormsIgnored);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const ignoredShowPaging = useSelector((state) => state.subscriber.ignoredShowPaging);
     const ignoredFormsLoading = useSelector((state) => state.subscriber.ignoredFormsLoading);
     const ignoredNumberPerpage = useSelector((state) => state.subscriber.ignoredNumberPerpage);
     const ignoredDisableNextPage = useSelector((state) => state.subscriber.ignoredDisableNextPage);
     const isLoading = useSelector((state) => state.subscriber.ignoredLoading);
     const showIgnoredInventoryForm = useSelector((state) => state.subscriber.showIgnoredInventoryForm);
     const applyChild = useSelector((state) => state.subscriber.applyChild);
     const applyRedirect = useSelector((state) => state.subscriber.applyRedirect);
     const formCategoriesRules = useSelector((state) => state.subscriber.formCategoriesRules);
     const [submitLoading, setSubmitLoading] = useState(false);
     const [listAccordionShow, setListAccordionShow] = useState();
     const [tooltipTimestamps, setTooltipTimestamps] = useState(false);
     const [updateModal, setUpdateModal] = useState(false); // false: not show
     const [errors, setErrors] = useState([]);
     const [tooltipSettings, setTooltipSettings] = useState(false);
     const statusRefSettings = useRef(null);
     const itemsPerPage = 12;
     useDraftForms({ formIgnored });

     const rulesDefault = formCategoriesRules ? formCategoriesRules.filter((rule) => rule.name === 'Categorize Forms') : [];

     const statusRefTimestamps = useRef(null);
     let formsEdit = formIgnored && formIgnored.every((el) => el.issue !== 'isDrafted'); // Enable save changes button if has at least 1 draft form

     // If save changes button disabled, then check has any changes
     if (formsEdit) {
          formsEdit =
               formIgnoreEdit.length > 0
                    ? formIgnoreEdit.every((item) => {
                           if (item.issue === 'isDrafted') {
                                return false;
                           }

                           const initForm = allFormsIgnored && allFormsIgnored.find((form) => form.id === item.id);

                           if (initForm)
                                return (
                                     item.categoryId === initForm.categoryId &&
                                     item.inheritParent === initForm.inheritParent &&
                                     item.isRedirect === initForm.isRedirect &&
                                     item.name === initForm.name
                                );
                           return false;
                      })
                    : true;
     }

     const treeFamilyOfForms = formIgnored
          ? formIgnored.map((form) => {
                 const formExisted = formIgnoreEdit.find((item) => item.id === form.id);

                 if (formExisted) {
                      if (!formExisted.hasChild && formExisted.name && formExisted.categoryId) {
                           formExisted.issue = 'isCategorized';
                      }
                      return { ...form, ...formExisted };
                 }
                 return form;
            })
          : [];

     const clickOutsideTimestamps = useCallback(() => {
          setTooltipTimestamps(false);
          setTooltipSettings(false);
     }, []);
     useOutsideHandling(statusRefTimestamps, clickOutsideTimestamps);
     useOutsideHandling(statusRefSettings, clickOutsideTimestamps);

     const checkValidate = () => {
          let newErrors = [];
          const newForms = formIgnoreEdit && formIgnoreEdit.length > 0 ? cloneDeep(formIgnoreEdit) : cloneDeep(formIgnored);

          for (let index = 0; index < newForms.length; index++) {
               if (newForms[index].categoryId && !newForms[index].name) {
                    newErrors = [...newErrors, { id: newForms[index].id, invalidName: true }];
               } else if (newForms[index].name && !newForms[index].categoryId) {
                    newErrors = [...newErrors, { id: newForms[index].id, invalidCategory: true }];
               } else {
                    newErrors = [...newErrors];
               }
          }

          if (newErrors.length > 0) {
               setErrors(newErrors);
               toast.error('Some fields are in error!');
          } else {
               setUpdateModal(true);
          }
     };

     const onSubmit = () => {
          setSubmitLoading(true);

          for (let i = 0; i < formIgnoreEdit.length; i++) {
               if (formIgnoreEdit[i].name && formIgnoreEdit[i].categoryId) {
                    formIgnoreEdit[i].issue = 'isCategorized';
               }
               if (formIgnoreEdit[i].identifiedDate && formIgnoreEdit[i].name && formIgnoreEdit[i].categoryId) {
                    formIgnoreEdit[i].identifiedDate = null;
               }
          }

          if (formIgnoreEdit && formIgnoreEdit.length > 0) {
               formIgnoreEdit.map((item) => {
                    if (item.name) {
                         item.name = item.name.trim();
                    }
                    return item;
               });

               const newDataRequest = {
                    accountId: activeAccount.id,
                    data: formIgnoreEdit,
                    type: 'ignored',
               };
               callTokenApi(API_CLIENT_UPDATE_FORM_CATEGORIES, 'PUT', newDataRequest)
                    .then((response) => {
                         if (response.status === 200) {
                              const { countChildIgnored } = response.data;

                              let newAllFormIgnored = [];

                              formIgnoreEdit.forEach((item) => {
                                   const existed =
                                        countChildIgnored && countChildIgnored.find((form) => form.id === item.id || form.id === item.parentId);
                                   if (existed) {
                                        item.count = existed.count || 0;
                                   }
                              });

                              newAllFormIgnored = allFormsIgnored.map((form) => {
                                   for (let i = 0; i < formIgnoreEdit.length; i++) {
                                        if (form.id === formIgnoreEdit[i].id) {
                                             form.name = formIgnoreEdit[i].name;
                                             form.categoryId = formIgnoreEdit[i].categoryId;
                                             form.isRedirect = formIgnoreEdit[i].isRedirect;
                                             form.issue = formIgnoreEdit[i].issue;
                                        }
                                   }
                                   return form;
                              });
                              newAllFormIgnored = newAllFormIgnored.filter(
                                   (item) =>
                                        !formIgnoreEdit.some(
                                             (f) =>
                                                  (f.id === item.id && f.hasChild && f.name && f.categoryId && f.count === 0) ||
                                                  (f.id === item.id && !f.hasChild && !f.parentId && f.name && f.categoryId) ||
                                                  (f.id === item.parentId && !f.hasChild && f.parentId && f.name && f.categoryId && f.count === 0),
                                        ),
                              );

                              if (ignoredNumberPerpage === 0) {
                                   dispatch(setFormIgnored(newAllFormIgnored.slice(0, itemsPerPage)));
                              }
                              dispatch(setAllFormsIgnore(newAllFormIgnored));

                              dispatch(handleUpdateChildFormsIgnored());
                              // reset form requiring
                              dispatch(setFormRequiringAction(null));
                              dispatch(setAllFormsRequiring(null));
                              // reset form inventory
                              dispatch(setFormInventory(null));
                              dispatch(setAllFormsInventory(null));

                              dispatch(setFormIgnoreEdit(null));

                              dispatch(handleFetchCountFormsRequiring());
                              dispatch(handleFetchCountFormsIgnored(null, activeAccount));
                              dispatch(handleFetchCountFormsInventory(null, activeAccount));
                              //reset filters
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(null));

                              if (rulesDefault && rulesDefault.length === 0) {
                                   dispatch(setSubscriberState({ formCategoriesRules: null }));
                              }
                              // toast.success('Form updated!');
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         setUpdateModal(false);
                         setSubmitLoading(false);
                    });
          }
     };

     const handleClickBubbleTimestamps = () => {
          setTimeout(() => {
               if (!tooltipTimestamps) {
                    setTooltipTimestamps(true);
               }
          }, 100);
     };

     const handleShowIgnoredForm = () => {
          dispatch(setShowIgnoredInventoryForm(!showIgnoredInventoryForm));
     };

     const handleApplyChild = () => {
          dispatch(setFormInherit(!applyChild));

          dispatch(handleApplyChildForms(!applyChild, 'ignored'));
     };

     const handleApplyRedirect = () => {
          dispatch(setFormRedirect(!applyRedirect));

          dispatch(handleApplyRedirectForms(!applyRedirect, 'ignored'));
     };

     const tabOverlay = className === 'inventory' && treeFamilyOfForms.length >= itemsPerPage && countFormIgnored > itemsPerPage;

     return (
          <>
               <div className="title-form-action form-inventory-header pb-2">
                    <p className="mb-0">
                         We’ve found and are Listening to {inventoryCountLoading ? '...' : countFormInventory}{' '}
                         {+countFormInventory === 1 ? ' form' : ' forms'} on your website. Make sure all of them are categorized below based on the
                         purpose of the forms.
                    </p>
                    <div className="form-action-settings mr-0">
                         <div className="checkbox-header checkbox-ignored-form mr-0">
                              <CFormGroup variant="custom-checkbox" inline>
                                   <CInputCheckbox
                                        custom
                                        id={'ignoredForm'}
                                        name={'ignoredForm'}
                                        checked={!!showIgnoredInventoryForm}
                                        onChange={handleShowIgnoredForm}
                                   />
                                   <CLabel variant="custom-checkbox" htmlFor={'ignoredForm'}>
                                        Show only ignored forms
                                   </CLabel>
                              </CFormGroup>
                         </div>

                         <div className="checkbox-header checkbox-settings">
                              {/* <CIcon icon='cil-settings' cursor='pointer' width={30} onClick={handleClickBubbleSettings} /> */}
                              {tooltipSettings && (
                                   <CCard className="block-tooltip reporting-values-tooltip" ref={statusRefSettings}>
                                        <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                        <div className="block-tooltip-text">
                                             <div className="checkbox-header checkbox-category">
                                                  <CFormGroup variant="custom-checkbox" inline>
                                                       <CInputCheckbox
                                                            custom
                                                            id={'requiring-action'}
                                                            name={'requiring-action'}
                                                            checked={!!applyChild}
                                                            onChange={() => handleApplyChild()}
                                                       />
                                                       <CLabel variant="custom-checkbox" htmlFor={'requiring-action'}>
                                                            Inherit from parent.
                                                       </CLabel>
                                                       <div className="tooltip-wrapper">
                                                            <ClickInfoIconTooltip>
                                                                 Changes you make in the interface are accumulated and must be published to go live on
                                                                 your website.
                                                            </ClickInfoIconTooltip>
                                                       </div>
                                                  </CFormGroup>
                                             </div>
                                             <div className="checkbox-header checkbox-redirect">
                                                  <CFormGroup variant="custom-checkbox" inline>
                                                       <CInputCheckbox
                                                            custom
                                                            id={'applyRedirect'}
                                                            name={'applyRedirect'}
                                                            checked={!!applyRedirect}
                                                            onChange={() => handleApplyRedirect()}
                                                       />
                                                       <CLabel variant="custom-checkbox" htmlFor={'applyRedirect'}>
                                                            Push events after redirect.
                                                       </CLabel>
                                                  </CFormGroup>
                                             </div>
                                        </div>
                                   </CCard>
                              )}
                         </div>
                    </div>
               </div>
               <div
                    className={`table-layout table-inventory ${tableClassName} ${
                         (formIgnored && formIgnored.length === 0) || isLoading ? 'no-form-found' : ''
                    }`}
               >
                    <div className="css-table-wrap">
                         <div className={`css-table ${tabOverlay && !isLoading ? 'table-overlay' : ''}`}>
                              {className === 'viewAllInventory' && ignoredFormsLoading && (
                                   <div className="table-forms-loading-overlay">
                                        <CenterSpinner />
                                   </div>
                              )}
                              <div className="css-table-header">
                                   <div className="listener-name">Listener Name</div>
                                   <div className="form-id">Form ID</div>
                                   <div className="form-location">Form Location</div>
                                   <div className="take-action">Category</div>
                                   <div className="form-name">Form Name</div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div className="redirects">
                                        <span>Redirects</span>
                                        {((allFormsIgnored && allFormsIgnored.length > 0) || !isLoading) && (
                                             <div className="icon-tooltip-block">
                                                  <CIcon icon="icon-info" className="ml-2" cursor="pointer" onClick={handleClickBubbleTimestamps} />
                                                  {tooltipTimestamps && (
                                                       <CCard className="block-tooltip reporting-values-tooltip redirect" ref={statusRefTimestamps}>
                                                            <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                                            <div className="block-tooltip-text">
                                                                 <p>
                                                                      Turn this on for any form that redirects to a thank you page. This will improve
                                                                      the accuracy of your tracking.
                                                                 </p>
                                                            </div>
                                                       </CCard>
                                                  )}
                                             </div>
                                        )}
                                   </div>
                              </div>

                              {!isLoading ? (
                                   treeFamilyOfForms.length > 0 ? (
                                        <>
                                             {treeFamilyOfForms.map((item, index) => {
                                                  const hasChild = item.hasChild;

                                                  return (
                                                       <React.Fragment key={`inventory-${item.id}`}>
                                                            {hasChild ? (
                                                                 <MultiForms
                                                                      className={className}
                                                                      index={index}
                                                                      formsData={item}
                                                                      listAccordionShow={listAccordionShow}
                                                                      setListAccordionShow={setListAccordionShow}
                                                                      errors={errors}
                                                                      setErrors={setErrors}
                                                                 />
                                                            ) : (
                                                                 <FormInventoryTableRow
                                                                      className={className}
                                                                      data={item}
                                                                      redirect={true}
                                                                      index={index}
                                                                      errors={errors}
                                                                      setErrors={setErrors}
                                                                 />
                                                            )}
                                                       </React.Fragment>
                                                  );
                                             })}
                                        </>
                                   ) : (
                                        <div className="table-body-row">
                                             <p>No form found!</p>
                                        </div>
                                   )
                              ) : (
                                   <CenterSpinner />
                              )}
                         </div>
                    </div>

                    {!isLoading && formIgnored && (
                         <>
                              {className === 'viewAllInventory' && treeFamilyOfForms.length > 0 && ignoredShowPaging && (
                                   <div className="text-center forms-paging">
                                        <CButton
                                             className="btn-paging-forms btn-prev"
                                             disabled={isLoading || ignoredFormsLoading || ignoredNumberPerpage < 1}
                                             onClick={() => onLoadMore(ignoredNumberPerpage - 1)}
                                        >
                                             {'< Prev'}
                                        </CButton>
                                        <CButton
                                             className="btn-paging-forms btn-next"
                                             disabled={isLoading || ignoredFormsLoading || ignoredDisableNextPage}
                                             onClick={() => onLoadMore(ignoredNumberPerpage + 1)}
                                        >
                                             {'Next >'}
                                        </CButton>
                                   </div>
                              )}

                              {tabOverlay && (
                                   <CButton className="btn-show-all" onClick={onClickShowAll}>
                                        See all {ignoredCountLoading ? '...' : countFormIgnored} forms
                                        <CIcon className="icon-arrow-bar" icon="iconChevronRight" width={6} height={7} />
                                   </CButton>
                              )}

                              {treeFamilyOfForms.length > 0 && (
                                   <div className="save-changes-button-wrapper">
                                        <CButton color="primary" disabled={submitLoading || formsEdit} onClick={checkValidate}>
                                             {submitLoading ? <span className="dots-waiting">Waiting</span> : 'Save Changes'}
                                        </CButton>
                                   </div>
                              )}
                         </>
                    )}
               </div>

               <ConfirmSaveChange
                    show={updateModal}
                    onClose={() => setUpdateModal(false)}
                    isLoading={submitLoading}
                    onAccept={() => onSubmit()}
                    title="Are you sure you want to save?"
               ></ConfirmSaveChange>
          </>
     );
};

export default FormInventoryIgnoredLayout;
